/*----- Imports -----*/
import React, { useState, useEffect } from 'react'
import { connect }                    from 'react-redux'

/*----- Actions -----*/
import { createDepartment, setNewDepartmentFields } from './DepartmentsActions'

/*----- Selectors -----*/
import { loadingSelector, newDepartmentFieldsSelector } from './selectors/CreateDepartmentSelector'
import { selectedUsersSelector }                        from './selectors/SelectedUsersSelector'

/*----- Antd -----*/
import { Form } from 'antd'

/*----- Fields -----*/
import { initialState } from './reducers/CreateDepartmentReducer'

/*----- Views -----*/
import MainView from './views/new/MainView'

const hasErrors = (fieldsError) => {
  return Object.keys(fieldsError).some(field => fieldsError[field])
}

const NewDepartment = ({ form, createDepartment, isLoading, setNewDepartmentFields, selectedUsers } ) => {
  const [showModal, setShowModal] = useState(false)

  // Clean redux after leave component render.
  useEffect(() => {
    return () => {
      setNewDepartmentFields(initialState.fields)
    }
  }, [setNewDepartmentFields])

  const handleSubmit = (event) => {
    event.preventDefault()
    form.validateFields({ force: true })

    if (!hasErrors(form.getFieldsError())) {
      let fields = form.getFieldsValue()
      createDepartment({ ...fields, users: selectedUsers })
    }
  }

  return (
    <MainView
      form          = { form }
      submitHandler = { handleSubmit }
      isLoading     = { isLoading }
      selectedUsers = { selectedUsers }
      showModal     = { showModal }
      setShowModal  = { setShowModal }
    />
  )
}

function mapStateToProps(state) {
  return {
    isLoading:     loadingSelector(state),
    formFields:    newDepartmentFieldsSelector(state),
    selectedUsers: selectedUsersSelector(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    createDepartment:       (params) => createDepartment(dispatch, params),
    setNewDepartmentFields: (params) => setNewDepartmentFields(dispatch, params)
  }
}

const ConnectedNewDepartment = connect(
  mapStateToProps,mapDispatchToProps
)(
  Form.create(
    {
      name: 'new_department_form',
      onFieldsChange({ setNewDepartmentFields }, changedFields) {
        setNewDepartmentFields(changedFields);
      },
      mapPropsToFields({ formFields }) {
        return {
          name: Form.createFormField({
            ...formFields.name,
            value: formFields.name.value
          })
        }
      }
    }
  )(NewDepartment)
)

ConnectedNewDepartment.defaultProps = {
  permission:   'create',
  model:        'Department',
  defaultRoute: '/admin'
}

export default ConnectedNewDepartment