/*----- Actions -----*/
import {
  EDIT_SUBJECT,
  EDIT_SUBJECT_ERROR,
  EDIT_SUBJECT_SUCCESS,
  SET_SUBJECT_EDITION_FIELDS,
  TOGGLE_EDITION_MODAL,
  TOGGLE_EDIT_DEPARTMENTS_MODAL
} from '../SubjectsActions'
  
/*----- Utils -----*/
import { serviceErrorsToFormFields } from '../../../../utils/ResponseFormatter' 

export const initialState = {
  isLoading:               false,
  modalVisible:            false,
  departmentsModalVisible: false,
  success:                 '',
  error: {
    message: '',
    fields:  []
  },
  fields: {
    name:        {},
    departments: [],
    code:        {}
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case EDIT_SUBJECT:
      return {
        ...state,
        isLoading: true
      }
    case EDIT_SUBJECT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success:   action.payload,
        fields:    initialState.fields
      }
    case EDIT_SUBJECT_ERROR:
      return {
        ...state,
        isLoading: false,
        error:     action.payload.error,
        fields:    serviceErrorsToFormFields(state.fields, action.payload.error.fields)
      }
    case SET_SUBJECT_EDITION_FIELDS:
      return {
        ...state,
        fields: {
          ...state.fields,
          ...action.payload
        }
      }
    case TOGGLE_EDITION_MODAL:
      return {
        ...state,
        modalVisible: action.payload
      }
    case TOGGLE_EDIT_DEPARTMENTS_MODAL:
      return {
        ...state,
        departmentsModalVisible: action.payload
      }
    default:
      return state
  }
}