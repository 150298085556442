/*----- Actions -----*/
import { DELETE_SUBJECT, DELETE_SUBJECT_SUCCESS, DELETE_SUBJECT_ERROR } from '../SubjectsActions'
  
export const initialState = {
  isLoading: false,
  success:   '',
  error:     ''
}
  
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case DELETE_SUBJECT:
      return {
        ...state,
        isLoading: true
      }
    case DELETE_SUBJECT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success:   action.payload
      }
    case DELETE_SUBJECT_ERROR:
      return {
        ...state,
        isLoading: false,
        error:     action.payload.error
      }
    default:
      return state
  }
}