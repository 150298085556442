import { DELETE_USER, DELETE_USER_SUCCESS, DELETE_USER_ERROR } from "../UserActions"

// UserInfo va a ser un {} luego
const initialState = {
  isLoading: false,
  success:   {},
  error:     null
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case DELETE_USER:
      return {
        ...state,
        isLoading: true
      }
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: action.payload
      }
    case DELETE_USER_ERROR:
      return {
        ...state,
        isLoading: false,
        error:     action.payload
      }
    default:
      return state;
  }
}