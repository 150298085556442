/*----- API -----*/
import BASE_URL from '../BaseUrl'
import {
  SUBJECT_RESOURCE,
  COMMISSION_RESOURCE,
  USER_RESOURCE,
  SURVEY_RESOURCE
} from '../Resources'

/*----- Utils -----*/
import { objToQueryString } from '../../utils/ParameterSanitizer'

const HEADERS = {
  'Content-Type': 'application/json'
}

export function* getGraphicableCommissions(action) {
  const params = objToQueryString(action.payload.body)

  return yield fetch(
    `${BASE_URL}/${SUBJECT_RESOURCE}/${action.payload.subjectId}/${COMMISSION_RESOURCE}/list_graphicable/?${params}`,
    {
      method:  'GET',
      headers: HEADERS
    }
  )
}

export function* getSubjects(action) {
  const params = objToQueryString(action.payload)

  return yield fetch(
    `${BASE_URL}/${SUBJECT_RESOURCE}/list_graphicable/?${params}`,
    {
      method:  'GET',
      headers: HEADERS
    }
  )
}

export function* getUsersList(action) {
  const params = objToQueryString(action.payload)

  return yield fetch(
    `${BASE_URL}/${USER_RESOURCE}/?${params}`,
    {
      method:  'GET',
      headers: HEADERS
    }
  )
}

export function* getSubjectGraphics(action) {
  return yield fetch(
    `${BASE_URL}/${SURVEY_RESOURCE}/graphic_subjects/`,
    {
      method:  'POST',
      body:    JSON.stringify(action.payload.body),
      headers: HEADERS
    }
  )
}

export function* getTeacherGraphics(action) {
  return yield fetch(
    `${BASE_URL}/${SURVEY_RESOURCE}/graphic_teachers/`,
    {
      method:  'POST',
      body:    JSON.stringify(action.payload.body),
      headers: HEADERS
    }
  )
}
