/*----- Imports -----*/
import React, { useEffect } from 'react'
import { connect }          from 'react-redux'
import { withRouter }       from 'react-router'

/*----- Actions -----*/
import { 
  getSubjectDetails,
  setSubjectEditionFields, 
  editSubject,
  toggleEditionModal,
  getSubjectDepartments,
  toggleEditDepartmentsModal,
  deleteSubjectDepartment
} from './SubjectsActions'

import { getSubjectCommissions } from '../commissions/CommissionActions'

/*----- Selectors -----*/
import { subjectInfoSelector, loadingSubjectSelector }            from './selectors/SubjectDetailsSelector'
import { subjectCommissionsSelector, loadingCommissionsSelector } from '../commissions/selectors/GetSubjectCommissionsSelector'
import { subjectDepartmentsSelector }                             from './selectors/SubjectDepartmentsSelector'
import { 
  editSubjectFieldsSelector, 
  editionModalVisibilitySelector,
  editDepartmentsModalVisibilitySelector 
} from './selectors/EditSubjectSelector'

/*----- Views -----*/
import MainView from './views/details/MainView'

/*----- Antd -----*/
import { Form } from 'antd'

const CLEAN_STATE_FIELDS = { name: {}, code: {}, departments: [] }

const hasErrors = (fieldsError) => {
  return Object.keys(fieldsError).some(field => fieldsError[field])
}

const SubjectDetails = (
  { 
    isLoadingCommissions, getSubjectDetails, subjectInfo, match, getSubjectCommissions, 
    commissions, setSubjectEditionFields, form, formFields, toggleEditionModal, 
    modalVisible, editSubject, otherDepartments, getSubjectDepartments, isLoadingDepartments,
    toggleEditDepartmentsModal, editDepartmentsModalVisible, deleteSubjectDepartment
  } 
) => {

  const subjectId = match.params.id

  useEffect(() => {
    getSubjectCommissions(subjectId)
  }, [getSubjectCommissions, subjectId])

  useEffect(() => {
    getSubjectDetails(subjectId)
  }, [getSubjectDetails, subjectId])

  useEffect(() => {
    getSubjectDepartments(subjectId)
  }, [getSubjectDepartments, subjectId])

  useEffect(
    () => {
      let result = {}

      for (let [key, value] of Object.entries(subjectInfo)) {
        if (key !== 'departments'){
          result[key] = { value: value }
        }
      }
      
      setSubjectEditionFields(result)

      return () => {
        setSubjectEditionFields(CLEAN_STATE_FIELDS)
      }
    },
    [subjectInfo, setSubjectEditionFields]
  )

  const editionSubmitHandler = (event) => {
    event.preventDefault()

    form.validateFields({ force: true })
    if (!hasErrors(form.getFieldsError())) {
      editSubject(subjectId, form.getFieldsValue())
    }
  }

  const setSelectedDepartments = (departments) => {
    setSubjectEditionFields({ departments: departments })
  }

  const handleUpdateDepartments = () => {
    editSubject(subjectId, { departments: formFields.departments })
  } 

  return (
    <MainView
      subjectInfo                 = { subjectInfo }
      isLoadingDepartments        = { isLoadingDepartments }
      isLoadingCommissions        = { isLoadingCommissions }
      commissions                 = { commissions }
      otherDepartments            = { otherDepartments }
      editionSubmitHandler        = { editionSubmitHandler }
      toggleEditionModal          = { toggleEditionModal }
      formFields                  = { formFields }
      modalVisible                = { modalVisible }
      form                        = { form }
      selectedDepartments         = { formFields.departments }
      setSelectedDepartments      = { setSelectedDepartments }
      handleUpdateDepartments     = { handleUpdateDepartments}
      getSubjectDepartments       = { getSubjectDepartments }
      subjectId                   = { subjectId }
      toggleEditDepartmentsModal  = { toggleEditDepartmentsModal }
      editDepartmentsModalVisible = { editDepartmentsModalVisible }
      deleteSubjectDepartment     = { deleteSubjectDepartment }
    />
  )
}

function mapStateToProps(state) {
  return {
    isLoadingDepartments:        loadingSubjectSelector(state),
    isLoadingCommissions:        loadingCommissionsSelector(state),
    subjectInfo:                 subjectInfoSelector(state),
    commissions:                 subjectCommissionsSelector(state),
    otherDepartments:            subjectDepartmentsSelector(state),
    formFields:                  editSubjectFieldsSelector(state),
    modalVisible:                editionModalVisibilitySelector(state),
    editDepartmentsModalVisible: editDepartmentsModalVisibilitySelector(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getSubjectDetails:                    (subjectId) => getSubjectDetails(dispatch, subjectId),
    getSubjectCommissions:                (subjectId) => getSubjectCommissions(dispatch, subjectId),
    getSubjectDepartments:        (subjectId, params) => getSubjectDepartments(dispatch, subjectId, params),
    setSubjectEditionFields:                 (params) => setSubjectEditionFields(dispatch, params),
    toggleEditionModal:                     (visible) => toggleEditionModal(dispatch, visible),
    editSubject:                  (subjectId, params) => editSubject(dispatch, subjectId, params),
    toggleEditDepartmentsModal:             (visible) => toggleEditDepartmentsModal(dispatch, visible),
    deleteSubjectDepartment: (subjectId, departments) => deleteSubjectDepartment(dispatch, subjectId, departments)
  }
}

const ConnectedDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(
  Form.create(
    {
      name: 'edit_subject_form',
      onFieldsChange({ setSubjectEditionFields }, changedFields) {
        setSubjectEditionFields(changedFields)
      },
      mapPropsToFields({ formFields }) {
        return {
          name: Form.createFormField({
            ...formFields.name,
            value: formFields.name.value
          }),
          code: Form.createFormField({
            ...formFields.code,
            value: formFields.code.value
          })
        }
      }
    }
  )(SubjectDetails)
))

ConnectedDetailsContainer.defaultProps = {
  permission:   'show',
  model:        'Subject',
  defaultRoute: '/admin'
}

export default ConnectedDetailsContainer
