import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  body {
    padding-top: 0;
  }

  #header {
    background-color: transparent;
    font-size: 1.25em;
    padding: 1em 2em;
    position: absolute;
  }

  #header > h1 {
    color: inherit;
    position: static;
    float: left;
  }

  #header a[href="#nav"] {
    margin-right: 0;
  }

  @media screen and (max-width: 980px) {
    #header {
      font-size: 1em;
    }
  }

  @media screen and (max-width: 480px) {
    #header {
      padding: 0.5em 1em;
    }
  }
`
