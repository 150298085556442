/*----- Imports -----*/
import React    from 'react'
import { Link } from 'react-router-dom'

/*----- Antd -----*/
import { Icon } from 'antd'

/*----- Components -----*/
import ActionButton from '../../../../../components/commons/button/ActionButton'

const columns = (t) => (
  [
    {
      title:          t('commission.name'),
      key:            'name',
      dataIndex:      'name',
      sorter:         (a,b) => a.name.localeCompare(b.name),
      sortDirections: ['ascend','descend']
    },
    {
      title:  t('global.actions'),
      key:    'actions',
      render: (_text, record) => (
        <span>
          {
            record.is_active &&
              <>
                <ActionButton>
                  <Link to = { `/admin/commissions/${record.id}` } >
                    <Icon type = 'eye'/>
                  </Link>
                </ActionButton>
              </>
          }
        </span>
      )
    }
  ]
)

export default columns
