/*----- Imports -----*/
import React              from 'react'
import { useTranslation } from 'react-i18next'
import styled             from 'styled-components'
import PropTypes          from 'prop-types'

/*----- Components -----*/
import SearchInput from '../../../../../components/commons/input/SearchInput'

/*----- Constants -----*/
import { PAGE_SIZE } from '../../../../../utils/Constants'

/*----- Antd -----*/
import { Table } from 'antd'

const StyledSearchInput = styled(SearchInput)`
  padding-top:   2%;
  margin-bottom: 5%;
`

const MainView = ( { getUsers, users, isLoading, onChangeHander, selectedUsers, columns } ) => {
  const { t } = useTranslation()

  const rowSelection = {
    selectedRowKeys: selectedUsers,
    onChange:        onChangeHander,
  }

  return(
    <>
      <StyledSearchInput
        placeholder     = { t('global.search_placeholder') }
        onSearchHandler = { (value) => { getUsers(value) } }
        allowClear      = { true }
      />

      <Table
        rowKey        = { (user) => user.id }
        dataSource    = { users }
        columns       = { columns }
        bordered size = 'medium'
        loading       = { isLoading }
        pagination    = {{ pageSize: parseInt(PAGE_SIZE) }}
        scroll        = {{ x: '400px'}}
        rowSelection  = { rowSelection }
      />
    </>
  )
}

export default MainView

/*----- PropTypes -----*/
MainView.propTypes = {
  users:          PropTypes.array.isRequired,
  isLoading:      PropTypes.bool.isRequired,
  getUsers:       PropTypes.func.isRequired,
  onChangeHander: PropTypes.func.isRequired,
  selectedUsers:  PropTypes.array.isRequired,
  columns:        PropTypes.array.isRequired,
}
