/*----- Imports -----*/
import React              from 'react'
import PropTypes          from 'prop-types'
import { useTranslation } from 'react-i18next'

/*----- Antd -----*/
import { Form, Input, Icon } from 'antd'

const { Item } = Form

const EditionForm = ({ form }) => {
  const { t }                 = useTranslation()
  const { getFieldDecorator } = form

  const fieldOptions = {
    rules: [
      {
        required: true,
        message:  t('rules.mandatory_field'),
      }
    ],
    validateTrigger: ['onSubmit', 'onBlur']
  }

  return(
    <Form>
      <Item label = { t('subjects.subject') } >
        {
          getFieldDecorator('name', fieldOptions)(
            <Input
              prefix = {
                <Icon type = 'book' style = {{ color: 'rgba(0, 0, 0, .25)' }} />
              }
            />
          )
        }
      </Item>
      
      <Item label = { t('subjects.code') } >
        {
          getFieldDecorator('code', fieldOptions)(
            <Input
              prefix = {
                <Icon type = 'barcode' style = {{ color: 'rgba(0, 0, 0, .25)' }} />
              }
            />
          )
        }
      </Item>
    </Form>
  )
}

export default EditionForm

/*----- PropTypes -----*/
EditionForm.propTypes = {
  form: PropTypes.object.isRequired
}