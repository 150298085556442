/*----- Imports -----*/
import React from 'react'

/*----- Antd -----*/
import { Descriptions } from 'antd'

const { Item } = Descriptions

export default (question, t) => {

  let type

  if (question.resourcetype === 'open') {
    type = t('question.open_question')
  } else if (question.resourcetype === 'multiplechoice') {
    type = t('question.choice')
  } else if (question.resourcetype === 'ternarychoice') {
    type = t('question.ternary_choice')
  }

  return(
    <Descriptions 
      bordered 
      style = {{ marginTop: '3%' }} 
      key   = { question.id }
    >
      <Item label = { t('question.title') } span = { 3 }>
        { `${question.question}` }
      </Item>

      <Item label = { t('question.type') }> { type } </Item>

      {
        question.max_value &&
        <Item label = { t('question.max_value') }>
          { `${question.max_value}` }
        </Item>
      }
    </Descriptions>
  )
}