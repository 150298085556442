/*----- Imports -----*/
import React              from 'react'
import styled             from 'styled-components'
import PropTypes          from 'prop-types'
import { useTranslation } from 'react-i18next'

/*----- Antd -----*/
import { Form, Checkbox, Button, Select } from 'antd'

/*----- Components -----*/
import FormBox       from '../../../../../components/commons/form/FormBox'
import YearPicker    from '../../../../../components/commons/form/YearPicker'
import PeriodsSelect from '../../../../../components/commons/form/PeriodsSelect'

/*----- Utils -----*/
import mapRecordsToOptions from '../../../../../utils/SelectOptionsMapper' 

const { Item } = Form

const StyledExportSurveyForm = styled.section`
  padding-top:    5%;
  padding-bottom: 10%;
  .form-box__data-container {
    width: 60%;
  }
`

const MainView = (
  { 
    form, isLoading, departments, subjects, commissions, surveys, handleSubmit
  }
) => {
  const { t }                 = useTranslation()
  const { getFieldDecorator } = form

  const surveyOptions      = mapRecordsToOptions(surveys)
  const departmentOptions  = mapRecordsToOptions(departments)
  const subjectsOptions    = mapRecordsToOptions(subjects)
  const commissionsOptions = mapRecordsToOptions(commissions)

  const filter = (input, option) => 
    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0

  const fieldOptions = {
    rules: [{
      required: true,
      message:  t('rules.mandatory_field')
    }],
    validateTrigger: ['onSubmit', 'onBlur']
  }

  const checkboxOptions = {
    valuePropName: 'checked'
  }

  return (
    <StyledExportSurveyForm>
      <FormBox title = { t('survey.export_survey_template') } >
        <Form onSubmit  = { handleSubmit } >

          <Item 
            label = { t('survey.title') }
          >
            {
              getFieldDecorator('survey_id', fieldOptions)(
                <Select 
                  placeholder  = { t('survey.title') }
                  disabled     = { isLoading }
                  showSearch   = { true }
                  allowClear   = { true }
                  filterOption = {
                    filter
                  }                   
                >
                  { surveyOptions }
                </Select>
              )
            }
          </Item>

          <Item 
            label = { t('admin.menubar.departments') }
          >
            {
              getFieldDecorator('departments', fieldOptions)(
                <Select 
                  mode         = 'multiple'
                  placeholder  = { t('admin.menubar.departments') }
                  disabled     = { isLoading }
                  showSearch   = { true }
                  allowClear   = { true }
                  filterOption = {
                    filter
                  }                   
                >
                  { departmentOptions }
                </Select>
              )
            }
          </Item>

          <Item 
            label = { t('admin.menubar.subjects') }
          >
            {
              getFieldDecorator('subjects')(
                <Select 
                  mode         = 'multiple'
                  placeholder  = { t('admin.menubar.subjects') }
                  disabled     = { isLoading }
                  showSearch   = { true }
                  allowClear   = { true }
                  filterOption = {
                    filter
                  }                   
                >
                  { subjectsOptions }
                </Select>
              )
            }
          </Item>

          <Item 
            label = { t('admin.menubar.commissions') }
          >
            {
              getFieldDecorator('commissions')(
                <Select 
                  mode         = 'multiple'
                  placeholder  = { t('admin.menubar.commissions') }
                  disabled     = { isLoading }
                  showSearch   = { true }
                  allowClear   = { true }
                  filterOption = {
                    filter
                  }                   
                >
                  { commissionsOptions }
                </Select>
              )
            }
          </Item>

          <Item>
            {
              getFieldDecorator('with_teachers', checkboxOptions)(
                <Checkbox>
                  { t('survey.with_teachers') }
                </Checkbox>
              )
            }
          </Item>

          <Item
            label = { t('survey.period') }
          >
            {
              getFieldDecorator('period', fieldOptions)(
                <PeriodsSelect isLoading = { isLoading } />
              )
            }
          </Item>
          
          <Item
            label = { t('survey.year') }
          >
            {
              getFieldDecorator('year', fieldOptions)(
                <YearPicker />
              )
            }
          </Item>

          <Button
            type     = 'primary'
            htmlType = 'submit'
            style    = {{ width: '100%' }}
          >
            { t('global.submit') }
          </Button>

        </Form>
      </FormBox>
    </StyledExportSurveyForm>
  )
}

export default MainView

/*----- PropTypes -----*/
MainView.propTypes = {
  form:         PropTypes.object.isRequired,
  isLoading:    PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  departments:  PropTypes.array.isRequired
}
