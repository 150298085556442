/*----- Saga -----*/
import { call, takeLatest, put } from 'redux-saga/effects'

/*----- Actions -----*/
import { 
  GET_QUESTION_CATEGORIES,
  GET_QUESTION_CATEGORIES_ERROR,
  GET_QUESTION_CATEGORIES_SUCCESS
} from '../SurveysActions'

/*----- Endpoint -----*/
import { getQuestionCategories } from '../../../../api/endpoints/Surveys'

/*----- Notifications -----*/
import notifyError from '../../../../components/commons/notifications/ErrorNotification'

export function* shootGetQuestionCategories(action) {
  try {
    const response = yield call(getQuestionCategories, action)
    const body     = yield response.json()
    let type

    if (response.status >= 200 && response.status < 300) {
      type = GET_QUESTION_CATEGORIES_SUCCESS
    } else {
      type = GET_QUESTION_CATEGORIES_ERROR
    }

    yield put(
      {
        type:    type,
        payload: body
      }
    )
  }
  catch (error) {
    yield notifyError('¡Ha ocurrido un error inesperado!')
    yield put(
      {
        type:    GET_QUESTION_CATEGORIES_ERROR,
        payload: error.message
      }
    )
  }
}

export default function*() {
  yield takeLatest(GET_QUESTION_CATEGORIES, shootGetQuestionCategories)
}
