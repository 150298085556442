/*----- Imports -----*/
import React             from 'react'
import { Route, Switch } from 'react-router'

/*----- Routers -----*/
import AdminRouter      from './AdminRouter'
import withPublicLayout from '../layouts/PublicLayout'

/*----- Components -----*/
import LoginForm       from '../modules/public/sessions/LoginForm'
import RestorePassword from '../modules/public/restore_password/RestorePassword'
import NotFoundError   from '../modules/commons/errors/NotFoundError'
import Home            from '../modules/public/home/Home'
import Subject         from '../modules/public/subjects/Subject'
import Teacher         from '../modules/public/teachers/Teacher'

/*----- Decorators ------*/
import GuestRoute   from '../decorators/GuestRouteDecorator'
import PrivateRoute from '../decorators/PrivateRouteDecorator'

const MainRouter = () => (
  <Switch>
    {/* Only if is not autenticated.*/}
    <GuestRoute exact path = '/login'                component = { withPublicLayout(LoginForm) } />
    <GuestRoute exact path = '/recuperar-contraseña' component = { withPublicLayout(RestorePassword) } />

    {/* Public routes. You can always visit this pages. */}
    <Route exact path = '/'                  component = { withPublicLayout(Home) } />
    <Route exact path = '/graphics/subjects' component = { withPublicLayout(Subject) } />
    <Route exact path = '/graphics/teachers' component = { withPublicLayout(Teacher) } />

    {/* Private routes. You must be authenticated. */}
    <PrivateRoute exact path = '*admin*' component = { AdminRouter } />   

    {/* Default route. Path does not match existing routes, so we render a 404 page. */}
    <Route component = { withPublicLayout(NotFoundError) } />
  </Switch>
)

export default MainRouter