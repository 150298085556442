import { all }               from 'redux-saga/effects'
import getSubjects           from './GetSubjects'
import getUsers              from './GetUsers'
import getSubjectCommissions from './GetSubjectCommissions'
import getSubjectGraphics    from './GetSubjectGraphics'
import getTeacherGraphics    from './GetTeacherGraphics'

export default function* dispatchSagas() {
  yield all([
    getSubjects(),
    getUsers(),
    getSubjectCommissions(),
    getSubjectGraphics(),
    getTeacherGraphics()
  ])
}
