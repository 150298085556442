/*----- Saga -----*/
import { call, takeLatest, put } from 'redux-saga/effects'

/*----- Notifications -----*/
import notifyError         from '../../../../components/commons/notifications/ErrorNotification'
import successNotification from '../../../../components/commons/notifications/SuccessNotification'
import loadingNotification from '../../../../components/commons/notifications/LoadingNotification'

/*----- Actions -----*/
import {
  PUT_CHANGE_PASSWORD,
  PUT_CHANGE_PASSWORD_SUCCESS,
  PUT_CHANGE_PASSWORD_ERROR
} from '../UserActions'
import { LOGOUT } from '../../../public/sessions/LogoutActions'

/*----- Endpoints -----*/
import { putChangePassword } from '../../../../api/endpoints/Users'

export function* shootPutChangePassword(action) {
  let userId = action.payload.userId
  yield loadingNotification()
  try {
    const response = yield call(putChangePassword, userId, action)
    let body       = {}
    let type

    if (response.status >= 200 && response.status < 300) {
      type = PUT_CHANGE_PASSWORD_SUCCESS
      yield successNotification()
      yield put({
        type: LOGOUT
      })
    }
    else {
      type = PUT_CHANGE_PASSWORD_ERROR
      body = yield response.json()

      yield notifyError(body.error.message)
    }
    
    yield put(
      {
        type:    type,
        payload: body
      }
    )
  }
  catch (error) {
    yield notifyError('¡Ha ocurrido un error inesperado!')
    yield put(
      {
        type:    PUT_CHANGE_PASSWORD_ERROR,
        payload: error.message
      }
    )
  }
}

export default function* putUserEditionWatcher() {
  yield takeLatest(PUT_CHANGE_PASSWORD, shootPutChangePassword)
}
