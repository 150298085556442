/*----- Imports -----*/
import React              from 'react'
import styled             from 'styled-components'
import PropTypes          from 'prop-types'
import { useTranslation } from 'react-i18next'
import { isMobile }       from 'react-device-detect'

/*----- Antd -----*/
import { Form, Input, Icon, Button, Card } from 'antd'

/*----- Components -----*/
import Question from '../new/Question'

const { Item } = Form

const CenteredContainer = styled.div`
  text-align: center;
  display:    block;
`

const StyledContainer = styled.div`
  width:          80%;
  margin-left:    10%;
  margin-top:     5%;
  padding-bottom: 5%;
`

const QuestionCard = styled(Card)`
  margin-bottom: 3%!important;
`

const StyledSpan = styled.span`
  display: ${isMobile ? 'none!important' : 'inherit'}
`

const StyledIcon = styled(Icon)`
  color: ${isMobile ? '#ffffff' : 'rgba(0, 0, 0, .25)!important'}
`

const MainView = ({ 
  form, isLoading, categories, handleSubmit, keys, addQuestionFields,
  deleteQuestionFields
}) => {

  const { t } = useTranslation()

  const { getFieldDecorator } = form

  const AddFieldsButton = <Button
    type    = 'primary'
    onClick = { addQuestionFields }
  >
    <StyledIcon type = 'plus' />
    <StyledSpan>
      { `${t('global.add')} ${t('question.title').toLowerCase()}` }
    </StyledSpan>
  </Button>

  const requiredField = {
    rules: [{
      required: true,
      message:  t('rules.mandatory_field')
    }]
  }

  return (
    <StyledContainer>
      <Card title = { `${t('global.create')} ${t('survey.title').toLowerCase()}` } extra = { AddFieldsButton }>

        <Form
          onSubmit = { handleSubmit }
          layout   = { 'horizontal' }
        >
          <Item label = { t('survey.new_survey_title') }>
            {
              getFieldDecorator('name', requiredField)(
                <Input placeholder = { t('survey.new_survey_title') } />
              )
            }
          </Item>
          
          {
            keys.map((element, index) => (
              <QuestionCard
                key     = { index }
                title   = { `${t('question.title')} ${index + 1}` }
                type    = 'inner'
                loading = { isLoading }
                extra   = {
                  <Button
                    disabled = { keys.length <= 1 }
                    onClick  = { () => deleteQuestionFields(element) }
                    style    = {{ border: 'none', background: 'none'}}
                  >
                    <Icon
                      type    = 'delete'
                      style   = {{ color: 'red' }}
                    />
                  </Button>
                }
              >

                <Question 
                  form          = { form }
                  categories    = { categories }
                  index         = { element }
                  requiredField = { requiredField }
                />

              </QuestionCard>
            ))
          }

          <CenteredContainer>
            <Button
              type     = 'primary'
              htmlType = 'submit'
              style    = {{ justifyContent: 'center' }}
              size     = 'large'
              disabled = { !keys.length }
            >
              { t('global.submit') }
            </Button>
          </CenteredContainer>

        </Form>

      </Card>
    </StyledContainer>
  )
}

export default MainView

/*----- PropTypes -----*/
MainView.propTypes = {
  form:                 PropTypes.object.isRequired,
  isLoading:            PropTypes.bool.isRequired,
  handleSubmit:         PropTypes.func.isRequired,
  categories:           PropTypes.array.isRequired,
  keys:                 PropTypes.array.isRequired,
  addQuestionFields:    PropTypes.func.isRequired,
  deleteQuestionFields: PropTypes.func.isRequired
}
