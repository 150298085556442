/*----- Imports -----*/
import React     from 'react'
import styled    from 'styled-components'
import PropTypes from 'prop-types'

/*----- Antd -----*/
import { Typography } from 'antd'

/*----- Components -----*/
const { Title } = Typography;

/*----- Styles-----*/
const StyledFormBox = styled.article`
	display: flex;
	justify-content: center;
	position: relative;
	.form-box__data-container {
		background-color: white;
		padding: 20px;
		border-radius: 6px;
		box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.1);
	}
`

/*----- Export -----*/
const FormBox = ({ children, title }) => (
  <StyledFormBox className="form-box">
    <div className="form-box__data-container">
      <Title level={3}>{title}</Title>
      {children}
    </div>
  </StyledFormBox>
)
export default FormBox

/*----- PropTypes -----*/
FormBox.propTypes = {
  title:   PropTypes.string.isRequired,
  childre: PropTypes.node
}
