/*----- Imports -----*/
import { call, takeLatest, put } from 'redux-saga/effects'
import { push }                  from 'connected-react-router'

/*----- Endpoints -----*/
import { logout } from '../../../../api/endpoints/Sessions'

/*----- Actions -----*/
import { LOGOUT, LOGOUT_SUCCESS, LOGOUT_FAIL } from '../LogoutActions'

/*----- Notifications -----*/
import notifyError from '../../../../components/commons/notifications/ErrorNotification'

export function* shootLogoutUser() {
  
  try {
    const response       = yield call(logout)

    if (response.status >= 200 && response.status < 300) {
      yield put(
        {
          type: LOGOUT_SUCCESS
        }
      )
      yield put(push('/login'))
    }
    else {
      const logout_data = yield response.json()
      yield notifyError(logout_data.error.message)
      yield put(
        {
          type:    LOGOUT_FAIL,
          payload: logout_data
        }
      )
    }
  }

  catch (error) {
    yield put(
      {
        type:    LOGOUT_FAIL,
        payload: error.message
      }
    )
  }
}

export default function* createRouteWatcher() {
  yield takeLatest(LOGOUT, shootLogoutUser)
}
