/*----- Imports -----*/
import React              from 'react'
import PropTypes          from 'prop-types'
import { useTranslation } from 'react-i18next'

/*----- Form -----*/
import Form from '../../forms/TeachingStaff'

/*----- Teaching staff -----*/
import Table   from '../../teaching_staff/Table'
import columns from '../../teaching_staff/Columns'

const TeachingStaffTab = (
  {
    role, form, isLoading, submitTeachingStaffData, teachingStaff, addTeachingStaffFields,
    keys, usersList, deleteTeachingStaffField, deleteCommissionTeachingStaff, commission
  }
) => {
  const { t } = useTranslation()

  return(
    <>
      <Table
        data      = { teachingStaff }
        isLoading = { isLoading }
        columns   = { columns(t, role, deleteCommissionTeachingStaff, commission) }
      />

      <Form
        form                     = { form }
        role                     = { role }
        keys                     = { keys }
        isLoading                = { isLoading }
        submitHandler            = { submitTeachingStaffData }
        addTeachingStaffFields   = { addTeachingStaffFields }
        deleteTeachingStaffField = { deleteTeachingStaffField }
        usersList                = { usersList }
      />
    </>
  )
}

export default TeachingStaffTab

TeachingStaffTab.propTypes = {
  form:                          PropTypes.object.isRequired,
  role:                          PropTypes.object.isRequired,
  isLoading:                     PropTypes.bool.isRequired,
  submitTeachingStaffData:       PropTypes.func.isRequired,
  teachingStaff:                 PropTypes.array.isRequired,
  addTeachingStaffFields:        PropTypes.func.isRequired,
  keys:                          PropTypes.array.isRequired,
  usersList:                     PropTypes.array.isRequired,
  deleteTeachingStaffField:      PropTypes.func.isRequired,
  deleteCommissionTeachingStaff: PropTypes.func.isRequired,
  commission:                    PropTypes.object.isRequired
}

