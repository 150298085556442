/*----- Actions -----*/
import { GET_QUESTION_CATEGORIES, GET_QUESTION_CATEGORIES_SUCCESS, GET_QUESTION_CATEGORIES_ERROR } from '../SurveysActions'

const initialState = {
  isLoading:  false,
  categories: [],
  error:      null
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_QUESTION_CATEGORIES:
      return {
        ...state,
        isLoading: true
      }
    case GET_QUESTION_CATEGORIES_SUCCESS:
      return {
        ...state,
        isLoading:  false,
        categories: action.payload
      }
    case GET_QUESTION_CATEGORIES_ERROR:
      return {
        ...state,
        isLoading: false,
        error:     action.payload
      }
    default:
      return state
  }
}