/*----- Imports -----*/
import { put, take } from 'redux-saga/effects'

/*----- Services -----*/
import { REFRESH, REFRESH_SUCCESS, REFRESH_FAIL } from '../modules/public/sessions/RefreshActions'

const refreshableDecorator = (fetch_function) => {
  return function* (...args) {
    const response = yield fetch_function(...args)
    if (response.status !== 401) {
      return response
    }

    yield put({ type: REFRESH })
    const action = yield take([REFRESH_SUCCESS, REFRESH_FAIL])

    if( action.type === REFRESH_SUCCESS ){
      return yield fetch_function(...args)
    }

    return response
  }
}

export default refreshableDecorator
