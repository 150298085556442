/*----- Imports -----*/
import React, { useEffect } from 'react'
import { connect }          from 'react-redux'

/*----- Views -----*/
import MainView from './views/import/MainView'

/*----- Actions -----*/
import { importSurveyResults, getSurveys, setImportSurveyFields } from './SurveysActions'

/*---- Antd -----*/
import { Form } from 'antd'

/*----- Selectors -----*/
import { surveysListSelector }        from './selectors/GetSurveysSelector'
import { importSurveyFieldsSelector } from './selectors/ImportSurveySelector'

const hasErrors = (fieldsError) => ( Object.keys(fieldsError).some(field => fieldsError[field]))

const ImportContainer = ( { importSurveyResults, getSurveys, surveys, form, setImportSurveyFields, formFields } ) => {

  useEffect(() => {
    getSurveys()
  }, [getSurveys])

  const handleSubmit = (event) => {
    event.preventDefault()
    form.validateFields({ force: true })

    if (!hasErrors(form.getFieldsError())) {
      let fields = form.getFieldsValue()
      importSurveyResults(fields)
    }
  }

  return(
    <MainView
      handleSubmit = { handleSubmit }
      surveys      = { surveys }
      form         = { form }
    />
  )

}

const mapStateToProps = (state) => (
  {
    surveys:    surveysListSelector(state),
    formFields: importSurveyFieldsSelector(state),
  }
)

const mapDispatchToProps = (dispatch) => (
  {
    importSurveyResults:     (file) => importSurveyResults(dispatch, file),
    getSurveys:                  () => getSurveys(dispatch),
    setImportSurveyFields: (fields) => setImportSurveyFields(dispatch, fields)
  }
)

const ConnectedImportSurvey = connect(mapStateToProps, mapDispatchToProps)(
  Form.create(
    {
      name: 'import_survey_form',
      onFieldsChange({ setImportSurveyFields }, changedFields) {
        if (changedFields.survey_file) {
          changedFields = {
            ...changedFields,
            survey_file: {
              ...changedFields.survey_file,
              errors: null
            }
          }
        }

        setImportSurveyFields(changedFields)
      },
      mapPropsToFields({ formFields }) {
        return {
          survey_id:   Form.createFormField(formFields.survey_id),
          survey_file: Form.createFormField(formFields.survey_file)
        }
      }
    }
)(ImportContainer))

ConnectedImportSurvey.defaultProps = {
  permission:   'create',
  model:        'Import',
  defaultRoute: '/admin'
}

export default ConnectedImportSurvey
