/*----- Imports -----*/
import React from 'react'

/*----- Antd -----*/
import { Select } from 'antd'

const { Option } = Select

export default (records) => (
  records.map((record) => (
    <Option key = { record.id } style = {{ whiteSpace: 'initial', overflow: 'hidden' }}>
      { record.name }
    </Option>
  ))
)

export const usersSelect = (records) => (
  records.map((record) => (
    <Option key = { record.id } >
      {`${record.first_name} ${record.last_name}`}
    </Option>
  ))
)
