/*----- Imports -----*/
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes          from 'prop-types'
import styled             from 'styled-components'

/*----- Antd -----*/
import { Form, Input, Select, InputNumber } from 'antd'

/*----- Utils -----*/
import mapRecordsToOptions from '../../../../../utils/SelectOptionsMapper'

const { Item }     = Form
const { Option }   = Select
const { TextArea } = Input

const StyledInputNumber = styled(InputNumber)`
  width: 150px!important;
`

const Question = ({ form, categories, index, requiredField }) => {
  const { t } = useTranslation()

  const { getFieldDecorator } = form

  const mappedCategories = useMemo(() => (
    categories.map(category => ({id: category.name, name: category.name}))
  ), [categories])

  const categoriesOptions = mapRecordsToOptions(mappedCategories)

  const customQuestionRules = {
    rules: [{
      required: true,
      pattern:  /(\w+,)+/i,
      message:  t('rules.custom_question')
    }]
  }

  return(
    <>
      <Item label = { t('question.title') }>
        {
          getFieldDecorator(`questions[${index}].question`, requiredField)(
            <TextArea 
              placeholder = { t('question.title') }
            />
          )
        }
      </Item>

      <Item label = { t('question.category') }>
        {
          getFieldDecorator(`questions[${index}].category`, requiredField)(
            <Select
              allowClear
              placeholder  = { t('question.category') }
              showSearch   = { true }
              filterOption = {
                (input, option) => 
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              { categoriesOptions }
            </Select>
          )
        }
      </Item>

      <Item label = { t('question.type') }>
        {
          getFieldDecorator(`questions[${index}].resourcetype`, requiredField)(
            <Select
              allowClear
              placeholder = { t('question.type') }
            >
              <Option value = 'multiplechoice' >
                { t('question.choice') }
              </Option>

              <Option value = 'open' >
                { t('question.open_question') }
              </Option>

              <Option value = 'ternarychoice' >
                { t('question.ternary_choice') }
              </Option>

              <Option value = 'textchoice' >
                { t('question.custom_question') }
              </Option>
            </Select>
          )
        }
      </Item>

      {
        (form.getFieldValue(`questions[${index}].resourcetype`) === 'multiplechoice') &&
          <Item label = { t('question.max_value') } >
            {
              getFieldDecorator(`questions[${index}].max_value`, requiredField)(
                <StyledInputNumber
                  placeholder = { t('question.max_value') }
                />
              )
            }
          </Item> 
      }

      {
        (form.getFieldValue(`questions[${index}].resourcetype`) === 'textchoice') &&
          <Item label = { t('question.custom_question_info') } >
            {
              getFieldDecorator(`questions[${index}].choices`, customQuestionRules)(
                <Input
                  placeholder = { t('global.options') }
                />
              )
            }
          </Item> 
      }

    </>
  )
}

export default Question

Question.propTypes = {
  form:          PropTypes.object.isRequired,
  categories:    PropTypes.array.isRequired,
  index:         PropTypes.number.isRequired,
  requiredField: PropTypes.object.isRequired
}