/*----- Actions -----*/
import {
  EDIT_DEPARTMENT,
  EDIT_DEPARTMENT_ERROR,
  EDIT_DEPARTMENT_SUCCESS,
  SET_DEPARTMENT_EDITION_FIELDS,
  TOGGLE_EDITION_MODAL,
  TOGGLE_EDIT_USERS_MODAL
} from '../DepartmentsActions'

/*----- Utils -----*/
import { serviceErrorsToFormFields } from '../../../../utils/ResponseFormatter' 

export const initialState = {
  isLoading:         false,
  modalVisible:      false,
  usersModalVisible: false,
  success:           '',
  error:             {
    message: '',
    fields:  []
  },
  fields:            {
    name:  {},
    users: []
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case EDIT_DEPARTMENT:
      return {
        ...initialState,
        isLoading: true,
      }
    case EDIT_DEPARTMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success:   action.payload,
        fields:    initialState.fields
      }
    case EDIT_DEPARTMENT_ERROR:
      return {
        ...state,
        isLoading: false,
        error:     action.payload.error,
        fields:    serviceErrorsToFormFields(state.fields, action.payload.error.fields)
      }
    case SET_DEPARTMENT_EDITION_FIELDS:
      return {
        ...state,
        fields: {
          ...state.fields,
          ...action.payload
        }
      }
    case TOGGLE_EDITION_MODAL:
      return {
        ...state,
        modalVisible: action.payload
      }
    case TOGGLE_EDIT_USERS_MODAL:
      return {
        ...state,
        usersModalVisible: action.payload
      }
    default:
      return state
  }
}