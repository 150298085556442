/*----- Actions -----*/
import { GET_SURVEYS, GET_SURVEYS_SUCCESS, GET_SURVEYS_ERROR } from '../SurveysActions'

const initialState = {
  isLoading: false,
  surveys:   [],
  error:     null
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_SURVEYS:
      return {
        ...state,
        isLoading: true
      }
    case GET_SURVEYS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        surveys:  action.payload
      }
    case GET_SURVEYS_ERROR:
      return {
        ...state,
        isLoading: false,
        error:     action.payload
      }
    default:
      return state
  }
}
