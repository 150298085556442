/*----- Core -----*/
import { createStore, applyMiddleware, combineReducers, compose } from 'redux'
import { connectRouter, routerMiddleware }                        from 'connected-react-router'
import createSagaMiddleware                                       from 'redux-saga'
import { all }                                                    from 'redux-saga/effects'
import { createBrowserHistory }                                   from 'history'

/*----- User Reducers -----*/
import currentUser     from '../modules/admin/CurrentUserReducer'
import userDetails     from '../modules/admin/users/reducers/UserDetailsReducer'
import usersList       from '../modules/admin/users/reducers/UsersListReducer'
import usersDeletion   from '../modules/admin/users/reducers/DeleteUserReducer'
import userCreation    from '../modules/admin/users/reducers/CreateUserReducer'
import userDataEdition from '../modules/admin/users/reducers/EditUserDataReducer'
import changePassword  from '../modules/admin/users/reducers/ChangePasswordReducer'

/*----- Sessions Reducers -----*/
import logout          from '../modules/public/sessions/reducers/LogoutReducer'
import login           from '../modules/public/sessions/reducers/LoginReducer'
import refresh         from '../modules/public/sessions/reducers/RefreshReducer'
import restorePassword from '../modules/public/restore_password/reducers/RestorePasswordReducer'

/*----- Departments Reducers -----*/
import createDepartment      from '../modules/admin/departments/reducers/CreateDepartmentReducer'
import selectUsers           from '../modules/admin/departments/reducers/SelectUsersReducer'
import departmentsList       from '../modules/admin/departments/reducers/DepartmentsListReducer'
import departmentDetails     from '../modules/admin/departments/reducers/DepartmentDetailsReducer'
import deleteDepartmentUsers from '../modules/admin/departments/reducers/DeleteDepartmentUserReducer'
import editDepartment        from '../modules/admin/departments/reducers/PutUpdateDepartmentReducer'
import usersNotInDepartment  from '../modules/admin/departments/reducers/UsersNotInDepartmentReducer'
import deleteDepartment      from '../modules/admin/departments/reducers/DeleteDepartmentReducer'
import restoreDepartment     from '../modules/admin/departments/reducers/RestoreDepartmentReducer'
import departmentUsers       from '../modules/admin/departments/reducers/DepartmentUsersReducer'

/*----- Subjects Reducers -----*/
import createSubject      from '../modules/admin/subjects/reducers/CreateSubjectReducer'
import subjectsList       from '../modules/admin/subjects/reducers/SubjectsListReducer'
import deleteSubject      from '../modules/admin/subjects/reducers/DeleteSubjectReducer'
import subjectDetails     from '../modules/admin/subjects/reducers/SubjectDetailsReducer'
import editSubject        from '../modules/admin/subjects/reducers/EditSubjectReducer'
import subjectDepartments from '../modules/admin/subjects/reducers/GetSubjectDepartmentsReducer'

/*----- Commissions Reducers -----*/
import commissionsList    from '../modules/admin/commissions/reducers/CommissionsListReducer'
import createCommission   from '../modules/admin/commissions/reducers/CreateCommissionReducer'
import commissionDetails  from '../modules/admin/commissions/reducers/CommissionDetailsReducer'
import editCommission     from '../modules/admin/commissions/reducers/EditCommissionReducer'
import subjectCommissions from '../modules/admin/commissions/reducers/GetSubjectCommissionsReducer'
import availableUsers     from '../modules/admin/commissions/reducers/AvailableUsersReducer'
import bulkImport         from '../modules/admin/commissions/reducers/BulkImportReducer'

/*----- Surveys Reducers -----*/
import exportSurvey        from '../modules/admin/surveys/reducers/ExportSurveyReducer'
import surveysList         from '../modules/admin/surveys/reducers/GetSurveysReducer'
import createSurvey        from '../modules/admin/surveys/reducers/CreateSurveyReducer'
import questionCategories  from '../modules/admin/surveys/reducers/GetQuestionCategoriesReducer'
import importSurveyResults from '../modules/admin/surveys/reducers/ImportSurveyReducer'
import importsList         from '../modules/admin/surveys/reducers/GetImportsListReducer'
import surveyDetails       from '../modules/admin/surveys/reducers/GetSurveyDetailsReducer'
import createCategory      from '../modules/admin/surveys/reducers/CreateCategoryReducer'

/*----- Roles Reducers -----*/
import rolesList from '../modules/admin/roles/reducers/RoleListReducer'

/*----- Menubar Reducer -----*/
import menubar from '../components/templates/admin/MenubarReducer'

/*----- Public reducers -----*/
import subjects               from '../modules/public/reducers/Subjects'
import users                  from '../modules/public/reducers/Users'
import graphicableCommissions from '../modules/public/reducers/SubjectCommissions'
import subjectGraphics        from '../modules/public/reducers/SubjectGraphics'
import teacherGraphics        from '../modules/public/reducers/TeacherGraphics'

/*----- Sagas -----*/
import currentUserSagas     from '../modules/admin/CurrentUserSagas'
import userSagas            from '../modules/admin/users/sagas/UserSagas'
import sessionsSagas        from '../modules/public/sessions/sagas/SessionsSagas'
import restorePasswordSagas from '../modules/public/restore_password/sagas/RestorePasswordSagas'
import departmentSagas      from '../modules/admin/departments/sagas/DepartmentSagas'
import subjectSagas         from '../modules/admin/subjects/sagas/SubjectSagas'
import commissionSagas      from '../modules/admin/commissions/sagas/CommissionSagas'
import roleSagas            from '../modules/admin/roles/sagas/RoleSagas'
import surveySagas          from '../modules/admin/surveys/sagas/SurveySagas'

/*----- Public Sagas -----*/
import publicSagas from '../modules/public/sagas/PublicSagas'

export const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware()

const publicReducers = combineReducers({
  subjects,
  users,
  graphicableCommissions,
  subjectGraphics,
  teacherGraphics
})

const rootReducer = combineReducers({
  menubar,
  currentUser,
  userDetails,
  usersList,
  usersDeletion,
  login,
  logout,
  refresh,
  userCreation,
  userDataEdition,
  changePassword,
  restorePassword,
  createDepartment,
  selectUsers,
  departmentsList,
  departmentDetails,
  deleteDepartmentUsers,
  editDepartment,
  usersNotInDepartment,
  deleteDepartment,
  restoreDepartment,
  departmentUsers,
  createSubject,
  subjectsList,
  deleteSubject,
  createCommission,
  commissionsList,
  commissionDetails,
  editCommission,
  rolesList,
  subjectDetails,
  subjectCommissions,
  editSubject,
  subjectDepartments,
  exportSurvey,
  surveysList,
  createSurvey,
  questionCategories,
  importSurveyResults,
  importsList,
  surveyDetails,
  availableUsers,
  createCategory,
  bulkImport,
  public: publicReducers,
  router: connectRouter(history)
})

const middlewares = [routerMiddleware(history), sagaMiddleware]

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose // eslint-disable-line

export const store = createStore(
  rootReducer,
  composeEnhancer(applyMiddleware(...middlewares))
)

export default function* rootSagas() {
  yield all([
    currentUserSagas(),
    sessionsSagas(),
    userSagas(),
    restorePasswordSagas(),
    departmentSagas(),
    subjectSagas(),
    commissionSagas(),
    roleSagas(),
    surveySagas(),
    publicSagas()
  ])
} 

sagaMiddleware.run(rootSagas)
