/*------------- React ------------------*/
import React               from 'react'
import { Route, Redirect } from 'react-router'

/*------------- Utils ------------------*/
import { isAuth } from '../utils/SessionService'

const GuestRoute = ({ component: Component, ...rest }) => {
  return(<Route {...rest} render = {(props) => (
    !isAuth()
      ? <Component {...props} />
      : <Redirect to = '/admin' />
  )} />)
}

export default GuestRoute
