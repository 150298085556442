/*----- Imports -----*/
import React, { useEffect } from 'react'
import { connect }          from 'react-redux'

/*----- Actions -----*/
import { createCommission, setCommissionCreationFields } from './CommissionActions'
import { getSubjects}                                    from '../subjects/SubjectsActions'

/*----- Selectors -----*/
import { loadingSelector, newCommissionFieldsSelector }  from './selectors/CreateCommissionSelector'
import { loadingSubjectsSelector, subjectsListSelector } from '../subjects/selectors/SubjectsListSelector'

/*----- Antd -----*/
import { Form } from 'antd'

/*----- Fields -----*/
import { initialState } from './reducers/CreateCommissionReducer'

/*----- Views -----*/
import MainView from './views/new/MainView'

const hasErrors = (fieldsError) => {
  return Object.keys(fieldsError).some(field => fieldsError[field])
}

const NewCommission = (
  {
    isLoading, form, createCommission, setCommissionCreationFields,
    loadingSubjects, getSubjects, subjectsList
  }
) => {

  // Clean redux after leave component render.
  useEffect(() => {
    return () => {
      setCommissionCreationFields(initialState.fields)
    }
  }, [setCommissionCreationFields])

  useEffect(() => {
    getSubjects()
  }, [getSubjects])

  const handleSubmit = (event) => {
    event.preventDefault()
    form.validateFields({ force: true })

    if (!hasErrors(form.getFieldsError())) {
      let fields = form.getFieldsValue()
      createCommission(fields)
    }
  }

  return (
    <MainView
      form            = { form }
      submitHandler   = { handleSubmit }
      isLoading       = { isLoading }
      loadingSubjects = { loadingSubjects }
      subjectsList    = { subjectsList }
    />
  )
}

function mapStateToProps(state) {
  return {
    isLoading:       loadingSelector(state),
    formFields:      newCommissionFieldsSelector(state),
    loadingSubjects: loadingSubjectsSelector(state),
    subjectsList:    subjectsListSelector(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    createCommission:            (params) => createCommission(dispatch, params),
    setCommissionCreationFields: (params) => setCommissionCreationFields(dispatch, params),
    getSubjects:            (search = '') => getSubjects(dispatch, search)
  }
}

const ConnectedNewContainer = connect(
  mapStateToProps,mapDispatchToProps
)(
  Form.create(
    {
      name: 'new_commission_form',
      onFieldsChange({ setCommissionCreationFields }, changedFields) {
        setCommissionCreationFields(changedFields)
      },
      mapPropsToFields({ formFields }) {
        return {
          name:               Form.createFormField({ ...formFields.name }),
          subject:            Form.createFormField({ ...formFields.subject }),
          workload:           Form.createFormField({ ...formFields.workload }),
          subscribers_amount: Form.createFormField({ ...formFields.subscribers_amount }),
          comments:           Form.createFormField({ ...formFields.comments }),
          year:               Form.createFormField({ ...formFields.year }),
          period:             Form.createFormField({ ...formFields.period })
        }
      }
    }
  )(NewCommission)
)

ConnectedNewContainer.defaultProps = {
  permission:   'create',
  model:        'Commission',
  defaultRoute: '/admin'
}

export default ConnectedNewContainer
