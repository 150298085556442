/*----- Imports -----*/
import React              from 'react'
import styled             from 'styled-components'
import PropTypes          from 'prop-types'
import { useTranslation } from 'react-i18next'

/*----- Antd -----*/
import { Form, Icon, Input, Button, Select } from 'antd'

/*----- Components -----*/
import FormBox from '../../../../../components/commons/form/FormBox'

/*----- Utils -----*/
import mapRecordsToOptions from '../../../../../utils/SelectOptionsMapper' 

const { Item } = Form

const StyledNewSubjectForm = styled.section`
  padding-top:    10%;
  padding-bottom: 10%;
  .form-box__data-container {
    width: 60%;
  }
`

const MainView = ({ form, isLoading, departments, handleSubmit }) => {
  const { t }                 = useTranslation()
  const { getFieldDecorator } = form

  const fieldOptions = {
    rules: [
      {
        required: true,
        message:  t('rules.mandatory_field'),
      }
    ],
    validateTrigger: ['onSubmit', 'onBlur']
  }

  const departmentOptions = mapRecordsToOptions(departments)

  return (
    <StyledNewSubjectForm>
      <FormBox title = { t('subjects.new_subject') } >
        <Form onSubmit  = { handleSubmit } >

          <Item 
            label = { t('subjects.subject') }
          >
            {
              getFieldDecorator('name', fieldOptions)(
                <Input
                  prefix      = { <Icon type='user' style={{ color: 'rgba(0, 0, 0, .25)'}}/> }
                  placeholder = { t('subjects.subject') }
                  disabled    = { isLoading }
                />
              )
            }
          </Item>

          <Item 
            label = { t('subjects.code') }
          >
            {
              getFieldDecorator('code', fieldOptions)(
                <Input
                  prefix      = { <Icon type='barcode' style={{ color: 'rgba(0, 0, 0, .25)'}}/> }
                  placeholder = { t('subjects.code') }
                  disabled    = { isLoading }
                />
              )
            }
          </Item>

          <Item 
            label = { t('admin.menubar.departments') }
          >
            {
              getFieldDecorator('departments', fieldOptions)(
                <Select 
                  mode         = 'multiple'
                  placeholder  = { t('subjects.select_departments') }
                  disabled     = { isLoading }
                  showSearch   = { true }
                  allowClear   = { true }
                  filterOption = {
                    (input, option) => 
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }                   
                >
                  { departmentOptions }
                </Select>
              )
            }
          </Item>

          <Button
            type     = 'primary'
            htmlType = 'submit'
            style    = {{ width: '100%' }}
          >
            { t('global.submit') }
          </Button>

        </Form>
      </FormBox>
    </StyledNewSubjectForm>
  )
}

export default MainView

/*----- PropTypes -----*/
MainView.propTypes = {
  form:         PropTypes.object.isRequired,
  isLoading:    PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  departments:  PropTypes.array.isRequired
}
