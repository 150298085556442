/*----- Imports -----*/
import React     from 'react'
import PropTypes from 'prop-types'

/*----- Constants -----*/
import { PAGE_SIZE } from '../../../../../utils/Constants'

/*----- Antd -----*/
import { Table } from 'antd'

const ListTable = ( { departments, isLoading, columns, className, rowSelection = null } ) => (
  <Table
    rowKey       = { (department) => department.id }
    dataSource   = { departments } 
    columns      = { columns } 
    size         = 'medium' 
    loading      = { isLoading }
    pagination   = { { pageSize: parseInt(PAGE_SIZE) } }
    scroll       = { { x: '400px'} }
    className    = { className }
    rowSelection = { rowSelection }
    bordered
  />
)

export default ListTable

/*----- PropTypes -----*/
ListTable.propTypes = {
  departments:  PropTypes.array.isRequired,
  isLoading:    PropTypes.bool.isRequired,
  columns:      PropTypes.array.isRequired,
  className:    PropTypes.string,
  rowSelection: PropTypes.object
}