/*----- Actions -----*/
import {
  BULK_IMPORT,
  BULK_IMPORT_SUCCESS,
  BULK_IMPORT_ERROR,
  SET_BULK_IMPORT_FIELDS
} from '../CommissionActions'

import { serviceErrorsToFormFields } from '../../../../utils/ResponseFormatter'

export const initialState = {
  isLoading: false,
  error:     {
    message: '',
    fields:  []
  },
  fields: {
    new_data_file: {}
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case BULK_IMPORT:
      return {
        ...state,
        isLoading: true,
      }
    case BULK_IMPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        fields:    initialState.fields
      }
    case BULK_IMPORT_ERROR:
      return {
        ...state,
        isLoading: false,
        error:     action.payload.error,
        fields:    serviceErrorsToFormFields(state.fields, action.payload.error.fields)
      }
    case SET_BULK_IMPORT_FIELDS:
      return {
        ...state,
        fields: {
          ...state.fields,
          ...action.payload
        }
      }
    default:
      return state;
  }
}