/*----- Actions -----*/
import { GET_SUBJECTS, GET_SUBJECTS_SUCCESS, GET_SUBJECTS_ERROR } from '../SubjectsActions'

const initialState = {
  isLoading: false,
  subjects:  [],
  error:     null
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_SUBJECTS:
      return {
        ...state,
        isLoading: true
      }
    case GET_SUBJECTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        subjects:  action.payload
      }
    case GET_SUBJECTS_ERROR:
      return {
        ...state,
        isLoading: false,
        error:     action.payload
      }
    default:
      return state
  }
}