/*----- Antd -----*/
import { notification } from 'antd'

export default (description) => {
  return notification.error({
    message:     'Ha ocurrido un error',
    description: description,
    duration:    3,
    placement:   'topRight'
  })
}