/*----- Notifications -----*/
import notifyError from '../components/commons/notifications/ErrorNotification'

/*----- Libraries -----*/
import streamSaver from 'streamsaver'
import { saveAs }  from 'file-saver'

export const downloadFileFromResponse = (response, filename) => {
  try {
    saveFile(response, filename)
  } catch {
    notifyError('El archivo no pudo ser descargado')
  }
}

const saveFile = (response, filename) => {
  try {
    // First strategy, using streamSaver
    const fileStream = streamSaver.createWriteStream(filename, {
      size: response.headers.get('Content-Length')
    })

    response.body.pipeTo(fileStream)
  }
  catch {
    // Fallback strategy. This one uses Blob, wich has some size limitations.
    // https://stackoverflow.com/questions/28307789/is-there-any-limitation-on-javascript-max-blob-size
    response.blob().then(blob => saveAs(blob, filename))
  }
}
