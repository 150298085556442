/*----- Imports -----*/
import React     from 'react'
import PropTypes from 'prop-types'

/*----- Antd -----*/
import { Collapse } from 'antd'

const { Panel } = Collapse

const Comments = ({ data, records }) => (
  <Collapse>
    {
      records.map((record, index) =>
        <Panel header = { record } key = { index }>
          {
            (data[record] || []).map((comment, index) =>
              <blockquote style = {{ textAlign: 'left' }} key = { index }>
                { comment }
              </blockquote>
            )
          }
        </Panel>
      )
    }
  </Collapse>
)

export default Comments

/*----- PropTypes -----*/
Comments.propTypes = {
  data:    PropTypes.object.isRequired,
  records: PropTypes.array.isRequired
}
