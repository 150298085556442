/*----- Saga -----*/
import { call, takeLatest, put } from 'redux-saga/effects'

/*----- Actions -----*/
import { GET_SUBJECTS, GET_SUBJECTS_ERROR, GET_SUBJECTS_SUCCESS } from '../SubjectsActions'

/*----- Endpoint -----*/
import { getSubjects } from '../../../../api/endpoints/Subjects'

/*----- Notifications -----*/
import notifyError from '../../../../components/commons/notifications/ErrorNotification'

export function* shootGetSubjects(action) {
  try {
    const response = yield call(getSubjects, action)
    const body     = yield response.json()
    let type

    if (response.status >= 200 && response.status < 300) {
      type = GET_SUBJECTS_SUCCESS
    } else {
      type = GET_SUBJECTS_ERROR
    }

    yield put(
      {
        type:    type,
        payload: body
      }
    )
  }
  catch (error) {
    yield notifyError('¡Ha ocurrido un error inesperado!')
    yield put(
      {
        type:    GET_SUBJECTS_ERROR,
        payload: error.message
      }
    )
  }
}

export default function* CreateSubjectWatcher() {
  yield takeLatest(GET_SUBJECTS, shootGetSubjects)
}
