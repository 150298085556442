/*----- Imports -----*/
import React              from 'react'
import styled             from 'styled-components'
import { Link }           from 'react-router-dom'
import { isMobile }       from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import PropTypes          from 'prop-types'

/*----- API -----*/
import BASE_URL from '../../../api/BaseUrl'

/*----- Antd -----*/
import { Menu, Avatar, Dropdown, Icon, Button } from 'antd'

const { Item, Divider } = Menu

/*----- Styles -----*/
const StyledHeader = styled.header`
  box-shadow:         0px 0px 6px 1px rgba(0, 0, 0, 0.1);
  .nav-icon-container {
    padding: 7px;
  }
`
const StyledItem = styled(Item)`
text-decoration: none;
padding:         0;
color:           rgba(0, 0, 0, 0.65);
`
const StyledDiv = styled.div`
  cursor: pointer;
  &:hover {
    box-shadow: inset 0px -1px 0px 0px #40a9f3;
  }
`

const Container = styled.div`
  margin-left:     5%;
  display:         flex;
  align-items:     center;
  justify-content: ${isMobile ? 'space-around' : 'flex-end'};
`

const Navbar = ({ currentUser, logoutUser, setMenuVisibility }) => {
  const { t, i18n } = useTranslation()

  const menu = (
    <Menu style = {{ width: 'inherit' }}>
      <StyledItem>
        <Link
          to    = { `/admin/users/edit/${currentUser.id}` }
          style = {{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}
        >
          <Icon type = 'edit' style = {{ display: 'inline'}}/>
            { t('admin.navbar.edit_info') }
        </Link>
      </StyledItem>
  
      <Divider />
  
      <StyledItem>
        <Link
          onClick = {
            (event) => {
              event.preventDefault()
              logoutUser()
            }
          }
          to    = '#'
          style = {{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}
        >
          <Icon type = 'logout' />
          { t('admin.navbar.logout') }
        </Link>
      </StyledItem>
    </Menu>
  )

  const langMenu = (
    <Menu style = {{ width: '100%' }}>
      <StyledItem
        onClick = { () => i18n.changeLanguage('es') }
      >
       { t('admin.home.lang.spanish') }
      </StyledItem>

      <Divider />

      <StyledItem
        onClick = { () => i18n.changeLanguage('en') }
      >
      { t('admin.home.lang.english') }
      </StyledItem>
    </Menu>
  )

  let avatarProps = {
    size:  'large',
    style: { marginLeft: '10px' }
  }

  if (currentUser.profile_picture) {
    avatarProps = { ...avatarProps, src: BASE_URL + currentUser.profile_picture }
  }
  else {
    avatarProps = { ...avatarProps, icon: 'user' }
  }

  return(
    <StyledHeader>
      <Container>
          { 
            isMobile && 
              <div>
                <Button
                  onClick = {() => setMenuVisibility(true) }
                >
                  <Icon type  = 'menu' />
                </Button>
              </div>
          }

        <div>
          <Dropdown overlay = { langMenu }>
            <StyledDiv className = 'nav-icon-container'>
              <Icon type = 'global' />
            </StyledDiv>
          </Dropdown>
        </div>

        <div>
          <Dropdown overlay = { menu }>
            <StyledDiv className = 'nav-icon-container'>
              { `${currentUser.first_name} ${currentUser.last_name}` }
              <Avatar {...avatarProps} />
            </StyledDiv>
          </Dropdown>
        </div>
      </Container>
    </StyledHeader>
  )
}

export default Navbar

/*----- PropTypes -----*/
Navbar.propTypes = {
  currentUser:       PropTypes.object.isRequired,
  logoutUser:        PropTypes.func.isRequired,
  setMenuVisibility: PropTypes.func.isRequired
}
