import { all }                    from 'redux-saga/effects'
import exportSurveySagas          from './ExportSurveySagas'
import getSurveysSagas            from './GetSurveysSagas'
import createSurveySagas          from './CreateSurveySagas'
import deleteSurvey               from './DeleteSurveySagas'
import getQuestionCategoriesSagas from './GetQuestionCategoriesSagas'
import importSurveySagas          from './ImportSurveySagas'
import importsListSagas           from './GetImportsListSagas'
import deleteImportSagas          from './DeleteImportSagas'
import surveyDetailsSagas         from './GetSurveyDetailsSagas'
import createCategorySagas        from './CreateCategorySagas'

export default function* dispatchSagas() {
  yield all([
    exportSurveySagas(),
    getSurveysSagas(),
    deleteSurvey(),
    createSurveySagas(),
    getQuestionCategoriesSagas(),
    importSurveySagas(),
    importsListSagas(),
    deleteImportSagas(),
    surveyDetailsSagas(),
    createCategorySagas()
  ])
}