/*----- Saga -----*/
import { call, takeLatest, put } from 'redux-saga/effects'

/*----- Actions -----*/
import { CREATE_SURVEY, CREATE_SURVEY_SUCCESS, CREATE_SURVEY_ERROR } from '../SurveysActions'

/*----- Endpoint -----*/
import { createSurvey } from '../../../../api/endpoints/Surveys'

/*----- Notifications -----*/
import loadingNotification from '../../../../components/commons/notifications/LoadingNotification'
import successNotification from '../../../../components/commons/notifications/SuccessNotification'
import notifyError         from '../../../../components/commons/notifications/ErrorNotification'

export function* shootCreateSurvey(action) {
  yield loadingNotification()
  try {
    const response = yield call(createSurvey, action)
    const body     = yield response.json()
    let type

    if (response.status >= 200 && response.status < 300) {
      type = CREATE_SURVEY_SUCCESS
      yield successNotification()
    } else {
      type = CREATE_SURVEY_ERROR
      yield notifyError(body.error.message)
    }

    yield put(
      {
        type:    type,
        payload: body
      }
    )
  }
  catch (error) {
    yield notifyError('¡Ha ocurrido un error inesperado!')
    yield put(
      {
        type:    CREATE_SURVEY_ERROR,
        payload: error.message
      }
    )
  }
}

export default function*() {
  yield takeLatest(CREATE_SURVEY, shootCreateSurvey)
}
