import { GET_USERS_LIST, GET_USERS_LIST_SUCCESS, GET_USERS_LIST_ERROR } from "../UserActions"

const initialState = {
  isLoading: false,
  users:     [],
  error:     null
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_USERS_LIST:
      return {
        ...state,
        isLoading: true
      }
    case GET_USERS_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        users:     action.payload
      }
    case GET_USERS_LIST_ERROR:
      return {
        ...state,
        isLoading: false,
        error:     action.payload
      }
    default:
      return state;
  }
}