/*----- Imports -----*/
import { call, takeLatest, put } from 'redux-saga/effects'
import { push }                  from 'connected-react-router'

/*----- Actions -----*/
import {
  GET_USERS_LIST_SUCCESS, GET_USERS_LIST, GET_USERS_LIST_ERROR
} from '../UserActions'

/*----- Endpoints -----*/
import { getUsersList } from '../../../../api/endpoints/Users'

/*----- Notifications -----*/
import notifyError from '../../../../components/commons/notifications/ErrorNotification'

export function* shootGetUsersList(action) {
  try {
    const response = yield call(getUsersList, action)
    let body       = yield response.json()
    let type

    if (response.status >= 200 && response.status < 300) {
      type = GET_USERS_LIST_SUCCESS
    } 
    else {
      type = GET_USERS_LIST_ERROR
      yield notifyError(body.error.message)
      yield put(push('/admin'))
    }

    yield put(
      {
        type:    type,
        payload: body
      }
    )
  }
  catch (error) {
    yield notifyError('¡Ha ocurrido un error inesperado!')
    yield put(
      {
        type:    GET_USERS_LIST_ERROR,
        payload: error.message
      }
    )
  }
}

export default function* getUserListWatcher() {
  yield takeLatest(GET_USERS_LIST, shootGetUsersList)
}
