/*----- Imports -----*/
import React, { useEffect } from 'react'
import { useTranslation }   from 'react-i18next'
import { connect }          from 'react-redux'
import PropTypes            from 'prop-types'

/*----- Graphicator -----*/
import Graphicator from '../graphics/Graphicator'

/*----- Filter -----*/
import Fields  from './FilterFields'

/*----- Actions -----*/
import { getSubjects, getSubjectGraphics, resetSubjectsGraphics } from '../Actions'

/*----- Antd -----*/
import { Form } from 'antd'

/*----- Selectors -----*/
import { subjectGraphicsSelector } from '../selectors/SubjectGraphics'

const hasErrors = (fieldsError) => (
  Object.keys(fieldsError).some(field => fieldsError[field])
)

const Subjects = ({ form, getSubjects, getGraphics, graphicsResponse, resetGraphics }) => {
  const { t } = useTranslation()

  useEffect(() => {
    getSubjects()
  }, [getSubjects])

  useEffect(
    () => () => resetGraphics(),
    [resetGraphics]
  )

  const submitFilterHandler = (event) => {
    event.preventDefault()
    form.validateFields(['subjects'], { force: true })

    const errors = form.getFieldError('subjects').map((records) => (
      hasErrors(Object.values(records)))
    )

    if (errors.every((result) => !result)) {
      let fields      = form.getFieldsValue()
      fields.subjects = fields.subjects.filter(Boolean)
      
      fields.subjects.map(field => {
        if (field.year) field.year = field.year.year()

        return field
      })

      getGraphics(fields)
    }
  }

  return( 
    <Graphicator
      form                = { form }
      getSubjects         = { getSubjects }
      graphicsResponse    = { graphicsResponse }
      resetGraphics       = { resetGraphics }
      submitFilterHandler = { submitFilterHandler }
      title               = { t('public.subjects.title') }
      fields              = { Fields }
    />
  )
}

const mapStateToProps = (state) => (
  {
    graphicsResponse: subjectGraphicsSelector(state)
  }
)

const mapDispatchToProps = (dispatch) => (
  {
    getSubjects:           () => getSubjects(dispatch),
    getGraphics: (filterData) => getSubjectGraphics(dispatch, filterData),
    resetGraphics:         () => resetSubjectsGraphics(dispatch)
  }
)

export default connect(mapStateToProps, mapDispatchToProps)(
  Form.create({ name: 'subjects_filter' })(Subjects)
)

/*----- PropTypes -----*/
Subjects.propTypes = {
  form:             PropTypes.object.isRequired,
  getSubjects:      PropTypes.func.isRequired,
  getGraphics:      PropTypes.func.isRequired,
  graphicsResponse: PropTypes.object.isRequired,
  resetGraphics:    PropTypes.func.isRequired
}
