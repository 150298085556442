export const driver = localStorage

export const startSession = (auth_token, refresh_token) => {
  driver.setItem('auth',    JSON.stringify(auth_token))
  driver.setItem('refresh', JSON.stringify(refresh_token))
}

export const destroySession = () => {
  driver.removeItem('auth')
  driver.removeItem('refresh')
}

export const getAuthToken = () => {
  return JSON.parse(driver.getItem('auth'))
}

export const getRefreshToken = () => {
  return JSON.parse(driver.getItem('refresh'))
}

export const isAuth = () => {
  return (driver.getItem('auth') != null)
}

export const renewToken = (new_token) => {
  driver.setItem('auth', JSON.stringify(new_token))
}
