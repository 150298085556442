/*----- Imports -----*/
import React       from 'react'
import { connect } from 'react-redux'

/*----- Views -----*/
import MainView from './views/bulk_import/MainView'

/*----- Actions -----*/
import { bulkImport, setBulkImportFields } from './CommissionActions'

/*---- Antd -----*/
import { Form } from 'antd'

/*----- Selectors -----*/
import { bulkImportFieldsSelector } from './selectors/BulkImportSelector'

const hasErrors = (fieldsError) => ( Object.keys(fieldsError).some(field => fieldsError[field]))

const ImportContainer = ( { bulkImport, form, setBulkImportFields, formFields } ) => {

  const handleSubmit = (event) => {
    event.preventDefault()
    form.validateFields({ force: true })

    if (!hasErrors(form.getFieldsError())) {
      let fields = form.getFieldsValue()
      bulkImport(fields)
    }
  }

  return(
    <MainView
      handleSubmit = { handleSubmit }
      form         = { form }
    />
  )

}

const mapStateToProps = (state) => (
  {
    formFields: bulkImportFieldsSelector(state),
  }
)

const mapDispatchToProps = (dispatch) => (
  {
    bulkImport:            (file) => bulkImport(dispatch, file),
    setBulkImportFields: (fields) => setBulkImportFields(dispatch, fields)
  }
)

const ConnectedBulkImportContainer = connect(mapStateToProps, mapDispatchToProps)(
  Form.create(
    {
      name: 'bulk_import_form',
      onFieldsChange({ setBulkImportFields }, changedFields) {
        if (changedFields.new_data_file) {
          changedFields = {
            ...changedFields,
            new_data_file: {
              ...changedFields.new_data_file,
              errors: null
            }
          }
        }

        setBulkImportFields(changedFields)
      },
      mapPropsToFields({ formFields }) {
        return {
          new_data_file: Form.createFormField(formFields.new_data_file)
        }
      }
    }
)(ImportContainer))

ConnectedBulkImportContainer.defaultProps = {
  permission:   'bulk-import',
  model:        'Commission',
  defaultRoute: '/admin'
}

export default ConnectedBulkImportContainer