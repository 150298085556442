/*----- Saga -----*/
import { call, takeLatest, put } from 'redux-saga/effects'

/*----- Actions -----*/
import { GET_SUBJECT_DETAILS, GET_SUBJECT_DETAILS_ERROR, GET_SUBJECT_DETAILS_SUCCESS } from '../SubjectsActions'

/*----- Endpoint -----*/
import { getSubjectDetails } from '../../../../api/endpoints/Subjects'

/*----- Notifications -----*/
import notifyError from '../../../../components/commons/notifications/ErrorNotification'

export function* shootGetSubjectDetails(action) {
  try {
    const response = yield call(getSubjectDetails, action)
    const body     = yield response.json()
    let type

    if (response.status >= 200 && response.status < 300) {
      type = GET_SUBJECT_DETAILS_SUCCESS
    } else {
      type = GET_SUBJECT_DETAILS_ERROR
    }

    yield put(
      {
        type:    type,
        payload: body
      }
    )
  }
  catch (error) {
    yield notifyError('¡Ha ocurrido un error inesperado!')
    yield put(
      {
        type:    GET_SUBJECT_DETAILS_ERROR,
        payload: error.message
      }
    )
  }
}

export default function* GetSubjectDetailsWatcher() {
  yield takeLatest(GET_SUBJECT_DETAILS, shootGetSubjectDetails)
}
