const columns = (t) => (
  [
    {
      title:          t('user.name'),
      dataIndex:      'first_name',
      key:            'first_name',
      sorter:         (a,b) => a.first_name.localeCompare(b.first_name),
      sortDirections: ['ascend','descend']
    },
    {
      title:          t('user.surname'),
      dataIndex:      'last_name',
      key:            'last_name',
      sorter:         (a,b) => a.last_name.localeCompare(b.last_name),
      sortDirections: ['ascend','descend']
    },
    {
      title:     t('user.email'),
      dataIndex: 'email',
      key:       'email',
    }
  ]
)

export default columns
