/*----- Imports -----*/
import React              from 'react'
import { useTranslation } from 'react-i18next'
import { connect }        from 'react-redux'
import styled             from 'styled-components'

/*----- Antd -----*/
import { Form, Input, Icon, Button } from 'antd'

/*----- Components -----*/
import FormBox from '../../../../../../components/commons/form/FormBox'

/*----- Actions -----*/
import { createQuestionCategory, setQuestionCategoryFields } from '../../../SurveysActions'

/*----- Selectors -----*/
import { createCategoryFieldsSelector, loadingSelector} from '../../../selectors/CreateCategorySelector'

const { Item } = Form

const StyledNewCategoryForm = styled.section`
  padding-top:    10%;
  padding-bottom: 10%;
  .form-box__data-container {
    width: 60%;
  }
`

const hasErrors = (fieldsError) => (Object.keys(fieldsError).some(field => fieldsError[field]))

const NewCategory = ({ form, isLoading, createQuestionCategory, setQuestionCategoryFields } ) => {

  const handleSubmit = (event) => {
    event.preventDefault()
    form.validateFields({ force: true })

    if (!hasErrors(form.getFieldsError())) {
      let fields = form.getFieldsValue()
      createQuestionCategory(fields)
    }
  }

  const { t } = useTranslation()

  const { getFieldDecorator } = form

  return (
    <StyledNewCategoryForm>
      <FormBox title = { t('question.new_category') } >
        <Form onSubmit = { handleSubmit } >

          <Item 
            label = { t('question.category') }
          >
            {
              getFieldDecorator('name')(
                <Input
                  prefix      = { <Icon type='edit' style={{ color: 'rgba(0, 0, 0, .25)'}}/> }
                  placeholder = { t('question.category') }
                  disabled    = { isLoading }
                />
              )
            }
          </Item>

          <Button
            type     = 'primary'
            htmlType = 'submit'
            style    = {{ width: '100%' }}
          >
            { t('global.submit') }
          </Button>

        </Form>
      </FormBox>
    </StyledNewCategoryForm>
  )
}

function mapStateToProps(state) {
  return {
    isLoading:  loadingSelector(state),
    formFields: createCategoryFieldsSelector(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    createQuestionCategory:    (fields) => createQuestionCategory(dispatch, fields),
    setQuestionCategoryFields: (fields) => setQuestionCategoryFields(dispatch, fields)
  }
}

const ConnectedNewCategory = connect(mapStateToProps, mapDispatchToProps)(
  Form.create(
    {
      name: 'new_category_form',
      onFieldsChange({ setQuestionCategoryFields }, changedFields) {
        setQuestionCategoryFields(changedFields);
      },
      mapPropsToFields({ formFields }) {
        return {
          name: Form.createFormField(formFields.name)
        }
      }
    }
)(NewCategory))

ConnectedNewCategory.defaultProps = {
  permission:   'create',
  model:        'Category',
  defaultRoute: '/admin'
}

export default ConnectedNewCategory