/*----- API -----*/
import BASE_URL                                                                     from '../BaseUrl'
import { USER_RESOURCE, CHANGE_PASSWORD, CURRENT_USER_RESOURCE, ACTIVATE_RESOURCE } from '../Resources'

/*----- Authorization -----*/
import { getAuthToken } from '../../utils/SessionService'

/*----- Decorators -----*/
import refreshableDecorator from '../../decorators/RefreshableDecorator'

/*----- Utils -----*/
import { objToQueryString } from '../../utils/ParameterSanitizer'


export const getUsersDetails = refreshableDecorator(
  function* (action) {
    let params = objToQueryString(action.payload.params)
    return yield fetch(
      `${BASE_URL}/${USER_RESOURCE}/${action.payload.user_id}/?${params}`,
      {
        method: 'GET',
        headers: { 'Authorization': `Bearer ${getAuthToken()}` }
      }
    )
  }
)

export const getUsersList = refreshableDecorator(
  function* (action) {
    let params = objToQueryString(action.payload)
    return yield fetch(
      `${BASE_URL}/${USER_RESOURCE}/?${params}`,
      {
        method: 'GET',
        headers: { 'Authorization': `Bearer ${getAuthToken()}` }
      }
    )
  }
)

export const getCurrentUser = refreshableDecorator(
  function* (_args) {
    return yield fetch(
      `${BASE_URL}/${CURRENT_USER_RESOURCE}/`,
      {
        method: 'GET',
        headers: { 'Authorization': `Bearer ${getAuthToken()}` }
      }
    )
  }
)

export const putUserEdition = refreshableDecorator(
  function* (action) {
    return yield fetch(
      `${BASE_URL}/${USER_RESOURCE}/${action.payload.id}/`,
      {
        method: 'PUT',
        body:   action.payload.body,
        headers: {
          'Authorization': `Bearer ${getAuthToken()}`
        }
      }
    )
  }
)

export const putActivateUser = refreshableDecorator(
  function* (action) {
    return yield fetch(
      `${BASE_URL}/${USER_RESOURCE}/${action.payload.id}/${ACTIVATE_RESOURCE}/`,
      {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${getAuthToken()}`
        }
      }
    )
  }
)

export const postUserCreation = refreshableDecorator(
  function* (action) {
    return yield fetch(
      `${BASE_URL}/${USER_RESOURCE}/`,
      {
        method:  'POST',
        body:    action.payload.body,
        headers: {
          'Authorization': `Bearer ${getAuthToken()}`
        }
      }
    )
  }
)

export const putChangePassword = refreshableDecorator(
  function* (userId, action) {
    return yield fetch(
      `${BASE_URL}/${USER_RESOURCE}/${userId}/${CHANGE_PASSWORD}/`,
      {
        method: 'PUT',
        body:    JSON.stringify(action.payload.body),
        headers: {
          'Content-Type':  'application/json',
          'Accept':        '*/*',
          'Authorization': `Bearer ${getAuthToken()}`
        }
      }
    )
  }
)

export const deleteUser = refreshableDecorator(
  function* (action) {
    return yield fetch(
      `${BASE_URL}/${USER_RESOURCE}/${action.payload}/`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type':  'application/json',
          'Authorization': `Bearer ${getAuthToken()}`
        }
      }
    )
  }
)