/*----- Imports -----*/
import React    from 'react'
import { Link } from 'react-router-dom'
import styled   from 'styled-components'

/*----- Antd -----*/
import { Icon, Divider, Popconfirm, Button, Tooltip } from 'antd'

/*----- Components -----*/
import ActionButton from '../../../../../components/commons/button/ActionButton'

/*----- CASL ------*/
import { Can } from '../../../../../ability/Can'

const StyledButton = styled(Button)`
  background: none!important;
  border:     none!important;
  box-shadow: none!important;
`

const columns = (t, is_admin, deleteUser, activateButtonHandler) => (
  [
    {
      title:          t('user.name'),
      dataIndex:      'first_name',
      key:            'first_name',
      sorter:         (a,b) => a.first_name.localeCompare(b.first_name),
      sortDirections: ['ascend','descend']
    },
    {
      title:          t('user.surname'),
      dataIndex:      'last_name',
      key:            'last_name',
      sorter:         (a,b) => a.last_name.localeCompare(b.last_name),
      sortDirections: ['ascend','descend']
    },
    {
      title:     t('user.email'),
      dataIndex: 'email',
      key:       'email',
    },
    {
      title:     t('user.departments'),
      dataIndex: 'departments',
      key:       'departments',
      render:    (departments) => {return departments.join(', ')}
    },
    {
      title:  t('global.actions'),
      key:    'action',
      render: (_text, record) => (
        <span>
          <Tooltip title = { t('global.go_to_detail') } >
              <ActionButton>
                  <Link to = { `/admin/users/${record.id}` } >
                    <Icon type='eye'/>
                  </Link>
              </ActionButton>
          </Tooltip>

          { 
            record.is_active && (
              <>
                <Divider type='vertical' />
                <Tooltip title = { t('global.delete') } >
                  <Popconfirm
                    title      = { t('notifications.confirm') }
                    onConfirm  = { () => { deleteUser(record.id) } }
                    okText     = { t('global.accept') }
                    cancelText = { t('global.cancel') }
                  >
                    <ActionButton>
                      <Icon type = 'delete' style = {{ color:'red' }} />
                    </ActionButton>
                  </Popconfirm>
                </Tooltip>
              </>
          )}

          <Can I = 'edit' a = 'User'>
            <>
              <Divider type='vertical' />
              <Tooltip title = { t('global.edit') }>
                <ActionButton>
                  <Link to = { `/admin/users/edit/${record.id}` } >
                    <Icon type = 'edit'/>
                  </Link>
                </ActionButton>
              </Tooltip>
            </>
          </Can>

          <Can I = 'restore' a = 'User'>
            { 
              !record.is_active && 
              <>
                <Divider type = 'vertical' />
                <Tooltip title = { t('global.activate') }>
                  <StyledButton onClick = { () => (activateButtonHandler(record.id)) }>
                      <Icon type = 'rollback'/>
                  </StyledButton>
                </Tooltip>
              </>
            }
          </Can>

        </span>
      )
    }
  ]
)

export default columns
