/*----- Imports -----*/
import React, { useState } from 'react'
import PropTypes           from 'prop-types'
import styled              from 'styled-components'
import { useTranslation }  from 'react-i18next'

/*----- Antd -----*/
import { Button, Form, Icon, Row, Col } from 'antd'

const ButtonContainer = styled.div`
  margin:     2%;
  text-align: right;
  display:    block;
`

const Filter = ({ form, submitHandler, isLoading, fields: Fields }) => {
  const { t }                 = useTranslation()
  const [keys, setKeys]       = useState([0])
  const [lastKey, setLastKey] = useState(0)

  const addFormFields = () => {
    const updatedLastKey = lastKey + 1
    setLastKey(updatedLastKey)
    setKeys(keys.concat(updatedLastKey))
  }

  const removeFormFields = (keyToDelete) => {
    const updatedKeys = new Array(...keys)
    const index       = updatedKeys.indexOf(keyToDelete)

    if (index > -1) {
      updatedKeys.splice(index, 1)
    }
    
    setKeys(updatedKeys)
  }

  return(
    <>
      <ButtonContainer>
        <Button
          type      = 'primary'
          htmlType  = 'submit'
          disabled  = { keys.length >= 4 }
          size      = 'large'
          className = 'button'
          onClick   = { addFormFields }
        >
          <Icon type = 'plus' />
          <span>
            { t('global.add') }
          </span>
        </Button>
      </ButtonContainer>

      <Form onSubmit = { submitHandler }>
        {
          keys.map(key => (
            <Row
              key     = { key }
              type    = 'flex'
              justify = 'space-around'
              align   = 'top'
              gutter  = { [16, 16] }
            >

              <Col
                xs = { 24 }
                sm = { 24 }
                md = { 24 }
                lg = { 21 }
                xl = { 21 }
              >
                <Fields form = { form } index = { key }/>
              </Col>

              <Col
                xs    = { 24 }
                sm    = { 24 }
                md    = { 24 }
                lg    = { 3  }
                xl    = { 3  }
                style = {{ textAlign: 'center' }}
              >
                <Button
                  type      = 'default'
                  disabled  = { keys.length <= 1 }
                  onClick   = { () => removeFormFields(key) }
                  style     = {{ padding: 0 }}
                >
                  <Icon type = 'delete' style = {{ color: 'red', fontSize: '20px' }} />
                </Button>
              </Col>

            </Row>
          ))
        }

        <ButtonContainer>
          <Button
            type      = 'primary'
            htmlType  = 'submit'
            size      = 'large'
            className = 'button alt'
            disabled  = { isLoading }
          >
            { t('global.submit') }
          </Button>
        </ButtonContainer>

      </Form>
    </>
  )
}

export default Filter

/*----- PropTypes -----*/
Filter.propTypes = {
  form:          PropTypes.object.isRequired,
  fields:        PropTypes.elementType.isRequired,
  submitHandler: PropTypes.func.isRequired,
  isLoading:     PropTypes.bool.isRequired
}
