/*----- Saga -----*/
import { call, takeLatest, put } from 'redux-saga/effects'

/*----- Actions -----*/
import {
  CREATE_DEPARTMENT, CREATE_DEPARTMENT_SUCCESS, CREATE_DEPARTMENT_ERROR, SET_SELECTED_USERS
} from '../DepartmentsActions'

/*----- Endpoint -----*/
import { postDepartmentCreation } from '../../../../api/endpoints/Departments'

/*----- Notifications -----*/
import loadingNotification from '../../../../components/commons/notifications/LoadingNotification'
import successNotification from '../../../../components/commons/notifications/SuccessNotification'
import notifyError         from '../../../../components/commons/notifications/ErrorNotification'

export function* shootCreateDepartment(action) {
  yield loadingNotification()
  try {
    const response = yield call(postDepartmentCreation, action)
    const body     = yield response.json()
    let type

    if (response.status >= 200 && response.status < 300) {
      type = CREATE_DEPARTMENT_SUCCESS
      yield put(
        {
          type:    SET_SELECTED_USERS,
          payload: []
        }
      )
      yield successNotification()
    } else {
      type = CREATE_DEPARTMENT_ERROR
      yield notifyError(body.error.message)
    }

    yield put(
      {
        type:    type,
        payload: body
      }
    )
  }
  catch (error) {
    yield notifyError('¡Ha ocurrido un error inesperado!')
    yield put(
      {
        type:    CREATE_DEPARTMENT_ERROR,
        payload: error.message
      }
    )
  }
}

export default function* CreateUserWatcher() {
  yield takeLatest(CREATE_DEPARTMENT, shootCreateDepartment)
}
