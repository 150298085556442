/*----- Actions -----*/
import {
  DELETE_DEPARTMENT_USERS, DELETE_DEPARTMENT_USERS_SUCCESS, DELETE_DEPARTMENT_USERS_ERROR
} from '../DepartmentsActions'

const initialState = {
  isLoading: false,
  error:     null
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case DELETE_DEPARTMENT_USERS:
      return {
        ...state,
        isLoading: true
      }
    case DELETE_DEPARTMENT_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false
      }
    case DELETE_DEPARTMENT_USERS_ERROR:
      return {
        ...state,
        isLoading: false,
        error:     action.payload
      }
    default:
      return state;
  }
}