/*----- Actions -----*/
import { DEPARTMENTS_LIST, DEPARTMENTS_LIST_SUCCESS, DEPARTMENTS_LIST_ERROR } from '../DepartmentsActions'

const initialState = {
  isLoading:   false,
  departments: [],
  error:       null
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case DEPARTMENTS_LIST:
      return {
        ...state,
        isLoading: true
      }
    case DEPARTMENTS_LIST_SUCCESS:
      return {
        ...state,
        isLoading:   false,
        departments: action.payload
      }
    case DEPARTMENTS_LIST_ERROR:
      return {
        ...state,
        isLoading: false,
        error:     action.payload
      }
    default:
      return state;
  }
}