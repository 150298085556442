/*----- Imports -----*/
import React, { useCallback } from 'react'
import styled                 from 'styled-components'
import PropTypes              from 'prop-types'
import { useTranslation }     from 'react-i18next'

/*----- Views -----*/
import Table   from './Table'
import columns from './Columns'
import Filters from './Filters'

/*----- Antd -----*/
import { Button } from 'antd'

/*----- Utils -----*/
import { useDownloadConfirm } from '../../../../../utils/hooks/FileDownloadConfirmation'

const Container = styled.div`
  margin-top:  5%;
  margin-left: 10%;
  width:       80%;
`

const StyledTable = styled(Table)`
  margin-top: 5%;
`

const MainView = (
  {
    onFiltersChange, commissions, isLoading, deleteCommission, restoreCommission,
    loadingFilters, departments, subjects, downloadFile, filters
  }
) => {
  const { t }         = useTranslation()
  const downloadExcel = useCallback(
    () => downloadFile('xlsx'),
    [downloadFile]
  )

  const confirm = useDownloadConfirm(downloadExcel)

  const tableTitle = () => (
    <Button
      type    = 'primary'
      icon    = 'file-excel'
      size    = 'large'
      onClick = { () => confirm() }
    >
      { t('global.download') }
    </Button>
  )

  return(
    <Container>
      
      <Filters
        onFiltersChange = { onFiltersChange }
        t               = { t }
        departments     = { departments }
        subjects        = { subjects }
        isLoading       = { loadingFilters }
      />

      <StyledTable
        commissions = { commissions }
        isLoading   = { isLoading }
        columns     = { columns(t, deleteCommission, restoreCommission, filters) }
        title       = { tableTitle }
      />
    </Container>
  )
}

export default MainView

/*----- PropTypes -----*/
MainView.propTypes = {
  onFiltersChange:   PropTypes.func.isRequired,
  commissions:       PropTypes.array.isRequired,
  isLoading:         PropTypes.bool.isRequired,
  deleteCommission:  PropTypes.func.isRequired,
  restoreCommission: PropTypes.func.isRequired,
  loadingFilters:    PropTypes.bool.isRequired,
  subjects:          PropTypes.array.isRequired,
  departments:       PropTypes.array.isRequired,
  downloadFile:      PropTypes.func.isRequired
}
