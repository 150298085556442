/*----- API -----*/
import BASE_URL from '../BaseUrl'

/*----- Resources -----*/
import { LOGIN_RESOURCE, USER_RESOURCE, REFRESH_RESOURCE, LOGOUT_RESOURCE } from '../Resources'

/*----- Utils -----*/
import { getRefreshToken } from '../../utils/SessionService'

/*----- Decorators -----*/
import refreshableDecorator from '../../decorators/RefreshableDecorator'

/*----- Authorization -----*/
import { getAuthToken } from '../../utils/SessionService'

export function* login(action) {
  return yield fetch(
    `${BASE_URL}/${LOGIN_RESOURCE}/`,
    {
      method:  'POST',
      body:    JSON.stringify(action.payload),
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )
}

export const logout = refreshableDecorator(
  function* () {
    return yield fetch(
      `${BASE_URL}/${LOGOUT_RESOURCE}/`,
      {
        method:  'GET',
        headers: {
          'Authorization': `Bearer ${getAuthToken()}`
        }
      }
    )
  }
)

export function* refresh_token() {
  return yield fetch(
    `${BASE_URL}/${REFRESH_RESOURCE}/`,
    {
      method:  'POST',
      body:    JSON.stringify({ 'refresh': getRefreshToken() }),
      headers: {
        'Content-Type': 'application/json',
      }
    }
  )
}

export function* restore_password(action) {
  return yield fetch(
    `${BASE_URL}/${USER_RESOURCE}/recover_password/`,
    {
      method:  'POST',
      body:    JSON.stringify(action.payload),
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )
}

export function* refreshToken() {
  return yield fetch(
    `${BASE_URL}/${REFRESH_RESOURCE}/`,
    {
      method:  'POST',
      body:    JSON.stringify(
        {
          'refresh': getRefreshToken()
        }
      ),
      headers: {
        'Content-Type': 'application/json',
      }
    }
  )
}