/*----- Imports -----*/
import React, { useState } from 'react'
import PropTypes           from 'prop-types'
import styled              from 'styled-components'

/*----- Components -----*/
import SearchInput from '../../../../../components/commons/input/SearchInput'

/*----- Antd -----*/
import { Select, Button, Icon, DatePicker } from 'antd'

/*----- Utils -----*/
import mapRecordsToOptions from '../../../../../utils/SelectOptionsMapper'

const { Option } = Select

const Container = styled.div`
  width:           100%;
  display:         flex;
  justify-content: space-between;
  align-items:     center;
`

const SelectContainer = styled.div`
  width:           100%;
  display:         flex;
  justify-content: center;
  align-items:     center;
`

const AdvancedFiltersContainer = styled.div`
  width:            100%;
  justify-content:  space-between;
  align-items:      center;
  margin-top:       2%;
  background-color: white;
	padding:          20px;
	border-radius:    6px;
	box-shadow:       0px 0px 1px 1px rgba(0, 0, 0, 0.1);
  display:         ${props => props.showAdvancedFilters ? 'flex' : 'none'};
`

const filterOption = (input, option) => (
  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
)

const Filters = (
  {
    onFiltersChange, t, departments, subjects, isLoading
  }
) => {
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false)
  const [isYearPickerOpen, setIsYearPickerOpen]       = useState(false)
  // El atributo value de DatePicker debe ser una instancia de moment.
  const [yearPickerValue, setYearPickerValue]         = useState(null)

  return(
    <>
      <Container>
        
        <SearchInput
          placeholder     = { t('global.search_placeholder') }
          onSearchHandler = { (value) => onFiltersChange({ search: value }) }
          allowClear      = { true }
        />

        <SelectContainer>

          <b style = { { marginRight: '2%' }}>
            { `${t('global.status')}: ` }
          </b>

          <Select
            defaultValue = 'false' 
            style        = { { width: 120 } }
            onChange     = { (value) => onFiltersChange({ blocked: value }) }
          >
              <Option value = 'false'>
                { t('global.active') }
              </Option>

              <Option value = 'true'>
                { t('global.blocked') }
              </Option>
          </Select>

        </SelectContainer>

        <Button
          type    = 'dashed'
          onClick = { () => setShowAdvancedFilters(!showAdvancedFilters) }
        >
          <Icon type = 'filter' theme = 'filled'/>

          <Icon type  = { showAdvancedFilters ? 'up' : 'down' } />
        </Button>
        
      </Container>

      <AdvancedFiltersContainer showAdvancedFilters = { showAdvancedFilters }>
        <Select
          mode         = 'multiple'
          placeholder  = { t('department.title') }
          onChange     = { (value) => onFiltersChange({ departments: value }) }
          filterOption = { filterOption }
          style        = {{ width: 350 }}
          disabled     = { isLoading }
          showSearch
          showArrow
          allowClear
        >
          { mapRecordsToOptions(departments) }
        </Select>


        <Select
          mode         = 'multiple'
          placeholder  = { t('subjects.subject') }
          onChange     = { (value) => onFiltersChange({ subjects: value }) }
          filterOption = { filterOption }
          style        = {{ width: 350 }}
          disabled     = { isLoading }
          showSearch
          showArrow
          allowClear
        >
            { mapRecordsToOptions(subjects) }
        </Select>

        <DatePicker
          mode          = 'year'
          format        = 'YYYY'
          value         = { yearPickerValue }
          open          = { isYearPickerOpen }
          placeholder   = { t('commission.year') }
          onOpenChange  = { (status) => setIsYearPickerOpen(status) } 
          onChange      = { () => {
            setYearPickerValue(null)
            onFiltersChange({ year: null })
          }}
          onPanelChange = {(value) => {
            setYearPickerValue(value)
            onFiltersChange({ year: value.year() })
            setIsYearPickerOpen(false)
          }}
        />

        <Select
          placeholder = { t('commission.period.title') }
          disabled    = { isLoading }
          style       = { { width: 350 } }
          onChange    = { (value) => onFiltersChange({ period: value }) }
          showArrow
          allowClear
        >
          <Option value = '1er semestre' >
            { t('commission.period.first_semester') }
          </Option>

          <Option value = '2do semestre'>
            { t('commission.period.second_semester') }
          </Option>

          <Option value = 'ANUAL'>
            { t('commission.period.anual') }
          </Option>
        </Select>

      </AdvancedFiltersContainer>
    </>
  )
}

export default Filters

/*----- PropTypes -----*/
Filters.propTypes = {
  onFiltersChange: PropTypes.func.isRequired,
  t:               PropTypes.func.isRequired,
  departments:     PropTypes.array.isRequired,
  subjects:        PropTypes.array.isRequired
}
