/*----- Imports -----*/
import React              from 'react'
import styled             from 'styled-components'
import PropTypes          from 'prop-types'
import { useTranslation } from 'react-i18next'

/*----- Views -----*/
import FormFields from '../forms/General'

/*----- Components -----*/
import FormBox from '../../../../../components/commons/form/FormBox'

const MainContainer = styled.section`
  padding-top:    10%;
  padding-bottom: 10%;
  .form-box__data-container {
    width: 60%;
  }
`

const MainView = ( { form, submitHandler, isLoading, subjectsList, loadingSubjects } ) => {
  const { t } = useTranslation()

  return(
    <MainContainer>

      <FormBox title = { t('admin.menubar.new_commission') } >
        <FormFields
          form            = { form }
          submitHandler   = { submitHandler }
          isLoading       = { isLoading }
          subjectsList    = { subjectsList }
          loadingSubjects = { loadingSubjects }
        />
      </FormBox>
      
    </MainContainer>
  )
}

export default MainView

/*----- PropTypes -----*/
MainView.propTypes = {
  form:            PropTypes.object.isRequired,
  submitHandler:   PropTypes.func.isRequired,
  isLoading:       PropTypes.bool.isRequired,
  loadingSubjects: PropTypes.bool.isRequired,
  subjectsList:    PropTypes.array.isRequired
}
