import { call, takeLatest, put } from 'redux-saga/effects'

/*----- Notifications -----*/
import notifyError from '../../components/commons/notifications/ErrorNotification'

import {
  GET_CURRENT_USER, GET_CURRENT_USER_SUCCESS, GET_CURRENT_USER_ERROR
} from './CurrentUserActions'

/*----- Endpoints -----*/
import { getCurrentUser } from '../../api/endpoints/Users'

export function* shootGetCurrentUser() {
  try {
    const response = yield call(getCurrentUser)
    let body       = yield response.json()
    let type
    
    if (response.status >= 200 && response.status < 300) {
      type = GET_CURRENT_USER_SUCCESS
    }
    else {
      type = GET_CURRENT_USER_ERROR
      yield notifyError(body.error.message)
    }

    yield put(
      {
        type:    type,
        payload: body
      }
    )
  }
  catch (error) {
    yield notifyError('¡Ha ocurrido un error inesperado!')
    yield put(
      {
        type:    GET_CURRENT_USER_ERROR,
        payload: error.message
      }
    )
  }
}

export default function* getCurrentUserWatcher() {
  yield takeLatest(GET_CURRENT_USER, shootGetCurrentUser)
}
