/*----- Actions -----*/
import {
  CREATE_SUBJECT, CREATE_SUBJECT_SUCCESS, CREATE_SUBJECT_ERROR, SET_NEW_SUBJECT_FORM_FIELDS
} from '../SubjectsActions'

import { serviceErrorsToFormFields } from '../../../../utils/ResponseFormatter'

export const initialState = {
  isLoading: false,
  success:   '',
  error:     {
    message: '',
    fields:  []
  },
  fields: {
    name:        {},
    code:        {},
    departments: []
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_SUBJECT:
      return {
        ...state,
        isLoading: true,
      }
    case CREATE_SUBJECT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success:   action.payload,
        fields:    initialState.fields
      }
    case CREATE_SUBJECT_ERROR:
      return {
        ...state,
        isLoading: false,
        error:     action.payload.error,
        fields:    serviceErrorsToFormFields(state.fields, action.payload.error.fields)
      }
    case SET_NEW_SUBJECT_FORM_FIELDS:
      return {
        ...state,
        fields: {
          ...state.fields,
          ...action.payload
        }
      }
    default:
      return state;
  }
}