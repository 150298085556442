/*----- Imports -----*/
import React     from 'react'
import PropTypes from 'prop-types'
import styled    from 'styled-components'

/*----- Antd -----*/
import { Select, DatePicker, Row, Col } from 'antd'

/*----- Utils -----*/
import mapRecordsToOptions from '../../../../../utils/SelectOptionsMapper'

const { RangePicker } = DatePicker

const Container = styled(Row)`
  margin: 5%;
`

const filterOption = (input, option) => (
  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
)

const Filters = ({ onFiltersChange, t, surveys, isLoading }) => {
  return(
    <Container gutter = { 16 }>
      <Col
        xs = { 24 }
        sm = { 24 }
        md = { 12 }
        lg = { 12 }
        xl = { 12 }
      >
        <RangePicker
          format   = 'YYYY-MM-DD'
          size     = 'large'
          style    = {{ width: '100%' }}
          onChange = {
            (_value, dateString) => {
              const [from, to] = dateString
              onFiltersChange({ from: from, to: to })
            }
          }
        />
      </Col>

      <Col
        xs = { 24 }
        sm = { 24 }
        md = { 12 }
        lg = { 12 }
        xl = { 12 }
      >
        <Select
          allowClear
          showSearch
          showArrow
          multiple
          size         = 'large'
          placeholder  = { t('admin.menubar.surveys') }
          filterOption = { filterOption }
          disabled     = { isLoading }
          style        = {{ width: '100%' }}
          onChange     = { (value) => onFiltersChange({ surveys: value }) }
        >
          { mapRecordsToOptions(surveys) }
        </Select>
      </Col>

    </Container>
  )
}

export default Filters

/*----- PropTypes -----*/
Filters.propTypes = {
  onFiltersChange: PropTypes.func.isRequired,
  t:               PropTypes.func.isRequired,
  isLoading:       PropTypes.bool.isRequired,
  surveys:         PropTypes.array.isRequired
}
