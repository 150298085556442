/*----- Imports -----*/
import React              from 'react'
import styled             from 'styled-components'
import { useTranslation } from 'react-i18next'

/*----- Antd -----*/
import { Spin } from 'antd'

const CustomSpin = styled(Spin)`
  width: 100%;
  height: 20%;
`

const StyledSpin = (props) => {
  const { t } = useTranslation();

  return <CustomSpin { ...props } tip = { t('public.login_form.loading') } />
}

export default StyledSpin
