/*----- Actions -----*/
import {
  CREATE_COMMISSION,
  CREATE_COMMISSION_ERROR,
  CREATE_COMMISSION_SUCCESS,
  SET_COMMISSION_CREATION_FIELDS
} from '../CommissionActions'

/*----- Utils -----*/
import { serviceErrorsToFormFields } from '../../../../utils/ResponseFormatter' 

export const initialState = {
  isLoading: false,
  success:   '',
  error:     {
    message: '',
    fields:  []
  },
  fields: {
    name:               {},
    subject:            {},
    workload:           {},
    subscribers_amount: {},
    comments:           {},
    year:               {},
    period:             {}
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_COMMISSION:
      return {
        ...state,
        isLoading: true
      }
    case CREATE_COMMISSION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success:   action.payload,
        fields:    initialState.fields
      }
    case CREATE_COMMISSION_ERROR:
      return {
        ...state,
        isLoading: false,
        error:     action.payload.error,
        fields:    serviceErrorsToFormFields(state.fields, action.payload.error.fields)
      }
      case SET_COMMISSION_CREATION_FIELDS:
        return {
          ...state,
          fields: {
            ...state.fields,
            ...action.payload
          }
        }
    default:
      return state
  }
}