const columns = (t) => (
  [
    {
      title:          t('department.name'),
      dataIndex:      'name',
      key:            'name',
      sorter:         (a,b) => a.name.localeCompare(b.name),
      sortDirections: ['ascend','descend']
    }
  ]
)
  
export default columns