/*----- Actions -----*/
import {
  DEPARTMENT_DETAILS, DEPARTMENT_DETAILS_SUCCESS, DEPARTMENT_DETAILS_ERROR
} from '../DepartmentsActions'

const initialState = {
  isLoading:      false,
  error:          null,
  departmentInfo: {
    name:  '',
    users: []
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case DEPARTMENT_DETAILS:
      return {
        ...state,
        isLoading: true
      }
    case DEPARTMENT_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading:      false,
        departmentInfo: action.payload
      }
    case DEPARTMENT_DETAILS_ERROR:
      return {
        ...state,
        isLoading: false,
        error:     action.payload
      }
    default:
      return state;
  }
}