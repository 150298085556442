/*----- Actions -----*/
import {
  GET_PUBLIC_SUBJECT_COMISSIONS,
  GET_PUBLIC_SUBJECT_COMISSIONS_ERROR,
  GET_PUBLIC_SUBJECT_COMISSIONS_SUCCESS
} from '../Actions'

const initialState = {
  isLoading: false,
  data:      {},
  error:     null
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_PUBLIC_SUBJECT_COMISSIONS:
      return {
        ...state,
        isLoading: true
      }
    case GET_PUBLIC_SUBJECT_COMISSIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data:      {
          ...state.data,
          [action.payload.subjectId]: action.payload.data
        }
      }
    case GET_PUBLIC_SUBJECT_COMISSIONS_ERROR:
      return {
        ...state,
        isLoading: false,
        error:     action.payload
      }
    default:
      return state
  }
}