/*----- Imports -----*/
import React, { useEffect, useState } from 'react'
import styled                         from 'styled-components'
import { useTranslation }             from 'react-i18next'
import PropTypes                      from 'prop-types'

/*----- Antd -----*/
import { Form, Icon, Input, Button, Upload, Checkbox } from 'antd'

/*----- API -----*/
import BASE_URL from '../../../../../api/BaseUrl'

/*----- Options -----*/
import { emailFieldOptions, requiredFieldOptions } from './FieldOptions'

const { Item } = Form

const CenteredContainer = styled.div`
  text-align: center;
`

const toBase64 = (file, callback) => {
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload  = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  }).then((result => {
    callback(result)
  }))
}

const uploadButton = (
  <div>
    <Icon type     = 'plus' />
    <div className = 'ant-upload-text'>Upload</div>
  </div>
)

const GeneralDataForm = ({
  form, formFields, isLoading, onSubmit, setUserDataFormFields,
  loadingUserInfo, adminCheckbox
}) => {
  const { t }                     = useTranslation()
  const { getFieldDecorator }     = form
  const [uploadSrc, setUploadSrc] = useState(uploadButton)

  const DniLengthValidator    = (_rule, value, callback) => {
    let len = `${value}`.length
    if (len < 7 || len > 8) {
      callback(t('rules.dni_format'))
    }

    return value
  }

  const RemoveProfilePicture = () => {
    setUserDataFormFields(
      {
        ...formFields,
        profile_picture: {
          ...formFields.profile_picture,
          value: null
        }
      }
    )
  }

  useEffect(
    () => {
      let value = formFields.profile_picture.value
      if (value) {
        if (value.file) {
          toBase64(value.file, setUploadSrc)
        }
        else {
          setUploadSrc(BASE_URL + value)
        }
      }
      else {
        setUploadSrc(uploadButton)
      }
    }, [setUploadSrc, formFields]
  )

  return (
    <Form
      onSubmit         = { onSubmit }
      hideRequiredMark = { true }
    >

      <Item label = { t('user.email') } >
        {
          getFieldDecorator('email', emailFieldOptions(t))(
            <Input
              placeholder = { t('user.email') }
              disabled    = { isLoading || loadingUserInfo }
              prefix      = {
                <Icon type = 'mail' style = { { color: 'rgba(0, 0, 0, .25)' } } />
              }
            />
          )
        }
      </Item>

      <Item label = { t('user.name') } >
        {
          getFieldDecorator(
            'first_name', requiredFieldOptions(t)
          )(
            <Input
              placeholder = { t('user.name') }
              disabled    = { isLoading || loadingUserInfo }
              prefix      = {
                <Icon type = 'user' style = { { color: 'rgba(0, 0, 0, .25)' } } />
              }
            />
          )
        }
      </Item>

      <Item label = { t('user.surname') } >
        {
          getFieldDecorator(
            'last_name', requiredFieldOptions(t)
          )(
            <Input
              placeholder = { t('user.surname') }
              disabled    = { isLoading || loadingUserInfo }
              prefix      = {
                <Icon
                  type  = 'user'
                  style = {{ color: 'rgba(0, 0, 0, .25)' }}
                />
              }
            />
          )
        }
      </Item>

      <Item label = { t('user.dni') } >
        {
          getFieldDecorator(
            'dni', {
              rules: [
                {
                  required: true,
                  message:  t('rules.mandatory_field'),
                },
                {
                  validator: DniLengthValidator
                }
              ],
              validateTrigger: ['onSubmit','onBlur']
            }
          )(
            <Input
              type        = { 'number' }
              placeholder = { t('user.dni') }
              disabled    = { isLoading || loadingUserInfo }
              prefix      = {
                <Icon type = 'number' style = { { color: 'rgba(0, 0, 0, .25)' } } />
              }
            />
          )
        }
      </Item>

      <div style = {{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
        <Item label = { t('user.image') } style = {{ display: 'inline' }} >
          {
            getFieldDecorator(
              'profile_picture',
              {
                valuePropName: 'fileList',
                getValueProps: (value) => {
                  if (!value || typeof value === 'string') {
                    return value
                  }
                  else {
                    return value.file
                  }
                },
                validateTrigger: ['onChange', 'onBlur']
              }
            )(
              <Upload
                name           = 'avatar'
                listType       = 'picture-card'
                showUploadList = { false }
                beforeUpload   = { () => false }
                disabled       = { isLoading || loadingUserInfo }
                accept         = '.jpg, .png, .jpeg'
              >
                {
                  formFields.profile_picture.value ? <img
                    src   = { uploadSrc }
                    alt   = 'avatar'
                    style = {{ width: '100%' }}
                  /> : uploadButton
                }
              </Upload>
            )
          }
        </Item>

        {
          formFields.profile_picture.value &&
            <Icon
              type    = 'delete'
              style   = {{ fontSize: '20px', color: 'red', display: 'inline' }}
              onClick = { RemoveProfilePicture }
            />    
        }
      </div>

      {
        adminCheckbox &&
          <Item>
            {
              getFieldDecorator('admin',{ valuePropName: 'checked' })(
                <Checkbox>
                  { t('admin.admin') }
                </Checkbox>
              )
            }
          </Item>
      }

      <CenteredContainer>
        <Button
          type     = 'primary'
          htmlType = 'submit'
          shape    = 'round'
          size     = 'large'
          style    = {{ cursor: 'pointer' }}
          disabled = { isLoading || loadingUserInfo }
        >
          { t('global.submit') }
        </Button>
      </CenteredContainer>
    </Form>
  )
}

export default GeneralDataForm

GeneralDataForm.defaultProps = {
  loadingUserInfo: false,
  adminCheckbox:   false
}

/*----- PropTypes -----*/
GeneralDataForm.propTypes = {
  form:                  PropTypes.object.isRequired,
  formFields:            PropTypes.object.isRequired,
  isLoading:             PropTypes.bool.isRequired,
  onSubmit:              PropTypes.func.isRequired,
  setUserDataFormFields: PropTypes.func.isRequired,
  loadingUserInfo:       PropTypes.bool.isRequired,
  adminCheckbox:         PropTypes.bool.isRequired
}

