/*----- Imports -----*/
import { call, takeLatest, put } from 'redux-saga/effects'

/*----- Actions -----*/
import { DELETE_USER, DELETE_USER_SUCCESS, DELETE_USER_ERROR, GET_USERS_LIST } from '../UserActions'

/*----- Notifications -----*/
import loadingNotification from '../../../../components/commons/notifications/LoadingNotification'
import successNotification from '../../../../components/commons/notifications/SuccessNotification'
import notifyError         from '../../../../components/commons/notifications/ErrorNotification'

/*----- Endpoints -----*/
import { deleteUser } from '../../../../api/endpoints/Users'

export function* shootDeleteUser(action) {
  yield loadingNotification()
  try {
    const response = yield call(deleteUser, action)
    let deletionResponse
    let type

    if (response.status >= 200 && response.status < 300) {
      type = DELETE_USER_SUCCESS
      yield put( { type: GET_USERS_LIST } )
      yield successNotification()
    } else {
      deletionResponse = yield response.json()
      type             = DELETE_USER_ERROR
      yield notifyError(deletionResponse.error.message)
    }

    yield put(
      {
        type:    type,
        payload: deletionResponse
      }
    )
  }
  catch (error) {
    yield notifyError('¡Ha ocurrido un error inesperado!')
    yield put(
      {
        type:    DELETE_USER_ERROR,
        payload: error.message
      }
    )
  }
}

export default function* deleteUserWatcher() {
  yield takeLatest(DELETE_USER, shootDeleteUser)
}
