/*----- Imports -----*/
import React  from 'react'
import styled from 'styled-components'

/*----- Antd -----*/
import { Icon } from 'antd'

const StyledIcon = styled(Icon)`
  font-size: 35px;
`

export default () => (
  <footer id = 'footer'>
    <div className = 'inner'>
      <ul className = 'icons'>
        <li>
          <a
            href   = 'https://www.facebook.com/pages/Facultad-de-Ciencias-Exactas-UNLP/919982214719720'
            target = '_blank'
            rel    = 'noopener noreferrer'
          >
            <StyledIcon type = 'facebook' />
          </a>
        </li>

        <li>              
          <a
            href   = 'https://twitter.com/exactas_unlp'
            target = '_blank'
            rel    = 'noopener noreferrer'
          >
            <StyledIcon type = 'twitter' />
          </a>
        </li>      

        <li>              
          <a
            href   = 'https://www.youtube.com/channel/UCTIo8Jk0_sdn3A2cDFuZdxw'
            target = '_blank'
            rel    = 'noopener noreferrer'
          >
            <StyledIcon type = 'youtube' />
          </a>
        </li>

        <li>              
          <a
            href   = 'http://www.exactas.unlp.edu.ar/articulo/2015/2/4/tutorial_rss'
            target = '_blank'
            rel    = 'noopener noreferrer'
          >
            <StyledIcon type = 'notification' />
          </a>
        </li>
      </ul>
    </div>
  </footer>
)
