/*----- Imports -----*/
import React    from 'react'
import { Link } from 'react-router-dom'

/*----- Antd -----*/
import { Icon, Divider, Popconfirm } from 'antd'

/*----- CASL ------*/
import { Can } from '../../../../../ability/Can'

/*---- Components -----*/
import ActionButton from '../../../../../components/commons/button/ActionButton'

const columns = (t, isAdmin, deleteSubject, restoreSubject) => (
  [
    {
      title:          t('subjects.code'),
      dataIndex:      'code',
      key:            'code',
      sorter:         (a, b) => a.code.localeCompare(b.code),
      sortDirections: ['ascend', 'descend']
    },
    {
      title:          t('admin.menubar.subjects'),
      dataIndex:      'name',
      key:            'name',
      sorter:         (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['ascend', 'descend']
    },
    {
      title:     t('admin.menubar.departments'),
      dataIndex: 'departments',
      key:       'departments',
      render:    (departments) => (departments.map(department => (department.name)).join(', '))
    },
    {
      title:  t('global.actions'),
      key:    'action',
      render: (_text, record) => (
        <span>
          <>

            <ActionButton>
              <Link to = { `/admin/subjects/${record.id}` } >
                <Icon type = 'eye'/>
              </Link>
            </ActionButton>

            <Divider
              type = 'vertical'
            />

            <Can I = 'delete' a = 'Subject'>
              {
                record.is_active &&
                  <Popconfirm
                    title      = { t('notifications.confirm') }
                    onConfirm  = { () => (deleteSubject(record.id)) }
                    okText     = { t('global.accept') }
                    cancelText = { t('global.cancel') }
                  >
                    <ActionButton>
                      <Icon type = 'delete' style = {{ color:'red' }} />
                    </ActionButton>
                  </Popconfirm>
              }
            </Can>

            <Can I = 'restore' a = 'Subject'>
              {
                !record.is_active &&
                  <Popconfirm
                    title      = { t('notifications.confirm') }
                    onConfirm  = { () => (restoreSubject(record.id)) }
                    okText     = { t('global.accept') }
                    cancelText = { t('global.cancel') }
                  >
                    <ActionButton>
                      <Icon type = 'rollback'/>
                    </ActionButton>
                  </Popconfirm>
              }
            </Can>

          </>
        </span>
      )
    }
  ]
)

export default columns
