export const EXPORT_SURVEY         = 'EXPORT_SURVEY'
export const EXPORT_SURVEY_SUCCESS = 'EXPORT_SURVEY_SUCCESS'
export const EXPORT_SURVEY_ERROR   = 'EXPORT_SURVEY_ERROR'

export const GET_SURVEYS         = 'GET_SURVEYS'
export const GET_SURVEYS_SUCCESS = 'GET_SURVEYS_SUCCESS'
export const GET_SURVEYS_ERROR   = 'GET_SURVEYS_ERROR'

export const GET_SURVEY_DETAILS         = 'GET_SURVEY_DETAILS'
export const GET_SURVEY_DETAILS_SUCCESS = 'GET_SURVEY_DETAILS_SUCCESS'
export const GET_SURVEY_DETAILS_ERROR   = 'GET_SURVEY_DETAILS_ERROR'

export const DELETE_SURVEY         = 'DELETE_SURVEY'
export const DELETE_SURVEY_SUCCESS = 'DELETE_SURVEY_SUCCESS'
export const DELETE_SURVEY_ERROR   = 'DELETE_SURVEY_ERROR'

export const CREATE_SURVEY         = 'CREATE_SURVEY'
export const CREATE_SURVEY_SUCCESS = 'CREATE_SURVEY_SUCCESS'
export const CREATE_SURVEY_ERROR   = 'CREATE_SURVEY_ERROR'

export const GET_QUESTION_CATEGORIES         = 'GET_QUESTION_CATEGORIES'
export const GET_QUESTION_CATEGORIES_SUCCESS = 'GET_QUESTION_CATEGORIES_SUCCESS'
export const GET_QUESTION_CATEGORIES_ERROR   = 'GET_QUESTION_CATEGORIES_ERROR'

export const SET_EXPORT_SURVEY_FIELDS   = 'SET_EXPORT_SURVEY_FIELDS'
export const SET_NEW_SURVEY_FORM_FIELDS = 'SET_NEW_SURVEY_FORM_FIELDS'
export const SET_IMPORT_SURVEY_FIELDS   = 'SET_IMPORT_SURVEY_FIELDS'

export const ADD_QUESTION_FIELDS    = 'ADD_QUESTION_FIELDS'
export const DELETE_QUESTION_FIELDS = 'DELETE_QUESTION_FIELDS'

export const IMPORT_SURVEY_RESULTS         = 'IMPORT_SURVEY_RESULTS'
export const IMPORT_SURVEY_RESULTS_SUCCESS = 'IMPORT_SURVEY_RESULTS_SUCCESS'
export const IMPORT_SURVEY_RESULTS_ERROR   = 'IMPORT_SURVEY_RESULTS_ERROR'

export const GET_IMPORTS         = 'GET_IMPORTS'
export const GET_IMPORTS_SUCCESS = 'GET_IMPORTS_SUCCESS'
export const GET_IMPORTS_ERROR   = 'GET_IMPORTS_ERROR'

export const DELETE_IMPORT         = 'DELETE_IMPORT'
export const DELETE_IMPORT_SUCCESS = 'DELETE_IMPORT_SUCCESS'
export const DELETE_IMPORT_ERROR   = 'DELETE_IMPORT_ERROR'

export const CREATE_QUESTION_CATEGORY         = 'CREATE_QUESTION_CATEGORY'
export const CREATE_QUESTION_CATEGORY_SUCCESS = 'CREATE_QUESTION_CATEGORY_SUCCESS'
export const CREATE_QUESTION_CATEGORY_ERROR   = 'CREATE_QUESTION_CATEGORY_ERROR'

export const SET_QUESTION_CATEGORY_FIELD = 'SET_QUESTION_CATEGORY_FIELD'

export const exportSurvey = (dispatch, survey_id, params) => {
  dispatch(
    {
      type:    EXPORT_SURVEY,
      payload: {
        params:    params,
        survey_id: survey_id
      }
    }
  )
}

export const setExportSurveyFields = (dispatch, fields) => {
  dispatch(
    {
      type:    SET_EXPORT_SURVEY_FIELDS,
      payload: fields
    }
  )
}

export const getSurveys = (dispatch, params) => {
  dispatch(
    {
      type:    GET_SURVEYS,
      payload: params
    }
  )
}

export const deleteSurvey = (dispatch, surveyId) => {
  dispatch(
    {
      type:    DELETE_SURVEY,
      payload: surveyId
    }
  )
}

export const createSurvey = (dispatch, params) => {
  params.questions.forEach(q => {
    if (q.choices){
      q.choices = q.choices.split(',').map(c => c.trim())
    }
  })
  
  dispatch(
    {
      type:    CREATE_SURVEY,
      payload: params
    }
  )
}

export const setNewSurveyFormFields = (dispatch, fields) => {
  dispatch(
    {
      type:    SET_NEW_SURVEY_FORM_FIELDS,
      payload: fields
    }
  )
}

export const getQuestionCategories = (dispatch) => {
  dispatch(
    {
      type: GET_QUESTION_CATEGORIES
    }
  )
}

export const addQuestionFields = (dispatch) => {
  dispatch(
    {
      type: ADD_QUESTION_FIELDS
    }
  )
}

export const deleteQuestionFields = (dispatch, key) => {
  dispatch(
    {
      type:    DELETE_QUESTION_FIELDS,
      payload: key
    }
  )
}

export const setImportSurveyFields = (dispatch, fields) => {
  dispatch(
    {
      type:    SET_IMPORT_SURVEY_FIELDS,
      payload: fields
    }
  )
}

export const importSurveyResults = (dispatch, fields) => {
  const survey_id = fields.survey_id
  const form_data = new FormData()

  fields.survey_file.fileList.forEach(file => {
    form_data.append('survey_file', file.originFileObj)
  })
  
  dispatch(
    {
      type:    IMPORT_SURVEY_RESULTS,
      payload: {
        survey_id: survey_id,
        body:      form_data
      }
    }
  )
}

export const getImportsList = (dispatch, params) => {
  dispatch(
    {
      type:    GET_IMPORTS,
      payload: params
    }
  )
}

export const deleteImport = (dispatch, importId) => {
  dispatch(
    {
      type:    DELETE_IMPORT,
      payload: importId
    }
  )
}

export const getSurveyDetails = (dispatch, surveyId) => {
  dispatch(
    {
      type:    GET_SURVEY_DETAILS,
      payload: surveyId
    }
  )
}

export const createQuestionCategory = (dispatch, category) => {
  dispatch(
    {
      type:    CREATE_QUESTION_CATEGORY,
      payload: category
    }
  )
}

export const setQuestionCategoryFields = (dispatch, fields) => {
  dispatch(
    {
      type:    SET_QUESTION_CATEGORY_FIELD,
      payload: fields
    }
  )
}