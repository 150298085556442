/*----- Imports -----*/
import React    from 'react'
import { Link } from 'react-router-dom'
import styled   from 'styled-components'

/*----- Antd -----*/
import { Icon, Popconfirm, Divider } from 'antd'

/*----- CASL ------*/
import { Can } from '../../../../../ability/Can'

/*----- Components -----*/
import ActionButton from '../../../../../components/commons/button/ActionButton'

const Container = styled.div`
  width:           100%;
  display:         flex;
  justify-content: space-around;
  align-items:     center;
`

const columns = (t, deleteCommission, restoreCommission, filters) => (
  [
    {
      title:          t('commission.name'),
      dataIndex:      'name',
      key:            'name',
      sorter:         (a,b) => a.name.localeCompare(b.name),
      sortDirections: ['ascend', 'descend']
    },
    {
      title:          t('commission.subject'),
      dataIndex:      'subject.name',
      key:            'subject',
      sorter:         (a, b) => a.subject.name.localeCompare(b.subject.name),
      sortDirections: ['ascend', 'descend']
    },
    {
      title:          t('commission.workload'),
      dataIndex:      'workload',
      key:            'workload',
      sorter:         (a, b) => (a.workload > b.workload),
      sortDirections: ['ascend', 'descend']
    },
    {
      title:          t('commission.subscribers_amount'),
      dataIndex:      'subscribers_amount',
      key:            'subscribers_amount',
      sorter:         (a, b) => (a.subscribers_amount > b.subscribers_amount),
      sortDirections: ['ascend', 'descend']
    },
    {
      title:          t('commission.year'),
      dataIndex:      'year',
      key:            'year',
      sorter:         (a, b) => (a.year > b.year),
      sortDirections: ['ascend', 'descend']
    },
    {
      title:          t('commission.period.title'),
      dataIndex:      'period',
      key:            'period',
      sorter:         (a, b) => a.period.localeCompare(b.period),
      sortDirections: ['ascend', 'descend']
    },
    {
      title:  t('global.actions'),
      key:    'action',
      render: (_text, record) => (
        <Container>
          
          {
            record.is_active &&
              <ActionButton>
                <Link to = { `/admin/commissions/${record.id}` } >
                  <Icon type = 'eye'/>
                </Link>
              </ActionButton>
          }

          <Can I = 'delete' a = 'Commission'>
            {
              record.is_active &&
                <Container>
                  <Divider type = 'vertical' />
                  <Popconfirm
                    title      = { t('notifications.confirm') }
                    onConfirm  = { () => deleteCommission(record.subject.id, record.id) }
                    okText     = { t('global.accept') }
                    cancelText = { t('global.cancel') }
                  >
                    <ActionButton>
                      <Icon type = 'delete' style = {{ color:'red' }} />
                    </ActionButton>
                  </Popconfirm>
                </Container>
            }
          </Can>

          <Can I = 'restore' a = 'Commission'> 
            {
              !record.is_active && (
                <Popconfirm
                  title      = { t('notifications.confirm') }
                  onConfirm  = { () => restoreCommission(record.subject.id, record.id, filters) }
                  okText     = { t('global.accept') }
                  cancelText = { t('global.cancel') }
                >
                  <ActionButton>
                    <Icon type = 'rollback'/>
                  </ActionButton>
                </Popconfirm>
              )
            }
          </Can>
        </Container>
      )
    }
  ]
)

export default columns
