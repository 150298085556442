/*----- Notifications -----*/
import { GET_CURRENT_USER, GET_CURRENT_USER_SUCCESS, GET_CURRENT_USER_ERROR } from "./CurrentUserActions"

const initialState = {
  isLoading: false,
  error:     null,
  loaded:    false,
  user:      {
    first_name:      '',
    last_name:       '',
    is_staff:        true,
    modify_password: false,
    first_login:     false
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_CURRENT_USER:
      return {
        ...state,
        isLoading: true
      }
    case GET_CURRENT_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        loaded:    true,
        user:      action.payload
      }
    case GET_CURRENT_USER_ERROR:
      return {
        ...state,
        isLoading: false,
        error:     action.payload
      }
    default:
      return state;
  }
}