export const GET_PUBLIC_SUBJECTS         = 'GET_PUBLIC_SUBJECTS'
export const GET_PUBLIC_SUBJECTS_ERROR   = 'GET_PUBLIC_SUBJECTS_ERROR'
export const GET_PUBLIC_SUBJECTS_SUCCESS = 'GET_PUBLIC_SUBJECTS_SUCCESS'

export const GET_PUBLIC_USERS         = 'GET_PUBLIC_USERS'
export const GET_PUBLIC_USERS_SUCCESS = 'GET_PUBLIC_USERS_SUCCESS'
export const GET_PUBLIC_USERS_ERROR   = 'GET_PUBLIC_USERS_ERROR'

export const GET_SUBJECT_GRAPHICS         = 'GET_SUBJECT_GRAPHICS'
export const GET_SUBJECT_GRAPHICS_ERROR   = 'GET_SUBJECT_GRAPHICS_ERROR'
export const GET_SUBJECT_GRAPHICS_SUCCESS = 'GET_SUBJECT_GRAPHICS_SUCCESS'
export const RESET_SUBJECT_GRAPHICS       = 'RESET_SUBJECT_GRAPHICS'

export const GET_TEACHER_GRAPHICS         = 'GET_TEACHER_GRAPHICS'
export const GET_TEACHER_GRAPHICS_ERROR   = 'GET_TEACHER_GRAPHICS_ERROR'
export const GET_TEACHER_GRAPHICS_SUCCESS = 'GET_TEACHER_GRAPHICS_SUCCESS'
export const RESET_TEACHER_GRAPHICS       = 'RESET_TEACHER_GRAPHICS'

export const GET_PUBLIC_SUBJECT_COMISSIONS         = 'GET_PUBLIC_SUBJECT_COMISSIONS'
export const GET_PUBLIC_SUBJECT_COMISSIONS_SUCCESS = 'GET_PUBLIC_SUBJECT_COMISSIONS_SUCCESS'
export const GET_PUBLIC_SUBJECT_COMISSIONS_ERROR   = 'GET_PUBLIC_SUBJECT_COMISSIONS_ERROR'

export const getSubjects = (dispatch, data) => dispatch({ type: GET_PUBLIC_SUBJECTS, payload: data })

export const getUsers = (dispatch) => dispatch({ type: GET_PUBLIC_USERS })

export const resetSubjectsGraphics = (dispatch) => dispatch({ type: RESET_SUBJECT_GRAPHICS })

export const getSubjectGraphics = (dispatch, data) => (
  dispatch({ type: GET_SUBJECT_GRAPHICS, payload: { body: data }})
)

export const getTeacherGraphics = (dispatch, data) => (
  dispatch({ type: GET_TEACHER_GRAPHICS, payload: { body: data }})
)

export const resetTeachersGraphics = (dispatch) => dispatch({ type: RESET_TEACHER_GRAPHICS })

export const getSubjectComissions = (dispatch, subjectId, data = {}) => (
  dispatch({ type: GET_PUBLIC_SUBJECT_COMISSIONS, payload: { subjectId: subjectId, body: data }})
)
