/*----- Imports -----*/
import React, { useEffect, useState, useRef } from 'react'
import { connect }                            from 'react-redux'
import { withRouter }                         from 'react-router'

/*----- Selectors -----*/
import { loadingUsersSelector, usersListSelector } from './selectors/usersListSelector'
import { currentUserSelector }                     from '../CurrentUserSelector'

/*----- Actions -----*/
import { getUsers, deleteUser, activateUser } from './UserActions'

/*----- View -----*/
import MainView from './views/list/MainView'

const ListViewContainer = ( { isLoading, users, getUsers, deleteUser, currentUser, activateUser } ) => {

  const [blocked, setBlocked] = useState('')
  const [search, setSearch]   = useState('')

  const onSearchHandler = (value) => {
    setSearch(value)
    getUsers(value, blocked)
  }

  const onChangeHandler = (value) =>{
    setBlocked(value)
    getUsers(search, value)
  }

  const input_search  = useRef()

  const cleanButtonHandler = () =>{
    input_search.current.input.state.value = ''
    setBlocked('')
    setSearch('')
    getUsers()
  }

  const activateButtonHandler = (user_id) =>{
    activateUser(user_id, blocked, search)
  }

  useEffect(() => {
    getUsers()
  }, [getUsers])

  const is_admin = currentUser && currentUser.is_staff

  return(
    <MainView
      isAdmin               = { is_admin }
      searchHandler         = { onSearchHandler }
      onChangeHandler       = { onChangeHandler }
      cleanButtonHandler    = { cleanButtonHandler }
      activateButtonHandler = { activateButtonHandler }
      users                 = { users }
      isLoading             = { isLoading }
      inputRef              = { input_search }
      deleteUser            = { deleteUser }
    />
  )
}

function mapStateToProps(state) {
  return {
    isLoading:   loadingUsersSelector(state),
    users:       usersListSelector(state),
    currentUser: currentUserSelector(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    deleteUser:                    (user_id) => deleteUser(dispatch, user_id),
    getUsers:    (search = '', blocked = '') => getUsers(dispatch, search, blocked),
    activateUser: (user_id, blocked, search) => activateUser(dispatch, user_id, blocked, search)
  }
}

const ConnectedList = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ListViewContainer))

ConnectedList.defaultProps = {
  permission:   'list',
  model:        'User',
  defaultRoute: '/admin'
}

export default ConnectedList
