/*----- Actions -----*/
import {
  PUT_CHANGE_PASSWORD, PUT_CHANGE_PASSWORD_SUCCESS,
  PUT_CHANGE_PASSWORD_ERROR, SET_CHANGE_PASSWORD_FORM_FIELDS
} from "../UserActions"

/*----- Utils -----*/
import { serviceErrorsToFormFields } from '../../../../utils/ResponseFormatter' 

const initialState = {
  isLoading: false,
  success:   {},
  error: {
    message: '',
    fields: []
  },
  fields: {
    old_password: {},
    new_password: {},
    new_password_repeat: {}
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case PUT_CHANGE_PASSWORD:
      return {
        ...state,
        isLoading: true
      }
    case PUT_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success:   action.payload,
        fields:    initialState.fields
      }
    case PUT_CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        isLoading: false,
        error:     action.payload,
        fields:    serviceErrorsToFormFields(state.fields, action.payload.error.fields)

      }
    case SET_CHANGE_PASSWORD_FORM_FIELDS:
      return {
        ...state,
        fields: {
          ...state.fields,
          ...action.payload
        }
      }
    default:
      return state;
  }
}