/*----- Imports -----*/
import React              from 'react'
import PropTypes          from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled             from 'styled-components'

/*----- Antd -----*/
import { Alert } from 'antd'

/*----- Components -----*/
import SearchInput from '../../../../../components/commons/input/SearchInput'

/*----- Views -----*/
import Table         from '../../../users/views/list/Table'
import Card          from './Card'
import users_columns from './UsersColumns'

const StyledSearchInput = styled(SearchInput)`
  padding-top:   2%;
  margin-bottom: 5%;
`

const Users = ( { users, isLoading, className, getUsers, deleteHandler, isAdmin, departmentId, extra } ) => {
  const { t }      = useTranslation()
  const show_users = (users.length > 0)

  return(
    <Card
      className = { className }
      title     = { t('department.staff') }
      type      = 'inner'
      extra     = { extra }
    >

      {
        (show_users &&
          <>
            <StyledSearchInput
              placeholder     = { t('global.search_placeholder') }
              onSearchHandler = { (value) => { getUsers(departmentId, value) } }
              allowClear      = { true }
            />

            <Table
              users     = { (users) }
              isLoading = { isLoading }
              columns   = { users_columns(t, deleteHandler, isAdmin, departmentId) }
            />
          </>
        ) ||
          <Alert message = { t('department.no_staff') } type = 'warning' />
      }
  
    </Card>
  )
}

export default Users

/*----- PropTypes -----*/
Users.propTypes = {
  users:         PropTypes.array.isRequired,
  isLoading:     PropTypes.bool.isRequired,
  className:     PropTypes.string,
  isAdmin:       PropTypes.bool.isRequired,
  departmentId:  PropTypes.number,
  getUsers:      PropTypes.func.isRequired,
  deleteHandler: PropTypes.func.isRequired,
  extra:         PropTypes.node
}
