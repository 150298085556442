import {
    RESTORE_PASSWORD, RESTORE_PASSWORD_FAIL, RESTORE_PASSWORD_SUCCESS
  } from "../RestorePasswordActions"

const initialState = {
  isLoading: false,
  error:     '',
  success:   ''
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case RESTORE_PASSWORD:
      return {
        ...state,
        isLoading: true
      }
    case RESTORE_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case RESTORE_PASSWORD_FAIL:
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
