/*----- Imports -----*/
import React     from 'react'
import PropTypes from 'prop-types'

/*----- Antd -----*/
import { Table } from 'antd'

/*----- Constants -----*/
import { PAGE_SIZE } from '../../../../../utils/Constants'

const ListTable = ( { subjects, isLoading, columns, className } ) => {
  return(
    <Table
      rowKey     = { (subject) => subject.id }
      dataSource = { subjects } 
      columns    = { columns } 
      size       = 'medium' 
      loading    = { isLoading }
      pagination = { { pageSize: parseInt(PAGE_SIZE) } }
      scroll     = { { x: '400px'} }
      className  = { className }
      bordered
    />
  )
}

export default ListTable

/*----- PropTypes -----*/
ListTable.propTypes = {
  subjects:  PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  columns:   PropTypes.array.isRequired,
  className: PropTypes.string
}