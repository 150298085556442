/*----- Imports -----*/
import React, { forwardRef } from 'react'
import PropTypes             from 'prop-types'

/*----- Antd -----*/
import { Input } from 'antd'

const { Search } = Input

const SearchInput = ({ className, placeholder, onSearchHandler, allowClear = false }, ref) => {
  return(
    <Search
      ref          = { ref } 
      placeholder  = { placeholder }
      onSearch     = { onSearchHandler }
      allowClear   = { allowClear }
      className    = { className }
      enterButton
    />
  )
}

export default forwardRef(SearchInput)

/*----- PropTypes -----*/
SearchInput.propTypes = {
  placeholder:     PropTypes.string.isRequired,
  onSearchHandler: PropTypes.func.isRequired,
  allowClear:      PropTypes.bool,
  className:       PropTypes.string
}
