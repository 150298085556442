/*----- Imports -----*/
import React, { useState, useMemo } from 'react'
import styled                       from 'styled-components'
import PropTypes                    from 'prop-types'
import { useTranslation }           from 'react-i18next'

/*----- Antd -----*/
import { Card, Typography, Skeleton } from 'antd'

/*----- Utils -----*/
import questionFormatter from './QuestionFormatter'

const { Title } = Typography

const Container = styled.div`
  padding: 5%;
  width:   100%;
`

const MainView = ({ surveyInfo, isLoading }) => {
  const [key, setKey]         = useState('')
  const [tabList, setTabList] = useState([])
  const { t }                 = useTranslation() 
  
  const onTabChange = (key) => { setKey(key) }

  const questionListFormatter = (key) => (
    surveyInfo.questions.filter(question => question.category === key).map(
      question => questionFormatter(question, t)
    )
  )

  useMemo(() => {
    const categories = surveyInfo.questions.map(question => question.category)
    
    const unique_categories = []
    categories.forEach(category => {
      if (!unique_categories.includes(category)){
        unique_categories.push(category)
      }
    })
    
    const newTabList = unique_categories.map((element) => (
      ({ key: element, tab: element })
    ))
    setTabList(newTabList)
  }, [setTabList, surveyInfo])

  return (
    <Container>
      <Skeleton active loading = { isLoading }>
        <Typography>
          <Title level = { 2 }>
            { `${surveyInfo.name}` }
          </Title>
        </Typography>
        <Card
          title        = { t('survey.questions_by_category') }
          tabList      = { tabList }
          activeTabKey = { key }
          onTabChange  = { onTabChange }
          loading      = { isLoading }
        >
          { key ? questionListFormatter(key) : t('survey.select_a_category') }
        </Card>
      </Skeleton>
    </Container>
  )
}

export default MainView

/*----- PropTypes -----*/
MainView.propTypes = {
  surveyInfo: PropTypes.object.isRequired,
  isLoading:  PropTypes.bool.isRequired
}