/*----- Imports -----*/
import React, { useState, useMemo, useCallback } from 'react'
import PropTypes                                 from 'prop-types'
import { useTranslation }                        from 'react-i18next'
import { connect }                               from 'react-redux'

/*----- Antd -----*/
import { Select, Form, Row, Col } from 'antd'

/*----- Selectors -----*/
import { subjectsListSelector, subjectsLoadingSelector } from '../selectors/Subjects'
import {
  commissionsBySubjectSelector,
  subjectCommissionsLoadingSelector
} from '../selectors/SubjectCommissions'

/*----- Actions -----*/
import { getSubjectComissions } from '../Actions'

/*----- Utils -----*/
import mapRecordsToOptions from '../../../utils/SelectOptionsMapper'

/*----- Validations -----*/
import { requiredFieldOptions } from '../Validations'

/*----- Components -----*/
import PeriodsSelect from '../../../components/commons/form/PeriodsSelect'
import YearPicker    from '../../../components/commons/form/YearPicker'

const { Item } = Form

const filterOption = (input, option) => (
  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
)

const FilterFields = ({
  form, index, subjects, isLoadingSubjects,
  getSubjectComissions, commissionsBySubject, subjectCommissionsLoading
}) => {
  const [fields, setFields]   = useState({})
  const { t }                 = useTranslation()
  const { getFieldDecorator } = form

  const onSubjectChange = (subjectId) => {
    form.resetFields([`subjects[${index}].commission_id`])

    setFields({ ...fields, subjectId: subjectId })

    if (subjectId && !subjectCommissions(subjectId)) {
      getSubjectComissions(subjectId)
    }
  }

  const subjectCommissions = useCallback(
    (subjectId = fields.subjectId) => (
      commissionsBySubject[parseInt(subjectId)]
    ),
    [commissionsBySubject, fields.subjectId],
  )

  const filteredCommissions = useMemo(
    () => {
      let commissions = subjectCommissions()

      if (!commissions) {
        return []
      }

      if (fields.year) {
        commissions = commissions.filter((commission) => commission.year === fields.year.year())
      }

      if (fields.period) {
        commissions = commissions.filter(
          (commission) => commission.period.toLowerCase() === fields.period.toLowerCase()
        )
      }

      return commissions
    },
    [subjectCommissions, fields]
  )

  return(
    <Row gutter = { 16 }>

      <Col
        xs = { 24 }
        sm = { 24 }
        md = { 24 }
        lg = { 6  }
        xl = { 6  }
      >
        <Item>
          {
            getFieldDecorator(`subjects[${index}].subject_id`, requiredFieldOptions(t))(
              <Select
                filterOption = { filterOption }
                placeholder  = { t('subjects.subject') }
                size         = 'large'
                disabled     = { isLoadingSubjects }
                onChange     = { onSubjectChange }
                showSearch
                showArrow
                allowClear
              >
                { mapRecordsToOptions(subjects) }
              </Select>
            )
          }
        </Item>
      </Col>

      <Col
        xs = { 24 }
        sm = { 24 }
        md = { 24 }
        lg = { 6  }
        xl = { 6  }
      >
        <Item>
          {
            getFieldDecorator(`subjects[${index}].year`)(
              <YearPicker
                disabled = { isLoadingSubjects }
                onChange = { (value) => setFields({ ...fields, year: value }) }
              />
            )
          }
        </Item>
      </Col>

      <Col
        xs = { 24 }
        sm = { 24 }
        md = { 24 }
        lg = { 6  }
        xl = { 6  }
      >
        <Item>
          {
            getFieldDecorator(`subjects[${index}].period`)(
              <PeriodsSelect
                isLoading = { isLoadingSubjects }
                onChange  = { (value) => setFields({ ...fields, period: value }) }
              />
            )
          }
        </Item>
      </Col>

      <Col
        xs = { 24 }
        sm = { 24 }
        md = { 24 }
        lg = { 6  }
        xl = { 6  }
      >
        <Item>
          {
            getFieldDecorator(`subjects[${index}].commission_id`)(
              <Select
                placeholder = { (filteredCommissions.length === 0 && !subjectCommissionsLoading) ?  t('public.graphics.empty_commissions') : t('public.graphics.all_commissions')}
                size        = 'large'
                disabled    = { isLoadingSubjects || filteredCommissions.length === 0 }
                loading     = { subjectCommissionsLoading }
                showArrow
                allowClear
              >
                { mapRecordsToOptions(filteredCommissions) }
              </Select>
            )
          }
        </Item>
      </Col>

    </Row>
  )
}

const mapStateToProps = (state) => (
  {
    subjects:                  subjectsListSelector(state),
    commissionsBySubject:      commissionsBySubjectSelector(state),
    isLoadingSubjects:         subjectsLoadingSelector(state),
    subjectCommissionsLoading: subjectCommissionsLoadingSelector(state)
  }
)

const mapDispatchToProps = (dispatch) => (
  {
    getSubjectComissions: (subjectId) => getSubjectComissions(dispatch, subjectId)
  }
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterFields)

/*----- PropTypes -----*/
FilterFields.propTypes = {
  form:                      PropTypes.object.isRequired,
  index:                     PropTypes.number.isRequired,
  subjects:                  PropTypes.array.isRequired,
  isLoadingSubjects:         PropTypes.bool.isRequired,
  subjectCommissionsLoading: PropTypes.bool.isRequired
}
