/*----- Imports -----*/
import React, { useEffect, useState } from 'react'
import { connect }                    from 'react-redux'

/*----- Selectors -----*/
import { loadingSelector, commissionsSelector } from './selectors/CommissionsListSelector'

import {
  loadingDepartmentsSelector, departmentsListSelector
} from '../departments/selectors/DepartmentsListSelector'

import {
  subjectsListSelector, loadingSubjectsSelector
} from '../subjects/selectors/SubjectsListSelector'

/*----- Actions -----*/
import { getDepartments } from '../departments/DepartmentsActions'
import { getSubjects }    from '../subjects/SubjectsActions'

import {
  getCommissions, deleteCommission,
  restoreCommission, getCommissionsFile
} from './CommissionActions'

/*----- View -----*/
import MainView from './views/list/MainView'

const DEFAULT_FILTERS = {
  search:      '',
  blocked:     false,
  subjects:    [],
  departments: [],
  year:        '',
  period:      ''
}

const ListContainer = (
  {
    getCommissions, commissions, isLoading, deleteCommission, restoreCommission,
    getDepartments, getSubjects, loadingSubjects, loadingDepartments, departments,
    subjects, getCommissionsFile
  }
) => {
  const [filters, setFilters] = useState(DEFAULT_FILTERS)

  const onFiltersChange = (params) => {
    const updated_filters = Object.assign(filters, params)

    setFilters(updated_filters)
    getCommissions(updated_filters)
  }

  const downloadFile = (format) => getCommissionsFile(filters, format)

  useEffect(() => {
    getDepartments()
  }, [getDepartments])

  useEffect(() => {
    getSubjects()
  }, [getSubjects])

  useEffect(
    () => getCommissions(),
    [getCommissions]
  )

  const filteredSubjects = () => {
    if (filters.departments.length === 0) return subjects

    let department_ids
    return subjects.filter((subject) => {
      department_ids = subject.departments.map((department) => department.id)

      return department_ids.some((id) => filters.departments.includes(id.toString()))
    })
  }

  return(
    <MainView
      commissions       = { commissions }
      isLoading         = { isLoading }
      loadingFilters    = { loadingSubjects || loadingDepartments }
      departments       = { departments }
      subjects          = { filteredSubjects() }
      onFiltersChange   = { onFiltersChange }
      deleteCommission  = { deleteCommission }
      restoreCommission = { restoreCommission }
      downloadFile      = { downloadFile }
      filters           = { filters }
    />
  )
}

const mapStateToProps = (state) => (
  {
    isLoading:          loadingSelector(state),
    commissions:        commissionsSelector(state),
    loadingSubjects:    loadingSubjectsSelector(state),
    loadingDepartments: loadingDepartmentsSelector(state),
    departments:        departmentsListSelector(state),
    subjects:           subjectsListSelector(state)
  }
)

const mapDispatchToProps = (dispatch) => (
  {
    getCommissions:                              (params) => getCommissions(dispatch, params),
    deleteCommission:           (subjectId, commissionId) => deleteCommission(dispatch, subjectId, commissionId),
    restoreCommission: (subjectId, commissionId, filters) => restoreCommission(dispatch, subjectId, commissionId, filters),
    getDepartments:                                    () => getDepartments(dispatch),
    getSubjects:                                       () => getSubjects(dispatch),
    getCommissionsFile:                  (params, format) => getCommissionsFile(dispatch, params, format)
  }
)

const ConnectedListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ListContainer)

ConnectedListContainer.defaultProps = {
  permission:   'list',
  model:        'Commission',
  defaultRoute: '/admin'
}

export default ConnectedListContainer
