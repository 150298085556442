/*----- Imports -----*/
import React, { useEffect } from 'react'
import { connect }          from 'react-redux'
import styled               from 'styled-components'
import { useTranslation }   from 'react-i18next'

/*----- Actions -----*/
import { createUser, setRegisterFormFields } from './UserActions'

/*----- Selectors -----*/
import { loadingSelector, creationFormFieldsSelector } from './selectors/createUserSelector'

/*----- Antd -----*/
import { Form } from 'antd'

/*----- Components -----*/
import FormBox from '../../../components/commons/form/FormBox'

/*----- Form -----*/
import GeneralDataForm from './views/forms/GeneralDataForm'

const StyledRegisterForm = styled.section`
  margin: 5%;
  .form-box__data-container {
    width: 60%;
  }
`
const CLEAN_STATE_FIELDS = { email: {}, first_name: {}, last_name: {}, dni: {}, profile_picture: {} }

const hasErrors = (fieldsError) => Object.keys(fieldsError).some(field => fieldsError[field])

const RegisterForm = ({ form, createUser, isLoading, formFields, setRegisterFormFields } ) => {
  const { t } = useTranslation()

  useEffect(
    () => () => setRegisterFormFields(CLEAN_STATE_FIELDS),
    [setRegisterFormFields]
  )

  const handleSubmit = (event) => {
    event.preventDefault()
    form.validateFields()

    if (!hasErrors(form.getFieldsError())) {
      createUser(form.getFieldsValue())
    }
  }

  return (
    <StyledRegisterForm>
      <FormBox title = { t('admin.register_form.title') } >
        <GeneralDataForm
          onSubmit              = { handleSubmit }
          isLoading             = { isLoading }
          form                  = { form }
          formFields            = { formFields }
          adminCheckbox         = { true }
          setUserDataFormFields = { setRegisterFormFields }
        />
      </FormBox>
    </StyledRegisterForm>
  )
}

const mapStateToProps = (state) => (
 {
    isLoading:  loadingSelector(state),
    formFields: creationFormFieldsSelector(state)
  }
)

const mapDispatchToProps = (dispatch) => (
  {
    createUser:            (params) => createUser(dispatch, params),
    setRegisterFormFields: (fields) => setRegisterFormFields(dispatch, fields)
  }
)

const ConnectedRegisterForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create({
    name: 'create_user_form',
    onFieldsChange({ setRegisterFormFields }, changedFields) {
      if (changedFields.profile_picture) {
        changedFields = {
          ...changedFields,
          profile_picture: {
            ...changedFields.profile_picture,
            errors: null
          }
        }
      }

      setRegisterFormFields(changedFields)
    },
    mapPropsToFields({ formFields }) {
      return {
        email:           Form.createFormField(formFields.email),
        dni:             Form.createFormField(formFields.dni),
        first_name:      Form.createFormField(formFields.first_name),
        last_name:       Form.createFormField(formFields.last_name),
        profile_picture: Form.createFormField(formFields.profile_picture),
        admin:           Form.createFormField(formFields.admin)
      }
    }
  })(RegisterForm)
)

ConnectedRegisterForm.defaultProps = {
  permission:   'create',
  model:        'User',
  defaultRoute: '/admin'
}

export default ConnectedRegisterForm
  