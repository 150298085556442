/*----- Saga -----*/
import { call, takeLatest, put } from 'redux-saga/effects'

/*----- Actions -----*/
import {
  GET_PUBLIC_SUBJECT_COMISSIONS,
  GET_PUBLIC_SUBJECT_COMISSIONS_ERROR,
  GET_PUBLIC_SUBJECT_COMISSIONS_SUCCESS
} from '../Actions'

/*----- Endpoint -----*/
import { getGraphicableCommissions } from '../../../api/endpoints/Public'

/*----- Notifications -----*/
import notifyError from '../../../components/commons/notifications/ErrorNotification'

function* fetchData(action) {
  try {
    const response = yield call(getGraphicableCommissions, action)
    const body     = yield response.json()
    let type

    if (response.status >= 200 && response.status < 300) {
      type = GET_PUBLIC_SUBJECT_COMISSIONS_SUCCESS
    } else {
      type = GET_PUBLIC_SUBJECT_COMISSIONS_ERROR
    }

    yield put(
      {
        type:    type,
        payload: {
          data:      body,
          subjectId: action.payload.subjectId
        }
      }
    )
  }
  catch (error) {
    yield notifyError('¡Ha ocurrido un error inesperado!')
    yield put(
      {
        type:    GET_PUBLIC_SUBJECT_COMISSIONS_ERROR,
        payload: error.message
      }
    )
  }
}

export default function* () {
  yield takeLatest(GET_PUBLIC_SUBJECT_COMISSIONS, fetchData)
}
