/*----- Actions -----*/
import { LOGOUT, LOGOUT_SUCCESS, LOGOUT_FAIL } from '../LogoutActions'

/*----- Services -----*/
import { destroySession } from '../../../../utils/SessionService'

const initialState = {
  isLoading: false,
  error:     ''
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOGOUT:
      return {
        ...state,
        isLoading: true
      }
    case LOGOUT_SUCCESS:
      destroySession()
      return {
        ...state,
        isLoading: false,
      }
    case LOGOUT_FAIL:
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}