/*----- Actions -----*/
import { GET_ROLES_LIST, GET_ROLES_LIST_SUCCESS, GET_ROLES_LIST_ERROR } from '../RolesActions'

const initialState = {
  isLoading: false,
  roles:     [],
  error:     null
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_ROLES_LIST:
      return {
        ...state,
        isLoading: true
      }
    case GET_ROLES_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        roles:     action.payload
      }
    case GET_ROLES_LIST_ERROR:
      return {
        ...state,
        isLoading: false,
        error:     action.payload
      }
    default:
      return state
  }
}
