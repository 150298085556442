/*----- Imports -----*/
import React, { useEffect, useState } from 'react'
import { connect }                    from 'react-redux'

/*----- Selectors -----*/
import { currentUserSelector } from '../CurrentUserSelector'
import {
  loadingDepartmentsSelector,
  departmentsListSelector
} from './selectors/DepartmentsListSelector'

/*----- Actions -----*/
import { getDepartments, deleteDepartment, restoreDepartment } from './DepartmentsActions'

/*----- View -----*/
import MainView from './views/list/MainView'

const ListContainer = (
  {
    getDepartments, departments, isLoading, currentUser,
    deleteDepartment, restoreDepartment
  }
) => {
  const [search, setSearch]   = useState('')
  const [blocked, setBlocked] = useState(false)
  
  const onSearchHandler = (value) => {
    setSearch(value)
    getDepartments(value, blocked)
  }

  const blockedSelectChangeHandler = (value) => {
    setBlocked(value)
    getDepartments(search, value)
  }

  useEffect(
    () => { getDepartments() },
    [getDepartments]
  )
  
  return(
    <MainView
      departments                = { departments }
      searchHandler              = { onSearchHandler }
      blockedSelectChangeHandler = { blockedSelectChangeHandler }
      isLoading                  = { isLoading }
      isAdmin                    = { currentUser && currentUser.is_staff }
      deleteDepartment           = { deleteDepartment }
      restoreDepartment          = { restoreDepartment }
    />
  )
}

const mapStateToProps = (state) => {
  return {
    isLoading:   loadingDepartmentsSelector(state),
    departments: departmentsListSelector(state),
    currentUser: currentUserSelector(state)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getDepartments: (value = '', blocked = false) => getDepartments(dispatch, value, blocked),
    deleteDepartment:              (departmentId) => deleteDepartment(dispatch, departmentId),
    restoreDepartment:             (departmentId) => restoreDepartment(dispatch, departmentId)
  }
}

const ConnectedListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ListContainer)

ConnectedListContainer.defaultProps = {
  permission:   'list',
  model:        'Department',
  defaultRoute: '/admin'
}

export default ConnectedListContainer