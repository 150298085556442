/*----- Imports -----*/
import React, { useState, forwardRef } from 'react'
import { useTranslation }              from 'react-i18next'
import PropTypes                       from 'prop-types'

/*----- Antd -----*/
import { DatePicker } from 'antd'

const YearInput = ({ value, onChange, disabled }, ref) => {
  const [isYearPickerOpen, setIsYearPickerOpen] = useState(false)

  const { t } = useTranslation()

  const onPanelChangeHandler = changedValue => {
    if (onChange) {
      onChange(changedValue)
    }

    setIsYearPickerOpen(false)
  }

  return(
    <DatePicker
      ref           = { ref }
      disabled      = { disabled }
      value         = { value }
      mode          = 'year'
      format        = 'YYYY'
      open          = { isYearPickerOpen }
      placeholder   = { t('commission.year') }
      style         = { { width: '100%' } }
      size          = 'large'
      onOpenChange  = { (status) => setIsYearPickerOpen(status) }
      onChange      = { onChange }
      onPanelChange = { onPanelChangeHandler }
    />
  )
}

export default forwardRef(YearInput)

/*----- PropTypes -----*/
YearInput.propTypes = {
  value:    PropTypes.object.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
}
