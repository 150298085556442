import { all }           from 'redux-saga/effects'
import loginUserSagas    from './LoginUserSagas'
import refreshTokenSagas from './RefreshTokenSagas'
import logoutUserSagas   from './LogoutUserSagas'

export default function* dispatchSagas(){
  yield all([
    loginUserSagas(),
    refreshTokenSagas(),
    logoutUserSagas()
  ])
}
