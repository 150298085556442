/*----- API -----*/
import BASE_URL from '../BaseUrl'
import { 
  SURVEY_RESOURCE,
  EXPORT_RESOURCE,
  QUESTION_CATEGORIES_RESOURCE,
  IMPORT_RESOURCE,
  SURVEY_IMPORTS_RESOURCE 
} from '../Resources'

/*----- Authorization -----*/
import { getAuthToken } from '../../utils/SessionService'

/*----- Decorators -----*/
import refreshableDecorator from '../../decorators/RefreshableDecorator'

/*----- Utils -----*/
import { objToQueryString } from '../../utils/ParameterSanitizer'

export const exportSurvey = refreshableDecorator(
  function* (action) {
    const params = objToQueryString(action.payload.params)
    
    return yield fetch(
      `${BASE_URL}/${SURVEY_RESOURCE}/${action.payload.survey_id}/${EXPORT_RESOURCE}/?${params}`,
      {
        method:  'GET',
        headers: {
          'Content-Type':  'application/json',
          'Authorization': `Bearer ${getAuthToken()}`
        }
      }
    )
  }
)

export const getSurveys = refreshableDecorator(
  function* (action) {
    const params = objToQueryString(action.payload)

    return yield fetch(
      `${BASE_URL}/${SURVEY_RESOURCE}/?${params}`,
      {
        method: 'GET',
        headers: {
          'Content-Type':  'application/json',
          'Authorization': `Bearer ${getAuthToken()}`
        }
      }
    )
  }
)

export const deleteSurvey = refreshableDecorator(
  function* (action) {
    return yield fetch(
      `${BASE_URL}/${SURVEY_RESOURCE}/${action.payload}/`,
      {
        method:  'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAuthToken()}`
        }
      }
    )
  }
)

export const createSurvey = refreshableDecorator(
  function* (action) {
    
    return yield fetch(
      `${BASE_URL}/${SURVEY_RESOURCE}/`,
      {
        method: 'POST',
        body:    JSON.stringify(action.payload),
        headers: {
          'Content-Type':  'application/json',
          'Authorization': `Bearer ${getAuthToken()}`
        }
      }
    )
  }
)

export const getQuestionCategories = refreshableDecorator(
  function* (_action) {
    return yield fetch(
      `${BASE_URL}/${QUESTION_CATEGORIES_RESOURCE}/`,
      {
        method:  'GET',
        headers: {
          'Content-Type':  'application/json',
          'Authorization': `Bearer ${getAuthToken()}`
        }
      }
    )
  }
)

export const importSurveyResults = refreshableDecorator(
  function* (action) {
    return yield fetch(
      `${BASE_URL}/${SURVEY_RESOURCE}/${action.payload.survey_id}/${IMPORT_RESOURCE}/`,
      {
        method:  'POST',
        body:    action.payload.body,
        headers: {
          'Authorization': `Bearer ${getAuthToken()}`
        }
      }
    )
  }
)

export const getImports = refreshableDecorator(
  function* (action) {
    const params = objToQueryString(action.payload)

    return yield fetch(
      `${BASE_URL}/${SURVEY_IMPORTS_RESOURCE}/?${params}`,
      {
        method:  'GET',
        headers: {
          'Content-Type':  'application/json',
          'Authorization': `Bearer ${getAuthToken()}`
        }
      }
    )
  }
)

export const deleteImport = refreshableDecorator(
  function* (action) {
    return yield fetch(
      `${BASE_URL}/${SURVEY_IMPORTS_RESOURCE}/${action.payload}/`,
      {
        method:  'DELETE',
        headers: {
          'Content-Type':  'application/json',
          'Authorization': `Bearer ${getAuthToken()}`
        }
      }
    )
  }
)

export const getSurveyDetails = refreshableDecorator(
  function* (action) {
    return yield fetch(
      `${BASE_URL}/${SURVEY_RESOURCE}/${action.payload}/`,
      {
        method:  'GET',
        headers: {
          'Content-Type':  'application/json',
          'Authorization': `Bearer ${getAuthToken()}`
        }
      }
    )
  }
)

export const createQuestionCategory = refreshableDecorator(
  function* (action) {
    return yield fetch(
      `${BASE_URL}/${QUESTION_CATEGORIES_RESOURCE}/`,
      {
        method: 'POST',
        body:    JSON.stringify(action.payload),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAuthToken()}`
        }
      }
    )
  }
)