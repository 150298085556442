export const currentUserSelector = (state) => (
  state.currentUser.user
)

export const currentUserResponseSelector = (state) => (
  state.currentUser
)

export const currentUserLoadedSelector = (state) => (
  state.currentUser.loaded
)