/*----- Imports -----*/
import React                from 'react'
import i18n                 from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector     from 'i18next-browser-languagedetector'

/*----- Locales -----*/
import en from './locales/en.json'
import es from './locales/es.json'

const resources = {
  en, es
}

i18n
  .use(initReactI18next)
  .use(LanguageDetector) // passes i18n down to react-i18next
  .init(
    {
      resources,
      lng:           'es',
      fallbackLng:   'en',
      whitelist:     ['es', 'en'],
      interpolation: {
        escapeValue: false // react already safes from xss
      },
      detection:     {
        htmlTag:        <html lang = { i18n.language } />,
        checkWhitelist: true
      }
    }
  )

export default i18n
