import { GET_USER_DETAILS, GET_USER_DETAILS_SUCCESS, GET_USER_DETAILS_ERROR } from "../UserActions"

const initialState = {
  isLoading: false,
  userInfo:  {},
  error:     null
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_USER_DETAILS:
      return {
        ...initialState,
        isLoading: true
      }
    case GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userInfo:  action.payload
      }
    case GET_USER_DETAILS_ERROR:
      return {
        ...state,
        isLoading: false,
        error:     action.payload
      }
    default:
      return state;
  }
}