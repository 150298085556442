/*----- Imports -----*/
import React     from 'react'
import PropTypes from 'prop-types'

/*----- Antd -----*/
import { Row, Col } from 'antd'

const Category = ({ category, questions, records, chart: Chart }) => (
  <section className = 'special'>

    <h2> { category } </h2>
    <hr />

    <Row type = 'flex' justify = 'space-between' align = 'top' gutter = { [20, 20] }>
      {
        questions.map((question, index) => (
          <Col
            xs  = { 24 }
            sm  = { 24 }
            md  = { 12 }
            lg  = { 12 }
            xl  = { 12 }
            key = { index }
          >
            <header>
              <p>
                { question.text }
              </p>
            </header>

            <Chart data = { question.results } records = { records } />
          </Col>
        ))
      }
    </Row>

  </section>
)

export default Category

/*----- PropTypes -----*/
Category.propTypes = {
  category:  PropTypes.string.isRequired,
  questions: PropTypes.array.isRequired,
  records:   PropTypes.array.isRequired,
  chart:     PropTypes.elementType.isRequired
}
