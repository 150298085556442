/*----- Actions -----*/
import { LOGIN, LOGIN_SUCCESS, LOGIN_FAIL } from '../LoginActions'

/*----- Services -----*/
import { startSession } from '../../../../utils/SessionService'

const initialState = {
  isLoading: false,
  error:     ''
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        isLoading: true
      }
    case LOGIN_SUCCESS:
      startSession(action.auth,action.refresh)
      return {
        ...state,
        isLoading: false,
      }
    case LOGIN_FAIL:
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}