/*----- Saga -----*/
import { call, takeLatest, put } from 'redux-saga/effects'

/*----- Actions -----*/
import {
  GET_SUBJECT_COMMISSIONS,
  GET_SUBJECT_COMMISSIONS_SUCCESS,
  GET_SUBJECT_COMMISSIONS_ERROR,
} from '../CommissionActions'

/*----- Endpoint -----*/
import { getSubjectCommissions } from '../../../../api/endpoints/Commissions'

/*----- Notifications -----*/
import notifyError from '../../../../components/commons/notifications/ErrorNotification'

export function* shootGetSubjectCommissions(action) {
  try {
    const response = yield call(getSubjectCommissions, action)
    const body     = yield response.json()
    let type

    if (response.status >= 200 && response.status < 300) {
      type = GET_SUBJECT_COMMISSIONS_SUCCESS
    }
    else {
      type = GET_SUBJECT_COMMISSIONS_ERROR
      yield notifyError(body.error.message)
    }

    yield put(
      {
        type:    type,
        payload: body
      }
    )
  }
  catch (error) {
    yield notifyError('¡Ha ocurrido un error inesperado!')
    yield put(
      {
        type:    GET_SUBJECT_COMMISSIONS_ERROR,
        payload: error.message
      }
    )
  }
}

export default function* () {
  yield takeLatest(GET_SUBJECT_COMMISSIONS, shootGetSubjectCommissions)
}
