/*----- Imports -----*/
import React from 'react'

/*----- Antd -----*/
import { Icon, Popconfirm } from 'antd'

/*----- Components -----*/
import ActionButton from '../../../../../components/commons/button/ActionButton'

const columns = (t, deleteHandler, isAdmin, departmentId) => (
  [
    {
      title:          t('user.name'),
      dataIndex:      'first_name',
      key:            'first_name',
      sorter:         (a, b) => a.first_name.localeCompare(b.first_name),
      sortDirections: ['ascend','descend']
    },
    {
      title:          t('user.surname'),
      dataIndex:      'last_name',
      key:            'last_name',
      sorter:         (a, b) => a.last_name.localeCompare(b.last_name),
      sortDirections: ['ascend','descend']
    },
    {
      title:     t('user.email'),
      dataIndex: 'email',
      key:       'email',
    },
    {
      title:     t('user.dni'),
      dataIndex: 'dni',
      key:       'dni',
    },
    {
      title:  t('global.actions'),
      key:    'action',
      render: (_text, record) => (
        <span>
          {
            (
              isAdmin &&
                <Popconfirm
                  title      = { t('notifications.confirm') }
                  onConfirm  = { () => { deleteHandler([record.id], departmentId) } }
                  okText     = { t('global.accept') }
                  cancelText = { t('global.cancel') }
                  style      = { { cursor: 'pointer' } }
                >
                  <ActionButton>
                    <Icon type = 'delete' style = {{ color:'red' }} />
                  </ActionButton>
                </Popconfirm>
            )
          }
        </span>
      )
    }
  ]
)

export default columns
