/*----- Imports -----*/
import React              from 'react'
import styled             from 'styled-components'
import PropTypes          from 'prop-types'
import { useTranslation } from 'react-i18next'

/*----- Views -----*/
import Table   from './Table'
import columns from './Columns'
import Filters from './Filters'

const Container = styled.div`
  margin-top:  5%;
  margin-left: 10%;
  width:       80%;
`

const StyledTable = styled(Table)`
  margin-top: 5%;
`

const MainView = (
  {
    searchHandler, subjects, isLoading, departments, departmentsSelectHandler, blockedSelectHandler,
    isAdmin, deleteSubject, restoreSubject
  }
) => {
  const { t }  = useTranslation()

  return(
    <Container>

      <Filters
        searchHandler            = { searchHandler }
        departments              = { departments }
        departmentsSelectHandler = { departmentsSelectHandler }
        blockedSelectHandler     = { blockedSelectHandler }
      />
      
      <StyledTable
        subjects  = { subjects }
        isLoading = { isLoading }
        columns   = { columns(t, isAdmin, deleteSubject, restoreSubject) }
      />

    </Container>
  )
}

export default MainView

/*----- PropTypes -----*/
MainView.propTypes = {
  subjects:                 PropTypes.array.isRequired,
  isLoading:                PropTypes.bool.isRequired,
  searchHandler:            PropTypes.func.isRequired,
  departments:              PropTypes.array.isRequired,
  blockedSelectHandler:     PropTypes.func.isRequired,
  departmentsSelectHandler: PropTypes.func.isRequired,
  isAdmin:                  PropTypes.bool.isRequired,
  deleteSubject:            PropTypes.func.isRequired,
  restoreSubject:           PropTypes.func.isRequired
}