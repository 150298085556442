/*----- Imports -----*/
import { call, takeLatest, put } from 'redux-saga/effects'
import { push }                  from 'connected-react-router'

/*----- Endpoints -----*/
import { login } from '../../../../api/endpoints/Sessions'

/*----- Actions -----*/
import { LOGIN, LOGIN_SUCCESS, LOGIN_FAIL } from '../LoginActions'
import { GET_CURRENT_USER_SUCCESS }         from '../../../admin/CurrentUserActions'

/*----- Notifications -----*/
import notifyError from '../../../../components/commons/notifications/ErrorNotification'

export function* shootLoginUser(action) {
  
  try {
    const response      = yield call(login, action)
    const login_data    = yield response.json()
    const auth_token    = yield response.headers.get("Auth-Token")
    const refresh_token = yield response.headers.get("Auth-Refresh")

    if (response.status >= 200 && response.status < 300) {
      yield put(
        {
          type:     LOGIN_SUCCESS,
          payload:  login_data,
          auth:     auth_token,
          refresh:  refresh_token
        }
      )
      
      yield put(
        {
          type:    GET_CURRENT_USER_SUCCESS,
          payload: login_data.user
        }
      )

      if (login_data.user.first_login || login_data.user.modify_password){
        yield put(push(`/admin/users/edit/${login_data.user.id}`))
      }else{
        yield put(push('/admin'))
      }
    }
    else {
      yield notifyError(login_data.error.message)
      yield put(
        {
          type:     LOGIN_FAIL,
          payload:  login_data
        }
      )
    }
  }

  catch (error) {
    yield put(
      {
        type:    LOGIN_FAIL,
        payload: error.message
      }
    )
  }
}

export default function* createRouteWatcher() {
  yield takeLatest(LOGIN, shootLoginUser)
}
