/*----- Imports -----*/
import React, { useEffect } from 'react'
import { connect }          from 'react-redux'

/*----- Selectors -----*/
import { departmentsListSelector } from '../departments/selectors/DepartmentsListSelector'
import { newSubjectFieldsSelector, loadingSelector } from './selectors/CreateSubjectSelector'

import { initialState } from './reducers/CreateSubjectReducer'

/*----- Antd -----*/
import { Form } from 'antd'

/*----- Actions -----*/
import { getDepartments } from '../departments/DepartmentsActions'
import { createSubject, setNewSubjectFields }  from './SubjectsActions'

/*----- Views -----*/
import MainView from './views/new/MainView'

const hasErrors = (fieldsError) => {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

const NewSubject = ({ form, isLoading, departments, getDepartments, createSubject, setNewSubjectFields } ) => {

  useEffect(() => {
    getDepartments()
  }, [getDepartments])

  useEffect(() => {
    return () => {
      setNewSubjectFields(initialState.fields)
    }
  }, [setNewSubjectFields])

  const handleSubmit = (event) => {
    event.preventDefault()
    form.validateFields({ force: true })

    if (!hasErrors(form.getFieldsError())) {
      let fields = form.getFieldsValue()
      createSubject(fields)
    }
  }

  return (
    <MainView
      form         = { form }
      handleSubmit = { handleSubmit }
      departments  = { departments }
      isLoading    = { isLoading }
    />
  )
}

function mapStateToProps(state) {
  return {
    isLoading:   loadingSelector(state),
    departments: departmentsListSelector(state),
    formFields:  newSubjectFieldsSelector(state),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getDepartments:            () => getDepartments(dispatch),
    createSubject:       (params) => createSubject(dispatch, params),
    setNewSubjectFields: (params) => setNewSubjectFields(dispatch, params)
  }
}

const ConnectedNewSubject = connect(mapStateToProps, mapDispatchToProps)(
  Form.create(
    {
      name: 'new_subject_form',
      onFieldsChange({ setNewSubjectFields }, changedFields) {
        setNewSubjectFields(changedFields);
      },
      mapPropsToFields({ formFields }) {
        return {
          name: Form.createFormField({
            ...formFields.name,
            value: formFields.name.value
          }),
          departments: Form.createFormField({
            ...formFields.departments,
            value: formFields.departments.value
          }),
          code: Form.createFormField({
            ...formFields.code,
            value: formFields.code.value
          })
        }
      }
    }
)(NewSubject))

ConnectedNewSubject.defaultProps = {
  permission:   'create',
  model:        'Subject',
  defaultRoute: '/admin'
}

export default ConnectedNewSubject