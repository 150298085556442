/*----- API -----*/
import BASE_URL                              from '../BaseUrl'
import { DEPARTMENT_RESOURCE, USER_RESOURCE } from '../Resources'

/*----- Authorization -----*/
import { getAuthToken } from '../../utils/SessionService'

/*----- Decorators -----*/
import refreshableDecorator from '../../decorators/RefreshableDecorator'

/*----- Utils -----*/
import { objToQueryString } from '../../utils/ParameterSanitizer'

export const postDepartmentCreation = refreshableDecorator(
  function* (action) {
    return yield fetch(
      `${BASE_URL}/${DEPARTMENT_RESOURCE}/`,
      {
        method: 'POST',
        body:    JSON.stringify(action.payload.body),
        headers: {
          'Content-Type':  'application/json',
          'Authorization': `Bearer ${getAuthToken()}`
        }
      }
    )
  }
)

export const getDepartmentsList = refreshableDecorator(
  function* (action) {
    const params = objToQueryString(action.payload)

    return yield fetch(
      `${BASE_URL}/${DEPARTMENT_RESOURCE}/?${params}`,
      {
        method:  'GET',
        headers: {
          'Content-Type':  'application/json',
          'Authorization': `Bearer ${getAuthToken()}`
        }
      }
    )
  }
)

export const getDepartmentDetails = refreshableDecorator(
  function* (action) {
    return yield fetch(
      `${BASE_URL}/${DEPARTMENT_RESOURCE}/${action.payload}/`,
      {
        method:  'GET',
        headers: {
          'Content-Type':  'application/json',
          'Authorization': `Bearer ${getAuthToken()}`
        }
      }
    )
  }
)

export const deleteDepartmentUsers = refreshableDecorator(
  function* (action) {
    return yield fetch(
      `${BASE_URL}/${DEPARTMENT_RESOURCE}/${action.payload.departmentId}/user_list/`,
      {
        method:  'DELETE',
        body:    JSON.stringify(action.payload.body),
        headers: {
          'Content-Type':  'application/json',
          'Authorization': `Bearer ${getAuthToken()}`
        }
      }
    )
  }
)

export const editDepartment = refreshableDecorator(
  function* (action) {
    return yield fetch(
      `${BASE_URL}/${DEPARTMENT_RESOURCE}/${action.payload.departmentId}/`,
      {
        method:  'PUT',
        body:    JSON.stringify(action.payload.body),
        headers: {
          'Content-Type':  'application/json',
          'Authorization': `Bearer ${getAuthToken()}`
        }
      }
    )
  }
)

export const getDepartmentUsers = refreshableDecorator(
  function* (action) {
    const params = objToQueryString(action.payload.params)

    return yield fetch(
      `${BASE_URL}/${DEPARTMENT_RESOURCE}/${action.payload.departmentId}/${USER_RESOURCE}/?${params}`,
      {
        method:  'GET',
        headers: {
          'Content-Type':  'application/json',
          'Authorization': `Bearer ${getAuthToken()}`
        }
      }
    )
  }
)

export const deleteDepartment = refreshableDecorator(
  function* (action) {
    return yield fetch(
      `${BASE_URL}/${DEPARTMENT_RESOURCE}/${action.payload.departmentId}/`,
      {
        method:  'DELETE',
        headers: {
          'Content-Type':  'application/json',
          'Authorization': `Bearer ${getAuthToken()}`
        }
      }
    )
  }
)

export const restoreDepartment = refreshableDecorator(
  function* (action) {
    return yield fetch(
      `${BASE_URL}/${DEPARTMENT_RESOURCE}/${action.payload}/activate/`,
      {
        method:  'PUT',
        headers: {
          'Content-Type':  'application/json',
          'Authorization': `Bearer ${getAuthToken()}`
        }
      }
    )
  }
)
