/*----- Imports -----*/
import React              from 'react'
import styled             from 'styled-components'
import PropTypes          from 'prop-types'
import { useTranslation } from 'react-i18next'
import { isMobile }       from 'react-device-detect'

/*----- Antd -----*/
import { Tabs, Icon, Skeleton, Modal } from 'antd'

/*----- Tabs -----*/
import General       from './tabs/General'
import TeachingStaff from './tabs/TeachingStaff'

const { TabPane } = Tabs
const { confirm } = Modal

const MainContainer = styled.section`
  padding:                  5%;
  width:                    100%;
  .form-box__data-container {
    width: 60%;
  }
`

// Luego de mucha investigación, no encontré forma alguna de agregar este estilo al texto de las tabs.
// Dudo que haya otra forma.
const StyledTabs = styled(Tabs)`
  .ant-tabs-tab {
    text-align: left !important;
  }
`

const MainView = (
  {
    form, isLoading, commission, submitTeachingStaffData, submitGeneralData,
    subjectsList, loadingSubjects, rolesList, loadingRoles, activeTab, changeTab,
    addTeachingStaffFields, deleteTeachingStaffField, staff_keys, loadingUsers, usersList,
    deleteCommissionTeachingStaff
  }
) => {
  const { t } = useTranslation()

  const filteredRoles = (roleId) => (
    commission.teaching_staff.filter((staff) => staff.role.id === roleId)
  )

  const withChanges = () => {
    let result    = false
    let first_key = staff_keys[0]

    if (Number.isInteger(first_key)) {
      let values = form.getFieldsValue()
      if (values.teaching_staff) {
        result = Object.values(values.teaching_staff[first_key]).filter(Boolean).length > 0
      }
    }

    return result
  }

  const showConfirm = (activeTab) => {
    if (staff_keys.length > 1 || withChanges()) {
      confirm(
        {
          title:     t('admin.edit_commission.confirm_modal_title'),
          content:   t('admin.edit_commission.confirm_modal_content'),
          onOk()     { changeTab(activeTab) },
          onCancel() {},
        }
      )
    }
    else {
      changeTab(activeTab)
    }
  }

  return(
    <MainContainer>

      <Skeleton loading = { loadingRoles } active>
        <StyledTabs
          activeKey   = { activeTab }
          tabPosition = { (isMobile && 'top') || 'left' }
          onChange    = { showConfirm }
        >

          <TabPane
            key = '#commission'
            tab = {
              <span>
                <Icon type = 'setting' />
                { t('admin.edit_commission.general_data') }
              </span>
            }
          >
            <General
              form              = { form }
              isLoading         = { isLoading }
              submitGeneralData = { submitGeneralData }
              loadingSubjects   = { loadingSubjects }
              subjectsList      = { subjectsList }
              commission        = { commission }
            />
          </TabPane>

          {
            rolesList.map(role => (
              <TabPane
                key = { `#${role.name}` }
                tab = {
                  <span>
                    <Icon type = 'plus' />
                    { role.name }
                  </span>
                }
              >

                <TeachingStaff
                  role                          = { role }
                  form                          = { form }
                  keys                          = { staff_keys }
                  isLoading                     = { isLoading && loadingUsers}
                  submitTeachingStaffData       = { submitTeachingStaffData }
                  teachingStaff                 = { filteredRoles(role.id) }
                  addTeachingStaffFields        = { addTeachingStaffFields }
                  deleteTeachingStaffField      = { deleteTeachingStaffField }
                  usersList                     = { usersList }
                  deleteCommissionTeachingStaff = { deleteCommissionTeachingStaff }
                  commission                    = { commission }
                />

              </TabPane>
            ))
          }

        </StyledTabs>
      </Skeleton>

    </MainContainer>
  )
}

export default MainView

/*----- PropTypes -----*/
MainView.propTypes = {
  form:                          PropTypes.object.isRequired,
  isLoading:                     PropTypes.bool.isRequired,
  commission:                    PropTypes.object.isRequired,
  submitTeachingStaffData:       PropTypes.func.isRequired,
  submitGeneralData:             PropTypes.func.isRequired,
  subjectsList:                  PropTypes.array.isRequired,
  loadingSubjects:               PropTypes.bool.isRequired,
  rolesList:                     PropTypes.array.isRequired,
  loadingRoles:                  PropTypes.bool.isRequired,
  activeTab:                     PropTypes.string.isRequired,
  changeTab:                     PropTypes.func.isRequired,
  addTeachingStaffFields:        PropTypes.func.isRequired,
  deleteTeachingStaffField:      PropTypes.func.isRequired,
  staff_keys:                    PropTypes.array.isRequired,
  loadingUsers:                  PropTypes.bool.isRequired,
  usersList:                     PropTypes.array.isRequired,
  deleteCommissionTeachingStaff: PropTypes.func.isRequired
}