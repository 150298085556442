/*----- Imports -----*/
import React              from 'react'
import PropTypes          from 'prop-types'
import styled             from 'styled-components'
import { useTranslation } from 'react-i18next'

/*----- Components -----*/
import SearchInput from '../../../../../components/commons/input/SearchInput'

/*----- Antd ------*/
import { Select } from 'antd'

/*----- Utils -----*/
import mapRecordsToOptions from '../../../../../utils/SelectOptionsMapper'

const { Option } = Select

const Container = styled.div`
  width:           100%;
  display:         flex;
  justify-content: space-between;
`

const SelectContainer = styled.div`
  width:           100%;
  display:         flex;
  justify-content: center;
  align-items:     center;
`

const Filters = ({ searchHandler, blockedSelectHandler, departmentsSelectHandler, departments }) => {

  const { t }  = useTranslation()

  return(
    <div>

      <Container>
        
        <SearchInput
          placeholder     = { t('global.search_placeholder') }
          onSearchHandler = { searchHandler }
          allowClear      = { true }
        />

        <SelectContainer>

          <b style = { {marginRight: '2%' }}>
            { `${t('global.status')}: ` }
          </b>

          <Select
            defaultValue = 'false'
            style        = {{ width: 120 }}
            onChange     = { blockedSelectHandler }
          >
              <Option value = 'false'>
                { t('global.active') }
              </Option>

              <Option value = 'true'>
                { t('global.blocked') }
              </Option>
          </Select>

        </SelectContainer>

      </Container>

      <b>
        { `${t('admin.menubar.departments')}: ` }
      </b>

      <Select
        mode       = 'multiple'
        style      = {{ width: 350, marginTop: '3%' }}
        onChange   = { departmentsSelectHandler }
        allowClear
      >
          { mapRecordsToOptions(departments) }
      </Select>

    </div>
  )
}

export default Filters

/*----- PropTypes -----*/
Filters.propTypes = {
  searchHandler:            PropTypes.func.isRequired,
  blockedSelectHandler:     PropTypes.func.isRequired,
  departmentsSelectHandler: PropTypes.func.isRequired,
  departments:              PropTypes.array.isRequired
}
