/*----- Imports -----*/
import React              from 'react'
import { useTranslation } from 'react-i18next'
import { Link }           from 'react-router-dom'
import PropTypes          from 'prop-types'

/*----- Antd -----*/
import { Icon } from 'antd'

const Header = ({ setShowNav }) => {
  const { t } = useTranslation()

  return(
    <header id = 'header'>
      <h1>
        <Link to = '/'> { t('public.header.title') } </Link>
      </h1>

      <Link
        to      = '#nav'
        style   = {{ display: 'inline' }}
        onClick = { () => setShowNav(true) }
      >
        <Icon type = 'menu' style = {{ paddingRight: '1%' }}/>
        { t('public.header.menu') }
      </Link>
    </header>
  )
}

export default Header

/*----- PropTypes -----*/
Header.propTypes = {
  setShowNav: PropTypes.func.isRequired
}
