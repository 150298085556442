/*----- Saga -----*/
import { call, takeLatest, put } from 'redux-saga/effects'

/*----- Actions -----*/
import {
  EDIT_SUBJECT,
  EDIT_SUBJECT_SUCCESS,
  EDIT_SUBJECT_ERROR,
  TOGGLE_EDITION_MODAL,
  TOGGLE_EDIT_DEPARTMENTS_MODAL,
  GET_SUBJECT_DETAILS
} from '../SubjectsActions'

/*----- Endpoint -----*/
import { editSubject } from '../../../../api/endpoints/Subjects'

/*----- Notifications -----*/
import loadingNotification from '../../../../components/commons/notifications/LoadingNotification'
import successNotification from '../../../../components/commons/notifications/SuccessNotification'
import notifyError         from '../../../../components/commons/notifications/ErrorNotification'

export function* shootEditSubject(action) {
  yield loadingNotification()
  try {
    const response = yield call(editSubject, action)
    const body     = yield response.json()
    let type

    if (response.status >= 200 && response.status < 300) {
      type = EDIT_SUBJECT_SUCCESS

      yield put(
        {
          type:    GET_SUBJECT_DETAILS,
          payload: action.payload.subjectId
        }
      )

      // Cerramos los modales de edición
      yield put(
        {
          type:    TOGGLE_EDITION_MODAL,
          payload: false
        }
      )
      yield put(
        {
          type:    TOGGLE_EDIT_DEPARTMENTS_MODAL,
          payload: false
        }
      )

      yield successNotification()
    }
    else {
      type = EDIT_SUBJECT_ERROR

      yield notifyError(body.error.message)
    }

    yield put(
      {
        type:    type,
        payload: body
      }
    )
  }
  catch (error) {
    yield notifyError('¡Ha ocurrido un error inesperado!')
    yield put(
      {
        type:    EDIT_SUBJECT_ERROR,
        payload: error.message
      }
    )
  }
}

export default function* () {
  yield takeLatest(EDIT_SUBJECT, shootEditSubject)
}
