/*----- Actions -----*/
import { REFRESH, REFRESH_FAIL, REFRESH_SUCCESS } from '../RefreshActions'

/*----- Services -----*/
import { renewToken, destroySession } from '../../../../utils/SessionService'

const initialState = {
  isLoading: false,
  error:     ''
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REFRESH:
      return state
    case REFRESH_SUCCESS:
      renewToken(action.payload)
      return state
    case REFRESH_FAIL:
      destroySession()
      return state
    default:
      return state
  }
}