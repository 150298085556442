/*----- Imports -----*/
import { call, takeLatest, put } from 'redux-saga/effects'
import { push }                  from 'connected-react-router'

/*----- Actions -----*/
import {
  ACTIVATE_USER, ACTIVATE_USER_SUCCESS, ACTIVATE_USER_ERROR, GET_USERS_LIST
} from '../UserActions'

/*----- Endpoints -----*/
import { putActivateUser } from '../../../../api/endpoints/Users'

/*----- Notifications -----*/
import notifyError         from '../../../../components/commons/notifications/ErrorNotification'
import successNotification from '../../../../components/commons/notifications/SuccessNotification'

export function* shootPutActivateUser(action) {
  try {
    const response = yield call(putActivateUser, action)
    let type

    if (response.status >= 200 && response.status < 300) {
      type = ACTIVATE_USER_SUCCESS
      yield put({ 
        type: GET_USERS_LIST,
        payload: {
          blocked: action.payload.blocked, 
          search: action.payload.search
        } 
      })
      yield successNotification()
    } 
    else {
      let error = response.json()
      yield notifyError(error.message)
      type = ACTIVATE_USER_ERROR
      yield put(push('/admin'))
    }

    yield put(
      {
        type: type
      }
    )
  }
  catch (error) {
    yield notifyError('¡Ha ocurrido un error inesperado!')
    yield put(
      {
        type:    ACTIVATE_USER_ERROR,
        payload: error.message
      }
    )
  }
}

export default function* getUserListWatcher() {
  yield takeLatest(ACTIVATE_USER, shootPutActivateUser)
}
