import { AbilityBuilder } from '@casl/ability'

export default (ability, user) => {
  const { can, rules } = new AbilityBuilder()

  if (user.is_staff) {
    can('manage', 'all') //puede hacer todo
  } else {
    can('list', 'Subject')
    can('show', 'Subject')
    can('list', 'Department')
    can('show', 'Department')
    can('list', 'Commission')
    can('show', 'Commission')
    can('create', 'Commission')
    can('edit', 'User')
    can('visit', 'Home')
  }

  ability.update(rules)
}