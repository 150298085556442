/*----- Imports -----*/
import React                from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useTranslation }   from 'react-i18next'

/*----- Antd -----*/
import { Icon, Divider, Popconfirm } from 'antd'

const DeleteIcon = <Icon
  type         = 'delete'
  style        = {{ fontSize: '20px' }}
  theme        = 'twoTone'
  twoToneColor = 'red'
/>

const EditIcon = <Icon
  type         = 'edit'
  style        = {{ fontSize: '20px' }}
  theme        = 'twoTone'
  twoToneColor = 'blue'
/>

const UserDetailsButtons = ({ deleteUser, userId }) =>{
  const { t }   = useTranslation()
  const history = useHistory()

  const deleteHandler = (userId) => {
    deleteUser(userId)
    history.push('/admin/users')
  }

  return(
    <div>
      <Link to = { `/admin/users/edit/${userId}` } >
        { EditIcon }
      </Link>
      <Divider type='vertical' />
      <Popconfirm
        title      = { t('notifications.confirm') }
        onConfirm  = { () => { deleteHandler(userId) } }
        okText     = { t('global.accept') }
        cancelText = { t('global.cancel') }
      >
        { DeleteIcon }
      </Popconfirm>
    </div>
  )
}

export default UserDetailsButtons