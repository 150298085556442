/*----- Imports -----*/
import React     from 'react'
import PropTypes from 'prop-types'
import styled    from 'styled-components'

/*----- Components -----*/
import SearchInput from '../../../../../components/commons/input/SearchInput'

/*----- Antd -----*/
import { Select } from 'antd'

const { Option } = Select

const Container = styled.div`
  width:           100%;
  display:         flex;
  justify-content: space-between;
`

const SeletContainer = styled.div`
  width:           100%;
  display:         flex;
  justify-content: center;
  align-items:     center;
`

const Filters = ({ blockedSelectChangeHandler, searchHandler, t }) => {
  return(
    <Container>
      
      <SearchInput
        placeholder     = { t('global.search_placeholder') }
        onSearchHandler = { searchHandler }
        allowClear      = { true }
      />

      <SeletContainer>

        <b style = { {marginRight: '2%' }}>
          { `${t('global.status')}: ` }
        </b>

        <Select
          defaultValue = 'false' 
          style        = {{ width: 120 }}
          onChange     = { blockedSelectChangeHandler }>
            <Option value = 'false'>
              { t('global.active') }
            </Option>

            <Option value = 'true'>
              { t('global.blocked') }
            </Option>
        </Select>

      </SeletContainer>
      
    </Container>
  )
}

export default Filters

/*----- PropTypes -----*/
Filters.propTypes = {
  blockedSelectChangeHandler: PropTypes.func.isRequired,
  searchHandler:              PropTypes.func.isRequired,
  t:                          PropTypes.func.isRequired,
}
