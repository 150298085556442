/*----- Actions -----*/
import {
  DEPARTMENT_USERS,
  DEPARTMENT_USERS_SUCCESS,
  DEPARTMENT_USERS_ERROR
} from '../DepartmentsActions'

const initialState = {
  isLoading:    false,
  departmentId: null,
  users:        [],
  error:        null
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case DEPARTMENT_USERS:
      return {
        ...state,
        isLoading: true
      }
    case DEPARTMENT_USERS_SUCCESS:
      return {
        ...state,
        isLoading:    false,
        departmentId: action.payload.departmentId,
        users:        action.payload.body
      }
    case DEPARTMENT_USERS_ERROR:
      return {
        ...state,
        isLoading:    false,
        departmentId: action.payload.departmentId,
        error:        action.payload.body
      }
    default:
      return state;
  }
}