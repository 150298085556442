export const GET_USERS_LIST         = 'GET_USERS'
export const GET_USERS_LIST_SUCCESS = 'GET_USERS_LIST_SUCCESS'
export const GET_USERS_LIST_ERROR   = 'GET_USERS_LIST_ERROR'

export const GET_USER_DETAILS         = 'GET_USER_DETAILS'
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS'
export const GET_USER_DETAILS_ERROR   = 'GET_USER_DETAILS_ERROR'

export const DELETE_USER         = 'DELETE_USER'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_ERROR   = 'DELETE_USER_ERROR'

export const CREATE_USER         = 'CREATE_USER'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_ERROR   = 'CREATE_USER_ERROR'

export const ACTIVATE_USER         = 'ACTIVATE_USER'
export const ACTIVATE_USER_SUCCESS = 'ACTIVATE_USER_SUCCESS'
export const ACTIVATE_USER_ERROR   = 'ACTIVATE_USER_ERROR'

export const PUT_USER_DATA_EDITION         = 'PUT_USER_DATA_EDITION'
export const PUT_USER_DATA_EDITION_SUCCESS = 'PUT_USER_DATA_EDITION_SUCCESS'
export const PUT_USER_DATA_EDITION_ERROR   = 'PUT_USER_DATA_EDITION_ERROR'

export const PUT_CHANGE_PASSWORD         = 'PUT_CHANGE_PASSWORD'
export const PUT_CHANGE_PASSWORD_SUCCESS = 'PUT_CHANGE_PASSWORD_SUCCESS'
export const PUT_CHANGE_PASSWORD_ERROR   = 'PUT_CHANGE_PASSWORD_ERROR'

export const SET_REGISTER_FORM_FIELDS        = 'SET_REGISTER_FORM_FIELDS'
export const SET_USER_DATA_FORM_FIELDS       = 'SET_USER_DATA_FORM_FIELDS'
export const SET_CHANGE_PASSWORD_FORM_FIELDS = 'SET_CHANGE_PASSWORD_FORM_FIELDS'

export const getUsers = (dispatch, search='', blocked='') => {
  dispatch(
    {
      type:    GET_USERS_LIST,
      payload: {
        search: search,
        blocked: blocked
      }
    }
  )
}

export const getUserDetails = (dispatch, user_id) => {
  dispatch(
    {
      type:    GET_USER_DETAILS,
      payload: {
        user_id: user_id
      }
    }
  )
}

export const getUserDetailsWithDepartments = (dispatch, user_id) => {
  dispatch(
    {
      type:    GET_USER_DETAILS,
      payload: {
        user_id: user_id,
        params:  {
          departments: true
        }
      }
    }
  )
}

export const deleteUser = (dispatch, user_id) => {
  dispatch(
    {
      type:    DELETE_USER,
      payload: user_id
    }
  )
}

export const createUser = (dispatch, params) => {
  let body      = params
  let form_data = new FormData()

  for (let [key, value] of Object.entries(body)) {
    if (key === 'profile_picture') {
      if (value) {
        if (value.file instanceof File) {
          form_data.append(key, value.file)
        }
        else {
          continue
        }
      }
    }
    else {
      form_data.append(key, value)
    }
  }

  dispatch(
    {
      type:    CREATE_USER,
      payload: {
        body:  form_data
      }
    }
  )
}

export const activateUser = (dispatch, id, blocked, search) => {
  dispatch(
    {
      type:    ACTIVATE_USER,
      payload: {
        id: id,
        blocked: blocked,
        search: search
      }
    }
  )
}

export const setRegisterFormFields = (dispatch, status) => {
  dispatch(
    {
      type:    SET_REGISTER_FORM_FIELDS,
      payload: status
    }
  )
}

export const editUserData = (dispatch, params, user_id, current_user_id) => {
  let body      = params
  let form_data = new FormData();

  for (let [key, value] of Object.entries(body)) {
    if (key === 'profile_picture') {
      if (value) {
        if (value.file instanceof File) {
          // We send the new picture.
          form_data.append(key, value.file)
        }
        else {
          // User already has a picture and don't want to edit it, we avoid sending the field.
          continue
        }
      }
      else {
      // Value is null, so the user wants to delete the picture. We send an empty string in the request.
        form_data.append(key, '')
      }
    }
    else {
      form_data.append(key, value)
    }
  }

  dispatch(
    {
      type: PUT_USER_DATA_EDITION,
      payload: {
        body:          form_data,
        id:            user_id,
        currentUserId: current_user_id
      }
    }
  )
}

export const setUserDataFormFields = (dispatch, fields) => {
  dispatch(
    {
      type:    SET_USER_DATA_FORM_FIELDS,
      payload: fields
    }
  )
}

export const editUserPassword = (dispatch, params, userId) => {
  dispatch(
    {
      type:    PUT_CHANGE_PASSWORD,
      payload: {
        body:   params,
        userId: userId
      }
    }
  )
}

export const setChangePasswordFormFields = (dispatch, fields) => {
  dispatch(
    {
      type:    SET_CHANGE_PASSWORD_FORM_FIELDS,
      payload: fields
    }
  )
}
