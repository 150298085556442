/*----- Imports -----*/
import React, { useEffect } from 'react'
import { connect }          from 'react-redux'
import styled               from 'styled-components'
import { useTranslation }   from 'react-i18next'

/*----- Antd -----*/
import { Form, Typography } from 'antd'

/*----- Actons -----*/
import { editUserData, setUserDataFormFields } from '../UserActions'

/*----- Selector -----*/
import { loadingEditionSelector, userDataEditionFormSelector } from '../selectors/userEditionSelector'
import { currentUserSelector }                                 from '../../CurrentUserSelector';

/*----- Form -----*/
import GeneralDataForm from '../views/forms/GeneralDataForm'

const { Title } = Typography

const CLEAN_STATE_FIELDS = { email: {}, first_name: {}, last_name: {}, dni: {}, profile_picture: {} }

const StyledContainer = styled.div`
  padding-bottom:  5%;
  form {
    display:         block;
    justify-content: center;
  }
`

const CenteredContainer = styled.div`
  text-align: center;
`

const hasErrors = (fieldsError) => {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

const GeneralDataTab = ({ form, formFields, userInfo, isLoading, editUserData, setUserDataFormFields, loadingUserInfo = false, currentUser }) => {
  const { t }            = useTranslation()
  let   user_id          = userInfo.id
  let   current_user_id  = currentUser.id

  useEffect(
    () => {
      let result = {}

      for (let [key, value] of Object.entries(userInfo)) {
        result[key] = { value: value }
      }
      
      setUserDataFormFields(result)

      return () => {
        setUserDataFormFields(CLEAN_STATE_FIELDS)
      }
    },
    [userInfo, setUserDataFormFields]
  )

  const handleSubmit = (event) => {
    event.preventDefault()
    form.validateFields()
  
    if (!hasErrors(form.getFieldsError())) {
      editUserData(form.getFieldsValue(), user_id, current_user_id)
    }
  }

  return (
    <StyledContainer>
      <CenteredContainer>
        <Title level = { 2 }> { t('admin.edit_user.general_data') } </Title>
      </CenteredContainer>

      <GeneralDataForm
        onSubmit              = { handleSubmit }
        isLoading             = { isLoading }
        loadingUserInfo       = { loadingUserInfo }
        form                  = { form }
        formFields            = { formFields }
        setUserDataFormFields = { setUserDataFormFields }
      />
    </StyledContainer>
  )
}

function mapStateToProps(state) {
  return {
    isLoading:   loadingEditionSelector(state),
    formFields:  userDataEditionFormSelector(state),
    currentUser: currentUserSelector(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    editUserData: (payload, user_id, current_user_id) => editUserData(dispatch, payload, user_id, current_user_id),
    setUserDataFormFields:            (updatedFields) => setUserDataFormFields(dispatch, updatedFields)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create({
    name: 'general_data_form',
    onFieldsChange({ setUserDataFormFields }, changedFields) {
      // Manually clearing profile_picture field errors.
      if (changedFields.profile_picture) {
        changedFields = {
          ...changedFields,
          profile_picture: {
            ...changedFields.profile_picture,
            errors: null
          }
        }
      }

      setUserDataFormFields(changedFields)
    },
    mapPropsToFields({ formFields }) {
      return {
        email:           Form.createFormField(formFields.email),
        dni:             Form.createFormField(formFields.dni),
        first_name:      Form.createFormField(formFields.first_name),
        last_name:       Form.createFormField(formFields.last_name),
        profile_picture: Form.createFormField(formFields.profile_picture)
      }
    }
  })(GeneralDataTab))
