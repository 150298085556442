/*----- Imports -----*/
import React, { useState } from 'react'
import styled              from 'styled-components'
import PropTypes           from 'prop-types'
import { useTranslation }  from 'react-i18next'
import { isMobile }        from 'react-device-detect'

/*----- Components ------*/
import DepartmentsTab from './DepartmentsTab'
import Card           from '../../../departments/views/details/Card'
import EditionForm    from './EditionForm'
import Table          from '../../../commissions/views/list/Table'
import columns        from '../commissions_table/Columns'

/*----- Antd -----*/
import { Row, Col, Tabs, Icon, Modal, Skeleton } from 'antd'

const { TabPane } = Tabs

const Container = styled.div`
  padding: 5%;
  width:   100%;
`

const StyledTable = styled(Table)`
  margin-top: 5%;
`

const SubjectCode = styled.span`
  margin-left: 1%;
  color:       gray;
`

const MainView = (
  { 
    subjectInfo, isLoadingDepartments, isLoadingCommissions, commissions, toggleEditionModal, 
    modalVisible, form, editionSubmitHandler, handleUpdateDepartments, otherDepartments,
    setSelectedDepartments, selectedDepartments, getSubjectDepartments, subjectId,
    toggleEditDepartmentsModal, editDepartmentsModalVisible, deleteSubjectDepartment
  }
) => {

  const { t } = useTranslation()
  const [activeKey, setActiveKey] = useState('#departments')

  const changeTab = (activeTab) => {
    setActiveKey(activeTab)
  }

  const openModalButton = <Icon
    type    = 'edit'
    theme   = 'twoTone'
    style   = {{ fontSize: '15px' }}
    onClick = { () => toggleEditionModal(true) }
  />

  const formattedTitle = <> 
    { subjectInfo.name } 
    <SubjectCode> 
      { `(${subjectInfo.code})` } 
    </SubjectCode> 
  </>

  return(
    <Container>
      <Skeleton loading = { isLoadingDepartments } active>
        <Card
          isLoading = { isLoadingDepartments }
          title     = { formattedTitle }
          extra     = { openModalButton }
        >
          <Modal
            title    = { t('admin.edit_subject.change_name') }
            visible  = { modalVisible }
            onOk     = { editionSubmitHandler }
            onCancel = { () => toggleEditionModal(false) }
          >
            <EditionForm form = { form } />
          </Modal>

          <Row>
            <Col offset = {2} span = {20}>
              <Tabs
                tabPosition = { (isMobile && 'top') || 'left' }
                activeKey   = { activeKey }
                onChange    = { changeTab }
              >

                <TabPane
                  tab = {
                    <span>
                      <Icon type = 'pie-chart' />
                      { t('admin.menubar.departments') }
                    </span>
                  }
                  key = '#departments'
                >
                  <DepartmentsTab 
                    departments                 = { subjectInfo.departments } 
                    isLoading                   = { isLoadingDepartments } 
                    handleUpdateDepartments     = { handleUpdateDepartments }
                    otherDepartments            = { otherDepartments }
                    setSelectedDepartments      = { setSelectedDepartments }
                    selectedDepartments         = { selectedDepartments }
                    getSubjectDepartments       = { getSubjectDepartments }
                    subjectId                   = { subjectId }
                    toggleEditDepartmentsModal  = { toggleEditDepartmentsModal }
                    editDepartmentsModalVisible = { editDepartmentsModalVisible }
                    deleteSubjectDepartment     = { deleteSubjectDepartment }
                  />
                </TabPane>

                <TabPane
                  tab = {
                    <span>
                      <Icon type = 'schedule' />
                      { t('admin.menubar.commissions') }
                    </span>
                  }
                  key = '#commissions'
                >
                  <StyledTable
                  commissions = { commissions }
                  columns     = { columns(t) }
                  isLoading   = { isLoadingCommissions }
                  />
                </TabPane>
              
              </Tabs>
            </Col>
          </Row>
        </Card>
      </Skeleton>
    </Container>
  )
}

export default MainView

/*----- PropTypes -----*/
MainView.propTypes = {
  subjectInfo:                 PropTypes.object.isRequired,
  commissions:                 PropTypes.array.isRequired,
  isLoadingDepartments:        PropTypes.bool.isRequired,
  isLoadingCommissions:        PropTypes.bool.isRequired,
  toggleEditionModal:          PropTypes.func.isRequired, 
  modalVisible:                PropTypes.bool.isRequired,
  form:                        PropTypes.object.isRequired,
  editionSubmitHandler:        PropTypes.func.isRequired,
  handleUpdateDepartments:     PropTypes.func.isRequired,
  otherDepartments:            PropTypes.array.isRequired,
  setSelectedDepartments:      PropTypes.func.isRequired,
  selectedDepartments:         PropTypes.array.isRequired,
  getSubjectDepartments:       PropTypes.func.isRequired,
  subjectId:                   PropTypes.string.isRequired,
  toggleEditDepartmentsModal:  PropTypes.func.isRequired,
  editDepartmentsModalVisible: PropTypes.bool.isRequired,
  deleteSubjectDepartment:     PropTypes.func.isRequired
}