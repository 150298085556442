/*----- API -----*/
import BASE_URL                                  from '../BaseUrl'
import { SUBJECT_RESOURCE, COMMISSION_RESOURCE } from '../Resources'

/*----- Authorization -----*/
import { getAuthToken } from '../../utils/SessionService'

/*----- Decorators -----*/
import refreshableDecorator from '../../decorators/RefreshableDecorator'

/*----- Utils -----*/
import { objToQueryString } from '../../utils/ParameterSanitizer'

export const postCommissionCreation = refreshableDecorator(
  function* (action) {
    return yield fetch(
      `${BASE_URL}/${SUBJECT_RESOURCE}/${action.payload.subjectId}/${COMMISSION_RESOURCE}/`,
      {
        method: 'POST',
        body:    JSON.stringify(action.payload.body),
        headers: {
          'Content-Type':  'application/json',
          'Authorization': `Bearer ${getAuthToken()}`
        }
      }
    )
  }
)

export const getAllCommissionsList = refreshableDecorator(
  function* (action) {
    const params = objToQueryString(action.payload)

    return yield fetch(
      `${BASE_URL}/${COMMISSION_RESOURCE}/?${params}`,
      {
        method:  'GET',
        headers: {
          'Content-Type':  'application/json',
          'Authorization': `Bearer ${getAuthToken()}`
        }
      }
    )
  }
)

export const getSubjectCommissions = refreshableDecorator(
  function* (action) {
    return yield fetch(
      `${BASE_URL}/${SUBJECT_RESOURCE}/${action.payload.subjectId}/${COMMISSION_RESOURCE}/`,
      {
        method:  'GET',
        headers: {
          'Content-Type':  'application/json',
          'Authorization': `Bearer ${getAuthToken()}`
        }
      }
    )
  }
)

export const deleteCommission = refreshableDecorator(
  function* (action) {
    return yield fetch(
      `${BASE_URL}/${SUBJECT_RESOURCE}/${action.payload.subjectId}/${COMMISSION_RESOURCE}/${action.payload.commissionId}/`,
      {
        method:  'DELETE',
        headers: {
          'Content-Type':  'application/json',
          'Authorization': `Bearer ${getAuthToken()}`
        }
      }
    )
  }
)

export const getCommissionDetails = refreshableDecorator(
  function* (action) {
    return yield fetch(
      `${BASE_URL}/${COMMISSION_RESOURCE}/${action.payload.commissionId}/`,
      {
        method: 'GET',
        headers: {
          'Content-Type':  'application/json',
          'Authorization': `Bearer ${getAuthToken()}`
        }
      }
    )
  }
)

export const restoreCommission = refreshableDecorator(
  function* (action) {
    return yield fetch(
      `${BASE_URL}/${SUBJECT_RESOURCE}/${action.payload.subjectId}/${COMMISSION_RESOURCE}/${action.payload.commissionId}/activate/`,
      {
        method:  'PUT',
        headers: {
          'Content-Type':  'application/json',
          'Authorization': `Bearer ${getAuthToken()}`
        }
      }
    )
  }
)

export const editCommission = refreshableDecorator(
  function* (action) {
    return yield fetch(
      `${BASE_URL}/${SUBJECT_RESOURCE}/${action.payload.subjectId}/${COMMISSION_RESOURCE}/${action.payload.commissionId}/`,
      {
        method:  'PUT',
        body:    JSON.stringify(action.payload.body),
        headers: {
          'Content-Type':  'application/json',
          'Authorization': `Bearer ${getAuthToken()}`
        }
      }
    )
  }
)

export const deleteCommissionTeachingStaff = refreshableDecorator(
  function* (action) {
    return yield fetch(
      `${BASE_URL}/${SUBJECT_RESOURCE}/${action.payload.subjectId}/${COMMISSION_RESOURCE}/${action.payload.commissionId}/teaching_staff/`,
      {
        method: 'DELETE',
        body:    JSON.stringify(action.payload.body),
        headers: {
          'Content-Type':  'application/json',
          'Authorization': `Bearer ${getAuthToken()}`
        }
      }
    )
  }
)

export const getAvailableUsers = refreshableDecorator(
  function* (action) {
    return yield fetch(
      `${BASE_URL}/${COMMISSION_RESOURCE}/${action.payload.commissionId}/available_users/`,
      {
        method:  'GET',
        headers: {
          'Content-Type':  'application/json',
          'Authorization': `Bearer ${getAuthToken()}`
        }
      }
    )
  }
)

export const bulkImport = refreshableDecorator(
  function* (action) {
    return yield fetch(
      `${BASE_URL}/${COMMISSION_RESOURCE}/bulk_upload/`,
      {
        method:  'POST',
        body:    action.payload.body,
        headers: {
          'Authorization': `Bearer ${getAuthToken()}`
        }
      }
    )
  }
)