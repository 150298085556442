import { all }                    from 'redux-saga/effects'
import PostCreateCommission       from './PostCreateCommission'
import GetCommissionsList         from './GetCommissionsList'
import DeleteCommission           from './DeleteCommissionSagas'
import RestoreCommission          from './RestoreCommissionSagas'
import GetCommissionDetails       from './GetCommissionDetails'
import PutUpdateCommission        from './PutUpdateCommission'
import DeleteCommissionStaff      from './DeleteCommissionTeachingStaffSagas'
import GetSubjectCommissionsSagas from './GetSubjectCommissionsSagas'
import GetCommissionsFile         from './GetCommissionsFile'
import GetAvailableUsers          from './GetAvailableUsers'
import BulkImport                 from './BulkImportSagas'

export default function* dispatchSagas() {
  yield all([
    PostCreateCommission(),
    GetCommissionsList(),
    DeleteCommission(),
    RestoreCommission(),
    GetCommissionDetails(),
    PutUpdateCommission(),
    DeleteCommissionStaff(),
    GetSubjectCommissionsSagas(),
    GetCommissionsFile(),
    GetAvailableUsers(),
    BulkImport()
  ])
}
