/*----- Actions -----*/
import {
  GET_PUBLIC_SUBJECTS,
  GET_PUBLIC_SUBJECTS_ERROR,
  GET_PUBLIC_SUBJECTS_SUCCESS
} from '../Actions'

const initialState = {
  isLoading: false,
  subjects:  [],
  error:     null
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_PUBLIC_SUBJECTS:
      return {
        ...state,
        isLoading: true
      }
    case GET_PUBLIC_SUBJECTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        subjects:  action.payload
      }
    case GET_PUBLIC_SUBJECTS_ERROR:
      return {
        ...state,
        isLoading: false,
        error:     action.payload
      }
    default:
      return state
  }
}