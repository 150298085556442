/*----- Saga -----*/
import { call, takeLatest, put } from 'redux-saga/effects'

/*----- Notifications -----*/
import notifyError         from '../../../../components/commons/notifications/ErrorNotification'
import successNotification from '../../../../components/commons/notifications/SuccessNotification'
import loadingNotification from '../../../../components/commons/notifications/LoadingNotification'

/*----- Actions -----*/
import {
  PUT_USER_DATA_EDITION,
  PUT_USER_DATA_EDITION_SUCCESS,
  PUT_USER_DATA_EDITION_ERROR,
  GET_USER_DETAILS_SUCCESS
} from '../UserActions'

import { GET_CURRENT_USER } from '../../CurrentUserActions'

/*----- Endpoints -----*/
import { putUserEdition } from '../../../../api/endpoints/Users'

export function* shootPutUserDataEdition(action) {
  yield loadingNotification()
  try {
    const response = yield call(putUserEdition, action)
    const body     = yield response.json()
    let type

    if (response.status >= 200 && response.status < 300) {
      type = PUT_USER_DATA_EDITION_SUCCESS
      let currentUserId = action.payload.currentUserId
      if (action.payload.id === currentUserId) {
        yield put(
          {
            type: GET_CURRENT_USER
          }
        )        
      }
      yield put(
        {
          type:    GET_USER_DETAILS_SUCCESS,
          payload: body
        }
      )
      yield successNotification()
    } else {
      type = PUT_USER_DATA_EDITION_ERROR
      yield notifyError(body.error.message)
    }

    yield put(
      {
        type:    type,
        payload: body
      }
    )
  }
  catch (error) {
    yield notifyError('¡Ha ocurrido un error inesperado!')
    yield put(
      {
        type:    PUT_USER_DATA_EDITION_ERROR,
        payload: error.message
      }
    )
  }
}

export default function* putUserEditionWatcher() {
  yield takeLatest(PUT_USER_DATA_EDITION, shootPutUserDataEdition)
}
