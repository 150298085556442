/*----- Imports -----*/
import React              from 'react'
import { useTranslation } from 'react-i18next'
import styled             from 'styled-components'
import PropTypes          from 'prop-types'

/*----- Components -----*/
import SearchInput from '../../../../../components/commons/input/SearchInput'

/*----- Antd -----*/
import Table from '../../../departments/views/list/Table'

const StyledSearchInput = styled(SearchInput)`
  padding-top:   2%;
  margin-bottom: 5%;
`

const MainView = ( 
    { 
      otherDepartments, isLoading, onChangeHandler, selectedDepartments, columns,
      getSubjectDepartments, subjectId
    } 
) => {
  const { t } = useTranslation()

  const rowSelection = {
    selectedRowKeys: selectedDepartments,
    onChange:        onChangeHandler
  }

  return(
    <>
      <StyledSearchInput
        placeholder     = { t('global.search_placeholder') }
        onSearchHandler = { (value) => { getSubjectDepartments(subjectId, value) } }
        allowClear      = { true }
      />

      <Table
        departments  = { otherDepartments }
        columns      = { columns }
        isLoading    = { isLoading }
        rowSelection = { rowSelection }
      />
    </>
  )
}

export default MainView

/*----- PropTypes -----*/
MainView.propTypes = {
  otherDepartments:      PropTypes.array.isRequired,
  isLoading:             PropTypes.bool.isRequired,
  onChangeHandler:       PropTypes.func.isRequired,
  selectedDepartments:   PropTypes.array.isRequired,
  columns:               PropTypes.array.isRequired,
  getSubjectDepartments: PropTypes.func.isRequired,
  subjectId:             PropTypes.string.isRequired
}
