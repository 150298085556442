/*----- Imports -----*/
import React, { useContext, useEffect } from 'react'
import { useTranslation }               from 'react-i18next'
import { connect }                      from 'react-redux'

/*----- Components -----*/
import Menubar from '../components/templates/admin/Menubar'
import Navbar  from '../components/templates/admin/Navbar'

/*----- CASL -----*/
import { AbilityContext } from '../ability/Can'
import updateAbility      from '../ability/UpdateAbility'

/*----- Selectors -----*/
import { visibleSelector }                                from '../components/templates/admin/MenubarSelector'
import { currentUserSelector, currentUserLoadedSelector } from '../modules/admin/CurrentUserSelector'

/*----- Actions -----*/
import { setMenuVisibility } from '../components/templates/admin/MenubarActions'
import { logoutUser }        from '../modules/public/sessions/LogoutActions'

/*----- Antd -----*/
import { Layout } from 'antd'

const { Content, Footer } = Layout

const AdminLayout = (
  {
    pathname, visible, currentUser, setMenuVisibility, logoutUser, children
  }
) => {
  const { t }   = useTranslation()
  const ability = useContext(AbilityContext)

  useEffect(() => {
    updateAbility(ability, currentUser)
  })

  return(
    <Layout style = { { minHeight: '100vh', width: '100%' } } >
      <Menubar
        visible    = { visible }
        setVisible = { setMenuVisibility }
        pathname   = { pathname }
      />

      <Layout style = { { backgroundColor: 'white', width: '100%' } } >

        <Navbar
          setMenuVisibility = { setMenuVisibility }
          currentUser       = { currentUser }
          logoutUser        = { logoutUser }
        />

        <Content>
          { children }
        </Content>

        <Footer style = { { textAlign: 'center' } } >
          { t('footer') }
        </Footer>
      </Layout>
    </Layout>
  )
}

const mapStateToProps = (state) => (
  {
    pathname:          state.router.location.pathname,
    visible:           visibleSelector(state),
    currentUser:       currentUserSelector(state),
    currentUserLoaded: currentUserLoadedSelector(state)
  }
)

const mapDispatchToProps = (dispatch) => (
  {
    setMenuVisibility: (visible) => setMenuVisibility(dispatch, visible),
    logoutUser:               () => logoutUser(dispatch)
  }
)

export default connect(mapStateToProps, mapDispatchToProps)(AdminLayout)