import React               from 'react'
import MainRouter          from './router/MainRouter'
import { ConnectedRouter } from 'connected-react-router'
import { Provider }        from 'react-redux'
import { store, history }  from './store/Store'

/*----- CASL -----*/
import { AbilityContext } from './ability/Can'
import ability            from './ability/Ability'

/*----- Antd -----*/
import { ConfigProvider } from 'antd'
import es                 from 'antd/es/locale/es_ES'
import 'moment/locale/es'

/*** Styles ***/
import 'antd/dist/antd.css'

export default () => (
  <Provider store = { store }>
    <ConfigProvider locale = { es }>
      <ConnectedRouter history = { history }>
        <AbilityContext.Provider value = { ability }>
          <MainRouter />
        </AbilityContext.Provider>
      </ConnectedRouter>
    </ConfigProvider>
  </Provider>
)
