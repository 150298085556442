/*----- Saga -----*/
import { call, takeLatest, put } from 'redux-saga/effects'

/*----- Actions -----*/
import {
  GET_COMMISSIONS_FILE, GET_COMMISSIONS_FILE_ERROR, GET_COMMISSIONS_FILE_SUCCESS
} from '../CommissionActions'

/*----- Endpoint -----*/
import { getAllCommissionsList } from '../../../../api/endpoints/Commissions'

/*----- Notifications -----*/
import notifyError from '../../../../components/commons/notifications/ErrorNotification'

/*----- Utils -----*/
import { downloadFileFromResponse } from '../../../../utils/FileDownloader'
import moment                       from 'moment'

export function* shootGetCommissions(action) {
  try {
    const response = yield call(getAllCommissionsList, action)
    let body
    let type
    
    if (response.status >= 200 && response.status < 300) {
      const filename = `Listado de comisiones ${moment().toISOString()}.xlsx`

      downloadFileFromResponse(response, filename)

      type = GET_COMMISSIONS_FILE_SUCCESS
    }
    else {
      type = GET_COMMISSIONS_FILE_ERROR
    }

    yield put(
      {
        type:    type,
        payload: body
      }
    )
  }
  catch (error) {
    yield notifyError('¡Ha ocurrido un error inesperado!')
    yield put(
      {
        type:    GET_COMMISSIONS_FILE_ERROR,
        payload: error.message
      }
    )
  }
}

export default function* () {
  yield takeLatest(GET_COMMISSIONS_FILE, shootGetCommissions)
}
