export const emailFieldOptions = (t) => (
  {
    rules: [
      {
        type:    'email',
        message: t('rules.valid_email')
      },
      {
        type:    'string',
        pattern: /(\.unlp\.edu\.ar)$/,
        message: t('rules.invalid_email_domain')
      },
      {
        required: true,
        message:  t('rules.insert_email'),
      }
    ],
    validateTrigger: ['onSubmit', 'onBlur']
  }
)

export const requiredFieldOptions = (t) => (
  {
    rules: [
      {
        required: true,
        message: t('rules.mandatory_field'),
      }
    ],
    validateTrigger: ['onSubmit', 'onBlur']
  }
)

