/*----- Saga -----*/
import { call, takeLatest, put } from 'redux-saga/effects'

/*----- Actions -----*/
import { GET_SUBJECT_DEPARTMENTS, GET_SUBJECT_DEPARTMENTS_ERROR, GET_SUBJECT_DEPARTMENTS_SUCCESS } from '../SubjectsActions'

/*----- Endpoint -----*/
import { getSubjectDepartments } from '../../../../api/endpoints/Subjects'

/*----- Notifications -----*/
import notifyError from '../../../../components/commons/notifications/ErrorNotification'

export function* shootGetSubjectDepartments(action) {
  try {
    const response = yield call(getSubjectDepartments, action)
    const body     = yield response.json()
    let type

    if (response.status >= 200 && response.status < 300) {
      type = GET_SUBJECT_DEPARTMENTS_SUCCESS
    } else {
      type = GET_SUBJECT_DEPARTMENTS_ERROR
    }

    yield put(
      {
        type:    type,
        payload: body
      }
    )
  }
  catch (error) {
    yield notifyError('¡Ha ocurrido un error inesperado!')
    yield put(
      {
        type:    GET_SUBJECT_DEPARTMENTS_ERROR,
        payload: error.message
      }
    )
  }
}

export default function* GetSubjectDepartmentsWatcher() {
  yield takeLatest(GET_SUBJECT_DEPARTMENTS, shootGetSubjectDepartments)
}
