/*----- Imports -----*/
import React     from 'react'
import PropTypes from 'prop-types'

/*----- Recharts -----*/
import {
  BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid, Legend
} from 'recharts'

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042']

const Chart = ({ data, records }) => (
  <ResponsiveContainer width = '100%' height = { 300 } >
    <BarChart data = { data }>
      <CartesianGrid strokeDasharray = '3 3' />
      <XAxis dataKey = 'rating' />
      <YAxis />
      <Tooltip />
      <Legend />

      {
        records.map(
          (record, index) => (
            <Bar
              dataKey = { record }
              fill    = { COLORS[index % COLORS.length] }
              key     = { index }
            />
          )
        )
      }

    </BarChart>
  </ResponsiveContainer>
)

export default Chart

/*----- PropTypes -----*/
Chart.propTypes = {
  data:    PropTypes.array.isRequired,
  records: PropTypes.array.isRequired
}
