/*----- Imports -----*/
import React  from 'react'
import styled from 'styled-components'
import moment from 'moment'

/*----- Antd -----*/
import { Icon, Popconfirm } from 'antd'

/*----- CASL ------*/
import { Can } from '../../../../../ability/Can'

/*----- Components -----*/
import ActionButton from '../../../../../components/commons/button/ActionButton'

const Container = styled.div`
  width:   100%;
  display: inline;
`

const columns = (t, deleteImport) => (
  [
    {
      title:          t('imports.created_at'),      
      key:            'created_at',
      sorter:         (a,b) => a.created_at.localeCompare(b.created_at),
      sortDirections: ['ascend', 'descend'],
      render:         (_text, record) => moment(record.created_at).format('YYYY-MM-DD HH:mm')
    },
    {
      title:          t('survey.title'),
      dataIndex:      'survey.name',
      key:            'name',
      sorter:         (a,b) => a.survey.name.localeCompare(b.survey.name),
      sortDirections: ['ascend', 'descend']
    },
    {
      title:  t('global.actions'),
      key:    'action',
      render: (_text, record) => (
        <Container>

          <Can I = 'delete' a = 'Import'>
            <Popconfirm
              title      = { t('notifications.confirm') }
              onConfirm  = { () => deleteImport(record.id) }
              okText     = { t('global.accept') }
              cancelText = { t('global.cancel') }
            >
              <ActionButton>
                <Icon type = 'delete' style = {{ color:'red' }} />
              </ActionButton>
            </Popconfirm>
          </Can>

        </Container>
      )
    }
  ]
)

export default columns
