/*----- Imports -----*/
import React, { useEffect } from 'react'
import { connect }          from 'react-redux'
import { withRouter }       from 'react-router'

/*----- Actions -----*/
import { getSurveyDetails } from './SurveysActions'

/*----- Selectors -----*/
import { loadingSurveySelector, surveyInfoSelector } from './selectors/SurveyDetailsSelector'

/*----- Views -----*/
import MainView from './views/details/MainView'

const SurveyDetails = ({ surveyInfo, getSurveyDetails, isLoading, match }) => {

  const surveyId = match.params.id

  useEffect( () => {
    getSurveyDetails(surveyId)
  }, [getSurveyDetails, surveyId])

  return (
    <MainView
      surveyInfo = { surveyInfo }
      isLoading  = { isLoading }
    />
  )
}

const mapStateToProps = (state) => (
  {
    surveyInfo: surveyInfoSelector(state),
    isLoading:  loadingSurveySelector(state)
  }
)

const mapDispatchToProps = (dispatch) => (
  {
    getSurveyDetails: (survey_id) => getSurveyDetails(dispatch, survey_id)
  }
)

const ConnectedDetailsContainer = connect(mapStateToProps, mapDispatchToProps)(withRouter(SurveyDetails))

ConnectedDetailsContainer.defaultProps = {
  permission:   'show',
  model:        'Survey',
  defaultRoute: '/admin'
}

export default ConnectedDetailsContainer
