/*----- Saga -----*/
import { call, takeLatest, put } from 'redux-saga/effects'

/*----- Actions -----*/
import {
  USERS_NOT_IN_DEPARTMENT,
  USERS_NOT_IN_DEPARTMENT_ERROR,
  USERS_NOT_IN_DEPARTMENT_SUCCESS
} from '../DepartmentsActions'

/*----- Endpoint -----*/
import { getDepartmentUsers } from '../../../../api/endpoints/Departments'

/*----- Notifications -----*/
import notifyError from '../../../../components/commons/notifications/ErrorNotification'

export function* shootGetUsersNotInDepartment(action) {
  try {
    const response = yield call(getDepartmentUsers, action)
    const body     = yield response.json()
    let type

    if (response.status >= 200 && response.status < 300) {
      type = USERS_NOT_IN_DEPARTMENT_SUCCESS
    } else {
      type = USERS_NOT_IN_DEPARTMENT_ERROR
    }

    yield put(
      {
        type:    type,
        payload: {
          departmentId: action.payload.departmentId,
          body:         body
        }
      }
    )
  }
  catch (error) {
    yield notifyError('¡Ha ocurrido un error inesperado!')
    yield put(
      {
        type:    USERS_NOT_IN_DEPARTMENT_ERROR,
        payload: error.message
      }
    )
  }
}

export default function* CreateUserWatcher() {
  yield takeLatest(USERS_NOT_IN_DEPARTMENT, shootGetUsersNotInDepartment)
}
