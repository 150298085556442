export const GET_CURRENT_USER         = 'GET_CURRENT_USER'
export const GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS'
export const GET_CURRENT_USER_ERROR   = 'GET_CURRENT_USER_ERROR'

export const currentUser = (dispatch) => {
  dispatch(
    {
      type: GET_CURRENT_USER
    }
  )
}
