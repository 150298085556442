/*----- Imports -----*/
import React    from 'react'
import { Link } from 'react-router-dom'
import styled   from 'styled-components'

/*----- Antd -----*/
import { Icon, Popconfirm, Divider } from 'antd'

/*----- CASL ------*/
import { Can } from '../../../../../ability/Can'

/*----- Components -----*/
import ActionButton from '../../../../../components/commons/button/ActionButton'

const Container = styled.div`
  width:   100%;
  display: inline;
`

const columns = (t, deleteSurvey) => (
  [
    {
      title:          t('survey.name'),
      dataIndex:      'name',
      key:            'name',
      sorter:         (a,b) => a.name.localeCompare(b.name),
      sortDirections: ['ascend', 'descend']
    },
    {
      title:  t('global.actions'),
      key:    'action',
      render: (_text, record) => (
        <Container>
          
          <Can I = 'show' a = 'Survey'>
            <ActionButton>
              <Link to = { `/admin/surveys/${record.id}` } >
                <Icon type = 'eye'/>
              </Link>
            </ActionButton>
          </Can>

          <Can I = 'delete' a = 'Survey'>
            <Container>
              <Divider type = 'vertical' />
              <Popconfirm
                title      = { t('notifications.confirm') }
                onConfirm  = { () => deleteSurvey(record.id) }
                okText     = { t('global.accept') }
                cancelText = { t('global.cancel') }
              >
                <ActionButton>
                  <Icon type = 'delete' style = {{ color:'red' }} />
                </ActionButton>
              </Popconfirm>
            </Container>
          </Can>

        </Container>
      )
    }
  ]
)

export default columns
