/*----- Actions -----*/
import {
  GET_COMMISSIONS_LIST, GET_COMMISSIONS_LIST_SUCCESS, GET_COMMISSIONS_LIST_ERROR
} from '../CommissionActions'

const initialState = {
  isLoading:   false,
  commissions: [],
  error:       null
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_COMMISSIONS_LIST:
      return {
        ...state,
        isLoading: true
      }
    case GET_COMMISSIONS_LIST_SUCCESS:
      return {
        ...state,
        isLoading:   false,
        commissions: action.payload
      }
    case GET_COMMISSIONS_LIST_ERROR:
      return {
        ...state,
        isLoading: false,
        error:     action.payload
      }
    default:
      return state
  }
}
