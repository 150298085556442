/*----- Imports -----*/
import React, { useMemo } from 'react'
import styled             from 'styled-components'
import PropTypes          from 'prop-types'
import { useTranslation } from 'react-i18next'

/*----- Views -----*/
import Table   from './Table'
import columns from './Columns'
import Filters from './Filters'

const Container = styled.div`
  margin-left: 10%;
  width:       80%;
`

const MainView = ({ imports, isLoading, deleteImport, onFiltersChange }) => {
  const { t }             = useTranslation()
  const uniqueSurveysList = useMemo(
    () => {
      const surveys = imports.map(importation => importation.survey)

      const result = []
      const map  = new Map()

      for (const survey of surveys) {
        if(!map.has(survey.id)) {
          map.set(survey.id, true)
          result.push({
            id:   survey.id,
            name: survey.name
          })
        }
      }

      return result
    }, [imports]
  )

  return(
    <Container>
      
      <Filters
        onFiltersChange = { onFiltersChange }
        t               = { t }
        surveys         = { uniqueSurveysList }
        isLoading       = { isLoading }
      />

      <Table
        imports   = { imports }
        isLoading = { isLoading }
        columns   = { columns(t, deleteImport) }
      />
    </Container>
  )
}

export default MainView

/*----- PropTypes -----*/
MainView.propTypes = {
  imports:         PropTypes.array.isRequired,
  isLoading:       PropTypes.bool.isRequired,
  deleteImport:    PropTypes.func.isRequired,
  onFiltersChange: PropTypes.func.isRequired
}
