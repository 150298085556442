/*----- Imports -----*/
import React              from 'react'
import { useTranslation } from 'react-i18next'
import { Link }           from 'react-router-dom'

/*----- Banner image -----*/
import Banner from './Banner'

/*----- Styles -----*/
import HomeStyles from './HomeStyles'

/*----- Sample graphics -----*/
import BarChart from './graphics/BarChart'
import PieChart from './graphics/PieChart'

const Home = () => {
  const { t } = useTranslation()

  return(
    <>
      <HomeStyles />
      <Banner />

      {/* Probar cambiando la clase style1 por style2 y style3. */}
      <section id = 'one' className = 'wrapper style1'>
        <div className = 'inner'>

          <article className = 'feature left'>
            <span className = 'image'>
              <BarChart />
            </span>

            <div className = 'content'>
              <h2>{ t('public.home.teachers_feature_title') }</h2>
              <p>{ t('public.home.teachers_feature_paragraph') }</p>
              <ul className = 'actions'>
                <li>
                  <Link to = '/graphics/teachers' className = 'button alt'>
                    { t('global.more') }
                  </Link>
                </li>
              </ul>
            </div>

          </article>

          <article className = 'feature right'>

            <span className = 'image'>
              <PieChart />
            </span>

            <div className = 'content'>
              <h2>{ t('public.home.subjects_feature_title') }</h2>
              <p>{ t('public.home.subjects_feature_paragraph') }</p>
              <ul className = 'actions'>
                <li>
                  <Link to = '/graphics/subjects' className = 'button alt'>
                    { t('global.more') }
                  </Link>
                </li>
              </ul>
            </div>

          </article>

        </div>
      </section>
    </>
  )
}

export default Home
