/*----- Actions -----*/
import {
  EDIT_COMMISSION,
  EDIT_COMMISSION_ERROR,
  EDIT_COMMISSION_SUCCESS,
  SET_COMMISSION_EDITION_FIELDS,
  ADD_TEACHING_STAFF_FIELDS,
  DELETE_TEACHING_STAFF_FIELD,
  CHANGE_EDITION_ACTIVE_TAB
} from '../CommissionActions'

/*----- Utils -----*/
import { mapErrorsRecursively } from '../../../../utils/ResponseFormatter'

export const TEACHING_STAFF_FIELDS = {
  user:            {},
  workload:        {},
  position_number: {},
  dedication:      {},
  category:        {}
}

export const initialState = {
  isLoading: false,
  activeTab: '#commission',
  success:   '',
  fields:    {
    last_key:           0,
    keys:               [0],
    name:               {},
    subject:            {},
    teaching_staff:     {
      0: TEACHING_STAFF_FIELDS
    },
    workload:           {},
    subscribers_amount: {},
    comments:           {},
    year:               {},
    period:             {}
  },
  error:     {
    message: '',
    fields:  []
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case EDIT_COMMISSION:
      return {
        ...state,
        activeTab: state.activeTab,
        isLoading: true,
      }
    case EDIT_COMMISSION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success:   action.payload
      }
    case EDIT_COMMISSION_ERROR:
      const updated_fields = Object.assign(
        state.fields,
        mapErrorsRecursively(
          state.fields, action.payload.error.fields
        )
      )

      return {
        ...state,
        isLoading: false,
        error:     action.payload.error,
        fields:    updated_fields
      }
    case SET_COMMISSION_EDITION_FIELDS:
      return {
        ...state,
        fields: {
          ...state.fields,
          ...action.payload
        }
      }
    case DELETE_TEACHING_STAFF_FIELD:
      const key_to_delete = action.payload
      const updated_staff = state.fields.teaching_staff
      const updated_keys  = state.fields.keys

      // Borramos el objeto entero de teaching_stafff
      delete(updated_staff[key_to_delete])

      // Borro la key recibida del arreglo de keys
      const index = updated_keys.indexOf(key_to_delete)
      if (index > -1) {
        updated_keys.splice(index, 1)
      }

      return {
        ...state,
        fields: {
          ...state.fields,
          teaching_staff: updated_staff,
          keys:           updated_keys
        }
      }
    case ADD_TEACHING_STAFF_FIELDS:
      const next_key = state.fields.last_key + 1

      return {
        ...state,
        fields: {
          ...state.fields,
          last_key:       next_key,
          teaching_staff: {
            ...state.fields.teaching_staff,
            [next_key]: TEACHING_STAFF_FIELDS
          },
          keys: state.fields.keys.concat(next_key)
        }
      }
    case CHANGE_EDITION_ACTIVE_TAB:
      // Cambio de tab + reseteo de campos de teaching_staff
      return {
        ...state,
        activeTab: action.payload,
        fields:    {
          ...state.fields,
          last_key:       0,
          keys:           [0],
          teaching_staff: {
            0: TEACHING_STAFF_FIELDS
          }
        }
      }
    default:
      return state
  }
}
