/*----- Imports -----*/
import { call, takeLatest, put } from 'redux-saga/effects'

/*----- Actions -----*/
import {
  DELETE_COMMISSION,
  DELETE_COMMISSION_SUCCESS,
  DELETE_COMMISSION_ERROR,
  GET_COMMISSIONS_LIST
} from '../CommissionActions'

/*----- Notifications -----*/
import loadingNotification from '../../../../components/commons/notifications/LoadingNotification'
import successNotification from '../../../../components/commons/notifications/SuccessNotification'
import notifyError         from '../../../../components/commons/notifications/ErrorNotification'

/*----- Endpoints -----*/
import { deleteCommission } from '../../../../api/endpoints/Commissions'

export function* shootDeletion(action) {
  yield loadingNotification()

  try {
    const response = yield call(deleteCommission, action)
    let deletionResponse
    let type

    if (response.status >= 200 && response.status < 300) {
      type = DELETE_COMMISSION_SUCCESS

      yield put({ type: GET_COMMISSIONS_LIST })
      yield successNotification()
    }
    else {
      deletionResponse = yield response.json()
      type             = DELETE_COMMISSION_ERROR

      yield notifyError(deletionResponse.error.message)
    }

    yield put(
      {
        type:    type,
        payload: deletionResponse
      }
    )
  }
  catch (error) {
    yield notifyError('¡Ha ocurrido un error inesperado!')
    yield put(
      {
        type:    DELETE_COMMISSION_ERROR,
        payload: error.message
      }
    )
  }
}

export default function* () {
  yield takeLatest(DELETE_COMMISSION, shootDeletion)
}
