/*----- Saga -----*/
import { call, takeLatest, put } from 'redux-saga/effects'

/*----- Actions -----*/
import { IMPORT_SURVEY_RESULTS, IMPORT_SURVEY_RESULTS_SUCCESS, IMPORT_SURVEY_RESULTS_ERROR } from '../SurveysActions'

/*----- Endpoint -----*/
import { importSurveyResults } from '../../../../api/endpoints/Surveys'

/*----- Notifications -----*/
import loadingNotification from '../../../../components/commons/notifications/LoadingNotification'
import successNotification from '../../../../components/commons/notifications/SuccessNotification'
import notifyError         from '../../../../components/commons/notifications/ErrorNotification'

export function* shootImportSurveyResults(action) {
  yield loadingNotification()
  try {
    const response = yield call(importSurveyResults, action)
    let body     
    let type

    if (response.status >= 200 && response.status < 300) {
      type = IMPORT_SURVEY_RESULTS_SUCCESS
      yield successNotification()
    } else {
      body = yield response.json()
      type = IMPORT_SURVEY_RESULTS_ERROR
      
      yield notifyError(body.error.message)
    }

    yield put(
      {
        type:    type,
        payload: body
      }
    )
  }
  catch (error) {
    yield notifyError('¡Ha ocurrido un error inesperado!')
    yield put(
      {
        type:    IMPORT_SURVEY_RESULTS_ERROR,
        payload: error.message
      }
    )
  }
}

export default function* () {
  yield takeLatest(IMPORT_SURVEY_RESULTS, shootImportSurveyResults)
}
