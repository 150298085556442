/*----- Imports -----*/
import React, { useEffect } from 'react'
import { connect }          from 'react-redux'
import { useTranslation }   from 'react-i18next'

/*----- Selectors -----*/
import { loadingUsersSelector, usersListSelector } from '../users/selectors/usersListSelector'
import { selectedUsersSelector }                   from './selectors/SelectedUsersSelector'

/*----- Actions -----*/
import { getUsers }        from '../users/UserActions'
import { setSeletedUsers } from './DepartmentsActions'

/*----- Views -----*/
import MainView from './views/users_table/MainView'

/*----- Columns -----*/
import columns from './views/users_table/Columns'

const UsersTableWithSelection = ( { getUsers, users, isLoading, setSeletedUsers, selectedUsers } ) => {
  const { t } = useTranslation()

  useEffect(() => {
    getUsers()
    setSeletedUsers([])
  }, [getUsers, setSeletedUsers])

  
  const onSelectChange = selectedRowKeys => {
    setSeletedUsers(selectedRowKeys)
  }

  return(
    <MainView
      users          = { users }
      getUsers       = { getUsers }
      columns        = { columns(t) }
      onChangeHander = { onSelectChange }
      selectedUsers  = { selectedUsers }
      isLoading      = { isLoading }
    /> 
  )
}

function mapStateToProps(state) {
  return {
    isLoading:     loadingUsersSelector(state),
    users:         usersListSelector(state),
    selectedUsers: selectedUsersSelector(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getUsers:          (search = '') => getUsers(dispatch, search),
    setSeletedUsers: (selectedUsers) => setSeletedUsers(dispatch, selectedUsers)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersTableWithSelection)
