/*----- Imports -----*/
import { call, takeLeading, put } from 'redux-saga/effects'
import { push }                   from 'connected-react-router'

/*----- Endpoints -----*/
import { refreshToken } from '../../../../api/endpoints/Sessions'

/*----- Actions -----*/
import { REFRESH, REFRESH_FAIL, REFRESH_SUCCESS} from '../RefreshActions'

/*----- Notifications -----*/
import notifyError from '../../../../components/commons/notifications/ErrorNotification'


export function* shootRefreshToken() {
  try {
    const response        = yield call(refreshToken)
    const auth_token      = yield response.headers.get('Auth-Token')

    if (response.status >= 200 && response.status < 300) {
      yield put(
        {
          type:    REFRESH_SUCCESS,
          payload: auth_token
        }
      )
    }
    else {
      yield notifyError('Debes iniciar sesión nuevamente')
      yield put({ type: REFRESH_FAIL })
      yield put(push('/login'))
    }

    return response
  }

  catch (error) {
    yield put(
      {
        type:    REFRESH_FAIL,
        payload: error.message
      }
    )
  }
}

export default function* createRouteWatcher() {
  yield takeLeading(REFRESH, shootRefreshToken)
}
