/*----- Imports -----*/
import React, { useEffect} from 'react'
import { connect }         from 'react-redux'

/*----- Selectors -----*/
import { loadingSurveysSelector, surveysListSelector } from './selectors/GetSurveysSelector'

/*----- Actions -----*/
import { getSurveys, deleteSurvey } from './SurveysActions'

/*----- View -----*/
import MainView from './views/list/MainView'

const ListContainer = ({ getSurveys, surveys, isLoading, deleteSurvey }) => {
  useEffect(
    () => getSurveys(),
    [getSurveys]
  )

  return(
    <MainView
      surveys      = { surveys }
      isLoading    = { isLoading }
      deleteSurvey = { deleteSurvey }
    />
  )
}

const mapStateToProps = (state) => (
  {
    isLoading: loadingSurveysSelector(state),
    surveys:   surveysListSelector(state),
  }
)

const mapDispatchToProps = (dispatch) => (
  {
    getSurveys:           () => getSurveys(dispatch),
    deleteSurvey: (surveyId) => deleteSurvey(dispatch, surveyId)
  }
)

const ConnectedListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ListContainer)

ConnectedListContainer.defaultProps = {
  permission:   'list',
  model:        'Survey',
  defaultRoute: '/admin'
}

export default ConnectedListContainer
