/*----- Imports -----*/
import { call, takeLatest, put } from 'redux-saga/effects'

/*----- Actions -----*/
import {
  RESTORE_DEPARTMENT,
  RESTORE_DEPARTMENT_SUCCESS,
  RESTORE_DEPARTMENT_ERROR,
  DEPARTMENTS_LIST
} from '../DepartmentsActions'

/*----- Notifications -----*/
import loadingNotification from '../../../../components/commons/notifications/LoadingNotification'
import successNotification from '../../../../components/commons/notifications/SuccessNotification'
import notifyError         from '../../../../components/commons/notifications/ErrorNotification'

/*----- Endpoints -----*/
import { restoreDepartment } from '../../../../api/endpoints/Departments'

export function* shootRestoreDepartment(action) {
  yield loadingNotification()

  try {
    const response = yield call(restoreDepartment, action)
    let body
    let type

    if (response.status >= 200 && response.status < 300) {
      type = RESTORE_DEPARTMENT_SUCCESS

      yield put(
        {
          type:    DEPARTMENTS_LIST,
          payload: {
            blocked: true
          }
        }
      )
      yield successNotification()
    }
    else {
      body = yield response.json()
      type = RESTORE_DEPARTMENT_ERROR

      yield notifyError(body.error.message)
    }

    yield put(
      {
        type:    type,
        payload: body
      }
    )
  }
  catch (error) {
    yield notifyError('¡Ha ocurrido un error inesperado!')
    yield put(
      {
        type:    RESTORE_DEPARTMENT_ERROR,
        payload: error.message
      }
    )
  }
}

export default function* restoreDepartmentWatcher() {
  yield takeLatest(RESTORE_DEPARTMENT, shootRestoreDepartment)
}
