/*----- Imports -----*/
import React    from 'react'
import { Link } from 'react-router-dom'

/*----- Antd -----*/
import { Icon, Popconfirm, Divider } from 'antd'

/*----- Components -----*/
import ActionButton from '../../../../../components/commons/button/ActionButton'

const columns = (t, deleteSubjectDepartment, subjectId) => (
  [
    {
      title:          t('department.name'),
      key:            'name',
      dataIndex:      'name',
      sorter:         (a,b) => a.name.localeCompare(b.name),
      sortDirections: ['ascend','descend']
    },
    {
      title:  t('global.actions'),
      key:    'action',
      render: (_text, record) => (
          <>
            <ActionButton>
              <Link to = { `/admin/departments/${record.id}` } >
                <Icon type = 'eye'/>
              </Link>
            </ActionButton>

            <Divider
              type = 'vertical'
            />

            <Popconfirm
              title      = { t('notifications.confirm') }
              onConfirm  = { () => (deleteSubjectDepartment(subjectId, [record.id])) }
              okText     = { t('global.accept') }
              cancelText = { t('global.cancel') }
            >
              <ActionButton>
                <Icon type = 'delete' style = {{ color:'red' }}/>
              </ActionButton>
            </Popconfirm>
          </>
      )
    }
  ]
)

export default columns
