/*----- Imports -----*/
import React              from 'react'
import styled             from 'styled-components'
import PropTypes          from 'prop-types'
import { useTranslation } from 'react-i18next'

/*----- Form -----*/
import GeneralForm from '../../forms/General'

const StyledTitle = styled.h2`
  text-align: center;
`

const GeneralTab = (
  {
    form, isLoading, submitGeneralData, loadingSubjects, subjectsList, commission
  }
) => {
  const { t } = useTranslation()

  return(
    <>
      <StyledTitle> { t('admin.edit_commission.title') } </StyledTitle>

      <GeneralForm
        form            = { form }
        isLoading       = { isLoading }
        submitHandler   = { submitGeneralData }
        loadingSubjects = { loadingSubjects }
        subjectsList    = { subjectsList }
        commission      = { commission }
      />
    </>
  )
}

export default GeneralTab

GeneralTab.propTypes = {
  form:              PropTypes.object.isRequired,
  isLoading:         PropTypes.bool.isRequired,
  submitGeneralData: PropTypes.func.isRequired,
  loadingSubjects:   PropTypes.bool.isRequired,
  subjectsList:      PropTypes.array.isRequired,
  commission:        PropTypes.object.isRequired
}

