/*----- Imports -----*/
import React, { useEffect } from 'react'
import { connect }          from 'react-redux'

/*----- Actions -----*/
import { 
  exportSurvey, 
  setExportSurveyFields,
  getSurveys
} from './SurveysActions'
import { getSubjects }    from '../subjects/SubjectsActions'
import { getDepartments } from '../departments/DepartmentsActions'
import { getCommissions } from '../commissions/CommissionActions'

/*----- Selectors -----*/
import { departmentsListSelector } from '../departments/selectors/DepartmentsListSelector'
import { subjectsListSelector }    from '../subjects/selectors/SubjectsListSelector'
import { commissionsSelector }     from '../commissions/selectors/CommissionsListSelector'
import { surveysListSelector }     from './selectors/GetSurveysSelector'
import { 
  exportSurveyFieldsSelector,
  loadingSelector
 } from './selectors/ExportSurveySelector'

/*----- Antd -----*/
import { Form } from 'antd'

/*----- Fields -----*/
import { initialState } from './reducers/ExportSurveyReducer'

/*----- Views -----*/
import MainView from './views/export/MainView'

const hasErrors = (fieldsError) => {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

const ExportSurvey = ({
  form, isLoading, departments, getDepartments, subjects, getSubjects,
  commissions, getCommissions, setExportSurveyFields, exportSurvey, surveys, getSurveys,
  formFields
}) => {

  // Clean redux after leave component render.
  useEffect(() => {
    setExportSurveyFields(initialState.fields)
  }, [setExportSurveyFields])

  useEffect(() => {
    getDepartments()
  }, [getDepartments])

  useEffect(() => {
    getSurveys()
  }, [getSurveys])

  useEffect(() => {
    getCommissions()
  }, [getCommissions])

  useEffect(() => {
    getSubjects()
  }, [getSubjects])

  const handleSubmit = (event) => {
    event.preventDefault()
    form.validateFields({ force: true })

    if (!hasErrors(form.getFieldsError())) {
      let fields = form.getFieldsValue()

      //Delete all undefined fields
      Object.keys(fields).forEach(key => fields[key] === undefined ? delete fields[key] : {})

      //Get Survey ID and delete it from fields
      let survey_id = fields['survey_id']
      delete fields['survey_id']

      fields['year'] = fields['year'].year()

      exportSurvey(survey_id, fields)
    }
  }

  const filteredSubjects = () => {
    if (formFields.departments.value.length === 0) return subjects

    return subjects.filter((subject) => {
      const department_ids = subject.departments.map((department) => department.id)

      return department_ids.some((id) => formFields.departments.value.includes(id.toString()))
    })
  }

  const filteredCommissions = () => {
    if (formFields.subjects.value.length !== 0){
      return commissions.filter(commission =>
        formFields.subjects.value.includes(commission.subject.id.toString())
      )
    } else if (formFields.departments.value.length !== 0) {
        const target_subjects_ids = subjects.filter( subject => 
          subject.departments.some( department =>
            formFields.departments.value.includes(department.id.toString())
          )).map(subject => subject.id)
        return commissions.filter(commission => target_subjects_ids.includes(commission.subject.id))
    }

    return commissions
  }

  return (
    <MainView
      form         = { form }
      handleSubmit = { handleSubmit }
      departments  = { departments }
      commissions  = { filteredCommissions() }
      subjects     = { filteredSubjects() }
      surveys      = { surveys }
      isLoading    = { isLoading }
    />
  )
}

const mapStateToProps = (state) => (
  {
    departments: departmentsListSelector(state),
    commissions: commissionsSelector(state),
    subjects:    subjectsListSelector(state),
    surveys:     surveysListSelector(state),
    formFields:  exportSurveyFieldsSelector(state),
    isLoading:   loadingSelector(state)
  }
)

const mapDispatchToProps = (dispatch) => (
  {
    getDepartments:                () => getDepartments(dispatch),
    getCommissions:                () => getCommissions(dispatch),
    getSubjects:                   () => getSubjects(dispatch),
    getSurveys:                    () => getSurveys(dispatch),
    exportSurvey: (survey_id, params) => exportSurvey(dispatch, survey_id, params),
    setExportSurveyFields:   (params) => setExportSurveyFields(dispatch, params)
  }
)

const ConnectedExportSurvey = connect(mapStateToProps, mapDispatchToProps)(
  Form.create(
    {
      name: 'export_survey_form',
      onFieldsChange({ setExportSurveyFields }, changedFields) {
        for (const prop in changedFields) {
          changedFields[prop].errors = undefined
        }
        setExportSurveyFields(changedFields)
      },
      mapPropsToFields({ formFields }) {
        return {
          survey_id:     Form.createFormField(formFields.survey_id),
          subjects:      Form.createFormField(formFields.subjects),
          departments:   Form.createFormField(formFields.departments),
          commissions:   Form.createFormField(formFields.commissions),
          with_teachers: Form.createFormField(formFields.with_teachers),
          period:        Form.createFormField(formFields.period),
          year:          Form.createFormField(formFields.year)
        }
      }
    }
)(ExportSurvey))

ConnectedExportSurvey.defaultProps = {
  permission:   'export',
  model:        'Survey',
  defaultRoute: '/admin'
}

export default ConnectedExportSurvey