export const CREATE_COMMISSION         = 'CREATE_COMMISSION'
export const CREATE_COMMISSION_SUCCESS = 'CREATE_COMMISSION_SUCCESS'
export const CREATE_COMMISSION_ERROR   = 'CREATE_COMMISSION_ERROR'

export const GET_COMMISSIONS_LIST         = 'GET_COMMISSIONS_LIST'
export const GET_COMMISSIONS_LIST_SUCCESS = 'GET_COMMISSIONS_LIST_SUCCESS'
export const GET_COMMISSIONS_LIST_ERROR   = 'GET_COMMISSIONS_LIST_ERROR'

export const DELETE_COMMISSION         = 'DELETE_COMMISSION'
export const DELETE_COMMISSION_SUCCESS = 'DELETE_COMMISSION_SUCCESS'
export const DELETE_COMMISSION_ERROR   = 'DELETE_COMMISSION_ERROR'

export const RESTORE_COMMISSION         = 'RESTORE_COMMISSION'
export const RESTORE_COMMISSION_SUCCESS = 'RESTORE_COMMISSION_SUCCESS'
export const RESTORE_COMMISSION_ERROR   = 'RESTORE_COMMISSION_ERROR'

export const GET_COMMISSION_DETAILS         = 'GET_COMMISSION_DETAILS'
export const GET_COMMISSION_DETAILS_SUCCESS = 'GET_COMMISSION_DETAILS_SUCCESS'
export const GET_COMMISSION_DETAILS_ERROR   = 'GET_COMMISSION_DETAILS_ERROR'

export const EDIT_COMMISSION         = 'EDIT_COMMISSION'
export const EDIT_COMMISSION_SUCCESS = 'EDIT_COMMISSION_SUCCESS'
export const EDIT_COMMISSION_ERROR   = 'EDIT_COMMISSION_ERROR'

export const DELETE_COMMISSION_TEACHING_STAFF         = 'DELETE_COMMISSION_TEACHING_STAFF'
export const DELETE_COMMISSION_TEACHING_STAFF_SUCCESS = 'DELETE_COMMISSION_TEACHING_STAFF_SUCCESS'
export const DELETE_COMMISSION_TEACHING_STAFF_ERROR   = 'DELETE_COMMISSION_TEACHING_STAFF_ERROR'

export const SET_COMMISSION_CREATION_FIELDS = 'SET_COMMISSION_CREATION_FIELDS'
export const SET_COMMISSION_EDITION_FIELDS  = 'SET_COMMISSION_EDITION_FIELDS'
export const ADD_TEACHING_STAFF_FIELDS      = 'ADD_TEACHING_STAFF_FIELDS'
export const DELETE_TEACHING_STAFF_FIELD    = 'DELETE_TEACHING_STAFF_FIELD'
export const CHANGE_EDITION_ACTIVE_TAB      = 'CHANGE_EDITION_ACTIVE_TAB'

export const GET_SUBJECT_COMMISSIONS         = 'GET_SUBJECT_COMMISSIONS'
export const GET_SUBJECT_COMMISSIONS_SUCCESS = 'GET_SUBJECT_COMMISSIONS_SUCCESS'
export const GET_SUBJECT_COMMISSIONS_ERROR   = 'GET_SUBJECT_COMMISSIONS_ERROR'

export const GET_COMMISSIONS_FILE         = 'GET_COMMISSIONS_FILE'
export const GET_COMMISSIONS_FILE_SUCCESS = 'GET_COMMISSIONS_FILE_SUCCESS'
export const GET_COMMISSIONS_FILE_ERROR   = 'GET_COMMISSIONS_FILE_ERROR'

export const GET_AVAILABLE_USERS         = 'GET_AVAILABLE_USERS'
export const GET_AVAILABLE_USERS_SUCCESS = 'GET_AVAILABLE_USERS_SUCCESS'
export const GET_AVAILABLE_USERS_ERROR   = 'GET_AVAILABLE_USERS_ERROR'

export const BULK_IMPORT         = 'BULK_IMPORT'
export const BULK_IMPORT_SUCCESS = 'BULK_IMPORT_SUCCESS'
export const BULK_IMPORT_ERROR   = 'BULK_IMPORT_ERROR'

export const SET_BULK_IMPORT_FIELDS = 'SET_BULK_IMPORT_FIELDS'

export const createCommission = (dispatch, fields) => {
  const { subject } = fields
  delete(fields.subject)

  dispatch(
    {
      type:    CREATE_COMMISSION,
      payload: {
        subjectId: subject,
        body:      fields
      }
    }
  )
}

export const getCommissions = (dispatch, params = {}) => {
  dispatch(
    {
      type:    GET_COMMISSIONS_LIST,
      payload: params
    }
  )
}

export const deleteCommission = (dispatch, subjectId, commissionId) => {
  dispatch(
    {
      type:    DELETE_COMMISSION,
      payload: {
        subjectId:    subjectId,
        commissionId: commissionId
      }
    }
  )
}

export const restoreCommission = (dispatch, subjectId, commissionId, filters) => {
  dispatch(
    {
      type:    RESTORE_COMMISSION,
      payload: {
        subjectId:    subjectId,
        commissionId: commissionId,
        filters:      filters
      }
    }
  )
}

export const setCommissionCreationFields = (dispatch, fields) => {
  dispatch(
    {
      type:    SET_COMMISSION_CREATION_FIELDS,
      payload: fields
    }
  )
}

export const getCommissionDetails = (dispatch, commissionId) => {
  dispatch(
    {
      type:    GET_COMMISSION_DETAILS,
      payload: {
        commissionId: commissionId
      }
    }
  )
}

export const editCommission = (dispatch, subjectId, commissionId, fields) => {
  dispatch(
    {
      type:    EDIT_COMMISSION,
      payload: {
        subjectId:    subjectId,
        commissionId: commissionId,
        body:         fields
      }
    }
  )
}

export const setCommissionEditionFields = (dispatch, fields) => {
  dispatch(
    {
      type:    SET_COMMISSION_EDITION_FIELDS,
      payload: fields
    }
  )
}

export const addTeachingStaffFields = (dispatch) => {
  dispatch(
    {
      type: ADD_TEACHING_STAFF_FIELDS
    }
  )
}

export const deleteTeachingStaffField = (dispatch, field_key) => {
  dispatch(
    {
      type:    DELETE_TEACHING_STAFF_FIELD,
      payload: field_key
    }
  )
}

export const deleteCommissionTeachingStaff = (dispatch, subjectId, commissionId, userId) => {
  dispatch(
    {
      type:    DELETE_COMMISSION_TEACHING_STAFF,
      payload: {
        subjectId:    subjectId,
        commissionId: commissionId,
        body:         {
          users: [userId]
        }
      }
    }
  )
}
  
export const getSubjectCommissions = (dispatch, subjectId) => {
  dispatch(
    {
      type:    GET_SUBJECT_COMMISSIONS,
      payload: {
        subjectId: subjectId
      }
    }
  )
}

export const changeEditionActiveTab = (dispatch, activeKey) => {
  dispatch(
    {
      type:    CHANGE_EDITION_ACTIVE_TAB,
      payload: activeKey
    }
  )
}

export const getCommissionsFile = (dispatch, params = {}, format) => {
  dispatch(
    {
      type:    GET_COMMISSIONS_FILE,
      payload: {
        ...params,
        format: format
      }
    }
  )
}

export const getAvailableUsers = (dispatch, commissionId) => {
  dispatch(
    {
      type:    GET_AVAILABLE_USERS,
      payload: {
        commissionId: commissionId
      }
    }
  )
}

export const setBulkImportFields = (dispatch, fields) => {
  dispatch(
    {
      type:    SET_BULK_IMPORT_FIELDS,
      payload: fields
    }
  )
}

export const bulkImport = (dispatch, fields) => {
  const form_data = new FormData()

  fields.new_data_file.fileList.forEach(file => {
    form_data.append('new_data_file', file.originFileObj)
  })

  dispatch(
    {
      type:    BULK_IMPORT,
      payload: {
        body:  form_data
      }
    }
  )
}
