export const serviceErrorsToFormFields = (stateFields, fieldsErrors) => {  
  let result = { ...stateFields }
  
  for (let key of Object.keys(fieldsErrors)) {
    result[key]['errors'] = fieldsErrors[key].map((error) => {
      return {
        message: error,
        field:   key
      }
    })
  }

  return result
}

export const mapErrorsRecursively = (stateFields, fieldsErrors) => {  
  parsedErrorsFromResponse(fieldsErrors, stateFields)

  return stateFields
}

const parsedErrorsFromResponse = (response, result) => {
  if (Array.isArray(response)) {
    return { errors: response.map((error) => ({ message: error })) }
  }
  else if (response instanceof Object) {
    for (let [field, fieldError] of Object.entries(response)) {
      result[field] = parsedErrorsFromResponse(fieldError, result[field])
    }
  }

  return result
}