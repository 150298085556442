/*----- Saga -----*/
import { call, takeLatest, put } from 'redux-saga/effects'

/*----- Actions -----*/
import {
  DEPARTMENT_DETAILS,
  DEPARTMENT_USERS,
  DELETE_DEPARTMENT_USERS,
  DELETE_DEPARTMENT_USERS_ERROR,
  DELETE_DEPARTMENT_USERS_SUCCESS
} from '../DepartmentsActions'

/*----- Endpoint -----*/
import { deleteDepartmentUsers } from '../../../../api/endpoints/Departments'

/*----- Notifications -----*/
import notifyError         from '../../../../components/commons/notifications/ErrorNotification'
import successNotification from '../../../../components/commons/notifications/SuccessNotification'
import loadingNotification from '../../../../components/commons/notifications/LoadingNotification'

function* shootDeleteDepartmentUser(action) {
  yield loadingNotification()

  try {
    const response = yield call(deleteDepartmentUsers, action)
    const body     = yield response.json()
    let type

    if (response.status >= 200 && response.status < 300) {
      type = DELETE_DEPARTMENT_USERS_SUCCESS
      yield put(
        {
          type:    DEPARTMENT_DETAILS,
          payload: action.payload.departmentId
        }
      )

      yield put(
        {
          type:    DEPARTMENT_USERS,
          payload: {
            departmentId: action.payload.departmentId
          }
        }
      )
      yield successNotification()
    }
    else {
      type = DELETE_DEPARTMENT_USERS_ERROR
      yield notifyError(body.error.message)
    }

    yield put(
      {
        type:    type,
        payload: body
      }
    )
  }
  catch (error) {
    yield notifyError('¡Ha ocurrido un error inesperado!')
    yield put(
      {
        type:    DELETE_DEPARTMENT_USERS_ERROR,
        payload: error.message
      }
    )
  }
}

export default function* () {
  yield takeLatest(DELETE_DEPARTMENT_USERS, shootDeleteDepartmentUser)
}
