/*----- React ------*/
import React, { forwardRef } from 'react'
import { useTranslation }    from 'react-i18next'
import PropTypes             from 'prop-types'

/*----- Antd ------*/
import { Select } from 'antd'

const { Option } = Select

const PeriodsSelect = ({ isLoading, onChange, value }, ref) => {
  
  const { t } = useTranslation()

  return(
    <Select
      ref         = { ref }
      placeholder = { t('commission.period.title') }
      disabled    = { isLoading }
      onChange    = { onChange }
      value       = { value }
      size        = 'large'
      showArrow
      allowClear
    >
      <Option value = '1er semestre' >
        { t('commission.period.first_semester') }
      </Option>

      <Option value = '2do semestre'>
        { t('commission.period.second_semester') }
      </Option>

      <Option value = 'ANUAL'>
        { t('commission.period.anual') }
      </Option>
    </Select>
  )
}

export default forwardRef(PeriodsSelect)

/*----- PropTypes -----*/
PeriodsSelect.propTypes = {
  isLoading: PropTypes.bool,
  onChange:  PropTypes.func.isRequired
}
