/*----- Actions -----*/
import {
  USERS_NOT_IN_DEPARTMENT,
  USERS_NOT_IN_DEPARTMENT_SUCCESS,
  USERS_NOT_IN_DEPARTMENT_ERROR
} from '../DepartmentsActions'

const initialState = {
  isLoading:    false,
  departmentId: null,
  users:        [],
  error:        null
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case USERS_NOT_IN_DEPARTMENT:
      return {
        ...state,
        isLoading: true
      }
    case USERS_NOT_IN_DEPARTMENT_SUCCESS:
      return {
        ...state,
        isLoading:    false,
        departmentId: action.payload.departmentId,
        users:        action.payload.body
      }
    case USERS_NOT_IN_DEPARTMENT_ERROR:
      return {
        ...state,
        isLoading:    false,
        departmentId: action.payload.departmentId,
        error:        action.payload.body
      }
    default:
      return state;
  }
}