export const requiredFieldOptions = (t) => (
  {
    rules: [
      {
        required: true,
        message:  t('rules.mandatory_field')
      }
    ],
    validateTrigger: ['onSubmit', 'onBlur', 'onChange']
  }
)

export const requiredYearPickerOptions = (t) => (
  {
    rules: [
      {
        required: true,
        message:  t('rules.mandatory_field'),
      }
    ],
    validateTrigger: ['onChange']
  }
)

