export const LOGIN         = 'LOGIN'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL    = 'LOGIN_FAIL'

export const loginUser = (dispatch, params) => {
  dispatch(
    {
      type:    LOGIN,
      payload: params
    }
  )
}