/*----- Saga -----*/
import { call, takeLatest, put } from 'redux-saga/effects'

/*----- Actions -----*/
import {
  EXPORT_SURVEY, EXPORT_SURVEY_ERROR, EXPORT_SURVEY_SUCCESS
} from '../SurveysActions'

/*----- Endpoint -----*/
import { exportSurvey } from '../../../../api/endpoints/Surveys'

/*----- Notifications -----*/
import notifyError from '../../../../components/commons/notifications/ErrorNotification'

/*----- Utils -----*/
import { downloadFileFromResponse } from '../../../../utils/FileDownloader'
import moment                       from 'moment'

export function* shootExportSurvey(action) {
  try {
    const response = yield call(exportSurvey, action)
    let body
    let type
    
    if (response.status >= 200 && response.status < 300) {
      const filename = `Encuesta ${moment().toISOString()}.xlsx`

      downloadFileFromResponse(response, filename)

      type = EXPORT_SURVEY_SUCCESS
    }
    else {
      type = EXPORT_SURVEY_ERROR
      body = yield response.json()
    }

    yield put(
      {
        type:    type,
        payload: body
      }
    )
  }
  catch (error) {
    yield notifyError('¡Ha ocurrido un error inesperado!')
    yield put(
      {
        type:    EXPORT_SURVEY_ERROR,
        payload: error.message
      }
    )
  }
}

export default function* () {
  yield takeLatest(EXPORT_SURVEY, shootExportSurvey)
}
