/*----- Actions -----*/
import { GET_IMPORTS, GET_IMPORTS_SUCCESS, GET_IMPORTS_ERROR } from '../SurveysActions'

const initialState = {
  isLoading: false,
  imports:   [],
  error:     null
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_IMPORTS:
      return {
        ...state,
        isLoading: true
      }
    case GET_IMPORTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        imports:   action.payload
      }
    case GET_IMPORTS_ERROR:
      return {
        ...state,
        isLoading: false,
        error:     action.payload
      }
    default:
      return state
  }
}
