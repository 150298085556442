/*----- Imports -----*/
import React              from 'react'
import { useTranslation } from 'react-i18next'

/*----- Antd -----*/
import Logo from '../../../components/commons/Logo'

export default () => {
  const { t } = useTranslation()

  return(
    <section id = 'banner'>
      <Logo size = { 64 }/>

      <h2> { t('public.banner.title') } </h2>

      <p>{ t('public.banner.subtitle') }</p>

      <ul className = 'actions'>
        <li>
          <a
            href      = 'http://www.exactas.unlp.edu.ar/'
            className = 'button big special'
            target    = '_blank'
            rel       = 'noopener noreferrer'
          >
              { t('global.more') }
          </a>
        </li>
      </ul>
    </section>
  )
}