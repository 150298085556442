export const objToQueryString = (obj) => {
  const keyValuePairs = []
  let key
  for (key in obj) {
    if (obj[key] !== undefined){
      keyValuePairs.push(
        encodeURIComponent(key) + '=' + encodeURIComponent(obj[key])
      );
    }
  }
  
  return keyValuePairs.join('&');
}
