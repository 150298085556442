import { all }                      from 'redux-saga/effects'
import createSubjectSagas           from './NewSubjectSagas'
import getSubjectSagas              from './GetSubjectsListSagas'
import deleteSubjectSagas           from './DeleteSubjectSagas'
import subjectDetailsSagas          from './SubjectDetailsSagas'
import editSubjectSagas             from './EditSubjectSagas'
import getSubjectDepartmentsSagas   from './GetSubjectDepartmentsSagas'
import restoreSubjectSagas          from './RestoreSubjectSagas'
import deleteSubjectDepartmentSagas from './DeleteSubjectDepartmentSagas'

export default function* dispatchSagas() {
  yield all([
    createSubjectSagas(),
    getSubjectSagas(),
    deleteSubjectSagas(),
    subjectDetailsSagas(),
    editSubjectSagas(),
    getSubjectDepartmentsSagas(),
    restoreSubjectSagas(),
    deleteSubjectDepartmentSagas()
  ])
}
