/*----- Imports -----*/
import React, { useState } from 'react'
import styled              from 'styled-components'
import PropTypes           from 'prop-types'
import { useTranslation }  from 'react-i18next'

/*----- Antd -----*/
import { Form, Button, Upload, Icon } from 'antd'

/*----- Utils -----*/
import { requiredFieldOptions } from '../../../commissions/views/forms/Options'

const CenteredContainer = styled.div`
  text-align: center;
  display:    block;
`

const { Item } = Form

const BulkImport = ( { handleSubmit, form } ) => {
  const { t }                   = useTranslation()
  const [fileList, setFileList] = useState([])

  const { getFieldDecorator } = form

  const onFileChange = (info) => {
    let fileList = [...info.fileList]

    // Limit the number of uploaded files to 1
    fileList = fileList.slice(-1)
    setFileList(fileList)
  }

  return(
    <Form
      onSubmit = { handleSubmit }
    >

      <Item label = { t('global.select_file') } >
        {
          getFieldDecorator('new_data_file', requiredFieldOptions(t))(
            <Upload
              accept       = '.xlsx'
              fileList     = { fileList }
              onChange     = { onFileChange }
              beforeUpload = { () => false }
            >
              <Button>
                <Icon type = 'upload' /> { t('global.upload_file') }
              </Button>
            </Upload>
          )
        }
      </Item>

      <CenteredContainer>
        <Button
          type     = 'primary'
          htmlType = 'submit'
          style    = {{ justifyContent: 'center' }}
          size     = 'large'
        >
          { t('global.submit') }
        </Button>
      </CenteredContainer>

    </Form>
  )
}

export default BulkImport

/*----- PropTypes -----*/
BulkImport.propTypes = {
  form:         PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
}