import { all }                 from 'redux-saga/effects'
import PostDepartmentCreation  from './PostDepartmentCreation'
import GetDepartmentsList      from './GetDepartmentsList'
import GetDepartmentDetails    from './GetDepartmentDetails'
import DeleteDepartmentUser    from './DeleteDepartmentUserSagas'
import PutUpdateDepartment     from './PutUpdateDepartmentSagas'
import GetUsersNotInDepartment from './GetUsersNotInDepartment'
import DeleteDepartment        from './DeleteDepartmentSagas'
import RestoreDepartment       from './PutRestoreDepartmentSagas'
import GetDepartmentUsers      from './GetDepartmentUsers'

export default function* dispatchSagas() {
  yield all([
    PostDepartmentCreation(),
    GetDepartmentsList(),
    GetDepartmentDetails(),
    DeleteDepartmentUser(),
    PutUpdateDepartment(),
    GetUsersNotInDepartment(),
    DeleteDepartment(),
    RestoreDepartment(),
    GetDepartmentUsers()
  ])
}
