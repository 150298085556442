export const editSubjectFieldsSelector = (state) => (
  state.editSubject.fields
)

export const editionModalVisibilitySelector = (state) => (
  state.editSubject.modalVisible
)

export const editDepartmentsModalVisibilitySelector = (state) => (
  state.editSubject.departmentsModalVisible
)

export const loadingEditionSelector = (state) => (
  state.editSubject.isLoading
)