/*----- Imports -----*/
import React              from 'react'
import styled             from 'styled-components'
import PropTypes          from 'prop-types'
import { useTranslation } from 'react-i18next'

/*----- Antd -----*/
import { Form, Input, Icon, Select, Button, InputNumber } from 'antd'

/*----- Options -----*/
import { requiredFieldOptions } from './Options'

/*----- Utils -----*/
import mapRecordsToOptions from '../../../../../utils/SelectOptionsMapper'

const { Item }     = Form
const { TextArea } = Input
const { Option }   = Select

const CenteredContainer = styled.div`
  text-align: center;
  display:    block;
`

const FormFields = (
  {
    form, isLoading, submitHandler, loadingSubjects, subjectsList
  }
) => {
  const { t }                 = useTranslation()
  const { getFieldDecorator } = form
  const subjectSelectOptions  = mapRecordsToOptions(subjectsList)

  return(
    <Form onSubmit = { submitHandler } >

      <Item label = { t('commission.name') } >
        {
          getFieldDecorator('name', requiredFieldOptions(t))(
            <Input
              placeholder = { t('commission.title') }
              disabled    = { isLoading || loadingSubjects }
              prefix      = {
                <Icon type = 'schedule' style = {{ color: 'rgba(0, 0, 0, .25)' }} />
              }
            />
          )
        }
      </Item>

      <Item label = { t('commission.subject') } >
        {
          getFieldDecorator('subject', requiredFieldOptions(t))(
            <Select 
              placeholder  = { t('commission.select_subject') }
              showSearch   = { true }
              disabled     = { isLoading || loadingSubjects }
              allowClear   = { true }
              filterOption = {(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              { subjectSelectOptions }
            </Select>
          )
        }
      </Item>

      <Item label = { t('commission.workload') } >
        {
          getFieldDecorator('workload', requiredFieldOptions(t))(
            <InputNumber
              placeholder = { t('commission.workload') }
              disabled    = { isLoading || loadingSubjects }
              style       = { { width: '100%' } }
            />
          )
        }
      </Item>

      <Item label = { t('commission.subscribers_amount') } >
        {
          getFieldDecorator('subscribers_amount', requiredFieldOptions(t))(
            <InputNumber
              placeholder = { t('commission.subscribers_amount') }
              disabled    = { isLoading || loadingSubjects }
              style       = { { width: '100%' } }
            />
          )
        }
      </Item>

      <Item label = { t('commission.year') } >
        {
          getFieldDecorator('year', requiredFieldOptions(t))(
            <InputNumber
              placeholder = { t('commission.year') }
              disabled    = { isLoading || loadingSubjects }
              style       = { { width: '100%' } }
            />
          )
        }
      </Item>

      <Item label = { t('commission.period.title') } >
        {
          getFieldDecorator('period', requiredFieldOptions(t))(
            <Select
              placeholder = { t('commission.period.title') }
              disabled    = { isLoading }
            >
              <Option value = '1er Semestre' >
                { t('commission.period.first_semester') }
              </Option>

              <Option value = '2do Semestre'>
                { t('commission.period.second_semester') }
              </Option>

              <Option value = 'Anual'>
                { t('commission.period.anual') }
              </Option>
            </Select>
          )
        }
      </Item>

      <Item label = { t('commission.comments') } >
        {
          getFieldDecorator('comments')(
            <TextArea
              placeholder = { t('commission.comments') }
              disabled    = { isLoading || loadingSubjects }
            />
          )
        }
      </Item>

      <CenteredContainer>
        <Button
          type     = 'primary'
          htmlType = 'submit'
          style    = {{ justifyContent: 'center' }}
          disabled = { isLoading || loadingSubjects }
          size     = 'large'
        >
          { t('global.submit') }
        </Button>
      </CenteredContainer>
    </Form>
  )
}

export default FormFields

/*----- PropTypes -----*/
FormFields.propTypes = {
  form:            PropTypes.object.isRequired,
  isLoading:       PropTypes.bool.isRequired,
  submitHandler:   PropTypes.func.isRequired,
  loadingSubjects: PropTypes.bool.isRequired,
  subjectsList:    PropTypes.array.isRequired
}

