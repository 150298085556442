/*----- Utils -----*/
import { serviceErrorsToFormFields } from '../../../../utils/ResponseFormatter'

/*----- Actions -----*/
import { 
  CREATE_QUESTION_CATEGORY,
  CREATE_QUESTION_CATEGORY_SUCCESS, 
  CREATE_QUESTION_CATEGORY_ERROR,
  SET_QUESTION_CATEGORY_FIELD
} from '../SurveysActions'

const initialState = {
  isLoading: false,
  error:     null,
  fields:    {
    name: {}
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_QUESTION_CATEGORY:
      return {
        ...state,
        isLoading: true
      }
    case CREATE_QUESTION_CATEGORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success:   action.payload,
        fields:    initialState.fields,
      }
    case CREATE_QUESTION_CATEGORY_ERROR:
      return {
        ...state,
        isLoading: false,
        error:     action.payload,
        fields:    serviceErrorsToFormFields(state.fields, action.payload.error.fields)

      }
    case SET_QUESTION_CATEGORY_FIELD:
      return {
        ...state,
        fields: {
          ...state.fields,
          ...action.payload
        }
      }
    default:
      return state
  }
}