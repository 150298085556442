/*----- Actions -----*/
import {
  CREATE_SURVEY, 
  CREATE_SURVEY_SUCCESS, 
  CREATE_SURVEY_ERROR, 
  SET_NEW_SURVEY_FORM_FIELDS, 
  ADD_QUESTION_FIELDS,
  DELETE_QUESTION_FIELDS,
} from '../SurveysActions'

/*----- Utils -----*/
import { serviceErrorsToFormFields } from '../../../../utils/ResponseFormatter'

export const QUESTION_FIELDS = {
  category:     {},
  question:     {},
  resourcetype: {},
  max_value:    {},
  choices:      {},
}

export const initialState = {
  isLoading: false,
  success:   '',
  error:     {
    message: '',
    fields:  []
  },
  fields: {
    name:      {},
    last_key:  0,
    keys:      [0],
    questions: {
      0: QUESTION_FIELDS
    }
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_SURVEY:
      return {
        ...state,
        isLoading: true,
      }
    case CREATE_SURVEY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success:   action.payload,
        fields:    initialState.fields
      }
    case CREATE_SURVEY_ERROR:
      return {
        ...state,
        isLoading: false,
        error:     action.payload.error,
        fields:    serviceErrorsToFormFields(state.fields, action.payload.error.fields)
      }
    case ADD_QUESTION_FIELDS:
      const next_key = state.fields.last_key + 1

      return {
        ...state,
        fields: {
          ...state.fields,
          last_key:  next_key,
          questions: {
            ...state.fields.questions,
            [next_key]: QUESTION_FIELDS
          },
          keys: state.fields.keys.concat(next_key)
        }
      }
    case DELETE_QUESTION_FIELDS:
      const key_to_delete     = action.payload
      const updated_questions = state.fields.questions
      const updated_keys      = state.fields.keys

      if (updated_keys.length === 1) return { ...state }

      delete(updated_questions[key_to_delete])

      const index = updated_keys.indexOf(key_to_delete)
      if (index > -1) {
        updated_keys.splice(index, 1)
      }

      return {
        ...state,
        fields: {
          ...state.fields,
          questions: updated_questions,
          keys:      updated_keys
        }
      }
    case SET_NEW_SURVEY_FORM_FIELDS:
      return {
        ...state,
        fields: {
          ...state.fields,
          ...action.payload
        }
      }
    default:
      return state;
  }
}