/*----- Imports -----*/
import React, { useEffect, useState } from 'react'
import { connect }                    from 'react-redux'

/*----- Selectors -----*/
import { loadingImportsSelector, importsListSelector } from './selectors/GetImportsListSelector'

/*----- Actions -----*/
import { getImportsList, deleteImport } from './SurveysActions'

/*----- View -----*/
import MainView from './views/imports/MainView'

const DEFAULT_FILTERS = {
  surveys: '',
  from:    '',
  to:      ''
}

const ListContainer = ({ getImportsList, imports, isLoading, deleteImport }) => {
  useEffect(
    () => getImportsList(),
    [getImportsList]
  )

  const [filters, setFilters] = useState(DEFAULT_FILTERS)

  const onFiltersChange = (params) => {
    const updated_filters = Object.assign(filters, params)

    setFilters(updated_filters)
    getImportsList(updated_filters)
  }

  return(
    <MainView
      imports         = { imports }
      isLoading       = { isLoading }
      deleteImport    = { deleteImport }
      onFiltersChange = { onFiltersChange}
    />
  )
}

const mapStateToProps = (state) => (
  {
    isLoading: loadingImportsSelector(state),
    imports:   importsListSelector(state),
  }
)

const mapDispatchToProps = (dispatch) => (
  {
    getImportsList: (params) => getImportsList(dispatch, params),
    deleteImport: (importId) => deleteImport(dispatch, importId)
  }
)

const ConnectedListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ListContainer)

ConnectedListContainer.defaultProps = {
  permission:   'list',
  model:        'Import',
  defaultRoute: '/admin'
}

export default ConnectedListContainer
