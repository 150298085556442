/*----- Imports -----*/
import React    from 'react'
import { Link } from 'react-router-dom'

/*----- Antd -----*/
import { Icon, Popconfirm, Divider } from 'antd'

/*----- CASL ------*/
import { Can } from '../../../../../ability/Can'

/*----- Components -----*/
import ActionButton from '../../../../../components/commons/button/ActionButton'

const columns = (t, isAdmin, deleteDepartment, restoreDepartment) => (
  [
    {
      title:          t('department.name'),
      dataIndex:      'name',
      key:            'name',
      sorter:         (a,b) => a.name.localeCompare(b.name),
      sortDirections: ['ascend','descend']
    },
    {
      title:  t('global.actions'),
      key:    'action',
      render: (_text, record) => (
        <span>
          {
            record.is_active &&
              <>
                <ActionButton>
                  <Link to = { `/admin/departments/${record.id}` } >
                    <Icon type = 'eye'/>
                  </Link>
                </ActionButton>

                <Divider type = 'vertical' />
              </>
          }

          <Can I = 'delete' a = 'Department'>
            {
              record.is_active &&
                <Popconfirm
                  title      = { t('notifications.confirm') }
                  onConfirm  = { () => deleteDepartment(record.id) }
                  okText     = { t('global.accept') }
                  cancelText = { t('global.cancel') }
                >
                  <ActionButton>
                    <Icon type = 'delete' style = {{ color:'red' }} />
                  </ActionButton>
                </Popconfirm>
            }
          </Can>

          <Can I = 'restore' a = 'Department'>
            {
              !record.is_active && (
                <Popconfirm
                  title      = { t('notifications.confirm') }
                  onConfirm  = { () => restoreDepartment(record.id) }
                  okText     = { t('global.accept') }
                  cancelText = { t('global.cancel') }
                >
                  <ActionButton>
                    <Icon type = 'rollback'/>
                  </ActionButton>
                </Popconfirm>
              )
            }
          </Can>
        </span>
      )
    }
  ]
)

export default columns
