/*----- Saga -----*/
import { call, takeLatest, put } from 'redux-saga/effects'

/*----- Actions -----*/
import {
  GET_SUBJECT_DETAILS,
  DELETE_SUBJECT_DEPARTMENT,
  DELETE_SUBJECT_DEPARTMENT_ERROR,
  DELETE_SUBJECT_DEPARTMENT_SUCCESS
} from '../SubjectsActions'

/*----- Endpoint -----*/
import { deleteSubjectDepartment } from '../../../../api/endpoints/Subjects'

/*----- Notifications -----*/
import notifyError         from '../../../../components/commons/notifications/ErrorNotification'
import successNotification from '../../../../components/commons/notifications/SuccessNotification'
import loadingNotification from '../../../../components/commons/notifications/LoadingNotification'

function* shootDeleteSubjectDepartment(action) {
  yield loadingNotification()

  try {
    const response = yield call(deleteSubjectDepartment, action)
    const body     = yield response.json()
    let type

    if (response.status >= 200 && response.status < 300) {
      type = DELETE_SUBJECT_DEPARTMENT_SUCCESS
      yield put(
        {
          type:    GET_SUBJECT_DETAILS,
          payload: action.payload.subjectId
        }
      )

      yield successNotification()
    }
    else {
      type = DELETE_SUBJECT_DEPARTMENT_ERROR
      yield notifyError(body.error.message)
    }

    yield put(
      {
        type:    type,
        payload: body
      }
    )
  }
  catch (error) {
    yield notifyError('¡Ha ocurrido un error inesperado!')
    yield put(
      {
        type:    DELETE_SUBJECT_DEPARTMENT_ERROR,
        payload: error.message
      }
    )
  }
}

export default function* () {
  yield takeLatest(DELETE_SUBJECT_DEPARTMENT, shootDeleteSubjectDepartment)
}
