/*----- Saga -----*/
import { call, takeLatest, put } from 'redux-saga/effects'

/*----- Actions -----*/
import { CREATE_USER, CREATE_USER_SUCCESS, CREATE_USER_ERROR } from '../UserActions'

/*----- Endpoint -----*/
import { postUserCreation } from '../../../../api/endpoints/Users'

/*----- Notifications -----*/
import loadingNotification from '../../../../components/commons/notifications/LoadingNotification'
import successNotification from '../../../../components/commons/notifications/SuccessNotification'
import notifyError         from '../../../../components/commons/notifications/ErrorNotification'

export function* shootCreateUser(action) {
  yield loadingNotification()
  try {
    const response = yield call(postUserCreation, action)
    const body     = yield response.json()
    let type

    if (response.status >= 200 && response.status < 300) {
      type = CREATE_USER_SUCCESS
      yield successNotification()
    } else {
      type = CREATE_USER_ERROR
      yield notifyError(body.error.message)
    }

    yield put(
      {
        type:    type,
        payload: body
      }
    )
  }
  catch (error) {
    yield notifyError('¡Ha ocurrido un error inesperado!')
    yield put(
      {
        type:    CREATE_USER_ERROR,
        payload: error.message
      }
    )
  }
}

export default function* CreateUserWatcher() {
  yield takeLatest(CREATE_USER, shootCreateUser)
}
