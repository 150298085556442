/*----- Actions -----*/
import {
  DELETE_DEPARTMENT, DELETE_DEPARTMENT_SUCCESS, DELETE_DEPARTMENT_ERROR
} from '../DepartmentsActions'

const initialState = {
  isLoading: false,
  error:     null
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case DELETE_DEPARTMENT:
      return {
        ...state,
        isLoading: true
      }
    case DELETE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        isLoading: false
      }
    case DELETE_DEPARTMENT_ERROR:
      return {
        ...state,
        isLoading: false,
        error:     action.payload
      }
    default:
      return state;
  }
}