/*----- Imports -----*/
import React     from 'react'
import styled    from 'styled-components'
import PropTypes from 'prop-types'

/*----- Antd -----*/
import { Avatar } from 'antd'

const StyledLogo = styled.section`
  text-align: center;
`

const Logo = ({ className, size = 'large' }) => (
  <StyledLogo className = { className }>
    <Avatar src = { `${process.env.PUBLIC_URL}/exactas-logo.png` } size = { size } />
  </StyledLogo>
)

export default Logo

Logo.propTypes = {
  className: PropTypes.string,
  size:      PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
}
