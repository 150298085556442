/*----- Imports -----*/
import React, { useEffect } from 'react'
import { connect }          from 'react-redux'
import { withRouter }       from 'react-router'

/*----- Selectors -----*/
import { loadingDepartmentSelector, departmentInfoSelector } from './selectors/DepartmentDetailsSelector'
import { currentUserSelector }                               from '../CurrentUserSelector'

import {
  editDepartmentFieldsSelector,
  editionModalVisibilitySelector,
  editUsersModalVisibilitySelector,
  loadingEditionSelector,
  usersNotInDepartmentSelector,
  loadingUsersNotInDepartmentSelector
} from './selectors/EditDepartmentSelector'

import {
  departmentUsersSelector,
  loadingDepartmentUsersSelector
} from './selectors/DepartmentUsersSelector'

/*----- Actions -----*/
import {
  getDepartmentDetails,
  deleteDepartmentUsers,
  setDepartmentEditionFields,
  editDepartment,
  toggleEditionModal,
  toggleEditUsersModal,
  getUsersNotInDepartment,
  getDepartmentUsers
} from './DepartmentsActions'

/*----- View -----*/
import MainView from './views/details/MainView'

/*----- Antd -----*/
import { Form } from 'antd'

const hasErrors = (fieldsError) => {
  return Object.keys(fieldsError).some(field => fieldsError[field])
}

const DetailsContainer = (
  {
    getDepartmentDetails, departmentInfo, isLoading, match,
    deleteDepartmentUsers, currentUser, form, editDepartment,
    modalVisible, toggleEditionModal, isLoadingEdition, formFields,
    setDepartmentEditionFields, toggleEditUsersModal, editUsersmodalVisible,
    getUsersNotInDepartment, usersNotInDepartment, loadingUsersNotInDepartment,
    departmentUsers, loadingDepartmentUsers, getDepartmentUsers
  }
) => {

  const department_id = match.params.id
  
  useEffect(
    () => {
      getUsersNotInDepartment(department_id, '')
      getDepartmentDetails(department_id)
      getDepartmentUsers(department_id, '')
    },
    [getUsersNotInDepartment, getDepartmentDetails, department_id, getDepartmentUsers]
  )

  const editionSubmitHandler = () => {
    form.validateFields({ force: true })

    if (!hasErrors(form.getFieldsError())) {
      let fields = { name: form.getFieldValue('name') }
      editDepartment(department_id, fields)
    }
  }

  const setSelectedUsers = (users) => {
    setDepartmentEditionFields({ users: users })
  }

  const handleUpdateUsers = () => {
    editDepartment(department_id, { users: formFields.users })
  }

  const searchUsersNotInDepartment = (search = '') => {
    getUsersNotInDepartment(department_id, search)
  }

  return(
    <MainView
      departmentInfo              = { departmentInfo }
      isLoading                   = { isLoading }
      deleteDepartmentUsers       = { deleteDepartmentUsers }
      isAdmin                     = { currentUser.is_staff }
      form                        = { form }
      editionSubmitHandler        = { editionSubmitHandler }
      modalVisible                = { modalVisible }
      toggleEditionModal          = { toggleEditionModal }
      isLoadingEdition            = { isLoadingEdition }
      getUsersNotInDepartment     = { searchUsersNotInDepartment}
      selectedUsers               = { formFields.users }
      setSelectedUsers            = { setSelectedUsers }
      handleUpdateUsers           = { handleUpdateUsers }
      toggleEditUsersModal        = { toggleEditUsersModal }
      editUsersmodalVisible       = { editUsersmodalVisible }
      usersNotInDepartment        = { usersNotInDepartment }
      loadingUsersNotInDepartment = { loadingUsersNotInDepartment }
      departmentUsers             = { departmentUsers }
      loadingDepartmentUsers      = { loadingDepartmentUsers }
      getDepartmentUsers          = { getDepartmentUsers }
    />
  )
}

const mapStateToProps = (state) => {
  return {
    isLoading:                   loadingDepartmentSelector(state),
    departmentInfo:              departmentInfoSelector(state),
    currentUser:                 currentUserSelector(state),
    formFields:                  editDepartmentFieldsSelector(state),
    modalVisible:                editionModalVisibilitySelector(state),
    editUsersmodalVisible:       editUsersModalVisibilitySelector(state),
    isLoadingEdition:            loadingEditionSelector(state),
    usersNotInDepartment:        usersNotInDepartmentSelector(state),
    loadingUsersNotInDepartment: loadingUsersNotInDepartmentSelector(state),
    departmentUsers:             departmentUsersSelector(state),
    loadingDepartmentUsers:      loadingDepartmentUsersSelector(state)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getDepartmentDetails:         (departmentId)    => getDepartmentDetails(dispatch, departmentId),
    deleteDepartmentUsers: (users, departmentId)    => deleteDepartmentUsers(dispatch, departmentId, users),
    setDepartmentEditionFields:         (params)    => setDepartmentEditionFields(dispatch, params),
    editDepartment:       (departmentId, params)    => editDepartment(dispatch, departmentId, params),
    toggleEditionModal:                (visible)    => toggleEditionModal(dispatch, visible),
    toggleEditUsersModal:              (visible)    => toggleEditUsersModal(dispatch, visible),
    getUsersNotInDepartment: (departmentId, search) => getUsersNotInDepartment(dispatch, departmentId, search),
    getDepartmentUsers:      (departmentId, search) => getDepartmentUsers(dispatch, departmentId, search)
  }
}

const ConnectedDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(
  Form.create(
    {
      name: 'edit_department_form',
      onFieldsChange({ setDepartmentEditionFields }, changedFields) {
        setDepartmentEditionFields(changedFields);
      },
      mapPropsToFields({ formFields }) {
        return {
          name: Form.createFormField({
            ...formFields.name,
            value: formFields.name.value
          })
        }
      }
    }
  )(DetailsContainer)
))

ConnectedDetailsContainer.defaultProps = {
  permission:   'show',
  model:        'Department',
  defaultRoute: '/admin'
}

export default ConnectedDetailsContainer
