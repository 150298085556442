/*----- Imports -----*/
import React     from 'react'
import PropTypes from 'prop-types'

/*----- Constants -----*/
import { PAGE_SIZE } from '../../../../../utils/Constants'

/*----- Antd -----*/
import { Table } from 'antd'

const ListTable = ( { data, isLoading, columns, className } ) => (
  <Table
    rowKey     = { (datum) => datum.id }
    dataSource = { data } 
    columns    = { columns } 
    size       = 'medium' 
    loading    = { isLoading }
    pagination = { { pageSize: parseInt(PAGE_SIZE) } }
    scroll     = { { x: '400px'} }
    className  = { className }
    bordered
  />
)

export default ListTable

/*----- PropTypes -----*/
ListTable.propTypes = {
  data:      PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  columns:   PropTypes.array.isRequired,
  className: PropTypes.string
}