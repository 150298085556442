/*----- Saga -----*/
import { call, takeLatest, put } from 'redux-saga/effects'

/*----- Actions -----*/
import {
  GET_TEACHER_GRAPHICS,
  GET_TEACHER_GRAPHICS_ERROR,
  GET_TEACHER_GRAPHICS_SUCCESS
} from '../Actions'

/*----- Endpoint -----*/
import { getTeacherGraphics } from '../../../api/endpoints/Public'

/*----- Notifications -----*/
import notifyError from '../../../components/commons/notifications/ErrorNotification'

export function* shootGetGraphics(action) {
  try {
    const response = yield call(getTeacherGraphics, action)
    const body     = yield response.json()
    let type

    if (response.status >= 200 && response.status < 300) {
      type = GET_TEACHER_GRAPHICS_SUCCESS
    } else {
      type = GET_TEACHER_GRAPHICS_ERROR
    }

    yield put(
      {
        type:    type,
        payload: body
      }
    )
  }
  catch (error) {
    yield notifyError('¡Ha ocurrido un error inesperado!')
    yield put(
      {
        type:    GET_TEACHER_GRAPHICS_ERROR,
        payload: error.message
      }
    )
  }
}

export default function* () {
  yield takeLatest(GET_TEACHER_GRAPHICS, shootGetGraphics)
}
