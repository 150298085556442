/*----- Imports -----*/
import React              from 'react'
import PropTypes          from 'prop-types'
import { useTranslation } from 'react-i18next'

/*----- Antd -----*/
import { Modal } from 'antd'

const EditionModal = ( { title, visible, onOkHandler, onCancelHandler, isLoading, children } ) => {
  const { t } = useTranslation()

  return(
    <Modal
      title             = { title }
      visible           = { visible }
      onOk              = { onOkHandler }
      onCancel          = { onCancelHandler }
      okText            = { t('global.submit') }
      okButtonProps     = {{ disabled: isLoading }}
      cancelButtonProps = {{ disabled: isLoading }}
    >
      { children }
    </Modal>
  )
}

export default EditionModal

/*----- PropTypes -----*/
EditionModal.propTypes = {
  title:           PropTypes.string.isRequired,
  visible:         PropTypes.bool.isRequired,
  onOkHandler:     PropTypes.func.isRequired,
  onCancelHandler: PropTypes.func.isRequired,
  children:        PropTypes.node.isRequired,
  isLoading:       PropTypes.bool.isRequired
}
