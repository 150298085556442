/*----- Saga -----*/
import { call, takeLatest, put } from 'redux-saga/effects'

/*----- Actions -----*/
import {
  DEPARTMENT_USERS,
  DEPARTMENT_USERS_ERROR,
  DEPARTMENT_USERS_SUCCESS
} from '../DepartmentsActions'

/*----- Endpoint -----*/
import { getDepartmentUsers } from '../../../../api/endpoints/Departments'

/*----- Notifications -----*/
import notifyError from '../../../../components/commons/notifications/ErrorNotification'

export function* shootGetDepartmentUsers(action) {
  try {
    const response = yield call(getDepartmentUsers, action)
    const body     = yield response.json()
    let type

    if (response.status >= 200 && response.status < 300) {
      type = DEPARTMENT_USERS_SUCCESS
    } else {
      type = DEPARTMENT_USERS_ERROR
    }

    yield put(
      {
        type:    type,
        payload: {
          departmentId: action.payload.departmentId,
          body:         body
        }
      }
    )
  }
  catch (error) {
    yield notifyError('¡Ha ocurrido un error inesperado!')
    yield put(
      {
        type:    DEPARTMENT_USERS_ERROR,
        payload: error.message
      }
    )
  }
}

export default function* () {
  yield takeLatest(DEPARTMENT_USERS, shootGetDepartmentUsers)
}
