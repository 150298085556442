/*----- Imports -----*/
import React     from 'react'
import PropTypes from 'prop-types'

/*----- Constants -----*/
import { PAGE_SIZE } from '../../../../../utils/Constants'

/*----- Antd -----*/
import { Table } from 'antd'

const ListTable = ({ commissions, isLoading, columns, title, className }) => (
  <Table
    rowKey     = { (commission) => commission.id }
    dataSource = { commissions } 
    columns    = { columns } 
    size       = 'medium' 
    loading    = { isLoading }
    pagination = { { pageSize: parseInt(PAGE_SIZE) } }
    scroll     = { { x: '400px' } }
    className  = { className }
    title      = { title }
    bordered
  />
)

export default ListTable

/*----- PropTypes -----*/
ListTable.propTypes = {
  commissions: PropTypes.array.isRequired,
  isLoading:   PropTypes.bool.isRequired,
  columns:     PropTypes.array.isRequired,
  title:       PropTypes.func,
  className:   PropTypes.string
}
