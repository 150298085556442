/*----- Actions -----*/
import {
  GET_TEACHER_GRAPHICS,
  GET_TEACHER_GRAPHICS_ERROR,
  GET_TEACHER_GRAPHICS_SUCCESS,
  RESET_TEACHER_GRAPHICS
} from '../Actions'

const initialData = {
  records:  [],
  graphics: [],
  comments: []
}

const initialState = {
  isLoading: false,
  data:      initialData,
  error:     null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_TEACHER_GRAPHICS:
      return {
        ...state,
        isLoading: true
      }
    case GET_TEACHER_GRAPHICS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data:      action.payload,
        error:     null
      }
    case GET_TEACHER_GRAPHICS_ERROR:
      return {
        ...state,
        isLoading: false,
        data:      initialData,
        error:     action.payload
      }
    case RESET_TEACHER_GRAPHICS:
      return initialState
    default:
      return state
  }
}