/*----- Imports -----*/
import React, { useState }   from 'react'
import styled, { keyframes } from 'styled-components'

/*----- Recharts -----*/
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const SpinnableChart = styled(PieChart)`
  animation: ${props => props.spinning ? rotate : ''} 1s linear infinite;
`

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042']
const data   = [
  { name: 'Group A', value: 400 },
  { name: 'Group B', value: 300 },
  { name: 'Group C', value: 300 },
  { name: 'Group D', value: 200 },
]

export default () => {
  const [spinning, setSpinning] = useState(false)

  return(
    <ResponsiveContainer width = '100%' height = { 300 } >
      <SpinnableChart
        onMouseEnter = { () => setSpinning(true) }
        onMouseLeave = { () => setSpinning(false) }
        spinning     = { spinning }
      >
        <Pie
          data         = { data }
          innerRadius  = { 50 }
          outerRadius  = { 80 }
          fill         = '#8884d8'
          paddingAngle = { 5 }
          dataKey      = 'value'
        >
          {
            data.map((_entry, index) => (
              <Cell key = {`cell-${index}`} fill = {COLORS[index % COLORS.length]} />
            ))
          }
        </Pie>
      </SpinnableChart>
    </ResponsiveContainer>
  )
}
