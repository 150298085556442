/*----- Imports -----*/
import React, { useEffect, useState } from 'react'
import { connect }          from 'react-redux'

/*----- Selectors -----*/
import { currentUserSelector }     from '../CurrentUserSelector'
import { departmentsListSelector } from '../departments/selectors/DepartmentsListSelector'

import {
  loadingSubjectsSelector,
  subjectsListSelector
} from './selectors/SubjectsListSelector'

/*----- Actions -----*/
import { getSubjects, deleteSubject, restoreSubject } from './SubjectsActions'
import { getDepartments }                             from '../departments/DepartmentsActions'

/*----- View -----*/
import MainView from './views/list/MainView'

const ListContainer = (
  { 
    getSubjects, subjects, isLoading, currentUser, departments, getDepartments, 
    deleteSubject, restoreSubject
  }
) => {

  const [search, setSearch]                       = useState('')
  const [blocked, setBlocked]                     = useState(false)
  const [departmentsSearch, setDepartmentsSearch] = useState([])
  
  const onSearchHandler = (value) => {
    setSearch(value)
    getSubjects(value, blocked, departmentsSearch)
  }

  const blockedSelectHandler = (value) => {
    setBlocked(value)
    getSubjects(search, value, departmentsSearch)
  }

  const departmentsSelectHandler = (value) => {
    setDepartmentsSearch(value)
    getSubjects(search, blocked, value)
  }

  useEffect(
    () => { getSubjects() },
    [getSubjects]
  )

  useEffect(
    () => { getDepartments() },
    [getDepartments]
  )
  
  return(
    <MainView
      subjects                 = { subjects }
      departments              = { departments }
      isLoading                = { isLoading }
      isAdmin                  = { currentUser && currentUser.is_staff }
      deleteSubject            = { deleteSubject }
      restoreSubject           = { restoreSubject }
      searchHandler            = { onSearchHandler }
      blockedSelectHandler     = { blockedSelectHandler }
      departmentsSelectHandler = { departmentsSelectHandler }
    />
  )
}

const mapStateToProps = (state) => {
  return {
    isLoading:   loadingSubjectsSelector(state),
    subjects:    subjectsListSelector(state),
    currentUser: currentUserSelector(state),
    departments: departmentsListSelector(state)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getSubjects:    (value = '', blocked = false, departmentsSearch) => getSubjects(dispatch, value, blocked, departmentsSearch),
    getDepartments:                                               () => getDepartments(dispatch),
    deleteSubject:                                       (subjectId) => deleteSubject(dispatch, subjectId),
    restoreSubject:                                      (subjectId) => restoreSubject(dispatch, subjectId)
  }
}

const ConnectedListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ListContainer)

ConnectedListContainer.defaultProps = {
  permission:   'list',
  model:        'Subject',
  defaultRoute: '/admin'
}

export default ConnectedListContainer