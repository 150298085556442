/*----- Imports -----*/
import React              from 'react'
import { connect }        from 'react-redux'
import { Link }           from 'react-router-dom'
import { useTranslation } from 'react-i18next'

/*----- Antd -----*/
import { Menu, Icon, Skeleton } from 'antd'

/*----- CASL -----*/
import { useAbility }     from '@casl/react'
import { AbilityContext } from '../../../ability/Can'

/*----- Selectors -----*/
import { currentUserLoadedSelector } from '../../../modules/admin/CurrentUserSelector'

/*----- Components -----*/
const { SubMenu, Item } = Menu

const openKeys = (pathname) => {
  if (pathname.includes('users') || pathname.includes('sign-up')) {
    return ['users']
  }

  if (pathname.includes('departments')) {
    return ['departments']
  }

  if (pathname.includes('subjects')) {
    return ['subjects']
  }

  if (pathname.includes('commissions')) {
    return ['commissions']
  }

  if (pathname.includes('surveys')) {
    return ['surveys']
  }

  return []
}

const NavigationMenu = ( { pathname, theme, currentUserLoaded } ) => {
  const { t }   = useTranslation()
  const ability = useAbility(AbilityContext)
  
  return (
    <Skeleton loading = { !currentUserLoaded } active>
      <Menu
        theme               = { theme }
        defaultSelectedKeys = { [pathname] }
        mode                = 'inline'
        defaultOpenKeys     = { openKeys(pathname) }
      >

        {
          ability.can('visit', 'Home') &&
            <Item key = '/admin'>
              <Link to = { '/admin' }>
                <Icon type = 'home' />
                <span>
                  { t('admin.menubar.home') }
                </span>
              </Link>
            </Item>
        }

        {
          (ability.can('list', 'Department') || ability.can('create', 'Department')) &&
            <SubMenu
              key   = 'departments'
              title = {
                <span>
                  <Icon type = 'pie-chart' />
                  <span>
                    { t('admin.menubar.departments') }
                  </span>
                </span>
              }
            >
              {
                ability.can('list', 'Department') &&
                  <Item key = '/admin/departments'>
                    <Link to = { '/admin/departments' } >
                      <Icon type = 'pie-chart' />
                      <span>
                        { t('admin.menubar.departments_list') }
                      </span>
                    </Link>
                  </Item>
              }

              { 
                ability.can('create', 'Department') &&
                  <Item key = '/admin/departments/new'>
                    <Link to = { '/admin/departments/new' } >
                      <Icon type = 'plus' />
                      <span>
                        { t('admin.menubar.new_department') }
                      </span>
                    </Link>
                  </Item>
              }

            </SubMenu>
        }

        { 
          (ability.can('list', 'Subject') || ability.can('create', 'Subject')) &&
            <SubMenu
              key   = 'subjects'
              title = {
                <span>
                  <Icon type = 'book' />
                  <span>
                    { t('admin.menubar.subjects') }
                  </span>
                </span>
              }
            >
              {
                ability.can('list', 'Subject') &&
                  <Item key = '/admin/subjects'>
                    <Link to = { '/admin/subjects' }>
                      <Icon type = 'book' />
                      <span>
                        { t('admin.menubar.subjects_list') }
                      </span>
                    </Link>
                  </Item>
              }

              { 
                ability.can('create', 'Subject') &&
                  <Item key = '/admin/subjects/new'>
                    <Link to = { '/admin/subjects/new' }>
                      <Icon type = 'plus' />
                      <span>
                        { t('admin.menubar.new_subject') }
                      </span>
                    </Link>
                  </Item>
              }
            </SubMenu>
        }

        {
          (ability.can('create', 'Commission') || ability.can('list', 'Commission')) &&
            <SubMenu
              key   = 'commissions'
              title = {
                <span>
                  <Icon type = 'schedule' />
                  <span>
                    { t('admin.menubar.commissions') }
                  </span>
                </span>
              }
            >
              {
                ability.can('list', 'Commission') &&
                  <Item key = '/admin/commissions'>
                    <Link to = { '/admin/commissions' } >
                      <Icon type = 'schedule' />
                      <span>
                        { t('admin.menubar.commissions_list') }
                      </span>
                    </Link>
                  </Item>
              }

              {
                ability.can('create', 'Commission') &&
                  <Item key = '/admin/commissions/new'>
                    <Link to = { '/admin/commissions/new' } >
                      <Icon type = 'plus' />
                      <span>
                        { t('admin.menubar.new_commission') }
                      </span>
                    </Link>
                  </Item>
              }
            </SubMenu>
        }


        { 
          (ability.can('create', 'User') || ability.can('list', 'User')) &&
            <SubMenu
              key   = 'users'
              title = {
                <span>
                  <Icon type = 'team' />
                  <span>
                    { t('admin.menubar.users') }
                  </span>
                </span>
              }
            >
              {
                ability.can('list', 'User') &&
                  <Item key = '/admin/users'>
                    <Link to = { '/admin/users' } >
                      <Icon type = 'team' />
                      <span>
                        { t('admin.menubar.users_list') }
                      </span>
                    </Link>
                  </Item>
              }

              {
                ability.can('create', 'User') &&
                  <Item key = '/admin/sign-up'>
                    <Link to = { '/admin/sign-up' }>
                      <Icon type = 'user-add' />
                      <span>
                        { t('admin.menubar.new_user') }
                      </span>
                    </Link>
                  </Item>
              }
            </SubMenu>
        }

        { 
          (ability.can('list', 'Survey') || ability.can('export', 'Survey')) &&
            <SubMenu
              key   = 'surveys'
              title = {
                <span>
                  <Icon type = 'form' />
                  <span>
                    { t('admin.menubar.surveys') }
                  </span>
                </span>
              }
            >
              {
                ability.can('create', 'Survey') &&
                  <Item key = '/admin/surveys/new'>
                    <Link to = { '/admin/surveys/new' } >
                      <Icon type = 'plus' />
                      <span>
                        { t('admin.menubar.new_survey') }
                      </span>
                    </Link>
                  </Item>
              }

              {
                ability.can('list', 'Survey') &&
                  <Item key = '/admin/surveys'>
                    <Link to = { '/admin/surveys' } >
                      <Icon type = 'team' />
                      <span>
                        { t('admin.menubar.surveys_list') }
                      </span>
                    </Link>
                  </Item>
              }

              {
                ability.can('export', 'Survey') &&
                  <Item key = '/admin/surveys/export'>
                    <Link to = { '/admin/surveys/export' }>
                      <Icon type = 'export' />
                      <span>
                        { t('admin.menubar.export_template') }
                      </span>
                    </Link>
                  </Item>
              }

              {
                ability.can('create', 'Import') &&
                  <Item key = '/admin/surveys/import'>
                    <Link to = { '/admin/surveys/import' }>
                      <Icon type = 'import' />
                      <span>
                        { t('admin.menubar.import_results') }
                        </span>
                    </Link>
                  </Item>
              }

              {
                ability.can('list', 'Import') &&
                  <Item key = '/admin/surveys/imports'>
                    <Link to = { '/admin/surveys/imports' }>
                      <Icon type = 'cloud-upload' />
                      <span>
                        { t('admin.menubar.imports_list') }
                      </span>
                    </Link>
                  </Item>
              }

              {
                ability.can('create', 'Category') &&
                  <Item key = '/admin/question_categories/new'>
                    <Link to = { '/admin/question_categories/new' }>
                      <Icon type = 'layout' />
                      <span>
                        { t('admin.menubar.new_category') }
                      </span>
                    </Link>
                  </Item>
              }
            </SubMenu>
        }

        { 
          ability.can('bulk-import', 'Commissions') &&
            <Item key = '/admin/commissions/bulk-import'>
              <Link to = { '/admin/commissions/bulk-import' }>
                <Icon type = 'plus' />
                <span>
                  { t('commission.bulk_import') }
                </span>
              </Link>
            </Item>
        }

      </Menu>
    </Skeleton>
  )
}

const mapStateToProps = (state) => (
  {
    currentUserLoaded: currentUserLoadedSelector(state)
  }
)

export default connect(mapStateToProps, null)(NavigationMenu)