/*----- Imports -----*/
import React     from 'react'
import PropTypes from 'prop-types'

/*----- Antd -----*/
import { Table } from 'antd'

/*----- Constants -----*/
import { PAGE_SIZE } from '../../../../../utils/Constants'

const DEFAULT_SCROLL = { x: '400px'}

const ListTable = ({ users, isLoading, columns, className, scroll = DEFAULT_SCROLL, rowSelection = null }) => (
  <Table
    rowKey       = { (user) => user.id }
    dataSource   = { users } 
    columns      = { columns } 
    size         = 'medium' 
    loading      = { isLoading }
    pagination   = { { pageSize: parseInt(PAGE_SIZE) } }
    scroll       = { scroll }
    className    = { className }
    rowSelection = { rowSelection }
    bordered
  />
)

export default ListTable

/*----- PropTypes -----*/
ListTable.propTypes = {
  users:     PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  columns:   PropTypes.array.isRequired,
  scroll:    PropTypes.object,
  className: PropTypes.string
}