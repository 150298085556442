/*----- Imports -----*/
import React              from 'react'
import styled             from 'styled-components'
import PropTypes          from 'prop-types'
import { useTranslation } from 'react-i18next'

/*----- Antd -----*/
import { Form, Icon, Input, Button, Modal, Badge } from 'antd'

/*----- Views -----*/
import UsersTableWithSelection from '../../UsersTableWithSelection'

/*----- Components -----*/
import FormBox from '../../../../../components/commons/form/FormBox'

const MainContainer = styled.section`
  padding-top: 10%
  .form-box__data-container {
    width: 60%
  }
`

const CenteredContainer = styled.div`
  text-align: center;
  display:    block;
`

const UsersContainer = styled.div`
  display:    block;
  text-align: left;
`

const { Item } = Form

const MainView = ( { isLoading, form, submitHandler, selectedUsers, showModal, setShowModal } ) => {
  const { t }                 = useTranslation()
  const { getFieldDecorator } = form

  const nameFieldOptions = {
    rules: [
      {
        required: true,
        message:  t('rules.mandatory_field'),
      }
    ],
    validateTrigger: ['onSubmit', 'onBlur']
  }

  return(
    <MainContainer>
      <FormBox title = { t('admin.menubar.new_department') } >
        <Form onSubmit = { submitHandler } >

          <Item label = { t('department.name') } >
            {
              getFieldDecorator('name', nameFieldOptions)(
                <Input
                  placeholder = { t('department.title') }
                  disabled    = { isLoading }
                  prefix      = {
                    <Icon type = 'pie-chart' style = {{ color: 'rgba(0, 0, 0, .25)' }} />
                  }
                />
              )
            }
          </Item>

          <UsersContainer>
            <Badge count = { selectedUsers.length }>
              <Button
                type     = 'primary'
                size     = 'large'
                onClick  = { () => setShowModal(true) }
                style    = { { marginTop: '1%', marginBottom: '1%'} }
                disabled = { isLoading }
              >
                <Icon type = 'plus' style = {{ color: 'rgba(0, 0, 0, .25)' }} />
                { t('department.add_users') }
              </Button>
            </Badge>

          </UsersContainer>

          <Modal
            title   = { t('department.add_users') }
            visible = { showModal }
            onOk    = { () => setShowModal(false) }
            onCancel= { () => setShowModal(false) }
          >
            <UsersTableWithSelection/>
          </Modal>

          <CenteredContainer>
            <Button
              type      = 'primary'
              htmlType  = 'submit'
              style     = {{ justifyContent: 'center' }}
              disabled  = { isLoading }
              size      = 'large'
            >
              { t('global.submit') }
            </Button>
          </CenteredContainer>
        </Form>
      </FormBox>
    </MainContainer>
  )
}

export default MainView

/*----- PropTypes -----*/
MainView.propTypes = {
  form:          PropTypes.object.isRequired,
  isLoading:     PropTypes.bool.isRequired,
  submitHandler: PropTypes.func.isRequired,
  selectedUsers: PropTypes.array.isRequired,
  showModal:     PropTypes.bool.isRequired,
  setShowModal:  PropTypes.func.isRequired
}
