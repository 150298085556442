export const CREATE_SUBJECT              = 'CREATE_SUBJECT'
export const CREATE_SUBJECT_SUCCESS      = 'CREATE_SUBJECT_SUCCESS'
export const CREATE_SUBJECT_ERROR        = 'CREATE_SUBJECT_ERROR'
export const SET_NEW_SUBJECT_FORM_FIELDS = 'SET_NEW_SUBJECT_FORM_FIELDS'

export const GET_SUBJECTS         = 'GET_SUBJECTS'
export const GET_SUBJECTS_ERROR   = 'GET_SUBJECTS_ERROR'
export const GET_SUBJECTS_SUCCESS = 'GET_SUBJECTS_SUCCESS'

export const DELETE_SUBJECT         = 'DELETE_SUBJECT'
export const DELETE_SUBJECT_SUCCESS = 'DELETE_SUBJECT_SUCCESS'
export const DELETE_SUBJECT_ERROR   = 'DELETE_SUBJECT_ERROR'

export const GET_SUBJECT_DETAILS         = 'GET_SUBJECT_DETAILS'
export const GET_SUBJECT_DETAILS_SUCCESS = 'GET_SUBJECT_DETAILS_SUCCESS'
export const GET_SUBJECT_DETAILS_ERROR   = 'GET_SUBJECT_DETAILS_ERROR'

export const EDIT_SUBJECT               = 'EDIT_SUBJECT'
export const EDIT_SUBJECT_SUCCESS       = 'EDIT_SUBJECT_SUCCESS'
export const EDIT_SUBJECT_ERROR         = 'EDIT_SUBJECT_ERROR'
export const SET_SUBJECT_EDITION_FIELDS = 'SET_SUBJECT_EDITION_FIELDS'

export const TOGGLE_EDITION_MODAL          = 'TOGGLE_EDITION_MODAL'
export const TOGGLE_EDIT_DEPARTMENTS_MODAL = 'TOGGLE_EDIT_DEPARTMENTS_MODAL'

export const GET_SUBJECT_DEPARTMENTS         = 'GET_SUBJECT_DEPARTMENTS'
export const GET_SUBJECT_DEPARTMENTS_SUCCESS = 'GET_SUBJECT_DEPARTMENTS_SUCCESS'
export const GET_SUBJECT_DEPARTMENTS_ERROR   = 'GET_SUBJECT_DEPARTMENTS_ERROR'

export const RESTORE_SUBJECT         = 'RESTORE_SUBJECT'
export const RESTORE_SUBJECT_SUCCESS = 'RESTORE_SUBJECT_SUCCESS'
export const RESTORE_SUBJECT_ERROR   = 'RESTORE_SUBJECT_ERROR'

export const DELETE_SUBJECT_DEPARTMENT         = 'DELETE_SUBJECT_DEPARTMENT'
export const DELETE_SUBJECT_DEPARTMENT_SUCCESS = 'DELETE_SUBJECT_DEPARTMENT_SUCCESS'
export const DELETE_SUBJECT_DEPARTMENT_ERROR   = 'DELETE_SUBJECT_DEPARTMENT_ERROR'

export const createSubject = (dispatch, params) => {
  dispatch(
    {
      type:    CREATE_SUBJECT,
      payload: {
        body: params
      }
    }
  )
}

export const setNewSubjectFields = (dispatch, fields) => {
  dispatch(
    {
      type:    SET_NEW_SUBJECT_FORM_FIELDS,
      payload: fields
    }
  )
}

export const getSubjects = (dispatch, search, blocked, departments) => {
  dispatch(
    {
      type:    GET_SUBJECTS,
      payload: {
        search:      search,
        blocked:     blocked,
        departments: departments
      }
    }
  )
}

export const getSubjectDetails = (dispatch, subjectId) => {
  dispatch(
    {
      type:    GET_SUBJECT_DETAILS,
      payload: subjectId
    }
  )
}

export const deleteSubject = (dispatch, subjectId) => {  
  dispatch(
    {
      type:    DELETE_SUBJECT,
      payload: {
        subjectId: subjectId
      }
    }
  )
}

export const editSubject = (dispatch, subjectId, fields) => {
  dispatch(
    {
      type:    EDIT_SUBJECT,
      payload: {
        subjectId: subjectId,
        body:      fields
      }
    }
  )
}

export const setSubjectEditionFields = (dispatch, fields) => {
  dispatch(
    {
      type:    SET_SUBJECT_EDITION_FIELDS,
      payload: fields
    }
  )
}

export const toggleEditionModal = (dispatch, visible) => {
  dispatch(
    {
      type:    TOGGLE_EDITION_MODAL,
      payload: visible
    }
  )
}

export const toggleEditDepartmentsModal = (dispatch, visible) => {
  dispatch(
    {
      type:    TOGGLE_EDIT_DEPARTMENTS_MODAL,
      payload: visible
    }
  )
}

export const getSubjectDepartments = (dispatch, subjectId, search = '', not_included = 'true') => {
  dispatch(
    {
      type:    GET_SUBJECT_DEPARTMENTS,
      payload: {
        subjectId: subjectId,
        params:    {
          not_included_departments: not_included,
          search:                   search
        }
      }
    }
  )
}

export const restoreSubject = (dispatch, subjectId) => {
  dispatch(
    {
      type:    RESTORE_SUBJECT,
      payload: {
        subjectId: subjectId
      }
    }
  )
}

export const deleteSubjectDepartment = (dispatch, subjectId, departments) => {
  dispatch(
    {
      type:    DELETE_SUBJECT_DEPARTMENT,
      payload: {
        subjectId: subjectId,
        body:      {
          departments: departments
        } 
      }
    }
  )
}