/*----- Actions -----*/
import {
  CREATE_DEPARTMENT, CREATE_DEPARTMENT_ERROR, CREATE_DEPARTMENT_SUCCESS,
  SET_NEW_DEPARTMENT_FORM_FIELDS
} from '../DepartmentsActions'

/*----- Utils -----*/
import { serviceErrorsToFormFields } from '../../../../utils/ResponseFormatter' 

export const initialState = {
  isLoading: false,
  success:   '',
  error:     {
    message: '',
    fields:  []
  },
  fields: {
    name: {}
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_DEPARTMENT:
      return {
        ...state,
        isLoading: true,
      }
    case CREATE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success:   action.payload,
        fields:    initialState.fields
      }
    case CREATE_DEPARTMENT_ERROR:
      return {
        ...state,
        isLoading: false,
        error:     action.payload.error,
        fields:    serviceErrorsToFormFields(state.fields, action.payload.error.fields)
      }
      case SET_NEW_DEPARTMENT_FORM_FIELDS:
        return {
          ...state,
          fields: {
            ...state.fields,
            ...action.payload
          }
        }
    default:
      return state;
  }
}