/*----- Saga -----*/
import { call, takeLatest, put } from 'redux-saga/effects'
import { push }                  from 'connected-react-router'

/*----- Actions -----*/
import {
  CREATE_COMMISSION,
  CREATE_COMMISSION_SUCCESS,
  CREATE_COMMISSION_ERROR,
} from '../CommissionActions'

/*----- Endpoint -----*/
import { postCommissionCreation } from '../../../../api/endpoints/Commissions'

/*----- Notifications -----*/
import loadingNotification from '../../../../components/commons/notifications/LoadingNotification'
import successNotification from '../../../../components/commons/notifications/SuccessNotification'
import notifyError         from '../../../../components/commons/notifications/ErrorNotification'

export function* shootCreateCommission(action) {
  yield loadingNotification()
  try {
    const response = yield call(postCommissionCreation, action)
    const body     = yield response.json()
    let type

    if (response.status >= 200 && response.status < 300) {
      type = CREATE_COMMISSION_SUCCESS

      yield put(push(`/admin/commissions/${body.id}`))

      yield successNotification()      
    }
    else {
      type = CREATE_COMMISSION_ERROR
      yield notifyError(body.error.message)
    }

    yield put(
      {
        type:    type,
        payload: body
      }
    )
  }
  catch (error) {
    yield notifyError('¡Ha ocurrido un error inesperado!')
    yield put(
      {
        type:    CREATE_COMMISSION_ERROR,
        payload: error.message
      }
    )
  }
}

export default function* () {
  yield takeLatest(CREATE_COMMISSION, shootCreateCommission)
}
