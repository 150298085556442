/*----- Imports -----*/
import { call, takeLatest, put } from 'redux-saga/effects'

/*----- Endpoints -----*/
import { restore_password } from '../../../../api/endpoints/Sessions'

/*----- Actions -----*/
import { RESTORE_PASSWORD, RESTORE_PASSWORD_SUCCESS, RESTORE_PASSWORD_FAIL } from '../RestorePasswordActions'

/*----- Notifications -----*/
import notifyError         from '../../../../components/commons/notifications/ErrorNotification'
import successNotification from '../../../../components/commons/notifications/SuccessNotification'


function* shootRestorePassword(action) {  
  try {
    const response = yield call(restore_password, action)
    const body     = yield response.json()
    let type

    if (response.status >= 200 && response.status < 300) {
      type = RESTORE_PASSWORD_SUCCESS
      yield successNotification(body)
    }
    else {
      type = RESTORE_PASSWORD_FAIL
      yield notifyError(body.error.message)
    }

    yield put(
      {
        type:    type,
        payload: body,
      }
    )
  }

  catch (error) {
    yield put(
      {
        type:    RESTORE_PASSWORD_FAIL,
        payload: error.message
      }
    )
  }
}

export default function* createRouteWatcher() {
  yield takeLatest(RESTORE_PASSWORD, shootRestorePassword)
}
