/*----- Imports -----*/
import React              from 'react'
import styled             from 'styled-components'
import PropTypes          from 'prop-types'
import { useTranslation } from 'react-i18next'

/*----- Views -----*/
import Table   from './Table'
import columns from './Columns'
import Filters from './Filters'


const Container = styled.div`
  margin-top:  5%;
  margin-left: 10%;
  width:       80%;
`

const StyledTable = styled(Table)`
  margin-top: 5%;
`

const MainView = (
  {
    searchHandler, blockedSelectChangeHandler, departments,
    isLoading, isAdmin, deleteDepartment, restoreDepartment
  }
) => {
  const { t }  = useTranslation()

  return(
    <Container>
      
      <Filters
        blockedSelectChangeHandler = { blockedSelectChangeHandler }
        searchHandler              = { searchHandler }
        t                          = { t }
      />

      <StyledTable
        departments = { departments }
        isLoading   = { isLoading }
        columns     = { columns(t, isAdmin, deleteDepartment, restoreDepartment) }
      />
    </Container>
  )
}

export default MainView

/*----- PropTypes -----*/
MainView.propTypes = {
  searchHandler:              PropTypes.func.isRequired,
  departments:                PropTypes.array.isRequired,
  isLoading:                  PropTypes.bool.isRequired,
  blockedSelectChangeHandler: PropTypes.func.isRequired,
  isAdmin:                    PropTypes.bool.isRequired,
  deleteDepartment:           PropTypes.func.isRequired,
  restoreDepartment:          PropTypes.func.isRequired
}