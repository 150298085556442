export const RESTORE_PASSWORD           = "RESTORE_PASSWORD"
export const RESTORE_PASSWORD_SUCCESS   = "RESTORE_PASSWORD_SUCCESS"
export const RESTORE_PASSWORD_FAIL      = "RESTORE_PASSWORD_FAIL"

export const restore_password = (dispatch, params) => {
  dispatch(
    {
      type:    RESTORE_PASSWORD,
      payload: params
    }
  )
}
