/*----- Imports -----*/
import React, {useEffect, useContext} from 'react'
import { connect }                    from 'react-redux'
import { Route, Switch }              from 'react-router'

/*----- Actions  -----*/
import { currentUser } from '../modules/admin/CurrentUserActions'     

/*----- Selectors -----*/
import { 
  currentUserSelector, 
  currentUserLoadedSelector 
} from '../modules/admin/CurrentUserSelector'

/*----- CASL -----*/
import { AbilityContext } from '../ability/Can'

/*----- Modules -----*/
import Home          from '../modules/admin/home/Home'
import NotFoundError from '../modules/commons/errors/NotFoundError'

/*----- HOCS -----*/
import withAuthorization from './hocs/Authorization'

/*----- Users -----*/
import UsersList     from '../modules/admin/users/ListViewContainer'
import RegisterForm  from '../modules/admin/users/RegisterForm'
import UserDetails   from '../modules/admin/users/UserDetails'
import EditProfile   from '../modules/admin/users/EditProfile'

/*----- Departments -----*/
import NewDepartment     from '../modules/admin/departments/NewDepartment'
import DepartmentsList   from '../modules/admin/departments/ListContainer'
import DepartmentDetails from '../modules/admin/departments/DetailsContainer'

/*----- Subjects -----*/
import NewSubject     from '../modules/admin/subjects/NewSubject'
import SubjectsList   from '../modules/admin/subjects/ListContainer'
import SubjectDetails from '../modules/admin/subjects/DetailsContainer'

/*----- Commissions -----*/
import CommissionsList     from '../modules/admin/commissions/ListContainer'
import NewCommission       from '../modules/admin/commissions/NewContainer'
import CommissionDetails   from '../modules/admin/commissions/DetailsContainer'
import BulkImportContainer from '../modules/admin/commissions/BulkImportContainer'

/*----- Components -----*/
import AdminLayout from '../layouts/AdminLayout'

/*----- Surveys ------*/
import SurveysList        from '../modules/admin/surveys/ListContainer'
import ExportContainer    from '../modules/admin/surveys/ExportContainer'
import NewSurveyContainer from '../modules/admin/surveys/NewSurveyContainer'
import ImportContainer    from '../modules/admin/surveys/ImportContainer'
import ImportsList        from '../modules/admin/surveys/ImportsListContainer'
import SurveyDetails      from '../modules/admin/surveys/DetailsContainer'

/*---- Categories ----*/
import NewCategory from '../modules/admin/surveys/views/question_categories/new/NewCategory'

const AdminRouter = ({ currentUser, getCurrentUser, currentUserLoaded }) => {
  
  const ability = useContext(AbilityContext)

  useEffect(() => {
    if (!currentUserLoaded){
      getCurrentUser()
    }
  }, [getCurrentUser, currentUserLoaded])
  
  return(
    <AdminLayout currentUser = { currentUser }>
      <Switch>
        {/* Logged user routes */}
          <Route exact path = '/admin' render = { withAuthorization(Home, currentUser, ability) } />

          <Route exact path = '/admin/users'          render = { withAuthorization(UsersList, currentUser, ability) } />
          <Route exact path = '/admin/sign-up'        render = { withAuthorization(RegisterForm, currentUser, ability)} />
          <Route exact path = '/admin/users/:id(\d+)' render = { withAuthorization(UserDetails, currentUser, ability) } />

          <Route exact path = '/admin/departments/new'      render = { withAuthorization(NewDepartment, currentUser, ability) } />
          <Route exact path = '/admin/departments'          render = { withAuthorization(DepartmentsList, currentUser, ability) } />
          <Route exact path = '/admin/departments/:id(\d+)' render = { withAuthorization(DepartmentDetails, currentUser, ability) } />
          
          <Route exact path = '/admin/subjects/new'      render  = { withAuthorization(NewSubject, currentUser, ability) } />
          <Route exact path = '/admin/subjects'          render  = { withAuthorization(SubjectsList, currentUser, ability) } />
          <Route exact path = '/admin/subjects/:id(\d+)' render  = { withAuthorization(SubjectDetails, currentUser, ability) }/>

          <Route exact path = '/admin/commissions'          render = { withAuthorization(CommissionsList, currentUser, ability) } />
          <Route exact path = '/admin/commissions/new'      render = { withAuthorization(NewCommission, currentUser, ability) } />
          <Route exact path = '/admin/commissions/:id(\d+)' render = { withAuthorization(CommissionDetails, currentUser, ability) } />

          <Route exact path = '/admin/commissions/bulk-import' render = { withAuthorization(BulkImportContainer, currentUser, ability) } />

          <Route exact path = '/admin/surveys'          render = { withAuthorization(SurveysList, currentUser, ability) } />
          <Route exact path = '/admin/surveys/:id(\d+)' render = { withAuthorization(SurveyDetails, currentUser, ability) } />
          <Route exact path = '/admin/surveys/export'   render = { withAuthorization(ExportContainer, currentUser, ability) } />
          <Route exact path = '/admin/surveys/new'      render = { withAuthorization(NewSurveyContainer, currentUser, ability) } />
          <Route exact path = '/admin/surveys/import'   render = { withAuthorization(ImportContainer, currentUser, ability) } />
          <Route exact path = '/admin/surveys/imports'  render = { withAuthorization(ImportsList, currentUser, ability) } />

          <Route exact path = '/admin/question_categories/new' render = { withAuthorization(NewCategory, currentUser, ability)} />

        {/* Admin or current user allowed routes */}
        <Route exact path = '/admin/users/edit/:id(\d+)' render = { withAuthorization(EditProfile, currentUser, ability)} />

        <Route component = { NotFoundError } />
      </Switch>
    </AdminLayout>
  )
}

const mapStateToProps = (state) => (
  {
    currentUser:       currentUserSelector(state),
    currentUserLoaded: currentUserLoadedSelector(state)
  }
)

const mapDispatchToProps = (dispatch) => (
  {
    getCurrentUser: () => currentUser(dispatch)
  }
)

export default connect(mapStateToProps, mapDispatchToProps)(AdminRouter)
