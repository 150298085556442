/*----- Actions -----*/
import {
  GET_SURVEY_DETAILS, GET_SURVEY_DETAILS_SUCCESS, GET_SURVEY_DETAILS_ERROR
} from '../SurveysActions'
  
export const initialState = {
  isLoading:  false,
  success:    '',
  error:      '',
  surveyInfo: {
    name:      {},
    questions: []
  }
}
  
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_SURVEY_DETAILS:
      return {
        ...state,
        isLoading: true
      }
    case GET_SURVEY_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading:  false,
        surveyInfo: action.payload
      }
    case GET_SURVEY_DETAILS_ERROR:
      return {
        ...state,
        isLoading: false,
        error:     action.payload.error
      }
    default:
      return state
  }
}