/*----- Actions -----*/
import {
  EXPORT_SURVEY, EXPORT_SURVEY_SUCCESS, EXPORT_SURVEY_ERROR, SET_EXPORT_SURVEY_FIELDS
} from '../SurveysActions'

import { serviceErrorsToFormFields } from '../../../../utils/ResponseFormatter'

export const initialState = {
  isLoading: false,
  success:   '',
  error:     {
    message: '',
    fields:  []
  },
  fields: {
    departments:   { value: [] },
    subjects:      { value: [] },
    commissions:   { value: [] },
    survey_id:     {},
    with_teachers: {},
    year:          {},
    period:        {}
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case EXPORT_SURVEY:
      return {
        ...state,
        isLoading: true,
      }
    case EXPORT_SURVEY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success:   action.payload,
        fields:    initialState.fields,
        error:     initialState.error
      }
    case EXPORT_SURVEY_ERROR:
      return {
        ...state,
        isLoading: false,
        error:     action.payload.error,
        fields:    serviceErrorsToFormFields(state.fields, action.payload.error.fields)
      }
    case SET_EXPORT_SURVEY_FIELDS:
      return {
        ...state,
        fields: {
          ...state.fields,
          ...action.payload
        }
      }
    default:
      return state;
  }
}