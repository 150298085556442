/*----- Imports -----*/
import React              from 'react'
import { Link }           from "react-router-dom"
import { connect }        from "react-redux"
import { useTranslation } from 'react-i18next'

/*----- Antd -----*/
import { Form, Icon, Input, Button, Checkbox } from 'antd'

/*----- Selectors -----*/
import { loadingLoginSelector, errorLoginSelector } from './selectors/loginSelector'

/*----- Actions -----*/
import { loginUser } from './LoginActions'

/*----- Components -----*/
import FormBox    from '../../../components/commons/form/FormBox'
import StyledSpin from '../../../components/commons/StyledSpin'

const { Item } = Form

const handleFormSubmit = (event, form, loginUser) => {
  form.validateFields()
  event.preventDefault()
  if (!hasErrors(form.getFieldsError())) {
    loginUser(form.getFieldsValue())
  }
}

const hasErrors = (fieldsError) => {
  return Object.keys(fieldsError).some(field => fieldsError[field])
}

const LoginForm = ( { form, isLoading, loginUser } ) => {
  const { t }                 = useTranslation()
  const { getFieldDecorator } = form

  return (
    <section id = 'main' className = 'wrapper'>
      <div className = 'container'>
        <FormBox title = { t("public.login_form.sign_in") }>
          <StyledSpin spinning = { isLoading } />
          <Form
            onSubmit = { (event) =>  { handleFormSubmit(event, form, loginUser) } }
            className = "login-form"
          >
            <Item>
              {
                getFieldDecorator(
                  'email',
                  {
                    rules: [
                      {
                        type:    'email',
                        message: t('rules.valid_email')
                      },
                      {
                        required: true,
                        message:  t('rules.insert_email'),
                      }
                    ],
                    validateTrigger: 'onSubmit'
                  }
                )(
                  <Input
                    placeholder = { t('user.email') }
                    disabled    = { isLoading }
                    prefix      = { <Icon type = 'user' style = {{ color: 'rgba(0,0,0,.25)' } } /> }
                  />
                )
              }
            </Item>

            <Item>
              {
                getFieldDecorator(
                  'password',
                  {
                    rules: [
                      {
                        required: true,
                        message: t('rules.insert_password')
                      },
                      {
                        min:     6,
                        message: t('rules.valid_password')
                      }
                    ],
                    validateTrigger: 'onSubmit'
                  }
                )(
                  <Input.Password
                    prefix     = {
                      <Icon type = "lock" style = { { color: 'rgba(0,0,0,.25)' } } />
                    }
                    type        = "password"
                    placeholder = { t('user.password') }
                    disabled    = { isLoading }
                  />
                )
              }
            </Item>

            <Item>
              {
                getFieldDecorator(
                  'remember',
                  {
                    valuePropName: 'checked',
                    initialValue:  true,
                  }
                )(
                  <Checkbox
                    disabled = { isLoading }
                  >
                    { t('public.login_form.remember_me') }
                  </Checkbox>
                )
              }

              <Button
                type      = "primary"
                htmlType  = "submit"
                className = "login-form-button"
                style     = { {width: '100%'} }
                disabled  = { isLoading }
              >
                { t('global.submit') }
              </Button>

              <Link
                to    = { "/recuperar-contraseña" }
                style = {{ width: '100%' }}
              >
                { t('public.login_form.restore_password') }
              </Link>

            </Item>

          </Form>
        </FormBox>
     </div>
    </section>
  )
}

function mapStateToProps(state) {
  return {
    isLoading: loadingLoginSelector(state),
    errorLogin: errorLoginSelector(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    loginUser: (username, password) => loginUser(dispatch, username, password)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: 'login_form' })(LoginForm))
