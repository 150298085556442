import { call, takeLatest, put } from 'redux-saga/effects'
import { push }                  from 'connected-react-router'

/*----- Notifications -----*/
import notifyError from '../../../../components/commons/notifications/ErrorNotification'

import {
  GET_USER_DETAILS, GET_USER_DETAILS_SUCCESS, GET_USER_DETAILS_ERROR
} from '../UserActions'

/*----- Endpoints -----*/
import { getUsersDetails } from '../../../../api/endpoints/Users'

export function* shootGetUsersDetails(action) {
  try {
    const response = yield call(getUsersDetails, action)
    let body       = yield response.json()
    let type

    if (response.status >= 200 && response.status < 300) {
      type = GET_USER_DETAILS_SUCCESS
    }
    else {
      type = GET_USER_DETAILS_ERROR
      yield notifyError(body.error.message)
      yield put(push('/admin/users'))
    }

    yield put(
      {
        type:    type,
        payload: body
      }
    )
  }
  catch (error) {
    yield notifyError('¡Ha ocurrido un error inesperado!')
    yield put(
      {
        type:    GET_USER_DETAILS_ERROR,
        payload: error.message
      }
    )
  }
}

export default function* getUserDetailsWatcher() {
  yield takeLatest(GET_USER_DETAILS, shootGetUsersDetails)
}
