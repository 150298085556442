/*----- API -----*/
import BASE_URL                                  from '../BaseUrl'
import { SUBJECT_RESOURCE, DEPARTMENT_RESOURCE } from '../Resources'

/*----- Authorization -----*/
import { getAuthToken } from '../../utils/SessionService'

/*----- Decorators -----*/
import refreshableDecorator from '../../decorators/RefreshableDecorator'

/*----- Utils -----*/
import { objToQueryString } from '../../utils/ParameterSanitizer'

export const postSubjectCreation = refreshableDecorator(
  function* (action) {
    return yield fetch(
      `${BASE_URL}/${SUBJECT_RESOURCE}/`,
      {
        method: 'POST',
        body:    JSON.stringify(action.payload.body),
        headers: {
          'Content-Type':  'application/json',
          'Authorization': `Bearer ${getAuthToken()}`
        }
      }
    )
  }
)

export const getSubjects = refreshableDecorator(
  function* (action) {
    const params = objToQueryString(action.payload)

    return yield fetch(
      `${BASE_URL}/${SUBJECT_RESOURCE}/?${params}`,
      {
        method: 'GET',
        headers: {
          'Content-Type':  'application/json',
          'Authorization': `Bearer ${getAuthToken()}`
        }
      }
    )
  }
)

export const getSubjectDetails = refreshableDecorator(
  function* (action) {
    return yield fetch(
      `${BASE_URL}/${SUBJECT_RESOURCE}/${action.payload}/`,
      {
        method:  'GET',
        headers: {
          'Content-Type':  'application/json',
          'Authorization': `Bearer ${getAuthToken()}`
        }
      }
    )
  }
)

export const deleteSubject = refreshableDecorator(
  function* (action) {
    return yield fetch(
      `${BASE_URL}/${SUBJECT_RESOURCE}/${action.payload.subjectId}/`,
      {
        method:  'DELETE',
        headers: {
          'Content-Type':  'application/json',
          'Authorization': `Bearer ${getAuthToken()}`
        }
      }
    )
  }
)

export const editSubject = refreshableDecorator(
  function* (action) {
    return yield fetch(
      `${BASE_URL}/${SUBJECT_RESOURCE}/${action.payload.subjectId}/`,
      {
        method:  'PUT',
        body:    JSON.stringify(action.payload.body),
        headers: {
          'Content-Type':  'application/json',
          'Authorization': `Bearer ${getAuthToken()}`
        }
      }
    )
  }
)

export const getSubjectDepartments = refreshableDecorator(
  function* (action) {
    const params = objToQueryString(action.payload.params)
    
    return yield fetch(
      `${BASE_URL}/${SUBJECT_RESOURCE}/${action.payload.subjectId}/${DEPARTMENT_RESOURCE}/?${params}`,
      {
        method: 'GET',
        headers: {
          'Content-Type':  'application/json',
          'Authorization': `Bearer ${getAuthToken()}`
        }
      }
    )
  }
)

export const restoreSubject = refreshableDecorator(
  function* (action) {
    return yield fetch(
      `${BASE_URL}/${SUBJECT_RESOURCE}/${action.payload.subjectId}/activate/`,
      {
        method:  'PUT',
        headers: {
          'Content-Type':  'application/json',
          'Authorization': `Bearer ${getAuthToken()}`
        }
      }
    )
  }
)

export const deleteSubjectDepartment = refreshableDecorator(
  function* (action) {
    return yield fetch(
      `${BASE_URL}/${SUBJECT_RESOURCE}/${action.payload.subjectId}/delete_departments/`,
      {
        method:  'DELETE',
        body:    JSON.stringify(action.payload.body),
        headers: {
          'Content-Type':  'application/json',
          'Authorization': `Bearer ${getAuthToken()}`
        }
      }
    )
  }
)