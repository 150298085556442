/*------------- React ------------------*/
import React               from 'react'
import { Route, Redirect } from 'react-router'

/*------------- Utils ------------------*/
import { isAuth }             from '../utils/SessionService'

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
        isAuth()
        ? <Component {...props} />
        : <Redirect to='/login' />
    )} />
)

export default PrivateRoute