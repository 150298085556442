export const DEPARTMENT_DETAILS         = 'DEPARTMENT_DETAILS'
export const DEPARTMENT_DETAILS_SUCCESS = 'DEPARTMENT_DETAILS_SUCCESS'
export const DEPARTMENT_DETAILS_ERROR   = 'DEPARTMENT_DETAILS_ERROR'

export const DEPARTMENTS_LIST         = 'DEPARTMENTS_LIST'
export const DEPARTMENTS_LIST_SUCCESS = 'DEPARTMENTS_LIST_SUCCESS'
export const DEPARTMENTS_LIST_ERROR   = 'DEPARTMENTS_LIST_ERROR'

export const CREATE_DEPARTMENT         = 'CREATE_DEPARTMENT'
export const CREATE_DEPARTMENT_SUCCESS = 'CREATE_DEPARTMENT_SUCCESS'
export const CREATE_DEPARTMENT_ERROR   = 'CREATE_DEPARTMENT_ERROR'

export const DELETE_DEPARTMENT_USERS         = 'DELETE_DEPARTMENT_USERS'
export const DELETE_DEPARTMENT_USERS_SUCCESS = 'DELETE_DEPARTMENT_USERS_SUCCESS'
export const DELETE_DEPARTMENT_USERS_ERROR   = 'DELETE_DEPARTMENT_USERS_ERROR'

export const DELETE_DEPARTMENT         = 'DELETE_DEPARTMENT'
export const DELETE_DEPARTMENT_SUCCESS = 'DELETE_DEPARTMENT_SUCCESS'
export const DELETE_DEPARTMENT_ERROR   = 'DELETE_DEPARTMENT_ERROR'

export const EDIT_DEPARTMENT         = 'EDIT_DEPARTMENT'
export const EDIT_DEPARTMENT_SUCCESS = 'EDIT_DEPARTMENT_SUCCESS'
export const EDIT_DEPARTMENT_ERROR   = 'EDIT_DEPARTMENT_ERROR'

export const USERS_NOT_IN_DEPARTMENT         = 'USERS_NOT_IN_DEPARTMENT'
export const USERS_NOT_IN_DEPARTMENT_SUCCESS = 'USERS_NOT_IN_DEPARTMENT_SUCCESS'
export const USERS_NOT_IN_DEPARTMENT_ERROR   = 'USERS_NOT_IN_DEPARTMENT_ERROR'

export const DEPARTMENT_USERS         = 'DEPARTMENT_USERS'
export const DEPARTMENT_USERS_SUCCESS = 'DEPARTMENT_USERS_SUCCESS'
export const DEPARTMENT_USERS_ERROR   = 'DEPARTMENT_USERS_ERROR'

export const SET_SELECTED_USERS             = 'SET_SELECTED_USERS'
export const SET_DEPARTMENT_EDITION_FIELDS  = 'SET_DEPARTMENT_EDITION_FIELDS'
export const SET_NEW_DEPARTMENT_FORM_FIELDS = 'SET_NEW_DEPARTMENT_FORM_FIELDS'

export const TOGGLE_EDITION_MODAL    = 'TOGGLE_EDITION_MODAL'
export const TOGGLE_EDIT_USERS_MODAL = 'TOGGLE_EDIT_USERS_MODAL'

export const RESTORE_DEPARTMENT         = 'RESTORE_DEPARTMENT'
export const RESTORE_DEPARTMENT_SUCCESS = 'RESTORE_DEPARTMENT_SUCCESS'
export const RESTORE_DEPARTMENT_ERROR   = 'RESTORE_DEPARTMENT_ERROR'

export const getDepartmentDetails = (dispatch, departmentId) => {
  dispatch(
    {
      type:    DEPARTMENT_DETAILS,
      payload: departmentId
    }
  )
}

export const getDepartments = (dispatch, search = '', blocked = false) => {
  dispatch(
    {
      type:    DEPARTMENTS_LIST,
      payload: {
        search:  search,
        blocked: blocked
      } 
    }
  )
}

export const createDepartment = (dispatch, params) => {
  dispatch(
    {
      type:    CREATE_DEPARTMENT,
      payload: {
        body: params
      } 
    }
  )
}

export const setSeletedUsers = (dispatch, params) => {
  dispatch(
    {
      type:    SET_SELECTED_USERS,
      payload: params
    }
  )
}

export const setNewDepartmentFields = (dispatch, fields) => {
  dispatch(
    {
      type:    SET_NEW_DEPARTMENT_FORM_FIELDS,
      payload: fields
    }
  )
}

export const deleteDepartmentUsers = (dispatch, departmentId, users) => {
  dispatch(
    {
      type:    DELETE_DEPARTMENT_USERS,
      payload: {
        departmentId: departmentId,
        body: {
          users: users
        }
      }
    }
  )
}

export const deleteDepartment = (dispatch, departmentId) => {
  dispatch(
    {
      type:    DELETE_DEPARTMENT,
      payload: {
        departmentId: departmentId
      }
    }
  )
}

export const editDepartment = (dispatch, departmentId, fields) => {
  dispatch(
    {
      type:    EDIT_DEPARTMENT,
      payload: {
        departmentId: departmentId,
        body:         fields
      }
    }
  )
}

export const setDepartmentEditionFields = (dispatch, fields) => {
  dispatch(
    {
      type:    SET_DEPARTMENT_EDITION_FIELDS,
      payload: fields
    }
  )
}

export const toggleEditionModal = (dispatch, visible) => {
  dispatch(
    {
      type:    TOGGLE_EDITION_MODAL,
      payload: visible
    }
  )
}

export const toggleEditUsersModal = (dispatch, visible) => {
  dispatch(
    {
      type:    TOGGLE_EDIT_USERS_MODAL,
      payload: visible
    }
  )
}

export const getUsersNotInDepartment = (dispatch, departmentId, search = '') => {
  dispatch(
    {
      type:    USERS_NOT_IN_DEPARTMENT,
      payload: {
        departmentId: departmentId,
        params:       {
          not_included_users: true,
          search:             search
        }
      } 
    }
  )
}

export const restoreDepartment = (dispatch, departmentId) => {
  dispatch(
    {
      type:    RESTORE_DEPARTMENT,
      payload: departmentId
    }
  )
}

export const getDepartmentUsers = (dispatch, departmentId, search = '') => {
  dispatch(
    {
      type:    DEPARTMENT_USERS,
      payload: {
        departmentId: departmentId,
        params:       {
          search: search
        }
      }
    }
  )
}
