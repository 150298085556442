/*----- Imports -----*/
import React              from 'react'
import PropTypes          from 'prop-types'
import { useTranslation } from 'react-i18next'

/*----- Antd -----*/
import { Form, Input, Icon } from 'antd'

const { Item } = Form

const EditionForm = ({ form }) => {
  const { t }                 = useTranslation()
  const { getFieldDecorator } = form

  const nameFieldOptions = {
    rules: [
      {
        required: true,
        message:  t('rules.mandatory_field'),
      }
    ],
    validateTrigger: ['onSubmit', 'onBlur']
  }

  return(
      <Form>
        <Item label = { t('department.name') } >
          {
            getFieldDecorator('name', nameFieldOptions)(
              <Input
                placeholder = { t('department.title') }
                prefix      = {
                  <Icon type = 'pie-chart' style = {{ color: 'rgba(0, 0, 0, .25)' }} />
                }
              />
            )
          }
        </Item>
      </Form>
  )
}

export default EditionForm

/*----- PropTypes -----*/
EditionForm.propTypes = {
  form: PropTypes.object.isRequired
}
