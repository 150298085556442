/*----- Saga -----*/
import { call, takeLatest, put } from 'redux-saga/effects'
import { push }                  from 'connected-react-router'

/*----- Actions -----*/
import {
  GET_COMMISSION_DETAILS, GET_COMMISSION_DETAILS_ERROR, GET_COMMISSION_DETAILS_SUCCESS
} from '../CommissionActions'

/*----- Endpoint -----*/
import { getCommissionDetails } from '../../../../api/endpoints/Commissions'

/*----- Notifications -----*/
import notifyError from '../../../../components/commons/notifications/ErrorNotification'

export function* shootGetCommission(action) {
  try {
    const response = yield call(getCommissionDetails, action)
    const body     = yield response.json()
    let type

    if (response.status >= 200 && response.status < 300) {
      type = GET_COMMISSION_DETAILS_SUCCESS
    }
    else {
      type = GET_COMMISSION_DETAILS_ERROR

      yield notifyError(body.error.message)
      yield put(push('/admin/commissions'))
    }

    yield put(
      {
        type:    type,
        payload: body
      }
    )
  }
  catch (error) {
    yield notifyError('¡Ha ocurrido un error inesperado!')
    yield put(
      {
        type:    GET_COMMISSION_DETAILS_ERROR,
        payload: error.message
      }
    )
  }
}

export default function* () {
  yield takeLatest(GET_COMMISSION_DETAILS, shootGetCommission)
}
