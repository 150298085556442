/*----- Saga -----*/
import { call, takeLatest, put } from 'redux-saga/effects'

/*----- Actions -----*/
import {
  CREATE_QUESTION_CATEGORY,
  CREATE_QUESTION_CATEGORY_SUCCESS,
  CREATE_QUESTION_CATEGORY_ERROR
} from '../SurveysActions'

/*----- Endpoint -----*/
import { createQuestionCategory } from '../../../../api/endpoints/Surveys'

/*----- Notifications -----*/
import loadingNotification from '../../../../components/commons/notifications/LoadingNotification'
import successNotification from '../../../../components/commons/notifications/SuccessNotification'
import notifyError         from '../../../../components/commons/notifications/ErrorNotification'

export function* shootCreateCategory(action) {
  yield loadingNotification()
  try {
    const response = yield call(createQuestionCategory, action)
    const body     = yield response.json()
    let type

    if (response.status >= 200 && response.status < 300) {
      type = CREATE_QUESTION_CATEGORY_SUCCESS
      yield successNotification()
    } else {
      type = CREATE_QUESTION_CATEGORY_ERROR
      yield notifyError(body.error.message)
    }

    yield put(
      {
        type:    type,
        payload: body
      }
    )
  }
  catch (error) {
    yield notifyError('¡Ha ocurrido un error inesperado!')
    yield put(
      {
        type:    CREATE_QUESTION_CATEGORY_ERROR,
        payload: error.message
      }
    )
  }
}

export default function*() {
  yield takeLatest(CREATE_QUESTION_CATEGORY, shootCreateCategory)
}
