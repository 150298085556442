/*----- Imports -----*/
import React, { useEffect } from 'react'
import { connect }          from 'react-redux'

/*----- Selectors -----*/
import { 
  questionCategoriesSelector, 
  loadingCategoriesSelector
} from './selectors/GetCategoriesSelector'
import { createSurveyFieldsSelector } from './selectors/CreateSurveySelector'

/*----- Antd -----*/
import { Form } from 'antd'

/*----- Actions -----*/
import { 
  getQuestionCategories, 
  setNewSurveyFormFields,
  addQuestionFields,
  createSurvey,
  deleteQuestionFields,
} from './SurveysActions'

/*----- Views -----*/
import MainView from './views/new/MainView'

const QUESTION_FIELDS = ['category', 'question', 'resourcetype', 'max_value', 'choices']

const hasErrors = (fieldsError) => (
  Object.keys(fieldsError).some(field => fieldsError[field])
)

const NewSurveyContainer = ({ 
  formFields, getQuestionCategories, categories, form, isLoading,
  addQuestionFields, createSurvey, deleteQuestionFields
}) => {

  useEffect(() => {
    getQuestionCategories()
  }, [getQuestionCategories])

  const handleSubmit = (event) => {
    event.preventDefault()
    
    if (form.getFieldValue('questions').length < 1) { return }
    
    form.validateFields(['questions'], { force: true })

    let errors = form.getFieldError('questions').map((records) => {
      return hasErrors(Object.values(records))
    })

    if (errors.every((result) => !result)) {
      let fields = form.getFieldsValue()
      createSurvey(fields)
    }
  }

  return (
    <MainView
      categories           = { categories }
      form                 = { form }
      isLoading            = { isLoading }
      handleSubmit         = { handleSubmit }
      keys                 = { formFields.keys }
      addQuestionFields    = { addQuestionFields }
      deleteQuestionFields = { deleteQuestionFields }
    />
  )
}

const mapStateToProps = (state) => (
  {
    categories: questionCategoriesSelector(state),
    formFields: createSurveyFieldsSelector(state),
    isLoading:  loadingCategoriesSelector(state)
  }
)

const mapDispatchToProps = (dispatch) => (
  {
    getQuestionCategories:        () => getQuestionCategories(dispatch),
    setNewSurveyFormFields: (fields) => setNewSurveyFormFields(dispatch, fields),
    addQuestionFields:            () => addQuestionFields(dispatch),
    deleteQuestionFields:      (key) => deleteQuestionFields(dispatch, key),
    createSurvey:           (fields) => createSurvey(dispatch, fields)
  }
)

const ConnectedNewSurveyContainer = connect(mapStateToProps, mapDispatchToProps)(
  Form.create(
    {
      name: 'new_survey_form',
      onFieldsChange({ setNewSurveyFormFields }, changedFields, allFields) {
        if (changedFields.questions) {
          let updatedQuestions = { ...allFields.questions }
          
          let changes = { ...changedFields.questions }
          
          for (const [key, value] of Object.entries(changes)) {
            updatedQuestions[key] = {
              ...updatedQuestions[key],
              ...value
            }
          }
  
          setNewSurveyFormFields({ questions: updatedQuestions })
        }
        else {
          setNewSurveyFormFields(changedFields)
        }
      },
      mapPropsToFields({ formFields }) {
        let nested_fields = {}

        formFields.keys.forEach(elem => {
          QUESTION_FIELDS.forEach(field => {
            nested_fields[`questions[${elem}].${field}`] = Form.createFormField(
              { 
                ...formFields.questions[elem][field]
              }
            )
          })
        })

        return {
          name: Form.createFormField({...formFields.name}),
          ...nested_fields
        }
      }
  })(NewSurveyContainer))

ConnectedNewSurveyContainer.defaultProps = {
  permission:   'create',
  model:        'Survey',
  defaultRoute: '/admin'
}

export default ConnectedNewSurveyContainer