import { all }              from 'redux-saga/effects'
import getUsersListSagas    from './GetUsersListSagas'
import getUsersDetailsSagas from './GetUsersDetailsSagas'
import deleteUserSagas      from './DeleteUserSagas'
import createUserSagas      from './PostCreateUserSagas'
import putEditUserSagas     from './PutEditUserSagas'
import putChangePassword    from './PutChangePassword'
import putActivateUser      from './PutActivateUserSagas'

export default function* dispatchSagas() {
  yield all([
    getUsersListSagas(),
    getUsersDetailsSagas(),
    deleteUserSagas(),
    createUserSagas(),
    putEditUserSagas(),
    putChangePassword(),
    putActivateUser()
  ])
}
