/*----- Imports -----*/
import React              from 'react'
import { Link }           from 'react-router-dom'
import { connect }        from 'react-redux'
import { useTranslation } from 'react-i18next'

/*----- Antd -----*/
import { Form, Icon, Input, Button } from 'antd'

/*----- Actions -----*/
import { restore_password } from './RestorePasswordActions'

/*----- Selectors -----*/
import { restorePasswordLoading } from './selectors/RestorePasswordSelector'

/*----- Components -----*/
import FormBox    from '../../../components/commons/form/FormBox'
import StyledSpin from '../../../components/commons/StyledSpin'
const { Item } = Form

const hasErrors = (fieldsError) => {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

const RestorePassword = ({ form, restore_password, isLoading } ) => {
  const { t }                 = useTranslation();
  const { getFieldDecorator } = form

  const emailFieldOptions = () => {
    return {
      rules: [
        {
          type:    'email',
          message: t('rules.valid_email')
        },
        {
          required: true,
          message:  t('rules.insert_email'),
        }
      ],
      validateTrigger: 'onSubmit'
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    form.validateFields()
    if (!hasErrors(form.getFieldsError())) {
      restore_password(form.getFieldsValue())
    }
  }

  return (
    <section id = 'main' className = 'wrapper' style = {{ height: '70vh' }}>
      <div className = 'container'>
        <FormBox title = { t('public.restore_password.title') } >
          <StyledSpin spinning = { isLoading } />

          <Form
            onSubmit  = { handleSubmit }
            className = 'register-form'
          >

            <Item>
              {
                getFieldDecorator('email', emailFieldOptions())(
                  <Input
                    prefix      = { <Icon type='user' style={{ color: 'rgba(0, 0, 0, .25)'}}/> }
                    placeholder = { t('user.email') }
                  />
                )
              }
            </Item>

            <Button
              type      = 'primary'
              htmlType  = 'submit'
              style     = {{ width: '100%' }}
            >
              { t('global.submit') }
            </Button>

          </Form>

          <div style = {{ width: '100%', textAlign: 'center', marginTop: '2%' }} >
            <Link
              to = {'/login'}
            >
              { t('public.restore_password.back_to_login') }
            </Link>
          </div>
        </FormBox>
      </div>
    </section>
  )
}

function mapStateToProps(state) {
  return {
    isLoading: restorePasswordLoading(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    restore_password: (form_data) => restore_password(dispatch, form_data)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: 'restore_password' })(RestorePassword))
