/*----- Saga -----*/
import { call, takeLatest, put } from 'redux-saga/effects'

/*----- Actions -----*/
import { DEPARTMENTS_LIST, DEPARTMENTS_LIST_ERROR, DEPARTMENTS_LIST_SUCCESS } from '../DepartmentsActions'

/*----- Endpoint -----*/
import { getDepartmentsList } from '../../../../api/endpoints/Departments'

/*----- Notifications -----*/
import notifyError from '../../../../components/commons/notifications/ErrorNotification'

export function* shootGetDepartments(action) {
  try {
    const response = yield call(getDepartmentsList, action)
    const body     = yield response.json()
    let type

    if (response.status >= 200 && response.status < 300) {
      type = DEPARTMENTS_LIST_SUCCESS
    } else {
      type = DEPARTMENTS_LIST_ERROR
    }

    yield put(
      {
        type:    type,
        payload: body
      }
    )
  }
  catch (error) {
    yield notifyError('¡Ha ocurrido un error inesperado!')
    yield put(
      {
        type:    DEPARTMENTS_LIST_ERROR,
        payload: error.message
      }
    )
  }
}

export default function* CreateUserWatcher() {
  yield takeLatest(DEPARTMENTS_LIST, shootGetDepartments)
}
