import React         from 'react'
import { Redirect }  from 'react-router'
import updateAbility from '../../ability/UpdateAbility'

export default (Component, currentUser, ability) => () => {
  updateAbility(ability, currentUser)
  
  if ((Component.defaultProps.model.toLowerCase() !== 'user' && 
      Component.defaultProps.permission.toLowerCase() !== 'edit') &&
      (currentUser.first_login || currentUser.modify_password))
  {
    return <Redirect to = { `/admin/users/edit/${currentUser.id}` } />
  }
  
  if (ability.can(Component.defaultProps.permission, Component.defaultProps.model)){
    return <Component />
  } else {
    return <Redirect to = { Component.defaultProps.defaultRoute } />
  }
}
