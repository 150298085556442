/*----- Imports -----*/
import React              from 'react'
import { useTranslation } from 'react-i18next'
import { Link }           from 'react-router-dom'
import styled             from 'styled-components'

const Ul = styled.ul`
  margin-top: 5%;
  list-style: none;
  padding:    0;
`

const Li = styled.li`
  padding: 5%;
`

const StyledLink = styled(Link)`
  border:          0;
  border-top:      solid 1px transparent;
  color:           inherit;
  display:         block;
  line-height:     3.5em;
  text-decoration: none;

  &:focus, &:hover, &:visited, &:link, &:active {
    text-decoration: none;
    color:           inherit;
  }
`

export default () => {
  const { t } = useTranslation()

  return(
    <nav>
      <Ul>
        <Li>
          <StyledLink to = '/'>
            { t('public.home.title') }
          </StyledLink>
        </Li>

        <Li>
          <StyledLink to = '/graphics/subjects'>
            { t('public.subjects.title') }
          </StyledLink>
        </Li>

        <Li>
          <StyledLink to = '/graphics/teachers'>
            { t('public.teachers.title') }
          </StyledLink>
        </Li>

        <Li>
          <StyledLink to = '/login'>
            { t('public.login_form.sign_in') }
          </StyledLink>
        </Li>
      </Ul>
    </nav>
  )
}
