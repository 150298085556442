/*----- Actions -----*/
import {
  GET_SUBJECT_DETAILS, GET_SUBJECT_DETAILS_SUCCESS, GET_SUBJECT_DETAILS_ERROR
} from '../SubjectsActions'
  
export const initialState = {
  isLoading:   false,
  success:     '',
  error:       '',
  subjectInfo: {
    name:        '',
    code:        '',
    departments: []
  }
}
  
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_SUBJECT_DETAILS:
      return {
        ...state,
        isLoading: true
      }
    case GET_SUBJECT_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading:   false,
        subjectInfo: action.payload
      }
    case GET_SUBJECT_DETAILS_ERROR:
      return {
        ...state,
        isLoading: false,
        error:     action.payload.error
      }
    default:
      return state
  }
}