/*----- Imports -----*/
import React              from 'react'
import styled             from 'styled-components'
import PropTypes          from 'prop-types'
import { useTranslation } from 'react-i18next'

/*----- Views -----*/
import Table   from './Table'
import columns from './Columns'

const Container = styled.div`
  margin-top:  5%;
  margin-left: 10%;
  width:       80%;
`

const StyledTable = styled(Table)`
  margin-top: 5%;
`

const MainView = ({ surveys, isLoading, deleteSurvey }) => {
  const { t } = useTranslation()

  return(
    <Container>
      <StyledTable
        surveys   = { surveys }
        isLoading = { isLoading }
        columns   = { columns(t, deleteSurvey) }
      />
    </Container>
  )
}

export default MainView

/*----- PropTypes -----*/
MainView.propTypes = {
  surveys:      PropTypes.array.isRequired,
  isLoading:    PropTypes.bool.isRequired,
  deleteSurvey: PropTypes.func.isRequired
}
