export const SET_MENU_VISIBILITY = 'SET_MENU_VISIBILITY'

export const setMenuVisibility = (dispatch, visible) => {
  dispatch(
    {
      type:    SET_MENU_VISIBILITY,
      payload: visible
    }
  )
}
