/*----- Imports -----*/
import React          from 'react'
import { withRouter } from "react-router"

/*----- Antd -----*/
import { Result, Button } from 'antd';

const NotFoundError = ({ history }) => {
  return (
    <Result
      status   = "404"
      title    = "404"
      subTitle = "Lo sentimos, la página que está solicitando no existe."
      extra    = {
        <Button
          type    = "primary"
          onClick = { () => { history.goBack() } }
        >
          Volver a la página anterior
        </Button>
      }
    />
  )
}

export default withRouter(NotFoundError)
