/*----- Imports -----*/
import React from 'react'

/*----- Antd -----*/
import { Icon, Popconfirm } from 'antd'

const columns = (t, role, deleteCommissionTeachingStaff, commission) => {
  let result = [
    {
      title:          role.name,
      dataIndex:      'name',
      key:            'name',
      sorter:         (a,b) => a.user.first_name.localeCompare(b.user.first_name),
      sortDirections: ['ascend','descend'],
      render:         (_text, record) => (
        `${record.user.first_name} ${record.user.last_name}`
      )
    },
    {
      title:          t('user.dni'),
      dataIndex:      'dni',
      key:            'dni',
      sorter:         (a, b) => String(a.user.dni).localeCompare(String(b.user.dni)),
      sortDirections: ['ascend','descend'],
      render:         (_text, record) => record.user.dni
    },
    {
      title:          t('commission.teaching_staff.workload'),
      dataIndex:      'workload',
      key:            'workload',
      sorter:         (a,b) => a.workload.localeCompare(b.workload),
      sortDirections: ['ascend','descend'],
      render:         (_text, record) => record.workload
    },
    {
      title:          t('commission.teaching_staff.position_number'),
      dataIndex:      'position_number',
      key:            'position_number',
      sorter:         (a,b) => a.position_number.localeCompare(b.position_number),
      sortDirections: ['ascend','descend'],
      render:         (_text, record) => record.position_number
    }
  ]

  if (role.include_dedication) {
    result.push(
      {
        title:          t('commission.teaching_staff.dedication'),
        dataIndex:      'dedication',
        key:            'dedication',
        sorter:         (a,b) => a.dedication.localeCompare(b.dedication),
        sortDirections: ['ascend','descend'],
        render:         (_text, record) => record.dedication
      }
    )
  }

  if (role.include_category) {
    result.push(
      {
        title:          t('commission.teaching_staff.category'),
        dataIndex:      'category',
        key:            'category',
        sorter:         (a,b) => a.category.localeCompare(b.category),
        sortDirections: ['ascend','descend'],
        render:         (_text, record) => record.category
      }
    )
  }

  result.push({
    title:  t('global.actions'),
    key:    'action',
    render: (_text, record) => (
      <span>
        <Popconfirm
          title      = { t('notifications.confirm') }
          onConfirm  = { () => deleteCommissionTeachingStaff(commission.subject.id, commission.id, record.user.id) }
          okText     = { t('global.accept') }
          cancelText = { t('global.cancel') }
        >
          <Icon type = 'delete' style = {{ color:'red' }} />
        </Popconfirm>
      </span>
    )
  })

  return result
}

export default columns
