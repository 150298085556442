/*----- Imports -----*/
import React                       from 'react'
import { BrowserView, MobileView } from 'react-device-detect'
import { useTranslation }          from 'react-i18next'
import PropTypes                   from 'prop-types'
import styled                      from 'styled-components'
import { Link }                    from 'react-router-dom'

/*----- Antd -----*/
import { Layout, Drawer } from 'antd'

/*----- Components -----*/
import Logo from '../../commons/Logo'
import Menu from './NavigationMenu'

const { Sider } = Layout

const StyledLogo = styled(Logo)`
  margin: 5%;
`

const Menubar = ({ pathname, visible, setVisible }) => {

  const { t }  = useTranslation()

  return (
    <>
      <BrowserView>
        <Sider
          collapsible
          collapsed  = { visible }
          onCollapse = { (visible) => setVisible(visible) }
          width      = '250'
          style      = { { height: '100%'} }
        >

          <Link to = '/' >
            <StyledLogo />
          </Link>
          <Menu pathname = { pathname } theme = 'dark' />

        </Sider>
      </BrowserView>

      <MobileView>
        <Drawer
          title     = { t('admin.menubar.title') }
          placement = 'top'
          visible   = { visible }
          onClose   = { () => setVisible(false) }
          closable  = { true }
        >
          <Menu pathname = { pathname } theme = 'light' />
        </Drawer>
      </MobileView>
    </>
  )
}

export default Menubar

/*----- PropTypes -----*/
Menubar.propTypes = {
  pathname:   PropTypes.string.isRequired,
  visible:    PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired
}
