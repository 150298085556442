/*----- Imports -----*/
import React              from 'react'
import styled             from 'styled-components'
import PropTypes          from 'prop-types'
import { useTranslation } from 'react-i18next'

/*----- Views -----*/
import Card        from './Card'
import Users       from './Users'
import EditionForm from './EditionForm'
import Modal       from './EditionModal'

/*----- Antd -----*/
import { Icon, Badge, Button } from 'antd'

/*----- Users table -----*/
import UsersTable from '../users_table/MainView'
import columns    from '../users_table/Columns'

const Container = styled.div`
  margin-top:  5%;
  margin-left: 10%;
  width:       80%;
`

const MainView = (
  {
    departmentInfo, isLoading, deleteDepartmentUsers, isAdmin,
    form, editionSubmitHandler, modalVisible, toggleEditionModal,
    isLoadingEdition, selectedUsers, setSelectedUsers, handleUpdateUsers,
    toggleEditUsersModal, editUsersmodalVisible, usersNotInDepartment,
    loadingUsersNotInDepartment, getUsersNotInDepartment,
    departmentUsers, loadingDepartmentUsers, getDepartmentUsers
  }
) => {

  const { t }           = useTranslation()
  const openModalButton = <Icon
    type    = 'edit'
    theme   = 'twoTone'
    style   = {{ fontSize: '15px' }}
    onClick = { () => toggleEditionModal(true) }
  />

  const openEditUsersModalButton = <Badge count = { selectedUsers.length }>
    <Button
      type     = 'primary'
      onClick  = { () => toggleEditUsersModal(true) }
      style    = { { marginTop: '1%', marginBottom: '1%'} }
    >
      <Icon type = 'plus' style = {{ color: 'rgba(0, 0, 0, .25)' }} />
      { t('department.add_users') }
    </Button>
  </Badge>

  return(
    <Container>
      <Card
        isLoading = { isLoading }
        title     = { departmentInfo.name }
        extra     = { openModalButton }
      >

        <Users
          users         = { departmentUsers }
          isLoading     = { loadingDepartmentUsers }
          deleteHandler = { deleteDepartmentUsers }
          getUsers      = { getDepartmentUsers }
          isAdmin       = { isAdmin }
          departmentId  = { departmentInfo.id }
          extra         = { openEditUsersModalButton }
        />

        {/* Modal para actualización de nombre */}
        <Modal
          title           = { t('admin.edit_department.change_name') }
          visible         = { modalVisible }
          onOkHandler     = { () => editionSubmitHandler() }
          onCancelHandler = { () => toggleEditionModal(false) }
          isLoading       = { isLoadingEdition }
        >

          <EditionForm form = { form } />

        </Modal>


        {/* Modal para agregar usuarios */}
        <Modal
          title           = { t('department.add_users') }
          visible         = { editUsersmodalVisible }
          onOkHandler     = { () => handleUpdateUsers() }
          onCancelHandler = { () => toggleEditUsersModal(false) }
          isLoading       = { isLoadingEdition }
        >

          <UsersTable
            users          = { usersNotInDepartment }
            getUsers       = { getUsersNotInDepartment }
            columns        = { columns(t) }
            onChangeHander = { setSelectedUsers }
            selectedUsers  = { selectedUsers }
            isLoading      = { loadingUsersNotInDepartment }
          />

        </Modal>

      </Card>
    </Container>
  )
}

export default MainView

/*----- PropTypes -----*/
MainView.propTypes = {
  departmentInfo:         PropTypes.object.isRequired,
  isLoading:              PropTypes.bool.isRequired,
  deleteDepartmentUsers:  PropTypes.func.isRequired,
  isAdmin:                PropTypes.bool.isRequired,
  editionSubmitHandler:   PropTypes.func.isRequired,
  form:                   PropTypes.object.isRequired,
  modalVisible:           PropTypes.bool.isRequired,
  toggleEditionModal:     PropTypes.func.isRequired,
  isLoadingEdition:       PropTypes.bool.isRequired,
  selectedUsers:          PropTypes.array.isRequired,
  setSelectedUsers:       PropTypes.func.isRequired,
  handleUpdateUsers:      PropTypes.func.isRequired,
  toggleEditUsersModal:   PropTypes.func.isRequired,
  editUsersmodalVisible:  PropTypes.bool.isRequired,
  departmentUsers:        PropTypes.array.isRequired,
  loadingDepartmentUsers: PropTypes.bool.isRequired,
  getDepartmentUsers:     PropTypes.func.isRequired
}
