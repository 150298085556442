/*----- Imports -----*/
import { useCallback }    from 'react'
import { useTranslation } from 'react-i18next'

/*----- Antd -----*/
import { Modal } from 'antd'

const { confirm } = Modal

// Custom hook to render confirm modal for download files.
// Returns the function ready to be called on any event.
export const useDownloadConfirm = (callback) => {
  const { t } = useTranslation()

  return useCallback(
    () => {
      confirm(
        {
          title:     t('notifications.confirm_list_download_title'),
          content:   t('notifications.confirm_list_download_content'),
          onOk()     { callback() },
          onCancel() {}
        }
      )
    },
    [t, callback]
  )
}
    