/*----- Saga -----*/
import { call, takeLatest, put } from 'redux-saga/effects'

/*----- Actions -----*/
import { GET_ROLES_LIST, GET_ROLES_LIST_ERROR, GET_ROLES_LIST_SUCCESS } from '../RolesActions'

/*----- Endpoint -----*/
import { getRoles } from '../../../../api/endpoints/Roles'

/*----- Notifications -----*/
import notifyError from '../../../../components/commons/notifications/ErrorNotification'

export function* shootGetRoles(action) {
  try {
    const response = yield call(getRoles, action)
    const body     = yield response.json()
    let type

    if (response.status >= 200 && response.status < 300) {
      type = GET_ROLES_LIST_SUCCESS
    }
    else {
      type = GET_ROLES_LIST_ERROR
    }

    yield put(
      {
        type:    type,
        payload: body
      }
    )
  }
  catch (error) {
    yield notifyError('¡Ha ocurrido un error inesperado!')
    yield put(
      {
        type:    GET_ROLES_LIST_ERROR,
        payload: error.message
      }
    )
  }
}

export default function* () {
  yield takeLatest(GET_ROLES_LIST, shootGetRoles)
}
