/*----- Imports -----*/
import React              from 'react'
import styled             from 'styled-components'
import PropTypes          from 'prop-types'
import { useTranslation } from 'react-i18next'

/*----- Antd -----*/
import { Button, Modal } from 'antd'

/*----- Views -----*/
import Table            from '../../../departments/views/list/Table'
import columns          from './DepartmentColumns'
import DepartmentsTable from '../departments_table/MainView'
import modal_columns    from '../departments_table/Columns'

const StyledTable = styled(Table)`
  margin-top: 5%;
`

const DepartmentsTab = (
  { 
    departments, toggleEditDepartmentsModal, editDepartmentsModalVisible, handleUpdateDepartments,
    setSelectedDepartments, selectedDepartments, otherDepartments, isLoading, getSubjectDepartments,
    subjectId, deleteSubjectDepartment
  }
) => {

    const { t } = useTranslation()
    
    return(
      <> 
        <Button
          type    = 'primary'
          onClick = { () => toggleEditDepartmentsModal(true) }
          icon    = 'plus'
        >
          { t('subjects.add_departments') }
        </Button>

        <Modal
          title    = { t('subjects.add_departments') }
          visible  = { editDepartmentsModalVisible }
          onOk     = { () => handleUpdateDepartments() }
          onCancel = { () => toggleEditDepartmentsModal(false) }
        >

          <DepartmentsTable
            otherDepartments      = { otherDepartments }
            columns               = { modal_columns(t) }
            onChangeHandler       = { setSelectedDepartments }
            selectedDepartments   = { selectedDepartments }
            getSubjectDepartments = { getSubjectDepartments }
            subjectId             = { subjectId }
            isLoading             = { isLoading }
          />

        </Modal>

        <StyledTable
          departments = { departments }
          columns     = { columns(t, deleteSubjectDepartment, subjectId) }
          isLoading   = { isLoading }
        />
      </>
    )
}

export default DepartmentsTab

DepartmentsTab.propTypes = {
  departments:                 PropTypes.array.isRequired,
  editDepartmentsModalVisible: PropTypes.bool.isRequired,
  handleUpdateDepartments:     PropTypes.func.isRequired,
  setSelectedDepartments:      PropTypes.func.isRequired,
  selectedDepartments:         PropTypes.array.isRequired,
  otherDepartments:            PropTypes.array.isRequired,
  isLoading:                   PropTypes.bool.isRequired,
  getSubjectDepartments:       PropTypes.func.isRequired,
  subjectId:                   PropTypes.string.isRequired,
  toggleEditDepartmentsModal:  PropTypes.func.isRequired,
  deleteSubjectDepartment:     PropTypes.func.isRequired
}