/*----- Saga -----*/
import { call, takeLatest, put } from 'redux-saga/effects'

/*----- Actions -----*/
import { DELETE_SUBJECT, DELETE_SUBJECT_ERROR, DELETE_SUBJECT_SUCCESS, GET_SUBJECTS } from '../SubjectsActions'

/*----- Endpoint -----*/
import { deleteSubject } from '../../../../api/endpoints/Subjects'

/*----- Notifications -----*/
import notifyError         from '../../../../components/commons/notifications/ErrorNotification'
import loadingNotification from '../../../../components/commons/notifications/LoadingNotification'
import successNotification from '../../../../components/commons/notifications/SuccessNotification'

export function* shootDeleteSubject(action) {
  yield loadingNotification()

  try {
    const response = yield call(deleteSubject, action)
    let type
    let deletionResponse

    if (response.status >= 200 && response.status < 300) {
      type = DELETE_SUBJECT_SUCCESS

      yield put( { type: GET_SUBJECTS } )
      yield successNotification()
    } else {
      deletionResponse = yield response.json()
      type             = DELETE_SUBJECT_ERROR

      yield notifyError(deletionResponse.error.message)
    }

    yield put(
      {
        type:    type,
        payload: deletionResponse
      }
    )
  }
  catch (error) {
    yield notifyError('¡Ha ocurrido un error inesperado!')
    yield put(
      {
        type:    DELETE_SUBJECT_ERROR,
        payload: error.message
      }
    )
  }
}

export default function* deleteSubjectWatcher() {
  yield takeLatest(DELETE_SUBJECT, shootDeleteSubject)
}
