/*----- Saga -----*/
import { call, takeLatest, put } from 'redux-saga/effects'

/*----- Actions -----*/
import { RESTORE_SUBJECT, RESTORE_SUBJECT_ERROR, RESTORE_SUBJECT_SUCCESS, GET_SUBJECTS } from '../SubjectsActions'

/*----- Endpoint -----*/
import { restoreSubject } from '../../../../api/endpoints/Subjects'

/*----- Notifications -----*/
import notifyError         from '../../../../components/commons/notifications/ErrorNotification'
import loadingNotification from '../../../../components/commons/notifications/LoadingNotification'
import successNotification from '../../../../components/commons/notifications/SuccessNotification'

export function* shootRestoreSubject(action) {
  yield loadingNotification()

  try {
    const response = yield call(restoreSubject, action)
    let type
    let restoreResponse

    if (response.status >= 200 && response.status < 300) {
      type = RESTORE_SUBJECT_SUCCESS

      yield put(
        { 
          type:    GET_SUBJECTS,
          payload: {
            blocked: true
          }
        } 
      )

      yield successNotification()
    } else {
      restoreResponse = yield response.json()
      type            = RESTORE_SUBJECT_ERROR

      yield notifyError(restoreResponse.error.message)
    }

    yield put(
      {
        type:    type,
        payload: restoreResponse
      }
    )
  }
  catch (error) {
    yield notifyError('¡Ha ocurrido un error inesperado!')
    yield put(
      {
        type:    RESTORE_SUBJECT_ERROR,
        payload: error.message
      }
    )
  }
}

export default function* restoreSubjectWatcher() {
  yield takeLatest(RESTORE_SUBJECT, shootRestoreSubject)
}
