/*----- Imports -----*/
import React              from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes          from 'prop-types'

/*----- Graphics grouped by category -----*/
import BarChart from './BarChart'
import Category from './Category'
import Comments from './Comments'

/*----- Filter -----*/
import Filters from '../Filters'

/*----- Antd -----*/
import { Skeleton, Result } from 'antd'

const Graphicator = ({
  form, graphicsResponse, submitFilterHandler, title, fields: Fields
}) => {
  const { t }           = useTranslation()
  const defaultSubtitle = [t('notifications.error')]

  return(
    <section id = 'main' className = 'wrapper'>
      <div className = 'container'>

        <header className = 'major special'>
          <h2>
            { title }
          </h2>
        </header>

        <Filters
          fields        = { Fields }
          form          = { form }
          submitHandler = { submitFilterHandler }
          isLoading     = { graphicsResponse.isLoading }
        />

        <Skeleton active = { true } loading = { graphicsResponse.isLoading } >
          {
            graphicsResponse.data.graphics.map(
              (graphic, index) => (
                <Category
                  { ...graphic }
                  chart   = { BarChart }
                  records = { graphicsResponse.data.records }
                  key     = { index }
                />
              )
            )
          }

          {
            graphicsResponse.data.comments.map(
              (comments, index) => (
                <Category
                  { ...comments }
                  chart   = { Comments }
                  records = { graphicsResponse.data.records }
                  key     = { index }
                />
              )
            )
          }

          {
            graphicsResponse.error &&
              <Result
                status   = 'error'
                title    = { graphicsResponse.error.error.message }
                subTitle = { (Object.values(graphicsResponse.error.error.fields) || defaultSubtitle)[0] }
              />
          }
        </Skeleton>

      </div>
    </section>
  )
}

export default Graphicator

/*----- PropTypes -----*/
Graphicator.propTypes = {
  form:                PropTypes.object.isRequired,
  graphicsResponse:    PropTypes.object.isRequired,
  submitFilterHandler: PropTypes.func.isRequired,
  title:               PropTypes.string.isRequired,
  fields:              PropTypes.elementType.isRequired
}
