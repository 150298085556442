/*----- Actions -----*/
import { GET_SUBJECT_COMMISSIONS, GET_SUBJECT_COMMISSIONS_SUCCESS, GET_SUBJECT_COMMISSIONS_ERROR } from '../CommissionActions'

const initialState = {
  isLoading:   false,
  commissions: [],
  error:       null
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_SUBJECT_COMMISSIONS:
      return {
        ...state,
        isLoading: true
      }
    case GET_SUBJECT_COMMISSIONS_SUCCESS:
      return {
        ...state,
        isLoading:   false,
        commissions: action.payload
      }
    case GET_SUBJECT_COMMISSIONS_ERROR:
      return {
        ...state,
        isLoading: false,
        error:     action.payload
      }
    default:
      return state
  }
}