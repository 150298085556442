/*----- Imports -----*/
import React              from 'react'
import styled             from 'styled-components'
import PropTypes          from 'prop-types'
import { useTranslation } from 'react-i18next'

/*----- Components -----*/
import FormBox    from '../../../../../components/commons/form/FormBox'
import ImportForm from './ImportForm'

const MainContainer = styled.section`
  padding-top:    10%;
  padding-bottom: 10%;
  .form-box__data-container {
    width: 60%;
  }
`

const MainView = ( { handleSubmit, surveys, form } ) => {
  const { t } = useTranslation()

  return(
    <MainContainer>

      <FormBox title = { t('admin.menubar.import_results') } >
        <ImportForm
          handleSubmit = { handleSubmit }
          surveys      = { surveys }
          form         = { form }
        />
      </FormBox>
      
    </MainContainer>
  )
}

export default MainView

/*----- PropTypes -----*/
MainView.propTypes = {
  form:         PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  surveys:      PropTypes.array.isRequired
}