/*----- Imports -----*/
import React, { useState } from 'react'

/*----- Recharts -----*/
import { BarChart, Bar, ResponsiveContainer } from 'recharts'

const initialData = [
  {
    name: 'Opción 1', value: Math.floor(Math.random() * 100)
  },
  {
    name: 'Opción 2', value: Math.floor(Math.random() * 100)
  },
  {
    name: 'Opción 3', value: Math.floor(Math.random() * 100)
  },
  {
    name: 'Opción 4', value: Math.floor(Math.random() * 100)
  },
  {
    name: 'Opción 5', value: Math.floor(Math.random() * 100)
  }
]

export default () => {
  const [data, setData]   = useState(initialData)
  const [timer, setTimer] = useState()

  const letsGetCrazy = () => {
    const updated_data = data.map(datum => {
      datum.value = Math.floor(Math.random() * 100)

      return datum
    })

    setTimer(setTimeout(letsGetCrazy, 500))
    setData(updated_data)
  }

  return(
    <ResponsiveContainer width = '100%' height = { 300 } >
      <BarChart
        data         = { data }
        onMouseEnter = { () => letsGetCrazy() }
        onMouseLeave = { () => clearInterval(timer) }
      >

        <Bar dataKey = 'value' fill = '#82ca9d' />
      </BarChart>
    </ResponsiveContainer>
  )
}
