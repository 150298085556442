export const USER_RESOURCE                = 'users'
export const CURRENT_USER_RESOURCE        = 'users/current'
export const LOGIN_RESOURCE               = 'login'
export const REFRESH_RESOURCE             = 'login/refresh'
export const CHANGE_PASSWORD              = 'change_password'
export const LOGOUT_RESOURCE              = 'logout'
export const ACTIVATE_RESOURCE            = 'activate'
export const DEPARTMENT_RESOURCE          = 'departments'
export const SUBJECT_RESOURCE             = 'subjects'
export const COMMISSION_RESOURCE          = 'commissions'
export const ROLE_RESOURCE                = 'roles'
export const SURVEY_RESOURCE              = 'surveys'
export const EXPORT_RESOURCE              = 'export_survey'
export const QUESTION_CATEGORIES_RESOURCE = 'question_categories'
export const IMPORT_RESOURCE              = 'import_survey'
export const SURVEY_IMPORTS_RESOURCE      = 'survey_imports'
