/*----- Imports -----*/
import React              from 'react'
import { useTranslation } from 'react-i18next'
import styled             from 'styled-components'

/*----- Antd -----*/
import { Alert, Icon, Typography } from "antd"

/*----- Constants -----*/
import { ENVIRONMENT } from '../../../utils/Constants'

const CenteredContainer = styled.div`
  text-align: center;
  display:    block;
  width:      80%;
  margin-top: 10%;
`

const StyledIcon = styled(Icon)`
font-size: 150px;
color:     #002140!important;
`

const ContactContainer = styled.div`
  margin-left: 25%;
`

const { Title } = Typography

const Home = () => {
  const { t } = useTranslation();

  return (
    <CenteredContainer>
      {
        (ENVIRONMENT === 'staging') && (
          <Alert
            message = { t('environment_message') }
            type    = 'info'
            style   = {{ textAlign: 'center', marginBottom: '5%', marginLeft: '20%' }}
          />
        )
      }
      <ContactContainer>
        <StyledIcon type = 'smile'/>
        <Title 
          level = { 2 } 
          style = {{ marginTop: '5%' }}
        >
          { t('admin.home.welcome') }
        </Title>
        <Title level = { 4 }>
          { t('admin.home.contact_text') }
          <a 
            href  = "mailto:encuestas@exactas.unlp.edu.ar"
            style = {{ marginLeft: '5px' }}
          >
            encuestas@exactas.unlp.edu.ar
          </a>.
        </Title>
      </ContactContainer>
    </CenteredContainer>
  )
}

Home.defaultProps = {
  permission:   'visit',
  model:        'Home',
  defaultRoute: '/login'
}

export default Home