/*----- Saga -----*/
import { call, takeLatest, put } from 'redux-saga/effects'

/*----- Actions -----*/
import {
  EDIT_DEPARTMENT,
  EDIT_DEPARTMENT_SUCCESS,
  EDIT_DEPARTMENT_ERROR,
  DEPARTMENT_DETAILS,
  DEPARTMENT_USERS,
  USERS_NOT_IN_DEPARTMENT,
  TOGGLE_EDITION_MODAL,
  TOGGLE_EDIT_USERS_MODAL
} from '../DepartmentsActions'

/*----- Endpoint -----*/
import { editDepartment } from '../../../../api/endpoints/Departments'

/*----- Notifications -----*/
import loadingNotification from '../../../../components/commons/notifications/LoadingNotification'
import successNotification from '../../../../components/commons/notifications/SuccessNotification'
import notifyError         from '../../../../components/commons/notifications/ErrorNotification'

export function* shootEditDepartment(action) {
  yield loadingNotification()
  try {
    const response = yield call(editDepartment, action)
    const body     = yield response.json()
    let type

    if (response.status >= 200 && response.status < 300) {
      type = EDIT_DEPARTMENT_SUCCESS

      // Refrescamos los datos del departamento.
      yield put(
        {
          type:    DEPARTMENT_DETAILS,
          payload: action.payload.departmentId
        }
      )

      yield put(
        {
          type:    DEPARTMENT_USERS,
          payload: {
            departmentId: action.payload.departmentId
          }
        }
      )

      // Cerramos los modales de edición
      yield put(
        {
          type:    TOGGLE_EDITION_MODAL,
          payload: false
        }
      )
      yield put(
        {
          type:    TOGGLE_EDIT_USERS_MODAL,
          payload: false
        }
      )

      // Traer de nuevo los usuarios que no están en el departamento.
      yield put(
        {
          type:    USERS_NOT_IN_DEPARTMENT,
          payload: {
            departmentId: action.payload.departmentId,
            params:       {
              not_included_users: true
            }
          } 
        }
      )

      yield successNotification()
    }
    else {
      type = EDIT_DEPARTMENT_ERROR

      yield notifyError(body.error.message)
    }

    yield put(
      {
        type:    type,
        payload: body
      }
    )
  }
  catch (error) {
    yield notifyError('¡Ha ocurrido un error inesperado!')
    yield put(
      {
        type:    EDIT_DEPARTMENT_ERROR,
        payload: error.message
      }
    )
  }
}

export default function* CreateWatcher() {
  yield takeLatest(EDIT_DEPARTMENT, shootEditDepartment)
}
