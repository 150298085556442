/*----- Imports -----*/
import React              from 'react'
import styled             from 'styled-components'
import PropTypes          from 'prop-types'
import { useTranslation } from 'react-i18next'

/*----- Antd -----*/
import { Form, Icon, Button } from 'antd'

/*----- Fields -----*/
import Fields from './TeachingStaffFields'

/*----- Views -----*/
import Card from './Card'

const CenteredContainer = styled.div`
  margin-top: 5%;
  text-align: center;
  display:    block;
`

const TeachingStaffForm = (
  {
    form, isLoading, submitHandler, role, keys, addTeachingStaffFields,
    usersList, deleteTeachingStaffField
  }
) => {
  const { t } = useTranslation()

  const AddFieldsButton = <Button
    type    = 'primary'
    onClick = { addTeachingStaffFields }
  >
    <Icon type = 'plus' style = {{ color: 'rgba(0, 0, 0, .25)' }} />
    { t('global.add') }
  </Button>

  return(
    <Card title = { `${t('global.add')} ${role.name}` } extra = { AddFieldsButton }>

      <Form
        onSubmit = { (event) => submitHandler(event, role) }
        layout   = { 'horizontal' }
      >
        {
          keys.map((element, index) => (
            <Card
              key   = { index }
              title = { `${role.name} ${index + 1}` }
              type  = 'inner'
              extra = {
                <Icon
                  type    = 'delete'
                  style   = {{ color:'red' }}
                  onClick = { () => deleteTeachingStaffField(element) }
                />
              }
            >

              <Fields
                form                     = { form }
                role                     = { role }
                isLoading                = { isLoading }
                index                    = { element }
                usersList                = { usersList }
                deleteTeachingStaffField = { deleteTeachingStaffField }
              />

            </Card>
          ))
        }

        <CenteredContainer>
          <Button
            type     = 'primary'
            htmlType = 'submit'
            style    = {{ justifyContent: 'center' }}
            disabled = { isLoading || keys.length < 1 }
            size     = 'large'
          >
            { t('global.submit') }
          </Button>
        </CenteredContainer>

      </Form>

    </Card>
  )
}

export default TeachingStaffForm

/*----- PropTypes -----*/
TeachingStaffForm.propTypes = {
  form:                     PropTypes.object.isRequired,
  isLoading:                PropTypes.bool.isRequired,
  submitHandler:            PropTypes.func.isRequired,
  role:                     PropTypes.object.isRequired,
  keys:                     PropTypes.array.isRequired,
  addTeachingStaffFields:   PropTypes.func.isRequired,
  usersList:                PropTypes.array.isRequired,
  deleteTeachingStaffField: PropTypes.func.isRequired
}

