/*----- Saga -----*/
import { call, takeLatest, put } from 'redux-saga/effects'
import { push }                  from 'connected-react-router'

/*----- Actions -----*/
import {
  DEPARTMENT_DETAILS, DEPARTMENT_DETAILS_ERROR, DEPARTMENT_DETAILS_SUCCESS
} from '../DepartmentsActions'

/*----- Endpoint -----*/
import { getDepartmentDetails } from '../../../../api/endpoints/Departments'

/*----- Notifications -----*/
import notifyError from '../../../../components/commons/notifications/ErrorNotification'

export function* shootGetDepartments(action) {
  try {
    const response = yield call(getDepartmentDetails, action)
    const body     = yield response.json()
    let type

    if (response.status >= 200 && response.status < 300) {
      type = DEPARTMENT_DETAILS_SUCCESS
    } else {
      type = DEPARTMENT_DETAILS_ERROR
      yield notifyError(body.error.message)
      yield put(push('/admin/departments'))
    }

    yield put(
      {
        type:    type,
        payload: body
      }
    )
  }
  catch (error) {
    yield notifyError('¡Ha ocurrido un error inesperado!')
    yield put(
      {
        type:    DEPARTMENT_DETAILS_ERROR,
        payload: error.message
      }
    )
  }
}

export default function* () {
  yield takeLatest(DEPARTMENT_DETAILS, shootGetDepartments)
}
