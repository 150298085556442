export const editDepartmentFieldsSelector = (state) => {
  return state.editDepartment.fields
}

export const editionModalVisibilitySelector = (state) => {
  return state.editDepartment.modalVisible
}

export const editUsersModalVisibilitySelector = (state) => {
  return state.editDepartment.usersModalVisible
}

export const loadingEditionSelector = (state) => {
  return state.editDepartment.isLoading
}

export const usersNotInDepartmentSelector = (state) => {
  return state.usersNotInDepartment.users
}

export const loadingUsersNotInDepartmentSelector = (state) => {
  return state.usersNotInDepartment.isLoading
}
