/*----- Imports -----*/
import { call, takeLatest, put } from 'redux-saga/effects'

/*----- Actions -----*/
import {
  DELETE_IMPORT,
  DELETE_IMPORT_SUCCESS,
  DELETE_IMPORT_ERROR,
  GET_IMPORTS
} from '../SurveysActions'

/*----- Notifications -----*/
import loadingNotification from '../../../../components/commons/notifications/LoadingNotification'
import successNotification from '../../../../components/commons/notifications/SuccessNotification'
import notifyError         from '../../../../components/commons/notifications/ErrorNotification'

/*----- Endpoints -----*/
import { deleteImport } from '../../../../api/endpoints/Surveys'

export function* shootDeletion(action) {
  yield loadingNotification()

  try {
    const response = yield call(deleteImport, action)
    let deletionResponse
    let type

    if (response.status >= 200 && response.status < 300) {
      type = DELETE_IMPORT_SUCCESS

      yield put({ type: GET_IMPORTS })
      yield successNotification()
    }
    else {
      deletionResponse = yield response.json()
      type             = DELETE_IMPORT_ERROR

      yield notifyError(deletionResponse.error.message)
    }

    yield put(
      {
        type:    type,
        payload: deletionResponse
      }
    )
  }
  catch (error) {
    yield notifyError('¡Ha ocurrido un error inesperado!')
    yield put(
      {
        type:    DELETE_IMPORT_ERROR,
        payload: error.message
      }
    )
  }
}

export default function* () {
  yield takeLatest(DELETE_IMPORT, shootDeletion)
}
