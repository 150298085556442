/*----- Actions -----*/
import {
  GET_AVAILABLE_USERS,
  GET_AVAILABLE_USERS_SUCCESS,
  GET_AVAILABLE_USERS_ERROR
} from '../CommissionActions'

const initialState = {
  isLoading: false,
  users:     [],
  error:     null
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_AVAILABLE_USERS:
      return {
        ...state,
        isLoading: true
      }
    case GET_AVAILABLE_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        users:     action.payload
      }
    case GET_AVAILABLE_USERS_ERROR:
      return {
        ...state,
        isLoading: false,
        error:     action.payload
      }
    default:
      return state
  }
}