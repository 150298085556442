/*----- Imports -----*/
import styled from 'styled-components'

/*----- Antd -----*/
import { Button } from 'antd'

const ActionButton = styled(Button)`
  background: none!important;
  border:     none!important;
  box-shadow: none!important;
`

export default ActionButton