/*----- Imports -----*/
import React, { useEffect } from 'react'
import { connect }          from 'react-redux'
import styled               from 'styled-components'
import { useTranslation }   from 'react-i18next';

/*----- Antd -----*/
import { Form, Icon, Input, Button, Typography, Divider } from 'antd';

/*----- Actons -----*/
import { editUserPassword, setChangePasswordFormFields } from '../UserActions'

/*----- Selector -----*/
import { loadingSelector, changePasswordFormSelector } from '../selectors/changePasswordSelector'
import { currentUserSelector }                         from '../../../admin/CurrentUserSelector'

const { Item }  = Form
const { Title } = Typography

const StyledContainer = styled.div`
  padding-bottom:  5%;
  form {
    display:         block;
    justify-content: center;
  }
`

const CenteredContainer = styled.div`
  text-align: center;
`

const RESET_FIELDS_STATE = {
  old_password: {},
  new_password: {},
  new_password_repeat: {}
}

const hasErrors = (fieldsError) => {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

const ChangePasswordForm = ({ form, isLoading, editUserPassword, setChangePasswordFormFields, changePasswordForm, currentUser }) => {
  const { t } = useTranslation();
  const { getFieldDecorator } = form

  const requiredValidator = {
    required: true,
    message:  t('rules.mandatory_field'),
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    // Forcing all fields validation, even if they had errors.
    form.validateFields({ force: true })
  
    if (!hasErrors(form.getFieldsError())) {
      editUserPassword(form.getFieldsValue(), currentUser.id)
    }
  }  
  
  const compareToFirstPassword = (_rule, value, callback) => {
    if (value && value !== changePasswordForm.new_password.value) {
      callback(t('rules.passwords_must_be_equal'))
    } else {
      callback()
    }
  }

  const validateToNextPassword = (_rule, value, callback) => {
    if (value && changePasswordForm.new_password_repeat.dirty) {
      changePasswordForm.validateFields(['new_password_repeat'], { force: true })
    }
    callback()
  }

  useEffect(() => {
    return () => {
      setChangePasswordFormFields(RESET_FIELDS_STATE)
    };
  }, [setChangePasswordFormFields])


  return (
    <StyledContainer>
      <CenteredContainer>
        <Title level = { 2 }>
          { t('admin.edit_user.change_password') }
        </Title>
      </CenteredContainer>

      <Form
        onSubmit         = { (event) => { handleSubmit(event, form, editUserPassword) } }
        hideRequiredMark = { true }
      >

        <Item label = { t('user.current_password') } >
          {
            getFieldDecorator(
              'old_password',
              {
                rules: [
                  requiredValidator,
                ],
                validateTrigger: ['onBlur', 'onSubmit']
              }
            )(
              <Input.Password
                prefix      = { <Icon type='lock' style = {{ color: 'rgba(0,0,0,.25)' }} /> }
                disabled    = { isLoading } 
                placeholder = { t('user.current_password') }
              />
            )
          }
        </Item>

        <Divider/>

        <Item label = { t('user.new_password') }>
          {
            getFieldDecorator(
              'new_password',
              {
                rules: [
                  requiredValidator,
                  {
                    validator: (validateToNextPassword),
                  },
                  {
                    min:     6,
                    message: t('rules.valid_password')
                  }
                ],
                validateTrigger: ['onBlur', 'onSubmit']
              }
            )(
              <Input.Password
                prefix      = { <Icon type = 'lock' style = {{ color: 'rgba(0,0,0,.25)' }} /> }
                disabled    = { isLoading }
                placeholder = { t('user.new_password') }
              />
            )
          }
        </Item>

        <Item label = { t('user.new_password_repeat') }>
          {
            getFieldDecorator(
              'new_password_repeat',
              {
                rules: [
                  requiredValidator,
                  {
                    validator: (compareToFirstPassword),
                  },
                  {
                    min:     6,
                    message: t('rules.valid_password')
                  }
                ],
                validateTrigger: ['onBlur', 'onSubmit']
              }
            )(
              <Input.Password
                prefix      = { <Icon type = 'lock' style = {{ color: 'rgba(0,0,0,.25)' }} /> }
                disabled    = { isLoading }
                placeholder = { t('user.new_password_repeat') }
              />
            )
          }
        </Item>

        <CenteredContainer>
          <Button
            type     = 'primary'
            htmlType = 'submit'
            shape    = 'round'
            size     = 'large'
          >
            { t('global.submit') }
          </Button>
        </CenteredContainer>
      </Form>
    </StyledContainer>
  )
}

function mapStateToProps(state) {
  return {
    isLoading:          loadingSelector(state),
    changePasswordForm: changePasswordFormSelector(state),
    currentUser:        currentUserSelector(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    editUserPassword:   (payload, userId) => editUserPassword(dispatch, payload, userId),
    setChangePasswordFormFields: (params) => setChangePasswordFormFields(dispatch, params)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create({
    name: 'change_password_form',
    onFieldsChange({ setChangePasswordFormFields }, changedFields) {
      setChangePasswordFormFields(changedFields);
    },
    mapPropsToFields(props) {
      return {
        new_password: Form.createFormField({
          ...props.changePasswordForm.new_password,
          value: props.changePasswordForm.new_password.value,
        }),
        old_password: Form.createFormField({
          ...props.changePasswordForm.old_password,
          value: props.changePasswordForm.old_password.value,
        }),
        new_password_repeat: Form.createFormField({
          ...props.changePasswordForm.new_password_repeat,
          value: props.changePasswordForm.new_password_repeat.value,
        })
      }
    }
  })(ChangePasswordForm)
)
