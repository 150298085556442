/*----- Imports -----*/
import React, { useEffect } from 'react'
import { useTranslation }   from 'react-i18next'
import { connect }          from 'react-redux'
import { withRouter }       from 'react-router'
import styled               from 'styled-components'

/*----- Antd -----*/
import { Card, Row, Col, Descriptions, Avatar } from 'antd'
import UserDetailsButtons                       from './UserDetailsButtons'

/*----- Selectors -----*/
import { loadingDetailsSelector, userDetailsSelector } from './selectors/userDetailsSelector'

/*----- Actions -----*/
import { getUserDetailsWithDepartments } from './UserActions'
import { deleteUser }                    from './UserActions'

/*----- API -----*/
import BASE_URL from '../../../api/BaseUrl'

const StyledImage = styled.img`
  vertical-align: middle;
  width:          100px;
  height:         100px;
`

const DescriptionsTitle = (userInfo) => {
  return `${userInfo.first_name} ${userInfo.last_name}`
}

const { Item } = Descriptions

const UserDetails = ( { isLoading, userInfo, getUserDetailsWithDepartments, match, deleteUser } ) => {
  const { t }  = useTranslation();
  const userId = match.params.id

  const profilePicture = () => {
    if (userInfo.profile_picture) {
      return <StyledImage src = { BASE_URL + userInfo.profile_picture} />
    }
    else {
      return <Avatar shape = 'square' size = {100} icon = 'user' />
    }
  }

  useEffect(() => {
    getUserDetailsWithDepartments(userId)
  }, [getUserDetailsWithDepartments, userId])

  return(
    <>
      <div style = {{ paddingTop: '5%' }} >
        <Row>
          <Col offset = {2} span = {20}>
            <Card
              title    = { t('admin.user_details.title') }
              bordered = { false }
              extra    = { <UserDetailsButtons deleteUser = { deleteUser } userId = { userId } /> }
              loading  = { isLoading }
            >

              <Descriptions
                title = { DescriptionsTitle(userInfo) }
                layout = 'vertical'
                column = {{ xs: 1, md: 2 }}
              >
                <Item label = { t('user.name')} span = {1}>
                  { userInfo.first_name }
                </Item>

                <Item label = { t('user.surname')} span = {1}>
                  { userInfo.last_name }
                </Item>

                <Item label = 'DNI' span = {1}>
                  { userInfo.dni }
                </Item>

                <Item label = { t('user.email') } span = {1}>
                  { userInfo.email }
                </Item>

                <Item label = { t('user.image') } span = {3}>
                  { profilePicture() }
                </Item>

                <Item label = { t('user.departments') } span = {3}>
                  { ((userInfo.departments) || []).join(', ') }
                </Item>

              </Descriptions>

            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

function mapStateToProps(state) {
  return {
    isLoading: loadingDetailsSelector(state),
    userInfo:  userDetailsSelector(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getUserDetailsWithDepartments: (user_id) => getUserDetailsWithDepartments(dispatch, user_id),
    deleteUser:                    (user_id) => deleteUser(dispatch, user_id)
  }
}

const ConnectedUserDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserDetails))

ConnectedUserDetails.defaultProps = {
  permission:   'show',
  model:        'User',
  defaultRoute: '/admin'
}

export default ConnectedUserDetails
