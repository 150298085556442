/*----- Imports -----*/
import React              from 'react'
import PropTypes          from 'prop-types'
import { useTranslation } from 'react-i18next'

/*----- Antd -----*/
import { Form, Input, Select } from 'antd'

/*----- Options -----*/
import { requiredSelectOptions, staffPositionNumberOptions } from './Options'

const { Item }   = Form
const { Option } = Select

const Fields = (
  {
    form, isLoading, role, index, usersList
  }
) => {
  const { t }                 = useTranslation()
  const { getFieldDecorator } = form

  const usersSelectOptions = usersList.map((record) => (
    <Option key = { record.id } >
      { `${record.first_name} ${record.last_name} (${record.dni})`  }
    </Option>
  ))

  return(
    <>
      <Item>
        {
          getFieldDecorator(`teaching_staff[${index}].user`, requiredSelectOptions(t))(
            <Select 
              placeholder = { t('commission.teaching_staff.user') }
              showSearch   = { true }
              disabled     = { isLoading }
              allowClear   = { true }
              filterOption = {(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              { usersSelectOptions }
            </Select>
          )
        }
      </Item>

      <Item>
        {
          getFieldDecorator(`teaching_staff[${index}].workload`, requiredSelectOptions(t))(
            <Select
              placeholder = { t('commission.teaching_staff.workload') }
              disabled    = { isLoading }
            >
              <Option value = 'SIMPLE' >
                SIMPLE
              </Option>

              <Option value = 'MEDIA'>
                MEDIA
              </Option>
            </Select>
          )
        }
      </Item>

      <Item>
        {
          getFieldDecorator(`teaching_staff[${index}].position_number`, staffPositionNumberOptions(t))(
            <Input
              placeholder = { t('commission.teaching_staff.position_number') }
              disabled    = { isLoading }
            />
          )
        }
      </Item>


      {
        role.include_dedication &&
          <Item>
            {
              getFieldDecorator(`teaching_staff[${index}].dedication`, requiredSelectOptions(t))(
                <Select
                  placeholder = { t('commission.teaching_staff.dedication') }
                  disabled    = { isLoading }
                >
                  <Option value = 'EXCLUSIVA' >
                    EXCLUSIVA
                  </Option>

                  <Option value = 'SEMI'>
                    SEMI
                  </Option>

                  <Option value = 'SIMPLE'>
                    SIMPLE
                  </Option>
                </Select>
              )
            }
          </Item>
      }

      {
        role.include_category &&
          <Item>
            {
              getFieldDecorator(`teaching_staff[${index}].category`, requiredSelectOptions(t))(
                <Select
                  placeholder = { t('commission.teaching_staff.category') }
                  disabled    = { isLoading }
                >
                  <Option value = 'ASOCIADO' >
                    ASOCIADO
                  </Option>

                  <Option value = 'ADJUNTO'>
                    ADJUNTO
                  </Option>

                  <Option value = 'TITULAR'>
                    TITULAR
                  </Option>

                  <Option value = 'JTP'>
                    JTP
                  </Option>
                </Select>
              )
            }
          </Item>
      }
    </>
  )
}

export default Fields

/*----- PropTypes -----*/
Fields.propTypes = {
  form:      PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  role:      PropTypes.object.isRequired,
  index:     PropTypes.number.isRequired,
  usersList: PropTypes.array.isRequired
}

