export const requiredFieldOptions = (t) => (
  {
    rules: [
      {
        required: true,
        message:  t('rules.mandatory_field'),
      }
    ],
    validateTrigger: ['onSubmit', 'onBlur']
  }
)

export const requiredSelectOptions = (t) => (
  {
    rules: [
      {
        required: true,
        message:  t('rules.mandatory_field'),
      }
    ],
    validateTrigger: ['onChange']
  }
)

export const staffPositionNumberOptions = (t) => (
  {
    rules: [
      {
        required: true,
        message:  t('rules.mandatory_field'),
      },
      {
        max:     8,
        message: t('rules.max_length_exceeded', { max_length: '8' })
      }
    ],
    validateTrigger: ['onSubmit', 'onBlur']
  }
)
