/*----- Imports -----*/
import React              from 'react'
import styled             from 'styled-components'
import PropTypes          from 'prop-types'
import { useTranslation } from 'react-i18next'

/*----- Views -----*/
import Table   from './Table'
import Filters from './Filters'
import columns from './Columns'

const StyledTable = styled(Table)`
  margin-top:  5%;
  margin-left: 10%;
  width:       80%;
  display:     inline-block;
`

const MainView = (
  {
    searchHandler, onChangeHandler, cleanButtonHandler,
    activateButtonHandler, users, isLoading, inputRef, deleteUser, isAdmin
  }
) => {

  const { t }  = useTranslation()

  return(
    <div>
      <Filters
        inputRef           = { inputRef }
        onSearchHandler    = { searchHandler }
        onChangeHandler    = { onChangeHandler }
        cleanButtonHandler = { cleanButtonHandler }
      />

      <StyledTable
        users     = { users }
        isLoading = { isLoading }
        columns   = { columns(t, isAdmin, deleteUser, activateButtonHandler) }
      />
    </div>
  )
}

export default MainView

/*----- PropTypes -----*/
MainView.propTypes = {
  searchHandler: PropTypes.func.isRequired,
  users:         PropTypes.array.isRequired,
  isLoading:     PropTypes.bool.isRequired
}
