/*----- Actions -----*/
import {
  RESTORE_DEPARTMENT, RESTORE_DEPARTMENT_SUCCESS, RESTORE_DEPARTMENT_ERROR
} from '../DepartmentsActions'

const initialState = {
  isLoading: false,
  error:     null
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case RESTORE_DEPARTMENT:
      return {
        ...state,
        isLoading: true
      }
    case RESTORE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        isLoading: false
      }
    case RESTORE_DEPARTMENT_ERROR:
      return {
        ...state,
        isLoading: false,
        error:     action.payload
      }
    default:
      return state;
  }
}