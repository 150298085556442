export const LOGOUT         = 'LOGOUT'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAIL    = 'LOGOUT_FAIL'

export const logoutUser = (dispatch, param) => {
    dispatch(
      {
        type:      LOGOUT,
        payload:   param
      }
    )
}