
/*----- Actions -----*/
  import {
  CREATE_USER, CREATE_USER_SUCCESS, CREATE_USER_ERROR, SET_REGISTER_FORM_FIELDS
  } from "../UserActions"

/*----- Utils -----*/
import { serviceErrorsToFormFields } from '../../../../utils/ResponseFormatter' 

const initialState = {
  isLoading: false,
  success:   {},
  error:     {
    message: '',
    fields:  []
  },
  fields: {
    email:           {},
    dni:             {},
    first_name:      {},
    last_name:       {},
    profile_picture: {},
    admin:           {}
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_USER:
      return {
        ...state,
        isLoading: true
      }
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success:   action.payload,
        fields:    initialState.fields
      }
    case CREATE_USER_ERROR:
      return {
        ...state,
        isLoading: false,
        error:     action.payload,
        fields:    serviceErrorsToFormFields(state.fields, action.payload.error.fields)
      }
    case SET_REGISTER_FORM_FIELDS:
      return {
        ...state,
        fields: {
          ...state.fields,
          ...action.payload
        }
      }
    default:
      return state;
  }
}
