/*----- Imports -----*/
import React, { useEffect, useState } from 'react'
import { Redirect }                   from 'react-router'
import { useTranslation }             from 'react-i18next'
import { withRouter }                 from 'react-router'
import { connect }                    from 'react-redux'
import styled                         from 'styled-components'
import { isMobile }                   from 'react-device-detect'

/*----- Antd -----*/
import { Row, Col, Tabs, Icon, Alert } from 'antd'

/*----- Selectors -----*/
import { loadingDetailsSelector, userDetailsSelector } from './selectors/userDetailsSelector'

/*----- Actions -----*/
import { getUserDetails } from './UserActions'

/*----- Tabs -----*/
import GeneralDataTab          from './editProfileTabs/GeneralDataTab'
import ChangePasswordForm      from './editProfileTabs/ChangePasswordForm'
import { currentUserSelector } from '../CurrentUserSelector'

const { TabPane } = Tabs

const StyledContainer = styled.div`
  padding-top: 5%;
  width:       90%;
  .ant-tabs-tab-active {
    span {
      font-weight: bold;
      width:       100%;
    };
  };
`

const AlertContainer = styled.div`
  margin-left:   30%;
  margin-bottom: 5%;
`

const StyledAlert = styled(Alert)`
  width: 50%;
`

const EditProfile = ( { isLoading, userInfo, getUserDetails, match, currentUser } ) => {

  const { t }                     = useTranslation()
  const user_id                   = match.params.id
  const [activeKey, setActiveKey] = useState('#change_password')

  useEffect(() => {
    getUserDetails(user_id)
  }, [getUserDetails, user_id])

  useEffect(() => {
    if ((currentUser.modify_password && !currentUser.first_login) || (!currentUser.is_staff)) {
      setActiveKey('#change_password')
    } else {
      setActiveKey('#general_data')
    }
  }, [setActiveKey, currentUser])

  if (user_id !== String(currentUser.id) && !currentUser.is_staff) {
    return(<Redirect to = '/admin' />)
  }

  const changeTab = (activeTab) => {
    setActiveKey(activeTab)
  }

  return(
    <StyledContainer>
      {
        (currentUser.first_login && currentUser.is_staff &&
        <AlertContainer>
          <StyledAlert message = { t('rules.complete_data') } type = 'info' />
        </AlertContainer>) ||
        (currentUser.modify_password && 
        <AlertContainer>
          <StyledAlert message = { t('rules.modify_password') } type = 'info' />
        </AlertContainer>)
      }
      <Row>
        <Col offset = {2} span = {20}>

          <Tabs
            activeKey   = { activeKey }
            tabPosition = { (isMobile && 'top') || 'left' }
            onChange    = { changeTab }
          >

            { 
              currentUser.is_staff &&
                <TabPane
                  tab = {
                    <span>
                      <Icon type = 'setting' />
                      { t('admin.edit_user.general_data') }
                    </span>
                  }
                  key = '#general_data'
                >
                  <GeneralDataTab userInfo = { userInfo } loadingUserInfo = { isLoading } />
                </TabPane>
            }

            {
              (currentUser.id === parseInt(user_id)) &&
                <TabPane
                  tab = {
                    <span>
                      <Icon type = 'key' />
                      { t('admin.edit_user.change_password') }
                    </span>
                  }
                  key = '#change_password'
                >
                  <ChangePasswordForm/>
                </TabPane>
            }

          </Tabs>

        </Col>
      </Row>
    </StyledContainer>
  )
}

function mapStateToProps(state) {
  return {
    isLoading:   loadingDetailsSelector(state),
    userInfo:    userDetailsSelector(state),
    currentUser: currentUserSelector(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getUserDetails: (user_id) => getUserDetails(dispatch, user_id)
  }
}

const ConnectedEditProfile = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EditProfile))

ConnectedEditProfile.defaultProps = {
  permission:   'edit',
  model:        'User',
  defaultRoute: '/admin'
}

export default ConnectedEditProfile
