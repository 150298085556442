/*----- Imports -----*/
import React     from 'react'
import PropTypes from 'prop-types'

/*----- Antd -----*/
import { Card } from 'antd'

const DetailsCard = ( { title, children, className, extra, isLoading = false, type } ) => {

  return(
    <Card
      title     = { title }
      type      = { type }
      bordered  = { false }
      extra     = { extra }
      loading   = { isLoading }
      className = { className }
    >
      { children }
    </Card>
  )
}

export default DetailsCard

/*----- PropTypes -----*/
DetailsCard.propTypes = {
  title:     PropTypes.any.isRequired,
  children:  PropTypes.node.isRequired,
  className: PropTypes.string,
  extra:     PropTypes.node,
  isLoading: PropTypes.bool,
  type:      PropTypes.string
}
