/*----- Imports -----*/
import React              from 'react'
import styled             from 'styled-components'
import { useTranslation } from 'react-i18next'
import PropTypes          from 'prop-types'

/*----- Components -----*/
import SearchInput from '../../../../../components/commons/input/SearchInput'

/*----- Antd -----*/
import { Button, Select } from 'antd'

const StyledSelect = styled(Select)`
  margin-top:  5%!important;
  margin-left: 1%!important;
`

const StyledSearchInput = styled(SearchInput)`
  width:         30%!important;
  padding-top:   5%;
  margin-bottom: -3%;
  margin-left:   10%;
`

const { Option } = Select

const Filters = ( { inputRef, onSearchHandler, onChangeHandler, cleanButtonHandler } ) => {

  const { t }  = useTranslation()

  return(
    <div>
      <StyledSearchInput
        ref             = { inputRef } 
        placeholder     = { t('global.search_placeholder') }
        onSearchHandler = { onSearchHandler }
      />

      <span style={{ marginLeft:'3%' }}>{ t('global.status') }: </span>

      <StyledSelect
        defaultValue  = 'false' 
        style         = {{ width: 120 }}
        onChange      = { onChangeHandler }>
          <Option value = 'false'>{ t('global.active') }</Option>
          <Option value = 'true'>{ t('global.blocked') }</Option>
      </StyledSelect>

      <Button 
        style   = {{ marginLeft: '3%'}} 
        type    = 'primary' 
        size    = { 'default' }
        onClick = { cleanButtonHandler }>
      { t('global.reset_filters') }
      </Button>
    </div>
  )
}

export default Filters

/*----- PropTypes -----*/
Filters.propTypes = {
  onSearchHandler:    PropTypes.func.isRequired,
  onChangeHandler:    PropTypes.func.isRequired,
  cleanButtonHandler: PropTypes.func.isRequired
}
