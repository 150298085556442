/*----- Imports -----*/
import React, { useState, useMemo, useCallback } from 'react'
import PropTypes                                 from 'prop-types'
import { useTranslation }                        from 'react-i18next'
import { connect }                               from 'react-redux'

/*----- Antd -----*/
import { Select, Form, Row, Col } from 'antd'

/*----- Selectors -----*/
import { subjectsListSelector, subjectsLoadingSelector } from '../selectors/Subjects'
import {
  commissionsBySubjectSelector,
  subjectCommissionsLoadingSelector
} from '../selectors/SubjectCommissions'

/*----- Actions -----*/
import { getSubjectComissions } from '../Actions'

/*----- Utils -----*/
import mapRecordsToOptions from '../../../utils/SelectOptionsMapper'

/*----- Validations -----*/
import { requiredFieldOptions } from '../Validations'

/*----- Components -----*/
import PeriodsSelect from '../../../components/commons/form/PeriodsSelect'
import YearPicker    from '../../../components/commons/form/YearPicker'

const { Item } = Form

const filterOption = (input, option) => (
  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
)

const FilterFields = ({
  form, index, subjects, isLoadingSubjects,
  getSubjectComissions, commissionsBySubject, subjectCommissionsLoading
}) => {
  const [fields, setFields]   = useState({})
  const { t }                 = useTranslation()
  const { getFieldDecorator } = form

  const onSubjectChange = (subjectId) => {
    form.resetFields([`teachers[${index}].commission_id`])
    form.resetFields([`teachers[${index}].role_id`])
    form.resetFields([`teachers[${index}].teacher_id`])

    setFields({ subjectId: subjectId, year: fields.year, period: fields.period })

    if (subjectId && !subjectCommissions(subjectId)) {
      getSubjectComissions(subjectId, { with_teachers: true })
    }
  }

  const subjectCommissions = useCallback(
    (subjectId = fields.subjectId) => (
      commissionsBySubject[parseInt(subjectId)]
    ),
    [commissionsBySubject, fields.subjectId],
  )

  const filteredCommissions = useMemo(
    () => {
      let commissions = subjectCommissions()

      if (!commissions) {
        return []
      }

      if (fields.year) {
        commissions = commissions.filter((commission) => commission.year === fields.year.year())
      }

      if (fields.period) {
        commissions = commissions.filter(
          (commission) => commission.period.toLowerCase() === fields.period.toLowerCase()
        )
      }

      if (fields.role_id) {
        commissions = commissions.filter(
          (commission) => commission.teaching_staff.some(
            (staff) => staff.role.id === parseInt(fields.role_id)
          )
        )
      }

      if (fields.teacher_id) {
        commissions = commissions.filter(
          (commission) => commission.teaching_staff.some(
            (staff) => staff.user.id === parseInt(fields.teacher_id)
          )
        )
      }

      return commissions
    },
    [subjectCommissions, fields]
  )

  const teachingStaffs = useMemo(
    () => {
      if (filteredCommissions.length === 0) {
        return []
      }

      return filteredCommissions.flatMap(commission => commission.teaching_staff)
    }, [filteredCommissions]
  )

  const filteredTeachers = useMemo(
    () => {
      let staffs = teachingStaffs

      if (fields.role_id) {
        staffs = staffs.filter(
          (staff) => staff.role.id === parseInt(fields.role_id)
        )
      }

      const result = []
      const map    = new Map()

      for (const staff of staffs) {
        if(!map.has(staff.user.id)) {
          map.set(staff.user.id, true)
          result.push({
            id:   staff.user.id,
            name: `${staff.user.first_name} ${staff.user.last_name}`
          })
        }
      }

      return result
    }, [teachingStaffs, fields.role_id]
  )

  const filteredRoles = useMemo(
    () => {
      let staffs = teachingStaffs

      if (fields.teacher_id) {
        staffs = staffs.filter(
          (staff) => staff.user.id === parseInt(fields.teacher_id)
        )
      }

      const result = []
      const map    = new Map()

      for (const staff of staffs) {
        if(!map.has(staff.role.id)) {
          map.set(staff.role.id, true)
          result.push({
            id:   staff.role.id,
            name: staff.role.name
          })
        }
      }

      return result
    }, [teachingStaffs, fields.teacher_id]
  )

  return(
    <Row gutter = { 16 }>

      <Col
        xs = { 24 }
        sm = { 24 }
        md = { 24 }
        lg = { 8  }
        xl = { 8  }
      >
        <Item>
          {
            getFieldDecorator(`teachers[${index}].subject_id`, requiredFieldOptions(t))(
              <Select
                filterOption = { filterOption }
                placeholder  = { t('subjects.subject') }
                size         = 'large'
                disabled     = { isLoadingSubjects }
                onChange     = { onSubjectChange }
                showSearch
                showArrow
                allowClear
              >
                { mapRecordsToOptions(subjects) }
              </Select>
            )
          }
        </Item>
      </Col>

      <Col
        xs = { 24 }
        sm = { 24 }
        md = { 24 }
        lg = { 8  }
        xl = { 8  }
      >
        <Item>
          {
            getFieldDecorator(`teachers[${index}].year`)(
              <YearPicker
                disabled = { isLoadingSubjects }
                onChange = { (value) => setFields({ ...fields, year: value }) }
              />
            )
          }
        </Item>
      </Col>

      <Col
        xs = { 24 }
        sm = { 24 }
        md = { 24 }
        lg = { 8  }
        xl = { 8  }
      >
        <Item>
          {
            getFieldDecorator(`teachers[${index}].period`)(
              <PeriodsSelect
                isLoading = { isLoadingSubjects }
                onChange  = { (value) => setFields({ ...fields, period: value }) }
              />
            )
          }
        </Item>
      </Col>

      <Col
        xs = { 24 }
        sm = { 24 }
        md = { 24 }
        lg = { 8  }
        xl = { 8  }
      >
        <Item>
          {
            getFieldDecorator(`teachers[${index}].commission_id`)(
              <Select
                filterOption = { filterOption }
                placeholder  = { (filteredCommissions.length === 0 && !subjectCommissionsLoading) ?  t('public.graphics.empty_commissions') : t('public.graphics.all_commissions')}
                size         = 'large'
                disabled     = { isLoadingSubjects || filteredCommissions.length === 0 }
                loading      = { subjectCommissionsLoading }
                showSearch
                showArrow
                allowClear
              >
                { mapRecordsToOptions(filteredCommissions) }
              </Select>
            )
          }
        </Item>
      </Col>

      <Col
        xs = { 24 }
        sm = { 24 }
        md = { 24 }
        lg = { 8  }
        xl = { 8  }
      >
        <Item>
          {
            getFieldDecorator(`teachers[${index}].role_id`)(
              <Select
                filterOption = { filterOption }
                placeholder  = { t('role.title') }
                size         = 'large'
                disabled     = { isLoadingSubjects || filteredCommissions.length === 0 }
                loading      = { subjectCommissionsLoading }
                onChange     = { (value) => setFields({ ...fields, role_id: value }) }
                showSearch
                showArrow
                allowClear
              >
                { mapRecordsToOptions(filteredRoles) }
              </Select>
            )
          }
        </Item>
      </Col>

      <Col
        xs = { 24 }
        sm = { 24 }
        md = { 24 }
        lg = { 8  }
        xl = { 8  }
      >
        <Item>
          {
            getFieldDecorator(`teachers[${index}].teacher_id`, requiredFieldOptions(t))(
              <Select
                filterOption = { filterOption }
                placeholder  = { t('user.title') }
                size         = 'large'
                disabled     = { isLoadingSubjects || filteredCommissions.length === 0 }
                loading      = { subjectCommissionsLoading }
                onChange     = { (value) => setFields({ ...fields, teacher_id: value }) }
                showSearch
                showArrow
                allowClear
              >
                { mapRecordsToOptions(filteredTeachers) }
              </Select>
            )
          }
        </Item>
      </Col>

    </Row>
  )
}

const mapStateToProps = (state) => (
  {
    subjects:                  subjectsListSelector(state),
    commissionsBySubject:      commissionsBySubjectSelector(state),
    isLoadingSubjects:         subjectsLoadingSelector(state),
    subjectCommissionsLoading: subjectCommissionsLoadingSelector(state)
  }
)

const mapDispatchToProps = (dispatch) => (
  {
    getSubjectComissions: (subjectId, params) => getSubjectComissions(dispatch, subjectId, params)
  }
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterFields)

/*----- PropTypes -----*/
FilterFields.propTypes = {
  form:                      PropTypes.object.isRequired,
  index:                     PropTypes.number.isRequired,
  subjects:                  PropTypes.array.isRequired,
  isLoadingSubjects:         PropTypes.bool.isRequired,
  getSubjectComissions:      PropTypes.func.isRequired,
  commissionsBySubject:      PropTypes.object.isRequired,
  subjectCommissionsLoading: PropTypes.bool.isRequired
}
