/*----- API -----*/
import BASE_URL          from '../BaseUrl'
import { ROLE_RESOURCE } from '../Resources'

/*----- Authorization -----*/
import { getAuthToken } from '../../utils/SessionService'

/*----- Decorators -----*/
import refreshableDecorator from '../../decorators/RefreshableDecorator'

export const getRoles = refreshableDecorator(
  function* (action) {
    return yield fetch(
      `${BASE_URL}/${ROLE_RESOURCE}/`,
      {
        method: 'GET',
        headers: {
          'Content-Type':  'application/json',
          'Authorization': `Bearer ${getAuthToken()}`
        }
      }
    )
  }
)
