/*----- Styles -----*/
import GlobalStyle from '../components/templates/public/PublicStyles'

/*----- Imports -----*/
import React, { useState } from 'react'

/*----- Components -----*/
import Header from '../components/templates/public/Header'
import Nav    from '../components/templates/public/Nav'
import Footer from '../components/templates/public/Footer'

/*----- Antd -----*/
import { Drawer } from 'antd'

const drawerProps = {
  placement: 'right',
  closable:  true,
  zIndex:    10002,
  bodyStyle: {
    boxSizing:  'border-box',
    margin:     0,
    border:     0,
    fontSize:   '100%',
    font:       'inherit',
    background: '#07090c',
    color:      '#fff',
    height:     '100%'
  }
}

export default (Component) => () => {
  const [showNav, setShowNav] = useState(false)

  return(
    <>
      <GlobalStyle />

      <Header setShowNav = { setShowNav } />

      <Drawer
        { ...drawerProps }
        visible = { showNav }
        onClose = { () => setShowNav(false) }
      >
        <Nav />
      </Drawer>

      <div>
        <Component />
      </div>

      <Footer />
    </>
  )
}
