/*----- Actions -----*/
import { SET_SELECTED_USERS } from '../DepartmentsActions'

export const initialState = {
  selectedUsers: []
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_SELECTED_USERS:
      return {
        selectedUsers: action.payload
      }
    default:
      return state;
  }
}