/*----- Actions -----*/
import {
  GET_PUBLIC_USERS,
  GET_PUBLIC_USERS_ERROR,
  GET_PUBLIC_USERS_SUCCESS
} from '../Actions'

const initialState = {
  isLoading: false,
  users:     [],
  error:     null
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_PUBLIC_USERS:
      return {
        ...state,
        isLoading: true
      }
    case GET_PUBLIC_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        users:     action.payload
      }
    case GET_PUBLIC_USERS_ERROR:
      return {
        ...state,
        isLoading: false,
        error:     action.payload
      }
    default:
      return state
  }
}