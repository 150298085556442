/*----- Actions -----*/
import {
  GET_COMMISSION_DETAILS, GET_COMMISSION_DETAILS_SUCCESS, GET_COMMISSION_DETAILS_ERROR
} from '../CommissionActions'

const initialState = {
  isLoading:      false,
  error:          null,
  commissionInfo: {
    name:               '',
    teaching_staff:     [],
    workload:           null,
    subscribers_amount: null,
    comments:           '',
    subject:            {}
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_COMMISSION_DETAILS:
      return {
        ...state,
        isLoading: true
      }
    case GET_COMMISSION_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading:      false,
        commissionInfo: action.payload
      }
    case GET_COMMISSION_DETAILS_ERROR:
      return {
        ...state,
        isLoading: false,
        error:     action.payload
      }
    default:
      return state;
  }
}