/*----- Imports -----*/
import React, { useEffect } from 'react'
import { withRouter }       from 'react-router'
import { connect }          from "react-redux"

/*----- Selectors -----*/
import { loadingCommissionSelector, commissionInfoSelector } from './selectors/CommissionDetailsSelector'
import { loadingRolesSelector, rolesListSelector }           from '../roles/selectors/RolesListSelector'
import { loadingUsersSelector, availableUsersSelector }      from './selectors/AvailableUsersSelector'
import { loadingSubjectsSelector, subjectsListSelector }     from '../subjects/selectors/SubjectsListSelector'
import {
  loadingEditionSelector, editCommissionFieldsSelector, activeTabSelector
} from './selectors/EditCommissionSelector'

/*----- Actions -----*/
import { getSubjects } from '../subjects/SubjectsActions'
import { getRoles }    from '../roles/RolesActions'
import {
  getCommissionDetails, editCommission, setCommissionEditionFields,
  addTeachingStaffFields, deleteTeachingStaffField, deleteCommissionTeachingStaff,
  changeEditionActiveTab, getAvailableUsers
} from './CommissionActions'

/*----- Antd -----*/
import { Form } from 'antd'

/*----- Views -----*/
import MainView from './views/details/MainView'

/*----- Fields -----*/
import { initialState } from './reducers/EditCommissionReducer'

const TEACHING_STAFF_FIELDS = ['user', 'workload', 'position_number', 'dedication', 'category']
const GENERAL_DATA_FIELDS   = [
  'name', 'subject', 'workload', 'subscribers_amount', 'year', 'period', 'comments'
]

const hasErrors = (fieldsError) => Object.keys(fieldsError).some(field => fieldsError[field])

const DetailsContainer = (
  {
    isLoading, commission, getCommissionDetails, match, form, editCommission,
    setCommissionEditionFields, loadingEdition, loadingSubjects, formFields,
    subjectsList, getSubjects, getRoles, loadingRoles, rolesList, addTeachingStaffFields,
    loadingUsers, usersList, getUsers, deleteTeachingStaffField, deleteCommissionTeachingStaff,
    changeEditionActiveTab, activeTab
  } ) => {

  const commissionId = match.params.id

  useEffect(() => {
    getCommissionDetails(commissionId)
  }, [getCommissionDetails, commissionId])

  useEffect(() => {
    getSubjects()
  }, [getSubjects])

  useEffect(() => {
    getRoles()
  }, [getRoles])

  useEffect(() => {
    getUsers(commissionId)
  }, [getUsers, commissionId])

  const submitGeneralData = (event) => {
    event.preventDefault()
    form.validateFields(GENERAL_DATA_FIELDS, { force: true })

    if (!hasErrors(form.getFieldsError(GENERAL_DATA_FIELDS))) {
      let body = form.getFieldsValue(GENERAL_DATA_FIELDS)

      editCommission(commission.subject.id, commissionId, body)
    }
  }

  const submitTeachingStaffData = (event, role) => {
    event.preventDefault()
    form.validateFields(['teaching_staff'], { force: true })

    let errors = form.getFieldError('teaching_staff').map((records) => {
      if (!role.include_category) {
        delete(records.category)
      }

      if (!role.include_dedication) {
        delete(records.dedication)
      }

      return hasErrors(Object.values(records))
    })

    // errors es un arreglo de booleanos. true es que tiene error, false que no tiene.
    if (errors.every((result) => !result)) {
      // Todos son false, así que no hay error.
      let fields = form.getFieldsValue(['teaching_staff'])
      fields.teaching_staff = fields.teaching_staff.map(field => (
        { ...field, role: role.id })
      ).filter(Boolean)

      editCommission(commission.subject.id, commissionId, fields)
    }
  }

  useEffect(
    () => {
      // Se completan los campos generales de la comisión con sus datos de la API.
      let aux_commission
      let result

      aux_commission = Object.assign({}, commission)
      result         = {
        subject: { value: String(commission.subject.id) }
      }

      delete(aux_commission.subject)
      delete(aux_commission.teaching_staff)

      for (let [key, value] of Object.entries(aux_commission)) {
        result[key] = { value: value }
      }

      setCommissionEditionFields(result)

      return () => {
        setCommissionEditionFields(initialState.fields)
      }
    },
    [commission, setCommissionEditionFields]
  )

  return(
    <MainView
      form                          = { form }
      commission                    = { commission }
      isLoading                     = { isLoading && loadingEdition }
      submitTeachingStaffData       = { submitTeachingStaffData }
      submitGeneralData             = { submitGeneralData }
      subjectsList                  = { subjectsList }
      loadingSubjects               = { loadingSubjects }
      rolesList                     = { rolesList }
      loadingRoles                  = { loadingRoles }
      activeTab                     = { activeTab }
      changeTab                     = { changeEditionActiveTab }
      addTeachingStaffFields        = { addTeachingStaffFields }
      deleteTeachingStaffField      = { deleteTeachingStaffField }
      staff_keys                    = { formFields.keys }
      loadingUsers                  = { loadingUsers }
      usersList                     = { usersList }
      deleteCommissionTeachingStaff = { deleteCommissionTeachingStaff }
    />
  )
}

const mapStateToProps = (state) => (
  {
    isLoading:       loadingCommissionSelector(state),
    commission:      commissionInfoSelector(state),
    loadingEdition:  loadingEditionSelector(state),
    formFields:      editCommissionFieldsSelector(state),
    loadingSubjects: loadingSubjectsSelector(state),
    subjectsList:    subjectsListSelector(state),
    loadingRoles:    loadingRolesSelector(state),
    loadingUsers:    loadingUsersSelector(state),
    usersList:       availableUsersSelector(state),
    rolesList:       rolesListSelector(state),
    activeTab:       activeTabSelector(state)
  }
)

const mapDispatchToProps = (dispatch) => (
  {
    getCommissionDetails:              (commissionId) => getCommissionDetails(dispatch, commissionId),
    editCommission: (subjectId, commissionId, fields) => editCommission(dispatch, subjectId, commissionId, fields),
    setCommissionEditionFields:              (fields) => setCommissionEditionFields(dispatch, fields),
    getSubjects:                                   () => getSubjects(dispatch),
    getRoles:                                      () => getRoles(dispatch),
    getUsers:                          (commissionId) => getAvailableUsers(dispatch, commissionId),
    addTeachingStaffFields:                        () => addTeachingStaffFields(dispatch),
    deleteTeachingStaffField:             (field_key) => deleteTeachingStaffField(dispatch, field_key),
    changeEditionActiveTab:                  (newTab) => changeEditionActiveTab(dispatch, newTab),
    deleteCommissionTeachingStaff: (subjectId, commissionId, userId) => deleteCommissionTeachingStaff(dispatch, subjectId, commissionId, userId)
  }
)

const ConnectedDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create(
  {
    name: 'new_commission_form',
    onFieldsChange({ setCommissionEditionFields }, changedFields, allFields) {
      if (changedFields.teaching_staff) {
        let updatedStaff = { ...allFields.teaching_staff }
        
        // Esto nos permite hacer el merge un nivel más en profundidad,
        // lo cual es necesario debido a la escructura del campo teaching_staff
        let changes = { ...changedFields.teaching_staff }
        
        for (const [key, value] of Object.entries(changes)) {
          updatedStaff[key] = {
            ...updatedStaff[key],
            ...value
          }
        }

        setCommissionEditionFields({ teaching_staff: updatedStaff })
      }
      else {
        setCommissionEditionFields(changedFields)
      }
    },
    mapPropsToFields({ formFields }) {
      let nested_fields = {}

      // Por cada "key" que el usuario agregó
      formFields.keys.forEach(elem => {
        // Tenemos que asegurarnos que formFields.teaching_staff[elem][field] exista,
        // eso lo hacemos agregando una nueva clave cuando agregamos una nueva fila de inputs

        // Iteramos sobre cada campo de la relación
        TEACHING_STAFF_FIELDS.forEach(field => {
          nested_fields[`teaching_staff[${elem}].${field}`] = Form.createFormField(
            { 
              ...formFields.teaching_staff[elem][field]
            }
          )
        })
      })

      return {
        name:               Form.createFormField({ ...formFields.name }),
        subject:            Form.createFormField({ ...formFields.subject }),
        workload:           Form.createFormField({ ...formFields.workload }),
        subscribers_amount: Form.createFormField({ ...formFields.subscribers_amount }),
        comments:           Form.createFormField({ ...formFields.comments }),
        year:               Form.createFormField({ ...formFields.year }),
        period:             Form.createFormField({ ...formFields.period }),
        ...nested_fields
      }
    }
  }
)(withRouter(DetailsContainer)))

ConnectedDetailsContainer.defaultProps = {
  permission:   'show',
  model:        'Commission',
  defaultRoute: '/admin'
}

export default ConnectedDetailsContainer