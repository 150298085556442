/*----- Saga -----*/
import { call, takeLatest, put } from 'redux-saga/effects'
import { push }                  from 'connected-react-router'

/*----- Actions -----*/
import {
  DELETE_COMMISSION_TEACHING_STAFF, DELETE_COMMISSION_TEACHING_STAFF_ERROR, DELETE_COMMISSION_TEACHING_STAFF_SUCCESS, GET_COMMISSION_DETAILS
} from '../CommissionActions'

/*----- Endpoint -----*/
import { deleteCommissionTeachingStaff } from '../../../../api/endpoints/Commissions'

/*----- Notifications -----*/
import loadingNotification from '../../../../components/commons/notifications/LoadingNotification'
import successNotification from '../../../../components/commons/notifications/SuccessNotification'
import notifyError         from '../../../../components/commons/notifications/ErrorNotification'

export function* shootDeletion(action) {
  yield loadingNotification()

  try {
    const response = yield call(deleteCommissionTeachingStaff, action)
    let body
    let type

    if (response.status >= 200 && response.status < 300) {
      type = DELETE_COMMISSION_TEACHING_STAFF_SUCCESS

      yield put(
        {
          type:    GET_COMMISSION_DETAILS,
          payload: {
            commissionId: action.payload.commissionId
          }
        }
      )

      yield successNotification()
    }
    else {
      body = yield response.json()
      type = DELETE_COMMISSION_TEACHING_STAFF_ERROR

      yield notifyError(body.error.message)
      yield put(push('/admin/commissions'))
    }

    yield put(
      {
        type:    type,
        payload: body
      }
    )
  }
  catch (error) {
    yield notifyError('¡Ha ocurrido un error inesperado!')
    yield put(
      {
        type:    DELETE_COMMISSION_TEACHING_STAFF_ERROR,
        payload: error.message
      }
    )
  }
}

export default function* () {
  yield takeLatest(DELETE_COMMISSION_TEACHING_STAFF, shootDeletion)
}
